import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import React, {useRef} from "react";
import {Alert, Button, Dialog, Icon} from "./shoelace";
import {RootState} from "../store";
import {connect, ConnectedProps} from "react-redux";

import './ImageCropDialog.scss';
import {UserImageType} from "./ImageUploadTabs";

const mapState = (state: RootState) => ({
});

const mapDispatch = {
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
  imageDataUrl: string,
  open: boolean,
  saveCroppedImage: (blob: Blob) => void,
  onCancelCrop: () => void,
  userImageType: UserImageType
}

const ImageCropDialog: React.FC<Props> = props => {
  const cropperRef = useRef<HTMLImageElement>(null);
  const dialogRef = useRef<typeof Dialog>(null);

  const onConfirmCrop = () => {
    const imageElement: any = cropperRef?.current;
    const cropper: any = imageElement?.cropper;
    cropper.getCroppedCanvas().toBlob((blob: Blob) => {
      props.saveCroppedImage(blob);
    });
  };

  return (
    <Dialog label="Crop Image" className="crop-dialog" open={props.open} ref={dialogRef}>
      <div className="crop-view">
        <Cropper
          src={props.imageDataUrl}
          style={{ height: 400, width: "100%" }}
          ref={cropperRef}
        />
      </div>
        {props.userImageType === 'logo' ?
          <Alert className="crop-description" variant="primary" open>
            <Icon slot="icon" name="info-circle"></Icon>
            <span>The height of your logo will be fixed at {window.getComputedStyle(document.documentElement).getPropertyValue('--header-height')} when it is displayed on your storefront.<br/>To maximise its size, you can <b>crop your image so the edges of your logo are touching the edges of the image.</b></span>
          </Alert>
            : null}
      <div slot="footer" className="dialog-buttons">
        <Button className={"neutral small"} onClick={props.onCancelCrop}>Cancel</Button>
        <Button className={"submit small"} onClick={onConfirmCrop}>Confirm</Button>
      </div>
    </Dialog>
  );
}

export default connector(ImageCropDialog);

