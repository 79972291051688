import React, {RefObject} from "react";
import {ColorPicker, Icon} from "./shoelace";
import OptionsPanel from "./OptionsPanel";
import OptionDropdown from "./OptionDropdown";
import PreviewPlanCardPanel from "./PreviewPlanCardPanel";
import {Plan} from "../store/shared/plan/types";
import LoadingButton from "./shared/LoadingButton";
import MarkdownTextArea from "./shared/MarkdownTextArea";
import {Prompt} from "react-router";
import {getBfAppUrl} from "../utils/utils";
import ImageUploadTabs from "./ImageUploadTabs";
import PaletteIcon from "../assets/icons/palette-fill.svg";
import type SlColorPickerElement from "@shoelace-style/shoelace/dist/components/color-picker/color-picker";

import "./EditPlanForm.scss";

interface Props {
  onSubmit: (formData: EditPlanFormData) => Promise<unknown>;
  plan: Plan;
}

interface State {
  formData: EditPlanFormData;
  submitting: boolean;
  isLoading: boolean;
}

type InputName = "cardText" | "cardColor" | "imageUrl";
type Inputs<T> = {
  [name in InputName]: T;
};
export type EditPlanFormData = Inputs<string>;

const defaultColor = "#c9c3f5";

class EditPlanForm extends React.Component<Props, State> {
  private readonly cardColorInputRef: RefObject<SlColorPickerElement>;
  private readonly initialColour: string;

  constructor(props: Props) {
    super(props);

    this.cardColorInputRef = React.createRef<SlColorPickerElement>();
    this.initialColour = this.props.plan.colour
      ? this.props.plan.colour.toLowerCase()
      : defaultColor;

    this.state = {
      formData: {
        cardText: this.props.plan.cardText,
        cardColor: this.initialColour,
        imageUrl: this.props.plan.imageUrl ?? "",
      },
      submitting: false,
      isLoading: false,
    };
  }

  private updateFormData(name: InputName, value: string) {
    this.setState((state: State) => {
      return {
        formData: { ...state.formData, [name]: value },
      };
    });
  }
  private updateCardColor(value: string) {
    this.updateFormData("cardColor", value);
    document.documentElement.style.setProperty("--color-card-preview", value);
  }

  private onColorInputUpdate = () => {
    const newValue = this.cardColorInputRef.current!.parseColor(this.cardColorInputRef.current!.value)?.hex;
    if (newValue) {
      this.updateCardColor(newValue);
      if (this.cardColorInputRef.current!.getFormattedValue('hex') !== newValue) {
        //What a mess. I have no clue why this is needed.
        this.cardColorInputRef.current!.setColor(newValue);
      }
    }

    // For some reason getFormattedValue('hex') returned #ffffff on the first call.
    //this.updateCardColor(this.cardColorInputRef.current!.getFormattedValue('hex'));
  };
  private onDetailsInputUpdate = (markdown: string) => {
    this.updateFormData("cardText", markdown);
  };
  private onImageUrlInputUpdate = (url: string) => {
    this.updateFormData("imageUrl", url);
  };
  private attachListeners = () => {
    this.cardColorInputRef.current!.addEventListener(
      "sl-change",
      this.onColorInputUpdate
    );
  };

  private userHasMadeChanges = () => {
    return (
      !this.state.submitting && // Check that we are not in the process of submitting the changes
      (this.props.plan.cardText !== this.state.formData.cardText || // Card text has changed
        (this.props.plan.colour &&
          this.props.plan.colour.toLowerCase() !==
            this.state.formData.cardColor.toLowerCase()) || // Colour has changed from previous custom colour
        (!this.props.plan.colour &&
          this.state.formData.cardColor.toLowerCase() !== defaultColor))
    ); // Colour was not previously set and has since been changed
  };

  private onSubmit() {
    this.setState({ submitting: true, isLoading: true });
    this.props.onSubmit(this.state.formData).then(
      (response) => {
        this.setState({ submitting: false, isLoading: false });
      },
      (error) => {
        this.setState({ submitting: false, isLoading: false });
      }
    );
  }

  componentDidMount() {
    // Set initial card color
    document.documentElement.style.setProperty(
      "--color-card-preview",
      this.state.formData.cardColor
    );
    this.attachListeners();
  }

  shouldComponentUpdate(
    nextProps: Readonly<Props>,
    nextState: Readonly<State>,
    nextContext: any
  ): boolean {
    this.forceUpdate();
    return true;
  }

  render() {
    return (
      <>
        <Prompt
          when={this.userHasMadeChanges()}
          message="You have unsaved plan changes. Are you sure you want to leave this page?"
        />
        <div
          className="edit-plan-form"
          hidden={this.props.plan.bfPlanPath === undefined}
        >
          <div className={"edit-plan-form-content"}>
            <OptionsPanel>
              <OptionDropdown title="Card Content" icon="file-text-fill">
                {this.props.plan.hasUsage ||
                this.props.plan.trialDescription ? (
                  <div className="warning">
                    <div className="icon-container">
                      <Icon name="exclamation-circle" />
                    </div>
                    <div className="warning-text-container">
                      {this.props.plan.hasUsage ? (
                        <div>
                          This plan has a usage component, which the card may
                          need to mention.
                        </div>
                      ) : null}
                      {this.props.plan.trialDescription ? (
                        <div>
                          This plan has a free trial. It will appear during
                          checkout as "{this.props.plan.trialDescription}".
                        </div>
                      ) : null}
                      <div>
                        <a
                          href={`${getBfAppUrl()}#/plans/view/${
                            this.props.plan.bfId
                          }`}
                        >
                          View in Billforward
                        </a>
                      </div>
                    </div>
                  </div>
                ) : null}
                <MarkdownTextArea
                  value={this.state.formData.cardText}
                  onChange={this.onDetailsInputUpdate}
                ></MarkdownTextArea>
              </OptionDropdown>
              <OptionDropdown title="Image" icon="image-fill">
                <ImageUploadTabs
                  initialSrc={this.state.formData.imageUrl}
                  onSuccess={this.onImageUrlInputUpdate}
                  userImageType="plan"
                ></ImageUploadTabs>
              </OptionDropdown>
              <OptionDropdown
                title="Card Color"
                iconImg={<img src={PaletteIcon} alt="Remove plan" />}
                arrowReplacement={
                  <div className="color-picker-container">
                    <ColorPicker
                      className="color-picker"
                      ref={this.cardColorInputRef} //Same ref as inputRefs.cardColor
                      // value={this.state.formData.cardColor}
                      value={this.initialColour}
                      swatches={[]}
                      size="small"
                    ></ColorPicker>
                  </div>
                }
              ></OptionDropdown>
            </OptionsPanel>
            {this.props.plan.bfPlanPath === undefined ? null : (
              <PreviewPlanCardPanel
                plan={{
                  ...this.props.plan,
                  ...this.state.formData,
                  colour: this.state.formData.cardColor,
                }}
                customDetailsMarkdown={this.state.formData.cardText}
              ></PreviewPlanCardPanel>
            )}
          </div>
          <div className={"save-button-container"}>
            <LoadingButton
              onSubmit={() => this.onSubmit()}
              isLoading={this.state.isLoading}
              noCustomStyle
            >
              Save
            </LoadingButton>
          </div>
        </div>
      </>
    );
  }
}

export default EditPlanForm;
