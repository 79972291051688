import { FC, useCallback, useState } from "react";
import { Product } from "../store/shared/plan/types";
import { BfApiPlan } from "../api/types";
import { Icon } from "./shoelace";
import { getPathWithoutProductName } from "../utils/utils";
import { useDispatch, useSelector } from "react-redux";
import {
  selectFlexcheckPlans,
  selectSelectedPlanToAdd,
} from "../store/merchant/plan/selectors";
import { updateSelectedPlanToAdd as updateSelectedPlanToAddAction } from "../store/merchant/plan/actions";
import { openPanelStart } from "../store/panel/actions";
import { PanelTypes } from "../store/panel/types";

interface Props {
  product: Product;
}

const ProductRow: FC<Props> = ({ product }) => {
  const dispatch = useDispatch();
  const flexcheckPlans = useSelector(selectFlexcheckPlans);
  const selectedPlanToAdd = useSelector(selectSelectedPlanToAdd);
  const [open, setOpen] = useState(false);

  const updateSelectedPlanToAdd = useCallback(
    (plan) => {
      dispatch(updateSelectedPlanToAddAction(plan));
    },
    [dispatch]
  );

  const toggleOpen = () => {
    const selectedPlanIsInProduct =
      product.plans.find((plan) => plan.path === selectedPlanToAdd.path) !==
      undefined;
    // If one of the plans in this product is selected, don't collapse the product (so user can always see the currently selected plan)
    if (!(selectedPlanIsInProduct && open)) {
      setOpen((prevOpen) => !prevOpen);
    }
  };

  const isPlanAdded = (path: string) => {
    return (
      flexcheckPlans.find(
        (plan: { bfPlanPath: string }) => plan.bfPlanPath === path
      ) !== undefined
    );
  };

  const openAddPlanPanel = (planId: string) => {
    dispatch(openPanelStart({ panelType: PanelTypes.AddPlan, planId }));
  };

  const onPlanSelect = (
    plan: BfApiPlan,
    isSelected: boolean,
    alreadyAdded: boolean
  ) => {
    if (alreadyAdded) return;
    updateSelectedPlanToAdd(isSelected ? ({} as BfApiPlan) : plan);
    openAddPlanPanel(plan.id);
  };

  return (
    <tbody className={`product-row-container ${open ? "open" : "closed"}`}>
      <tr className="plans-table-add-row product-row" onClick={toggleOpen}>
        <td className="dropdown-arrow">
          <div className="dropdown-arrow-icon-container">
            <Icon name="caret-right-fill"></Icon>
          </div>
        </td>
        <td className="path">/{product.name}</td>
        <td className="name"></td>
      </tr>
      {open
        ? (product.plans as Array<BfApiPlan>).map((plan, j) => {
            const isSelected = plan.path === selectedPlanToAdd.path;
            const alreadyAdded = isPlanAdded(plan.path);

            return (
              <tr
                className={`plans-table-add-row plan-row ${
                  alreadyAdded ? "added" : ""
                }`}
                key={plan.path}
                onClick={() => onPlanSelect(plan, isSelected, alreadyAdded)}
              >
                <td className="enabled">
                  {alreadyAdded && (
                    <Icon className="added-icon" name="check2"></Icon>
                  )}
                </td>
                <td className="path">
                  <span>/{getPathWithoutProductName(plan.path)}</span>
                </td>
                <td className="name">{plan.displayName || plan.name}</td>
              </tr>
            );
          })
        : null}
    </tbody>
  );
};
export default ProductRow;
