import {Alert, AlertActionTypes, AlertState, REMOVE_ALERT, SHOW_ALERT} from "./types";
import {generateUUID} from "../../../utils/utils";
import {RESET_USER} from "../../user/resetUser";

const initialState: AlertState = {
  lastAlert: {} as Alert
}

export function alertReducer(state: AlertState = initialState, action: AlertActionTypes): AlertState {
  switch (action.type) {
    case SHOW_ALERT:
      return { ...state, lastAlert: {
        type: action.payload.type,
        message: action.payload.message,
        id: generateUUID()
      }};
    case REMOVE_ALERT:
      if (state.lastAlert.id === action.payload) {
        return initialState;
      } else {
        return state;
      }
    case RESET_USER:
      return initialState;
    default:
      return state;
  }
}
