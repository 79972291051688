import React from "react";
import {RootState} from "../store";
import {connect, ConnectedProps} from "react-redux";

import './PreviewPlanCardPanel.scss';
import PlanCard from "./PlanCard";
import {Plan} from "../store/shared/plan/types";
import PlanCardMarkdown from "./PlanCardMarkdown";

const mapState = (state: RootState) => ({
  selectedPlanToAdd: state.main.merchant.plan.selectedPlanToAdd
});

const mapDispatch = {
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
  plan: Plan,
  customDetailsMarkdown: string
}

type State = {
}

class PreviewPlanCardPanel extends React.Component<Props, State> {
  render() {
    return (
      <div className="preview-plan-card-panel-container">
        <h2 className="preview-plan-card-panel-title">Preview</h2>
        <div className="preview-plan-card-panel">
          <PlanCard plan={this.props.plan} handleBuy={() => {}} preview>
            <PlanCardMarkdown className="details-markdown" content={this.props.customDetailsMarkdown} />
          </PlanCard>
        </div>
      </div>
    );
  }
}

export default connector(PreviewPlanCardPanel);
