import React, { FC } from "react";
import MerchantLoginForm from "../../MerchantLoginForm";
import LoginHeader from "../../LoginHeader";
import AlertStack from "../../shared/AlertStack";

interface Props {
  nextPage: Function;
}

const Login: FC<Props> = ({ nextPage }) => {
  return (
    <div className="user-container">
      <LoginHeader />
      <div className="page">
        <MerchantLoginForm nextPage={nextPage}></MerchantLoginForm>
      </div>
      <AlertStack />
    </div>
  );
};

export default Login;
