import {
  ADD_TO_CART,
  Cart,
  CartActionTypes,
  CartLine,
  CartState,
  CHECKOUT_ONE,
  CLEAR_CART,
  LOGGED_IN_PAYMENT_METHOD,
  REMOVE_ONE,
  SET_CART_ID,
  SUCCESSFUL_PAYMENT_METHOD,
  UPDATE_CART_FROM_QUOTE,
} from "./types";
import {generateUUID} from "../../../utils/utils";
import {CustomizedPlan} from "../../shared/plan/types";
import deepEqual from "deep-equal";
import {RESET_USER} from "../resetUser";

const initialState: CartState = {
  cartId: "",
  cart: [],
  checkedOut: [],
};

const samePlan = (plan1: CustomizedPlan, plan2: CustomizedPlan) => {
  if (plan1.id !== plan2.id) {
    return false;
  }
  if (!deepEqual(plan1.pricing, plan2.pricing)) {
    return false;
  }
  if (
    !deepEqual(
      plan1.customizedPricingComponents,
      plan2.customizedPricingComponents
    )
  ) {
    return false;
  }
  return deepEqual(plan1.couponCodes, plan2.couponCodes);
};

export const getIdenticalPlansInCart = (cart: Cart, plan: CustomizedPlan) => {
  // Count up the number of cart lines that are equivalent to the plan, i.e. with all the same pricing component customizations
  return cart.filter((cartLine) => samePlan(cartLine.plan, plan));
};

const addOneToCart = (cart: Cart, plan: CustomizedPlan): Cart => {
  return [...cart, { id: generateUUID(), plan }];
};

const removeOneFromCart = (cart: Cart, id: string) => {
  let returnCart = [...cart];
  const cartLineIndex = returnCart.findIndex((line) => line.id === id);

  if (cartLineIndex >= 0) {
    // If plan is in cart, remove one instance
    returnCart.splice(cartLineIndex, 1);
  }
  // If plan is not in cart, do nothing
  return returnCart;
};

const addOneToCheckedOut = (checkedOut: Cart, cartLine: CartLine) => {
  return [...checkedOut, cartLine];
};

function mergePricingIntoCart(currentCart: Cart, cartLineId: string, quotedPlan: CustomizedPlan) {
  const newCart: Cart = [];
  let done = false;
  for (const currentLine of currentCart) {
    if (currentLine.id === cartLineId) {
      done = true;
      const newLine: CartLine = {
        ...currentLine,
        plan: {
          ...currentLine.plan,
          price: quotedPlan.price,
          bfAccountId: quotedPlan.bfAccountId
        }
      };
      newCart.push(newLine);
    } else {
      newCart.push(currentLine);
    }
  }
  if (!done) {
    console.error("Could not find line id " + quotedPlan.id);
  }
  return newCart;
}

export function cartReducer(
  state: CartState = initialState,
  action: CartActionTypes
): CartState {
  switch (action.type) {
    case ADD_TO_CART:
      //If the user adds to their cart then we can remove their existing checkedOut (they're considered to be historic at that point)
      return {
        ...state,
        cart: addOneToCart(state.cart, {
          ...action.payload.plan,
          couponCodes: action.payload.couponCodes.filter(Boolean)
        }),
        checkedOut: [],
      };
    case REMOVE_ONE:
      return { ...state, cart: removeOneFromCart(state.cart, action.payload) };
    case CLEAR_CART:
    case RESET_USER:
      return initialState;
    case SUCCESSFUL_PAYMENT_METHOD:
    case LOGGED_IN_PAYMENT_METHOD:
      return { ...state, paymentMethodId: action.payload };
    case SET_CART_ID:
      return { ...state, cartId: action.payload };
    case CHECKOUT_ONE:
      //Move line from cart to checkedOut
      return {
        ...state,
        cart: removeOneFromCart(state.cart, action.payload.id),
        checkedOut: addOneToCheckedOut(state.checkedOut, action.payload),
      };
    case UPDATE_CART_FROM_QUOTE:
      return {
        ...state,
        cart: mergePricingIntoCart(state.cart, action.id, action.quote)
      }
    default:
      return state;
  }
}
