import {
  ADD_TO_CART,
  CartActionTypes,
  CartLine,
  CHECKOUT_ONE,
  CLEAR_CART,
  LOGGED_IN_PAYMENT_METHOD,
  REMOVE_ONE,
  SET_CART_ID,
  SUCCESSFUL_PAYMENT_METHOD,
  UPDATE_CART_FROM_QUOTE,
} from "./types";
import {CustomizedPlan} from "../../shared/plan/types";

export function addToCart(
  plan: CustomizedPlan,
  couponCodes: string[] = []
): CartActionTypes {
  return {
    type: ADD_TO_CART,
    payload: {
      plan,
      couponCodes
    },
  };
}

export function updatePlan(
    plan: CustomizedPlan,
    couponCodes: string[] = []
): CartActionTypes {
  return {
    type: ADD_TO_CART,
    payload: {
      plan,
      couponCodes
    },
  };
}

export function updateCartFromQuote(id: string, quotedLine: CustomizedPlan) {
  return {
    type: UPDATE_CART_FROM_QUOTE,
    quote: quotedLine,
    id: id
  };
}

export function removeOne(id: string): CartActionTypes {
  return {
    type: REMOVE_ONE,
    payload: id,
  };
}

export function clearCart(): CartActionTypes {
  return {
    type: CLEAR_CART,
  };
}

export function updateCardId(id: string): CartActionTypes {
  return {
    type: SET_CART_ID,
    payload: id,
  };
}

export function loggedInPaymentMethod(id: string): CartActionTypes {
  return {
    type: LOGGED_IN_PAYMENT_METHOD,
    payload: id,
  };
}

export function successfulPaymentMethod(id: string): CartActionTypes {
  return {
    type: SUCCESSFUL_PAYMENT_METHOD,
    payload: id,
  };
}

export function checkoutOne(line: CartLine): CartActionTypes {
  return {
    type: CHECKOUT_ONE,
    payload: line,
  };
}
