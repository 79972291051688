import React from "react";
import {Spinner} from "../shoelace";
import {RootState} from "../../store";
import {connect, ConnectedProps} from "react-redux";
import {showAlert} from "../../store/shared/alert/actions";
import {getAlertFromApiErrorResponse, getBasicAlert} from "../../utils/utils";
import {SquarePaymentForm} from 'react-square-payment-form'
import 'react-square-payment-form/lib/default.css';
import {SqCardData, SqError} from "react-square-payment-form/lib/components/models";
import {
  CreditCardCVVInput,
  CreditCardExpirationDateInput,
  CreditCardNumberInput,
  CreditCardPostalCodeInput
} from "react-square-payment-form/lib";
import SquareLoadingButton from "./SquareLoadingButton";
import {apiGetSquareConfig, bfApi} from "../../api";
import SquareInputWrapper from "./SquareInputWrapper";
import {PaymentMethodFormContext} from "./PaymentMethodSwitch";

const mapState = (state: RootState) => ({
  currency: state.main.user.plan.currency,
  userDetails: state.main.user.userDetails.details,
  cart: state.main.user.cart.cart,
  bfAccountId: state.main.user.userDetails.bfAccountId,
  bfPublicToken: state.main.storefront.storefront.publicToken
});

const mapDispatch = {
  showAlert: showAlert,
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
  onSuccessfulSubmit: (id: string) => void,
  onSubmitExtraDetails: () => boolean,
  extraDetails: {
    country: string,
    city: string,
    addressLine1: string,
    addressLine2: string,
    postcode: string
  }
}

type SquareFormConfigLoading = {
  type: "loading"
}
type SquareFormConfigLoaded = {
  type: "loaded",
  appId: string,
  locationId: string
}

type SquareFormConfig = SquareFormConfigLoading | SquareFormConfigLoaded;

type InputName = 'cardNumber' | 'cvv' | 'expirationDate' | 'postalCode';
type InputErrors = {
  [name in InputName]?: SqError
}

type State = {
  formIsLoading: boolean,
  isLoading: boolean,
  squareConfiguration: SquareFormConfig,
  inputErrors: InputErrors,
  hasError: boolean
}

const oldXHROpen = window.XMLHttpRequest.prototype.open;
// let potentialErrorDescription = '';

class SquarePaymentMethodForm extends React.Component<Props, State> {
  // Called once this.context.onCreateNonce() returns a value
  private cardNonceResponseReceived = (errors: Array<SqError> | null, nonce: string, cardData: SqCardData, buyerVerificationToken: string | undefined) => {
    this.setState({isLoading: true});
    if (!this.props.onSubmitExtraDetails()) {
      this.setState({
        hasError: true,
        isLoading: false
      });
    } else if (errors) {
      let newInputErrors: InputErrors = {};
      for (let error of errors) {
        try {
          newInputErrors[error.field as InputName] = error;
        } catch {
          this.props.showAlert(getBasicAlert('There was a problem processing your payment method.', error.message));
        }
      }
      this.setState({
        inputErrors: newInputErrors,
        hasError: true,
        isLoading: false
      });
    } else {
      this.setState({inputErrors: {}});
        bfApi.post('v1/tokenization/auth-capture', {
          headers: {
            "Authorization": "Bearer " + this.props.bfPublicToken,
            "Content-Type": "application/json"
          },
          body: JSON.stringify(this.props.bfAccountId
            ? {
              "@type": "SquareAuthCaptureRequest",
              gateway: "Square",
              defaultPaymentMethod: true,
              accountID: this.props.bfAccountId,
              firstName: this.props.userDetails.firstName,
              lastName: this.props.userDetails.lastName,
              cardNonce: nonce,
              buyerVerificationToken: buyerVerificationToken,
              locationId: (this.state.squareConfiguration as SquareFormConfigLoaded).locationId
            }
            : {
              "@type": "SquareAuthCaptureRequest",
              gateway: "Square",
              defaultPaymentMethod: true,
              email: this.props.userDetails.email,
              firstName: this.props.userDetails.firstName,
              lastName: this.props.userDetails.lastName,
              cardNonce: nonce,
              buyerVerificationToken: buyerVerificationToken,
              locationId: (this.state.squareConfiguration as SquareFormConfigLoaded).locationId,
              billingAddress: {
                firstName: this.props.userDetails.firstName,
                lastName: this.props.userDetails.lastName,
                country: this.props.extraDetails.country,
                postcode: this.props.extraDetails.postcode,
                city: this.props.extraDetails.city,
                addressLine1: this.props.extraDetails.addressLine1
              }})
        }).then(response => {
          this.setState({isLoading: false});
          response.json()
              .then(json => {
                this.props.onSuccessfulSubmit(json.results[0].id);
              });
        }, error => {
          this.props.showAlert(getAlertFromApiErrorResponse(error, 'There was a problem processing your payment method.'));
          this.setState({
            hasError: true,
            isLoading: false
          });
        });
    }
  }

  private readonly focusField = () => 'cardNumber';

  private createVerificationDetails = () => {
    return {
      intent: "STORE",
      billingContact: {
        familyName: this.props.userDetails.lastName,
        givenName: this.props.userDetails.firstName,
        email: this.props.userDetails.email,
        country: this.props.extraDetails.country,
        city: this.props.extraDetails.city,
        addressLines: [
          this.props.extraDetails.addressLine1,
          this.props.extraDetails.addressLine2
        ],
        postalCode: this.props.extraDetails.postcode
      }
    }
  }

  private paymentFormLoaded = () => {
    this.setState({formIsLoading: false});
  }

  // private extraDetailsSubmit = () => {
  //     return this.extraDetailsFormRef.onSubmit();
  // }
  // private extraDetailsFormRef: any;

  constructor(props: Props) {
    super(props);

    this.state = {
      isLoading: false,
      formIsLoading: true,
      squareConfiguration: { type: "loading" },
      inputErrors: {},
      hasError: false
    }
  }

  private mountHttpListener = () => {
    // Change XMLHttpRequest prototype to analyse every http response
    // Looks for Square proxy notifications, and if one of them is an error, assumes that nonce generation has failed
    ((component: SquarePaymentMethodForm) => {
      window.XMLHttpRequest.prototype.open = function(method: any, url: any, async?: any, user?: any, password?: any) {
        this.addEventListener('load', function() {
          const request: XMLHttpRequest = this;
          let squareApiUrlRegex = /^(https:\/\/api\.squareup(|sandbox)\.com\/[\d.]*\/threeds\/proxy-message\/NONCE\/)/g;
          if (squareApiUrlRegex.test(request.responseURL)) {
            const responseText = JSON.parse(request.responseText);
            // if (responseText.errorDescription) {
            //   potentialErrorDescription = responseText.errorDescription
            // }
            if (responseText.result === 'ERROR') {
              // component.props.showAlert(getBasicAlert('There was a problem processing your payment method.', potentialErrorDescription));
              component.props.showAlert(getBasicAlert('There was a problem processing your payment method.', 'Your card was declined.'));
              component.setState({hasError: true, isLoading: false});
            }
          }
        });

        return oldXHROpen.apply(this, [method, url, async, user, password]);
      }
    })(this);
  }

  private dismountHttpListener = () => {
    window.XMLHttpRequest.prototype.open = oldXHROpen;
  }

  componentDidMount() {
    apiGetSquareConfig().then(response => {
      this.setState({
        squareConfiguration: {
          type: "loaded",
          appId: response.data.applicationId,
          locationId: response.data.locationId
        }
      });
    }, error => {
      this.props.showAlert(getAlertFromApiErrorResponse(error, 'There was a problem loading the payment form.'));
      this.setState({formIsLoading: false});
    });
    this.mountHttpListener();
  }

  componentWillUnmount() {
    this.dismountHttpListener();
  }

  private inputStyles = [{
    backgroundColor:"#F9FAFD",
    fontFamily:"sans-serif",
    fontSize:"16px",
    color:"#9E9E9E",
    placeholderColor:"#9E9E9E",
    lineHeight:"18px",
    padding: "10px 12px",
    fontWeight: "lighter"
  }]

  render() {
    return (
      <div className={`payment-method-form ${this.state.formIsLoading ? 'loading':''}`}>
        {this.state.squareConfiguration.type === "loading" ? null : (
            <SquarePaymentForm
                sandbox={window.REACT_APP_DEV_MODE === 'true'}
                applicationId={this.state.squareConfiguration.appId}
                locationId={this.state.squareConfiguration.locationId}
                cardNonceResponseReceived={this.cardNonceResponseReceived}
                createVerificationDetails={this.createVerificationDetails}
                paymentFormLoaded={this.paymentFormLoaded}
                focusField={this.focusField}
                apiWrapper="reactjs"
                formId="sq-payment-form"
                inputStyles={this.inputStyles}
                placeholderCVV={' '}
                placeholderPostal={' '}
                placeholderCreditCard={' '}
            >
              <fieldset className="sq-fieldset form-section">
                <SquareInputWrapper error={this.state.inputErrors.cardNumber}>
                  <CreditCardNumberInput/>
                </SquareInputWrapper>

                <div className="sq-form-third">
                  <SquareInputWrapper error={this.state.inputErrors.cvv}>
                    <CreditCardCVVInput/>
                  </SquareInputWrapper>
                </div>

                <div className="sq-form-third">
                  <SquareInputWrapper error={this.state.inputErrors.expirationDate}>
                    <CreditCardExpirationDateInput/>
                  </SquareInputWrapper>
                </div>

                <div className="sq-form-third">
                  <SquareInputWrapper error={this.state.inputErrors.postalCode}>
                    <CreditCardPostalCodeInput label="Postcode"/>
                  </SquareInputWrapper>
                </div>
              </fieldset>

              <div className={"form-footer"}>
                <PaymentMethodFormContext.Consumer>
                  {context =>
                    <SquareLoadingButton
                      resetHasError={(() => {
                        this.setState({hasError: false})
                      })}
                      hasError={this.state.hasError}
                    >{context.buttonText}</SquareLoadingButton>
                  }
                </PaymentMethodFormContext.Consumer>
              </div>
            </SquarePaymentForm>
        )}

        <div className="payment-method-spinner-container" hidden={!this.state.formIsLoading}>
          <Spinner className="payment-method-spinner custom"></Spinner>
        </div>
    </div>
    )
  }
}

export default connector(SquarePaymentMethodForm);
