export interface PlanGroup {
  /** Plan group identifier */
  bfPlanPathPrefix: string;
  /** Display index */
  index: number;
  /** Plan group card text displayed on store page */
  cardText: string | null;
  /** Description to be displayed on group page */
  description: string | null;
  /** Colour hex to override plans */
  colour: string | null;
  /** Image URL to be shown as hero */
  heroImg: string;
  /** Image URL to be shown in plan group card */
  cardImg: string;
  /** Plan group category */
  category: string | null;
}

export const UPDATE_PLAN_GROUP = "UPDATE_PLAN_GROUP";
export const UPDATE_PLAN_GROUPS = "UPDATE_PLAN_GROUPS";
export const RESET_PLAN_GROUP = "RESET_PLAN_GROUP";

interface UpdatePlanGroupAction {
  type: typeof UPDATE_PLAN_GROUP;
  payload: PlanGroup;
}

interface UpdatePlanGroupsAction {
  type: typeof UPDATE_PLAN_GROUPS;
  payload: PlanGroup[];
}

interface ResetPlanGroupAction {
  type: typeof RESET_PLAN_GROUP;
}

export type PlanGroupActionTypes =
  | UpdatePlanGroupAction
  | UpdatePlanGroupsAction
  | ResetPlanGroupAction;

export interface PlanGroupState {
  planGroups: PlanGroup[];
  planGroup: PlanGroup | null;
}
