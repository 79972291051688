import {CustomizedPlan} from "../store/shared/plan/types";
import {Subscription} from "../store/portal/subscription/types";
import {Payment} from "../store/portal/payment/types";
import {AccountDetails} from "../store/portal/account/types";
import {SavedPaymentMethod} from "../store/shared/user/types";
import {ApiAddress} from "../api/types";

export const templatePlan: CustomizedPlan = {
  id: "",
  name: "Sample Plan",
  description: "This is an example of a plan",
  baseCurrency: "USD",
  bfId: "",
  bfPlanPath: "",
  cardText: "",
  colour: "#6B1FCC",
  disabled: false,
  displayIndex: 0,
  firstPeriodEnd: "",
  firstRecurringPaymentDate: "",
  fixedTerm: {},
  hasUsage: false,
  hidden: false,
  oneOffPayment: false,
  period: "month",
  periodLengthInMonths: 1,
  price: {
    discountedInitialPrice: 19.99,
    discountedPricePerPeriod: 19.99,
    initialPrice: 19.99,
    pricePerPeriod: 19.99,
    discountedInitialPriceExcludingTax: 18.99,
    discountedPricePerPeriodExcludingTax: 18.99,
    initialPriceExcludingTax: 18.99,
    pricePerPeriodExcludingTax: 18.99,
    taxPerPeriod: 1,
    immediatePayment: 19.99,
    immediateTax: 1,
  },
  pricing: {},
  trialDescription: "",
  customizedPricingComponents: [],
  couponCodes: [],
  taxStatus: "inclusive",
  localisedTax: false
};

export const templateSubscriptions: Array<Subscription> = [{
  plan: templatePlan,
  id: 'white',
  name: 'White Winegagag',
  status: 'cancelled',
  pricingComponentValues: []
}, {
  plan: templatePlan,
  id: 'red',
  name: 'Red wines',
  status: 'active',
  pricingComponentValues: []
}, {
  plan: templatePlan,
  id: 'rose',
  name: 'Rose wines',
  status: 'paused',
  pricingComponentValues: []
}];

export const templatePayments: Array<Payment> = [{
  subscription: templateSubscriptions[0],
  id: 'white_1',
  status: 'paid',
  total: 24.99,
  date: 1631895580,
  subscriptionName: templateSubscriptions[0].name,
  paymentAttempts: []
}, {
  subscription: templateSubscriptions[1],
  id: 'red_1',
  status: 'pending',
  total: 14.99,
  date: 1631795580,
  subscriptionName: templateSubscriptions[1].name,
  paymentAttempts: []
}, {
  subscription: templateSubscriptions[2],
  id: 'rose_1',
  status: 'unpaid',
  total: 101.99,
  date: 1631995580,
  subscriptionName: templateSubscriptions[2].name,
  paymentAttempts: []
}];

export const templateBillingAddress: ApiAddress = {
  addressLine1: 'Faux House',
  addressLine2: '12 Cheat Street',
  province: 'Greater London',
  city: 'London',
  country: 'United Kingdom',
  postcode: 'ABC 123'
};

export const templateDeliveryAddress: ApiAddress = {
  addressLine1: '12 Delivery Street',
  province: 'Volgograd Oblast',
  city: 'Volgograd',
  country: 'Russia',
  postcode: '191021'
};

export const templatePaymentMethods: Array<SavedPaymentMethod> = [{
  id: 'payment_method_1',
  cardHolder: 'Kenneth Perlin',
  firstSix: '424242',
  lastFour: '4242',
  expiryMonth: '03',
  expiryYear: '26',
  isPrimary: true
}, {
  id: 'payment_method_2',
  cardHolder: 'Kenneth Perlin',
  firstSix: '400000',
  lastFour: '0001',
  expiryMonth: '12',
  expiryYear: '31',
  isPrimary: false
}, {
  id: 'payment_method_3',
  cardHolder: 'Kenneth Perlin',
  firstSix: '233400',
  lastFour: '4321',
  expiryMonth: '12',
  expiryYear: '31',
  isPrimary: false
}, {
  id: 'payment_method_4',
  cardHolder: 'Kenneth Perlin',
  firstSix: '343500',
  lastFour: '0724',
  expiryMonth: '12',
  expiryYear: '31',
  isPrimary: false
}, {
  id: 'payment_method_5',
  cardHolder: 'Kenneth Perlin',
  firstSix: '600000',
  lastFour: '1531',
  expiryMonth: '12',
  expiryYear: '31',
  isPrimary: false
}];

export const templateAccountDetails: AccountDetails = {
  firstName: 'Kenneth',
  lastName: 'Perlin',
  email: 'kenperlin@example.com',
  customFields: [{
   customField: {
     id: 'custom_field_1',
     name: 'dietary requirements',
     displayName: 'Dietary Requirements',
     valueType: 'String'
   },
   content: 'Lactose intolerant, allergic to peanuts.'
  }]
}
