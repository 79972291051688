import React, {ReactNode} from "react";

import {connect, ConnectedProps} from "react-redux";
import {Redirect} from "react-router-dom";
import {portalLogout} from "../store/persisted/auth/actions";
import {updateStorefront} from "../store/storefront/actions";
import {RootState} from "../store";
import SpinnerPage from "./shared/SpinnerPage";
import {getAlertFromApiErrorResponse, portalIsLoggedIn} from "../utils/utils";
import {showAlert} from "../store/shared/alert/actions";
import {fetchStorefront} from "../api/storefront";
import {fetchPortalData} from "../api/portalCompositeCalls";

const mapState = (state: RootState) => ({
  storefront: state.main.storefront.storefront,
  portalStorefrontAliasLoggedInto: state.main.user.auth.portalStorefrontAliasLoggedInto,
  portalLoginExpiryTime: state.main.user.auth.portalLoginExpiryTime
});

const mapDispatch = {
  portalLogout: portalLogout,
  updateStorefront: updateStorefront,
  showAlert: showAlert
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
  children?: ReactNode,
  alias: string
}

type State = {
  isLoading: boolean,
  redirectToLogin: boolean
}

class PortalLoginCheckWrapper extends React.Component<Props, State> {
  //This is listed as an anti-pattern because we should cancel the request but in this case we want the data
  // regardless of whether we're mounted or not. However, I don't actually understand why we are unmounted before
  // the request is complete. It happens when going through the purchase journey and then entering an e-mail address
  // that was used before, clicking on the link that appears and then logging in.
  private _mounted: boolean = false;

  constructor(props: Props) {
    super(props);

    this.state = {
      isLoading: true,
      redirectToLogin: false
    }
  }

  private handleFetchPortalData = () => {
    fetchPortalData().then(responses => {
      if (this._mounted)
        this.setState({isLoading: false});
    }, error => {
      if (error.isAxiosError && error.response && error.response.status === 401) {
        //Seems our cookie has expired or similar
        this.logout();
      } else {
        this.props.showAlert(getAlertFromApiErrorResponse(error, "There was a problem fetching user data."))
        this.setState({isLoading: false});
      }
    });
  }

  componentDidMount() {
    this._mounted = true;
    if (portalIsLoggedIn(this.props.storefront.alias, this.props.portalStorefrontAliasLoggedInto)) {
      if (this.props.portalLoginExpiryTime && this.props.portalLoginExpiryTime < new Date()) {
        this.logout();
      } else if (this.props.storefront.id) {
        this.handleFetchPortalData();
      } else {
        fetchStorefront(this.props.alias)
            .then(success => this.handleFetchPortalData())
            .catch(error => this.logout());
      }
    } else {
      this.redirectToLogin();
    }
  }

  private logout = () => {
    this.props.portalLogout();
    this.redirectToLogin();
  };

  private redirectToLogin = () => this.setState({isLoading: false, redirectToLogin: true});

  componentWillUnmount() {
    this._mounted = false;
  }

  render() {
    return (
      this.state.isLoading
        ? <SpinnerPage></SpinnerPage>
        : (this.state.redirectToLogin
          ? <Redirect to={{pathname: `/store/${this.props.storefront.alias}/login`}}/>
          : this.props.children)
    )
  };
}

export default connector(PortalLoginCheckWrapper);
