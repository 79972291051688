import React, {FC, useCallback, useEffect, useState} from "react";

import {useDispatch, useSelector} from "react-redux";
import OptionsPanel from "./OptionsPanel";
import OptionDropdown from "./OptionDropdown";
import LoadingButton from "./shared/LoadingButton";
import {Input} from "./shoelace";
import MarkdownTextArea from "./shared/MarkdownTextArea";
import "./SettingsForm.scss";
import {apiSuggestAlias} from "../api";
import {showAlert} from "../store/shared/alert/actions";
import {AlertType} from "../store/shared/alert/types";
import {addTrailingSlash, getAlertFromApiErrorResponse, getBasicWarning, getBfAppUrl,} from "../utils/utils";
import MerchantCheckbox from "./MerchantCheckbox";
import {debounce} from "ts-debounce";
import PaymentGatewayOptions from "./payments/PaymentGatewayOptions";
import {PaymentGatewayStatus, TermOrCondition} from "../store/storefront/types";
import {Prompt} from "react-router";
import deepEqual from "deep-equal";
import PreviewCompleteStep from "./PreviewCompleteStep";
import SlInputElement from "@shoelace-style/shoelace/dist/components/input/input";
import {selectSquareConfig, selectStorefront,} from "../store/storefront/selectors";
import {saveStorefrontSettings} from "../api/storefront";

type SaveCondition = {
  check: () => boolean;
  onFail: () => void;
};

interface SettingsFormData {
  paymentGateway: string;
  paymentGatewayStatus: PaymentGatewayStatus;
  squareLocation: string | null;
  termsAndConditionsText: string | TermOrCondition[];
  purchaseMessageText: string;
  allowCart: boolean;
  allowCoupons: boolean;
  allowCustomerPortal: boolean;
  captureCompany: boolean;
  captureAddress: boolean;
  name: string;
  alias: string;
  trading: boolean;
}

const SettingsForm: FC = () => {
  const nameRef = React.useRef<SlInputElement>(null);
  const aliasRef = React.useRef<SlInputElement>(null);
  const [initialFormData, setInitialFormData] = useState<SettingsFormData>(
    {} as SettingsFormData
  );
  const dispatch = useDispatch();

  const storefront = useSelector(selectStorefront);
  const squareConfig = useSelector(selectSquareConfig);

  const [formData, setFormData] = useState({
    paymentGateway: storefront.paymentGateway,
    paymentGatewayStatus: null,
    squareLocation: null,
    termsAndConditionsText: storefront.termsConditions || "",
    purchaseMessageText: storefront.purchaseMessage || "",
    allowCart: storefront.allowCart,
    allowCoupons: storefront.allowCoupons,
    captureCompany: storefront.captureCompany,
    captureAddress: storefront.captureAddress,
    allowCustomerPortal: storefront.allowCustomerPortal,
    name: storefront.name,
    alias: storefront.alias,
    trading: storefront.trading,
  });

  const saveConditions: SaveCondition[] = [
    {
      // Payment gateway must not be null or inactive
      check: () => !formData.trading || (formData.paymentGatewayStatus !== null && formData.paymentGatewayStatus !== "inactive"),
      onFail: () =>
        dispatch(
          showAlert(
            getBasicWarning(
              "No payment gateway selected.",
              "You must choose an active payment gateway before opening your store. Your changes have not been saved."
            )
          )
        ),
    },
    {
      // If Square is the payment gateway, a location must be selected
      check: () => !formData.trading || formData.paymentGateway !== "Square" || formData.squareLocation !== null,
      onFail: () =>
        dispatch(
          showAlert(
            getBasicWarning(
              "No Square location selected.",
              "When using Square as a payment gateway, you must choose a Square merchant location before opening your store. Your changes have not been saved."
            )
          )
        ),
    },
    {
      // Terms & conditions must be set
      //We don't allow saving terms and conditions that are not a string yet, so we just return true for that.
      check: () => !formData.trading ||
          !(typeof formData.termsAndConditionsText === "string") ||
          formData.termsAndConditionsText.length > 0,
      onFail: () =>
        dispatch(
          showAlert(
            getBasicWarning(
              "Terms & conditions have not been set.",
              "You must set terms & conditions, which new customers must agree to. Your changes have not been saved."
            )
          )
        ),
    },
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [closestAlias, setClosestAlias] = useState(storefront.alias);
  const [isSuggestAliasLoading, setIsSuggestAliasLoading] = useState(false);

  const debounceAliasSuggestion = debounce((input) => {
    apiSuggestAlias(input).then(
      (response) => {
        setClosestAlias(response.data);
        setIsLoading(false);
        setIsSuggestAliasLoading(false);
      },
      (error) => {
        dispatch(
          showAlert(
            getAlertFromApiErrorResponse(
              error.response,
              "There was a problem finding a valid alias."
            )
          )
        );
        setIsLoading(false);
        setIsSuggestAliasLoading(false);
      }
    );
  }, 800);

  const updateFormData = (
    name: keyof SettingsFormData,
    value: unknown,
    initial: boolean
  ) => {
    if (initial) {
      setInitialFormData((state) => ({ ...state, [name]: value }));
    }
    setFormData((state) => ({ ...state, [name]: value }));
  };

  const onTermsAndConditionsInputUpdate = (
    markdown: string,
    initial: boolean
  ) => {
    updateFormData("termsAndConditionsText", markdown, initial);
  };
  const onPurchaseMessageInputUpdate = (markdown: string, initial: boolean) => {
    updateFormData("purchaseMessageText", markdown, initial);
  };
  const onAllowCartCheckboxUpdate = () => {
    updateFormData("allowCart", !formData.allowCart, false);
  };
  const onCaptureCompanyCheckboxUpdate = () => {
    updateFormData("captureCompany", !formData.captureCompany, false);
  };
  const onCaptureAddressCheckboxUpdate = () => {
    updateFormData("captureAddress", !formData.captureAddress, false);
  };
  const onAllowCouponsCheckboxUpdate = () => {
    updateFormData("allowCoupons", !formData.allowCoupons, false);
  };
  const onAllowCustomerPortalCheckboxUpdate = () => {
    updateFormData("allowCustomerPortal", !formData.allowCustomerPortal, false);
  };
  const onTradingCheckboxUpdate = () => {
    updateFormData("trading", !formData.trading, false);
  };
  const onNameInputUpdate = () => {
    updateFormData("name", nameRef.current!.value, false);
  };
  const onAliasInputUpdate = () => {
    const requestedAlias = aliasRef.current!.value;
    updateFormData("alias", requestedAlias, false);
    setIsLoading(true);
    setIsSuggestAliasLoading(true);
    debounceAliasSuggestion.cancel();
    debounceAliasSuggestion(requestedAlias)
        .catch(console.log);
  };

  const checkValid = (): boolean => {
    for (let saveCondition of saveConditions) {
      if (!saveCondition.check()) {
        saveCondition.onFail();
        return false;
      }
    }
    return true;
  };

  const updatePaymentGateway = useCallback(
    (
      gatewayName: string,
      gatewayStatus: PaymentGatewayStatus,
      initial: boolean
    ) => {
      updateFormData("paymentGateway", gatewayName, initial);
      updateFormData("paymentGatewayStatus", gatewayStatus, initial);
    },
    []
  );

  const onSubmit = () => {
    if (!checkValid())
      return;

    setIsLoading(true);

    saveStorefrontSettings(
      {
        termsConditions: formData.termsAndConditionsText,
        purchaseMessage: formData.purchaseMessageText,
        allowCart: formData.allowCart,
        allowCoupons: formData.allowCoupons,
        allowCustomerPortal: formData.allowCustomerPortal,
        captureCompany: formData.captureCompany,
        captureAddress: formData.captureAddress,
        name: formData.name,
        alias: closestAlias,
        trading: formData.trading,
        paymentGateway: formData.paymentGateway,
      },
      formData.squareLocation &&
        squareConfig?.locationId !== formData.squareLocation
        ? { locationId: formData.squareLocation }
        : undefined
    )
      .then(
        (response) => {
          updateFormData("alias", response.alias, true);
          dispatch(
            showAlert({
              type: AlertType.Success,
              message: "Your changes have been saved.",
            })
          );
        },
        (error) => {
          dispatch(
            showAlert(
              getAlertFromApiErrorResponse(
                error.response,
                "There was an error saving your settings."
              )
            )
          );
        }
      )
      .finally(() => {
        setIsLoading(false);
        setInitialFormData(formData);
      });
  };

  const addEventListeners = () => {
    // termsAndConditionsRef.current.element.current.addEventListener('sl-input', onTermsAndConditionsInputUpdate);
    nameRef.current!.addEventListener("sl-input", onNameInputUpdate);
    aliasRef.current!.addEventListener("sl-input", onAliasInputUpdate);
  };

  const userHasMadeSettingsChanges = () => {
    return !deepEqual(initialFormData, formData);
  };

  useEffect(() => {
    addEventListeners();
    setInitialFormData({ ...formData });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Prompt
          when={userHasMadeSettingsChanges()}
          message={"You have unsaved settings changes. Are you sure you want to leave this page?"}
      />
      <div className="settings-form">
        <div className="settings-form-content">
          <OptionsPanel>
            <OptionDropdown
              className="trading-option"
              title="Open For Business"
              icon="door-open-fill"
              tooltipText="If activated, your storefront will be available to the public!"
              arrowReplacement={
                <MerchantCheckbox
                  active={formData.trading}
                  handleClick={onTradingCheckboxUpdate}
                />
              }
            ></OptionDropdown>
            <OptionDropdown
              title="Enable Customer Portal"
              icon="person-circle"
              tooltipText="Enables customers to log in and manage their subscriptions, payments and personal details."
              arrowReplacement={
                <MerchantCheckbox
                  active={formData.allowCustomerPortal}
                  handleClick={onAllowCustomerPortalCheckboxUpdate}
                />
              }
            ></OptionDropdown>
            <OptionDropdown
              title="Allow Cart Checkout"
              icon="cart-fill"
              tooltipText="Allows customers to purchase any number of available plans, rather than just one."
              arrowReplacement={
                <MerchantCheckbox
                  active={formData.allowCart}
                  handleClick={onAllowCartCheckboxUpdate}
                />
              }
            ></OptionDropdown>
            <OptionDropdown
              title="Allow Coupons"
              icon="cart-fill"
              tooltipText="Allows customers to redeem coupons before purchasing. You can manage coupons in Billforward."
              arrowReplacement={
                <MerchantCheckbox
                  active={formData.allowCoupons}
                  handleClick={onAllowCouponsCheckboxUpdate}
                />
              }
            ></OptionDropdown>
            <OptionDropdown
              title="Capture Company Name"
              icon="cart-fill"
              tooltipText="Require customers to enter their company name before purchasing."
              arrowReplacement={
                <MerchantCheckbox
                  active={formData.captureCompany}
                  handleClick={onCaptureCompanyCheckboxUpdate}
                />
              }
            />
            <OptionDropdown
              title="Capture Address"
              icon="cart-fill"
              tooltipText="Require customers to enter their address before purchasing."
              arrowReplacement={
                <MerchantCheckbox
                  active={formData.captureAddress}
                  handleClick={onCaptureAddressCheckboxUpdate}
                />
              }
            />
            <PaymentGatewayOptions
              updatePaymentGateway={updatePaymentGateway}
              updateSquareLocation={(
                locationId: string | null,
                initial: boolean
              ) => updateFormData("squareLocation", locationId, initial)}
            />
            <OptionDropdown title="Terms and Conditions" icon="file-text-fill">
              {typeof formData.termsAndConditionsText === "string" || (formData.termsAndConditionsText.length === 1 && !formData.termsAndConditionsText[0].contents.startsWith("http")) ?
                  <MarkdownTextArea
                      tcsPreview
                      value={typeof formData.termsAndConditionsText === "string" ? formData.termsAndConditionsText : formData.termsAndConditionsText[0].contents}
                      onChange={onTermsAndConditionsInputUpdate}
                  /> :
                 <>
                  <div>Terms &amp; conditions have been configured separately, please contact support to edit.<br/><br/></div>
                  {formData.termsAndConditionsText.map((tc) => (
                    <>
                      <h4>{tc.title}</h4>
                      <p>{tc.contents}</p>
                    </>
                  ))}
                 </>
              }
            </OptionDropdown>
            <OptionDropdown
              title="Message to New Customers"
              icon="file-text-fill"
            >
              <div className="purchase-message-container">
                <div className="purchase-message-help-text">
                  <span>
                    This message will be shown to your customers as soon as they
                    make a successful purchase.
                  </span>
                  <span>
                    You can use this to direct them towards your product or
                    website.
                  </span>
                </div>
                <div className="purchase-message-preview-container">
                  <MarkdownTextArea
                    value={formData.purchaseMessageText}
                    onChange={onPurchaseMessageInputUpdate}
                  />
                  <div>
                    <span className="preview-title">Preview</span>
                    <PreviewCompleteStep
                      content={formData.purchaseMessageText}
                    />
                  </div>
                </div>
              </div>
            </OptionDropdown>
            <OptionDropdown title="Edit Storefront Name" icon="pencil-fill">
              <div className="name-container">
                <Input
                  className={`form-input light name-input`}
                  ref={nameRef}
                  value={formData.name}
                />
              </div>
            </OptionDropdown>
            <OptionDropdown
              title="Edit Storefront URL"
              icon="slash-square-fill"
            >
              <div className="alias-container">
                <Input
                  className={`form-input light alias-input`}
                  ref={aliasRef}
                  value={formData.alias}
                />
                <div
                  className={`alias-details ${
                    isSuggestAliasLoading ? "loading" : ""
                  }`}
                >
                  <span>
                    The closest valid alias is <b>{closestAlias}</b>
                  </span>
                  <span>
                    Your storefront URL will be:
                    <b>
                      {addTrailingSlash(window.REACT_APP_FLEXCHECK_DOMAIN)}
                      store/{closestAlias}
                    </b>
                  </span>
                </div>
              </div>
            </OptionDropdown>
            <OptionDropdown title="Confirmation Email" icon="envelope-fill">
              Head over to Billforward to configure your
              <a
                href={`${getBfAppUrl()}#/setup/email-configuration/InvoicePaid`}
              >
                Payment Successful
              </a>
              and
              <a
                href={`${getBfAppUrl()}#/setup/email-configuration/InvoicePaymentFailed`}
              >
                Payment Failed
              </a>
              emails.
            </OptionDropdown>
          </OptionsPanel>
        </div>
        <div className={"save-button-container"}>
          <LoadingButton
            onSubmit={() => onSubmit()}
            isLoading={isLoading}
            noCustomStyle
          >
            Save
          </LoadingButton>
        </div>
      </div>
    </>
  );
};

export default SettingsForm;
