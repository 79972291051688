import React, {ReactNode} from "react";
import {Button, Spinner} from "../shoelace";

import './SquareButton.scss';
import {RootState} from "../../store";
import {connect, ConnectedProps} from "react-redux";

const mapState = (state: RootState) => ({
  storefrontType: state.main.storefront.storefront.type
});
const mapDispatch = {
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux{
  label: string,
  onClick?: () => void,
  className?: string,
  noCustomStyle?: boolean,
  disabled?: boolean,
  isLoading?: boolean,
  children?: ReactNode
}

const SquareButton = (props: Props) => {
  return (
    <div className={`square-button-container ${props.className}`}>
      <Button className={`${props.className} ${props.storefrontType === "BfAccountingOrg" || props.noCustomStyle ? '' : 'custom'}`}
              onClick={props.isLoading ? undefined : props.onClick} disabled={props.disabled}>
        {props.isLoading
          ? <Spinner/>
          : props.children}
      </Button>
      <span className={`square-button-label ${props.disabled ? 'disabled' : ''}`}>{props.label}</span>
    </div>
  )
}

export default connector(SquareButton);
