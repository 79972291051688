import React, { FC, ReactElement, ReactNode } from "react";
import AlertStack from "./shared/AlertStack";

import "./MerchantPage.scss";

interface Props {
  title: string;
  subtitle?: string;
  description: string;
  button?: ReactElement;
  className?: string;
  children?: ReactNode;
}

const MerchantPage: FC<Props> = ({
  title,
  subtitle,
  description,
  button,
  className,
  children,
}) => {
  return (
    <div className="merchant-page">
      <div className="merchant-page-title-section">
        <div className="merchant-page-title-container">
          <h1 className="merchant-page-title">{title}</h1>
          {subtitle !== undefined ? (
            <span className="merchant-page-subtitle">{subtitle}</span>
          ) : null}
        </div>
        {button !== undefined ? React.cloneElement(button) : null}
      </div>
      <span className="merchant-page-description">{description}</span>
      <div className={`merchant-page-content ${className || ""}`}>
        {children}
      </div>
      <AlertStack merchant />
    </div>
  );
};

export default MerchantPage;
