import {Middleware} from "redux";
import {RootState} from "../index";
import { updateStorefrontCss } from "../../utils/style";
import { UPDATE_STOREFRONT } from "./types";

export const styleWatcher: Middleware<{}, RootState> = storeApi => next => action => {
  const result = next(action);
  if (action.type === UPDATE_STOREFRONT) {
    updateStorefrontCss();
  }
  return result;
};
