import React from "react";
import {RootState} from "../store";
import {connect, ConnectedProps} from "react-redux";
import FormBase, {BaseState, InputTypeGenerator} from "./shared/FormBase";
import {debounce} from "ts-debounce";

const mapState = (state: RootState) => ({
  storefront: state.main.storefront.storefront
});

const mapDispatch = {
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
  updateButtonText: (text: string) => void,
  initialButtonText: string
}

const typeGenerator = new InputTypeGenerator([
  {name: "buttonText", type: "text"}
]);
type InputName = typeof typeGenerator.InputName.type;

class StorefrontUrlButtonForm extends FormBase<Props, BaseState<InputName>, InputName> {
  getInitialState(baseState: BaseState<InputName>): BaseState<InputName> {
    return baseState;
  }

  onSubmitValidForm() {
    return true;
  }

  private debounceUpdateButtonText = debounce((buttonText: string) => {
    this.props.updateButtonText(buttonText);
  }, 500);

  protected onTextInputUpdateExtension(name: InputName) {
    if (this.state.isValid.buttonText) {
      this.debounceUpdateButtonText.cancel();
      this.debounceUpdateButtonText(this.state.formData.buttonText);
      // this.props.updateButtonText(this.state.formData.buttonText);
    }
  }

  constructor(props: Props) {
    super(
      props,
      typeGenerator,
      {
        setValid: {
          buttonText: (value: string) => {
            const buttonTextRegex = /^[a-zA-Z0-9 _-]*$/;
            return value.length > 0 && buttonTextRegex.test(value);
          },
        },
        invalidMessages: {
          buttonText: 'Please only include alphanumeric characters, spaces, underscores and dashes.',
        },
        initialFormData: {
          buttonText: props.initialButtonText
        }
      },
    );
  }

  render() {
    return (
      <form className={"storefront-button-text-form"} ref={this.formRef}>
        <div className={"form-section"}>
          {this.getInputComponent('buttonText', 'Button Text')}
        </div>
      </form>
    )
  }
}

export default connector(StorefrontUrlButtonForm);
