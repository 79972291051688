import React from "react";
import LoginHeader from "../LoginHeader";
import {Alert as AlertComponent, Button, Icon} from "../shoelace";
import {AlertMessage} from "../../store/shared/alert/types";
import {apiSendError} from "../../api";

interface Props {
  message?: AlertMessage
}
interface State {
  hasError: boolean
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: any, info: any) {
    //Fire and forget the error to Flexcheck API
    apiSendError({error: error, info: info});

    // Display fallback UI
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      // Fallback UI
      return (
        <div className="user-container">
          <LoginHeader/>
          <div className="page">
            <div className="error-fallback">
              <AlertComponent className="error-fallback-alert" open variant="danger">
                <div className="error-fallback-title-container">
                  <Icon className="error-fallback-title-icon" name="exclamation-octagon"></Icon>
                  <span className="error-fallback-title">Uh oh, something went wrong.</span>
                </div>
                {this.props.message ||
                <p>The app encountered an unexpected error. Sorry for the inconvenience!<br/><br/>
                  A report has been sent to the Billforward team.<br/>
                  Please refresh the page and continue using the app.<br/>
                  If the problem persists, you can get in touch at <a href="https://www.billforward.io/contact-us" target="_blank" rel="noreferrer">billforward.io/contact-us</a>.</p>}
              </AlertComponent>
              <Button className="primary" onClick={() => {window.location.reload(); return false;}}>Refresh</Button>
            </div>
          </div>
        </div>);
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
