import {
  PlanActionTypes,
  PlanState,
  UPDATE_AVAILABLE_PLANS,
  UPDATE_CURRENCY
} from "./types";
import {getAvailableCurrencies} from "../../../utils/utils";
const initialState: PlanState = {
  currency: 'USD',
  availableCurrencies: [],
  availablePlans: []
}

export function planReducer(state: PlanState = initialState, action: PlanActionTypes): PlanState {
  switch (action.type) {
    case UPDATE_CURRENCY:
      return { ...state, currency: action.payload };
    case UPDATE_AVAILABLE_PLANS:
      const availableCurrencies = getAvailableCurrencies(action.payload);
      const newCurrency = availableCurrencies.includes(state.currency)
        ? state.currency
        : availableCurrencies.length > 0 ? availableCurrencies[0] : 'USD';
      return { ...state,
        availablePlans: action.payload,
        availableCurrencies,
        currency: newCurrency
      };
    default:
      return state;
  }
}
