import React from "react";

import {RootState} from "../../../store";
import {connect, ConnectedProps} from "react-redux";
import PortalPage from "../../PortalPage";
import PortalPanel from "../../portal/PortalPanel";
import SubscriptionCard from "../../portal/SubscriptionCard";
// TODO: This carousel library hasn't had an update since May 2021
import Carousel, {arrowsPlugin, slidesToScrollPlugin, slidesToShowPlugin,} from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";
import {Icon} from "../../shoelace";
import PaymentCard from "../../portal/PaymentCard";
import LabelledContainer from "../../portal/LabelledContainer";
import PaymentMethodCard from "../../portal/PaymentMethodCard";
import ShortAddress from "../../portal/ShortAddress";
import {useHistory} from "react-router-dom";
import {LARGE_SCREEN} from "../../../config/constants";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import SubscriptionLink from "../../portal/SubscriptionLink";
import {isPlanInGroup} from "../../../utils/utils";

import "./Home.scss";

const mapState = (state: RootState) => ({
  storefront: state.main.storefront.storefront,
  subscriptions: state.main.portal.subscription.subscriptions,
  payments: state.main.portal.payment.payments,
  billingAddress: state.main.portal.account.billingAddress,
  primaryPaymentMethod: state.main.portal.account.primaryPaymentMethod,
  accountDetails: state.main.portal.account.accountDetails,
  planGroups: state.main.user.planGroup.planGroups,
});

const mapDispatch = {};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {}

const NUMBER_OF_SLIDES = 3;

const Home = (props: Props) => {
  const history = useHistory();
  const windowDimensions = useWindowDimensions();

  const goToSubscriptionPage = (subscriptionId: string) => {
    history.push(
      `/store/${props.storefront.alias}/portal/subscriptions/view/${subscriptionId}`
    );
  };

  const goToPaymentPage = (paymentId: string) => {
    history.push(
      `/store/${props.storefront.alias}/portal/billing-history/view/${paymentId}`
    );
  };

  const hasSubscriptions = props.subscriptions.length > 0;
  const isLargeScreen = windowDimensions.width >= LARGE_SCREEN;

  return (
    <PortalPage title={props.storefront.name}>
      <h1 className="portal-home-title">
        Welcome, <span>{props.accountDetails.firstName}!</span>
      </h1>
      <div className="home-grid">
        <div className="subscriptions-area">
          <PortalPanel
            className="subscription-panel"
            title="Subscriptions"
            link={`/store/${props.storefront.alias}/portal/subscriptions`}
          >
            <div className="home-subscriptions-list">
              {hasSubscriptions && isLargeScreen ? (
                <Carousel
                  plugins={[
                    {
                      resolve: arrowsPlugin,
                      options: {
                        arrowLeft: <Icon name="caret-left-fill" />,
                        arrowLeftDisabled: (
                          <Icon
                            name="caret-left-fill"
                            style={{ opacity: 0.5 }}
                          />
                        ),
                        arrowRight: <Icon name="caret-right-fill" />,
                        arrowRightDisabled: (
                          <Icon
                            name="caret-right-fill"
                            style={{ opacity: 0.5 }}
                          />
                        ),
                        addArrowClickHandler: true,
                      },
                    },
                    {
                      resolve: slidesToShowPlugin,
                      options: { numberOfSlides: NUMBER_OF_SLIDES },
                    },
                    {
                      resolve: slidesToScrollPlugin,
                      options: { numberOfSlides: NUMBER_OF_SLIDES },
                    },
                  ]}
                >
                  {props.subscriptions.map((sub) => (
                    <SubscriptionCard
                      key={sub.id}
                      subscription={sub}
                      planGroup={
                        props.planGroups.find(
                          (planGroup) =>
                              isPlanInGroup(sub.plan, planGroup)
                        )
                      }
                      goToSubscriptionPage={goToSubscriptionPage}
                    />
                  ))}
                </Carousel>
              ) : hasSubscriptions ? (
                props.subscriptions.map((sub) => (
                  <SubscriptionLink
                    key={sub.id}
                    subscription={sub}
                    planGroup={props.planGroups.find(
                      (planGroup) =>
                          isPlanInGroup(sub.plan, planGroup)
                    )}
                    goToSubscriptionPage={goToSubscriptionPage}
                  />
                ))
              ) : (
                <div className="home-subscriptions-list-empty">
                  You don't have any subscriptions.
                </div>
              )}
            </div>
          </PortalPanel>
        </div>
        <div className="billing-history-area">
          <PortalPanel
            title="Billing History"
            link={`/store/${props.storefront.alias}/portal/billing-history`}
          >
            <div className="home-billing-history-panel">
              <div className="total-payments">
                <span>
                  <b>{props.payments.length}</b> total payments
                </span>
              </div>
              {props.payments.length > 0 ? (
                <LabelledContainer label="Latest Payment">
                  <PaymentCard
                    payment={props.payments[0]}
                    goToPaymentPage={goToPaymentPage}
                    isMini
                  />
                </LabelledContainer>
              ) : null}
            </div>
          </PortalPanel>
        </div>
        <div className="account-area">
          <PortalPanel
            title="Account"
            link={`/store/${props.storefront.alias}/portal/account`}
          >
            <div className="home-account-panel">
              <LabelledContainer
                label="Billing Address"
                includeCard
                className="set-width"
              >
                <ShortAddress address={props.billingAddress} />
              </LabelledContainer>
              <LabelledContainer label="Primary Payment Method">
                <PaymentMethodCard paymentMethod={props.primaryPaymentMethod} />
              </LabelledContainer>
            </div>
          </PortalPanel>
        </div>
      </div>
    </PortalPage>
  );
};

export default connector(Home);
