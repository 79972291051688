import SquareExtraDetailsForm from "./SquareExtraDetailsForm";
import ShuttlePaymentForm from "./ShuttlePaymentForm";
import CardConnectPaymentForm from "./CardConnectPaymentForm";
import PaymentMethodForm from "./PaymentMethodForm";
import React from "react";
import {RootState} from "../../store";
import {connect, ConnectedProps} from "react-redux";
import {successfulPaymentMethod} from "../../store/user/cart/actions";

const mapState = (state: RootState) => ({
  paymentGateway: state.main.storefront.storefront.paymentGateway
});

const mapDispatch = {
  successfulPaymentMethod: successfulPaymentMethod
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
  onSuccessfulSubmit: (id: string) => void,
  buttonText: string
}

type PaymentMethodFormContextType = {
  buttonText: string
}
export const PaymentMethodFormContext = React.createContext<PaymentMethodFormContextType>({
  buttonText: 'Submit'
});

const renderGateway = (props: Props) => {
  const handleSuccess = (id: string) => {
    props.successfulPaymentMethod(id);
    props.onSuccessfulSubmit(id);
  };

  switch(props.paymentGateway) {
    case 'Square' : return <SquareExtraDetailsForm onSuccessfulSubmit={handleSuccess}/>
    case 'Shuttle': return <ShuttlePaymentForm onSuccessfulSubmit={handleSuccess} />
    case 'CardConnect': return <CardConnectPaymentForm onSuccessfulSubmit={handleSuccess} />
    default : return <PaymentMethodForm onSuccessfulSubmit={handleSuccess}/>
  }
}

const PaymentMethodSwitch = (props: Props) => {
  return (
    <PaymentMethodFormContext.Provider value={{
      buttonText: props.buttonText
    }}>
      {renderGateway(props)}
    </PaymentMethodFormContext.Provider>
  )
}

export default connector(PaymentMethodSwitch);
