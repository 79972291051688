import React from "react";

import {Alert as AlertComponent, Icon} from '../shoelace';
import {Alert, AlertMessage, AlertType} from "../../store/shared/alert/types";

import './AlertToast.scss';

interface Props {
  alert: Alert
}

interface State {}

class AlertToast extends React.Component<Props, State> {

  // Assign keys to all JSX elements in a message
  private addKeys = (message: AlertMessage): AlertMessage => {
    if (Array.isArray(message)) {
       return message.map((el, index) => {
        if (typeof el !== "string" && el !== undefined && "key" in el) {
          return React.cloneElement(el, {key: index});
        }
        return el;
      })
    }
    return message;
  }

  private getType = () => {
    switch (this.props.alert.type) {
      case AlertType.Error:
        return 'danger';
      case AlertType.Warning:
        return 'warning';
      case AlertType.Success:
        return 'success';
      case AlertType.Info:
        return 'primary';
    }
  }

  private getIcon = () => {
    switch (this.props.alert.type) {
      case AlertType.Error:
        return 'exclamation-octagon';
      case AlertType.Warning:
        return 'exclamation-triangle';
      case AlertType.Success:
        return 'check2-circle';
      case AlertType.Info:
        return 'info-circle';
    }
  }

  private getDuration = () => {
    switch (this.props.alert.type) {
      case AlertType.Success:
        return 3000;
      default:
        return 10000;
    }
  }

  render () {
    return <AlertComponent className={"alert-toast"} variant={this.getType()} duration={this.getDuration()} closable open>
      <Icon slot="icon" name={this.getIcon()}/>
      {this.addKeys(this.props.alert.message)}
    </AlertComponent>
  }
}

export default AlertToast;
