import React, {ReactNode} from "react";

import {RootState} from "../store";
import {connect, ConnectedProps} from "react-redux";
import AlertStack from "./shared/AlertStack";
import Header from "./Header";

const mapState = (state: RootState) => ({
});

const mapDispatch = {
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
  children?: ReactNode
}
interface State {}

class PortalLoginPage extends React.Component<Props, State> {
  render() {
    return (
      <div className="user-container">
        <Header
          showCartButton={false}
          showCurrencySelect={false}
          isPreview={false}
          showLogin={false}
          showStorefrontLink={true}/>
        <div className="page">
          {this.props.children}
        </div>
        <AlertStack/>
      </div>
    );
  }
}

export default connector(PortalLoginPage);
