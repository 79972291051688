import React, {FC, useCallback, useEffect, useState} from "react";
import clsx from "clsx";
import {useDispatch, useSelector} from "react-redux";
import {
  selectPanelIsLoading,
  selectPanelIsOpen,
  selectPanelPlanId,
  selectPanelType,
} from "../../store/panel/selectors";
import {closePanelStart} from "../../store/panel/actions";
import {Icon, Spinner} from "../shoelace";
import EditPlanForm, {EditPlanFormData} from "../EditPlanForm";
import {selectFlexcheckPlans, selectSelectedPlanToAdd,} from "../../store/merchant/plan/selectors";
import {api} from "../../api";
import {useHistory} from "react-router";
import {showAlert as showAlertAction} from "../../store/shared/alert/actions";
import {AlertType} from "../../store/shared/alert/types";
import {PanelTypes} from "../../store/panel/types";
import {BfApiPlan} from "../../api/types";
import {formatPeriod, getAlertFromApiErrorResponse} from "../../utils/utils";
import {Plan} from "../../store/shared/plan/types";
import {updatePlanWithQuote} from "../../store/shared/plan/utils";
import {
  updateFlexcheckPlans as updateFlexcheckPlansAction,
  updateSelectedPlanToAdd,
} from "../../store/merchant/plan/actions";
import WarningDialog from "../WarningDialog";

import styles from "./SidePanel.module.scss";
import {fetchFlexcheckPlans} from "../../api/quotes";

const SidePanel: FC = () => {
  const isOpen = useSelector(selectPanelIsOpen);
  const panelType = useSelector(selectPanelType);
  const planId = useSelector(selectPanelPlanId);
  const isLoading = useSelector(selectPanelIsLoading);
  const plan = useSelector(selectFlexcheckPlans).find((p) => p.id === planId);
  const [planToDelete, setPlanToDelete] = useState<Plan>();
  const selectedPlanToAdd = useSelector(selectSelectedPlanToAdd);
  const history = useHistory();
  const dispatch = useDispatch();
  const dialogRef = React.useRef<WarningDialog>(null);

  const closeSidePanel = () => {
    dispatch(updateSelectedPlanToAdd({} as BfApiPlan));
    dispatch(closePanelStart());
  };

  const showAlert = useCallback(
    (error: any) => {
      dispatch(
        showAlertAction({
          type: AlertType.Error,
          message: `There was an error saving changes to this plan: ${error.response.data.message}`,
        })
      );
    },
    [dispatch]
  );

  useEffect(() => {
    if (plan) {
      setPlanToDelete(plan);
    }
  }, [plan]);

  const updateFlexcheckPlans = useCallback(
    (plans) => {
      dispatch(updateFlexcheckPlansAction(plans));
    },
    [dispatch]
  );

  const fetchPlans = useCallback(async () => {
    try {
      const plans = await fetchFlexcheckPlans({ includeHidden: true, coupons: [] });
      updateFlexcheckPlans(plans);
    } catch (error: any) {
      showAlert(
        getAlertFromApiErrorResponse(
          error.response,
          "There was an error fetching the plans list."
        )
      );
    }
  }, [updateFlexcheckPlans, showAlert]);

  const onSubmit = useCallback(
    async (formData: EditPlanFormData) => {
      try {
        await api.put("plan", {
          bfPlanPath: plan ? plan.bfPlanPath : selectedPlanToAdd.path,
          cardText: formData.cardText,
          colour: formData.cardColor,
          imageUrl: formData.imageUrl,
        });
        await fetchPlans();
        history.push("/merchant/plans");
      } catch (error) {
        showAlert(error);
      } finally {
        dispatch(updateSelectedPlanToAdd({} as BfApiPlan));
        dispatch(closePanelStart());
      }
    },
    [selectedPlanToAdd.path, history, showAlert, dispatch, fetchPlans, plan]
  );

  const convertPlan = (selectedPlan: BfApiPlan) => {
    if ("path" in selectedPlan) {
      let plan = {
        bfId: selectedPlan.id,
        bfPlanPath: selectedPlan.path,
        name: selectedPlan.displayName || selectedPlan.name,
        baseCurrency: selectedPlan.currency,
        price: {},
        period: formatPeriod(
          selectedPlan.duration,
          selectedPlan.durationPeriod
        ),
        description: selectedPlan.description,
        cardText: "",
        colour: "#c9c3f5",
        hasUsage: selectedPlan.hasUsage,
        trialDescription: selectedPlan.trialDescription,
      } as Plan;
      return selectedPlan.quote
        ? updatePlanWithQuote(plan, selectedPlan.quote)
        : plan;
    } else {
      return {} as Plan;
    }
  };

  const [convertedPlanToAdd, setConvertedPlanToAdd] = useState<Plan>(convertPlan(selectedPlanToAdd));

  useEffect(() => {
    setConvertedPlanToAdd({} as Plan);
    setConvertedPlanToAdd(convertPlan(selectedPlanToAdd));
  }, [selectedPlanToAdd]);

  const removePlan = async (plan: Plan) => {
    try {
      await api.delete(`plan/${plan.id}`);
      await fetchPlans();
      showAlert({
        type: AlertType.Success,
        message: [<b>{plan.bfPlanPath}</b>, " successfully removed."],
      });
      dialogRef.current?.hideDialog();
    } catch (error: any) {
      showAlert(
        getAlertFromApiErrorResponse(
          error.response,
          `There was an error removing ${plan.bfPlanPath}.`
        )
      );
    }
  };

  const showRemovePlanWarning = () => {
    closeSidePanel();
    dialogRef.current!.showDialog();
  };

  return (
    <>
      <aside className={clsx(styles["side-panel"], isOpen && styles.open)}>
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <button className={styles.close} onClick={closeSidePanel}>
              <Icon name="x-lg" />
            </button>
            {!panelType ? (
              <p>Error showing panel</p>
            ) : (
              <>
                {panelType === PanelTypes.EditPlan && (
                  <>
                    <h2>Edit Plan</h2>
                    <p>{plan?.bfPlanPath}</p>
                    <EditPlanForm plan={plan!} onSubmit={onSubmit} />
                    <div className={styles["danger-zone"]}>
                      <h3>Danger zone</h3>
                      <button
                        onClick={() => {
                          plan && showRemovePlanWarning();
                        }}
                      >
                        Delete plan
                      </button>
                    </div>
                  </>
                )}
                {panelType === PanelTypes.AddPlan && (
                  <>
                    <h2>Add Plan</h2>
                    <p>{selectedPlanToAdd.path}</p>
                    <EditPlanForm
                      plan={convertedPlanToAdd}
                      onSubmit={onSubmit}
                    />
                  </>
                )}
              </>
            )}
          </>
        )}
      </aside>
      <WarningDialog
        ref={dialogRef}
        buttonText={{ confirm: "Remove", cancel: "Cancel" }}
        onConfirm={async () => {
          if (planToDelete) {
            await removePlan(planToDelete);
          }
          setPlanToDelete(undefined);
        }}
      >
        Are you sure you want to remove <b>{planToDelete?.bfPlanPath}</b> from
        Flexcheck?
        <br />
        <br />
        This plan will still be available in Billforward.
      </WarningDialog>
    </>
  );
};

export default SidePanel;
