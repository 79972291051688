import React, {FC} from "react";
import {CustomizedPlan} from "../store/shared/plan/types";
import "./PriceText.scss";
import PriceText from "./PriceText";
import {useSelector} from "react-redux";
import {RootState} from "../store";

interface Props {
  plan: CustomizedPlan;
  type: "initial" | "per_period";
  showTax: boolean;
}

const TaxText: FC<Props> = (props) => {
  const taxAmount = props.type === "initial" ?
      (props.plan.price.initialPrice - props.plan.price.initialPriceExcludingTax) :
      (props.plan.price.pricePerPeriod - props.plan.price.pricePerPeriodExcludingTax);

  const bfAccountId = useSelector((state: RootState) => state.main.user.userDetails.bfAccountId);

  const shouldShowAnything = props.showTax &&
      (!props.plan.localisedTax || props.plan.bfAccountId === bfAccountId) &&
      taxAmount > 0;


  return (
      shouldShowAnything ? (
          <div className="tax-section">
            ({props.plan.taxStatus === "inclusive" ? "including" : "plus"}
            <PriceText
                currentPrice={taxAmount}
                currency={props.plan.baseCurrency}
                isSentence
            />
            taxes and fees)
          </div>
      ) : null);
};

export default TaxText;
