import {StyleActionTypes, UPDATE_BACKGROUND, UPDATE_LOGO} from "./types";

export function updateBackground(url: string, temporarilyOverrideTheStorefront: boolean): StyleActionTypes {
  return {
    type: UPDATE_BACKGROUND,
    payload: {
      url: url,
      temporary: temporarilyOverrideTheStorefront
    }
  }
}

export function updateLogo(url: string, temporarilyOverrideTheStorefront: boolean): StyleActionTypes {
  return {
    type: UPDATE_LOGO,
    payload: {
      url: url,
      temporary: temporarilyOverrideTheStorefront
    }
  }
}
