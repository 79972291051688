import {
  Details,
  UPDATE_BF_ACCOUNT_ID,
  UPDATE_CUSTOM_FIELD_INSTANCES,
  UPDATE_CUSTOMER_ID_AND_BF_ACCOUNT_ID,
  UPDATE_DETAILS,
  UPDATE_PAYMENT_METHOD,
  UserDetailsActionTypes
} from "./types";
import {PaymentMethod} from "../../shared/user/types";
import {CustomFieldInstance} from "../../portal/account/types";

export function updateDetails(newDetails: Details): UserDetailsActionTypes {
  return {
    type: UPDATE_DETAILS,
    payload: newDetails
  }
}

export function updatePaymentMethod(newPaymentMethod: PaymentMethod): UserDetailsActionTypes {
  return {
    type: UPDATE_PAYMENT_METHOD,
    payload: newPaymentMethod
  }
}

export function updateCustomerIdAndBfAccountId(newCustomerId: string, newBfAccountId: string): UserDetailsActionTypes {
  return {
    type: UPDATE_CUSTOMER_ID_AND_BF_ACCOUNT_ID,
    payload: [newCustomerId, newBfAccountId]
  }
}

export function updateBfAccountId(newBfAccountId: string): UserDetailsActionTypes {
  return {
    type: UPDATE_BF_ACCOUNT_ID,
    payload: newBfAccountId
  }
}

export function updateCustomFieldInstances(newCustomFieldInstances: Array<CustomFieldInstance>): UserDetailsActionTypes {
  return {
    type: UPDATE_CUSTOM_FIELD_INSTANCES,
    payload: newCustomFieldInstances
  }
}
