import { FC } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { PlanGroup } from "../store/user/planGroup/types";
import { getStoreRoute } from "../utils/utils";
import { selectStorefrontAlias } from "../store/storefront/selectors";

import styles from "./PlanGroupCard.module.scss";

interface Props {
  planGroup: PlanGroup;
}

const PlanGroupCard: FC<Props> = ({ planGroup }) => {
  const storefrontAlias = useSelector(selectStorefrontAlias);
  return (
    <article className={styles["plan-group-card"]}>
      <Link
        className={styles.link}
        to={getStoreRoute(storefrontAlias, planGroup.bfPlanPathPrefix)}
      >
        <img className={styles.background} src={planGroup.cardImg} alt="" />
        <section className={styles.content}>
          <h2>{planGroup.bfPlanPathPrefix}</h2>
        </section>
      </Link>
    </article>
  );
};

export default PlanGroupCard;
