import React, {RefObject} from "react";

import {RootState} from "../store";
import {connect, ConnectedProps} from "react-redux";
import {addToCart, clearCart, removeOne} from "../store/user/cart/actions";

import './CartDrawer.scss';
import {Button, ButtonGroup, Drawer, Icon} from "./shoelace";
import PlanCardMini from "./PlanCardMini";
import {CartLine} from "../store/user/cart/types";
import {CustomizedPlan} from "../store/shared/plan/types";
import {getIdenticalPlansInCart} from "../store/user/cart/reducers";
import {updateShowCart} from "../store/user/steps/actions";

import type SlDrawerElement from '@shoelace-style/shoelace/dist/components/drawer/drawer';

const mapState = (state: RootState) => ({
  cart: state.main.user.cart.cart,
  allowCart: state.main.storefront.storefront.allowCart
});

const mapDispatch = {
  addToCart: addToCart,
  removeOne: removeOne,
  clearCart: clearCart,
  updateShowCart: updateShowCart
};

const connector = connect(mapState, mapDispatch, null, {forwardRef: true});
type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
  handleCheckout: Function;
  showControls: boolean;
}

type State = {
}

class CartDrawer extends React.Component<Props, State> {
  private readonly drawer: RefObject<SlDrawerElement>;
  constructor(props: Props) {
    super(props);
    this.drawer = React.createRef<SlDrawerElement>();
  }

  public showCart = () => {
    try {
      this.drawer.current?.show();
    } catch(e) {
      console.error(e);
    }
  }
  public hideCart = () => {
    try {
      this.drawer.current?.hide();
    } catch {}
  }

  private hideAndHandleCheckout = () => {
    this.hideCart();
    this.props.handleCheckout();
  }

  private handleAddToCart = (plan: CustomizedPlan) => {
    this.props.addToCart(plan);
  }

  private handleClearCart = () => {
    this.props.clearCart();
  }

  shouldComponentUpdate(nextProps: Readonly<Props>, nextState: Readonly<State>, nextContext: any): boolean {
    this.forceUpdate();
    return true;
  }

  componentDidMount() {
    //I *DO NOT* understand why I need both of these lines in order to make the "View" button work on the details page.
    //  Either line does not work on its own ¯\_(ツ)_/¯
    this.props.updateShowCart(() => this.showCart());
    setTimeout(() => this.props.updateShowCart(() => this.showCart()));
  }

  render() {
    const isCartEmpty = this.props.cart.length === 0;
    let skipPlans: string[] = [];
    return(
        !this.props.allowCart ? null :
        <Drawer className={`cart-drawer`} label="Cart" ref={this.drawer}>
          <div className="cart-drawer-contents">
            {this.props.cart.map((cartLine: CartLine, i: number) => {
              if (skipPlans.includes(cartLine.id)) {
                return null;
              }
              const identicalPlans = getIdenticalPlansInCart(this.props.cart, cartLine.plan);
              const quantity = identicalPlans.length;
              skipPlans = [...skipPlans, ...identicalPlans.map(line => line.id)];
              const removeId = identicalPlans[identicalPlans.length - 1].id; //Use last item when removing one.
              return (<div className="cart-drawer-item-container" key={JSON.stringify(cartLine.plan)}>
                <PlanCardMini plan={cartLine.plan} key={i} showTax={true}/>
                <ButtonGroup>
                  <Button className="neutral tiny icon" pill onClick={() => this.props.removeOne(removeId)}
                          disabled={!this.props.showControls}>
                    {quantity === 1 ?
                        <Icon name="trash" /> : '-'}
                  </Button>
                  <div className="cart-drawer-item-quantity">{quantity}</div>
                  <Button className="neutral tiny" pill onClick={() => this.handleAddToCart(cartLine.plan)}
                          disabled={!this.props.showControls}>+</Button>
                </ButtonGroup>
              </div>)
            })}
          </div>
          <div className="cart-footer" slot="footer">
            <div className="cart-buttons" hidden={!this.props.showControls}>
              <Button className="small neutral" onClick={this.handleClearCart} disabled={isCartEmpty}>
                Clear cart
              </Button>
              <Button
                className="small primary"
                onClick={this.hideAndHandleCheckout}
                disabled={isCartEmpty || !this.props.showControls}
              >
                Checkout
              </Button>
            </div>
          </div>
        </Drawer>
    );
  };
}

export default connector(CartDrawer);
