import {RootState} from "./store";
import {connect, ConnectedProps, useDispatch, useSelector} from "react-redux";
import React, {useCallback, useEffect, useState} from "react";
import {Redirect, Route, Switch, useHistory, useLocation,} from "react-router-dom";
import {Helmet} from "react-helmet-async";
import moment from "moment";
import Header from "./components/Header";
import Home from "./components/pages/user/Home";
import Details from "./components/pages/user/Details";
import PaymentMethod from "./components/pages/user/PaymentMethod";
import Confirm from "./components/pages/user/Confirm";
import Complete from "./components/pages/user/Complete";
import {setCurrentStep} from "./store/user/steps/actions";
import {updateDetails, updatePaymentMethod,} from "./store/user/userDetails/actions";
import AlertStack from "./components/shared/AlertStack";
import CartDrawer from "./components/CartDrawer";
import Preview from "./components/pages/user/Preview";
import PlanGroup from "./components/pages/user/PlanGroup";
import {selectUserCart} from "./store/user/cart/selectors";
import useQuery from "./hooks/useQuery";
import {updateAvailablePlans} from "./store/user/plan/actions";
import {switchBackgroundToUser} from "./utils/style";
import {setSource} from "./store/persisted/source/actions";
import './translation';
import "./UserApp.scss";
import {resetUser} from "./store/user/resetUser";
import {fetchFlexcheckPlans} from "./api/quotes";
import {useTranslation} from "react-i18next";

const mapState = (state: RootState) => ({
  checkedOut: state.main.user.cart.checkedOut,
  paymentMethodId: state.main.user.cart.paymentMethodId,
  storefront: state.main.storefront.storefront,
  currentStep: state.main.user.steps.currentStep,
  alias: state.main.storefront.storefront.alias,
  firstName: state.main.user.userDetails.details.firstName,
  lastName: state.main.user.userDetails.details.lastName,
  companyName: state.main.user.userDetails.details.companyName,
  email: state.main.user.userDetails.details.email,
  customerId: state.main.user.userDetails.customerId,
  bfAccountId: state.main.user.userDetails.bfAccountId,
  portalStorefrontAliasLoggedInto: state.main.user.auth.portalStorefrontAliasLoggedInto,
});

const mapDispatch = {
  setCurrentStep: setCurrentStep,
  updateDetails: updateDetails,
  updatePaymentMethod: updatePaymentMethod,
  resetUser: resetUser
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {}

const UserApp = (props: Props) => {
  const cart = useSelector(selectUserCart);
  const query = useQuery();
  const history = useHistory();
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const [plansLoading, setPlansLoading] = useState(true);
  const [plansError, setPlansError] = useState(false);

  //TODO: Should source be removed from the URL?
  const source = query.get("source");

  if (source) {
    dispatch(setSource(source));
  }

  const getNextPageFromStep = (step: number) => {
    return () => history.push(getNextPagePathFromStep(step));
  }

  const getNextPagePathFromStep = (step: number) => {
    switch (step) {
      case 0:
        if (props.portalStorefrontAliasLoggedInto === props.storefront.alias) {
          if (props.paymentMethodId) {
            return `/store/${props.alias}/confirm`;
          } else {
            return (`/store/${props.alias}/payment-method`);
            }
        } else {
          return (`/store/${props.alias}/details`);
        }
      case 1:
        return `/store/${props.alias}/payment-method`;
      case 2:
        return `/store/${props.alias}/confirm`;
      case 3:
        return (`/store/${props.alias}/complete`);
      default:
        return (`/store/${props.alias}/`);
    }
  };

  const clearUserData = () => {
    if (props.portalStorefrontAliasLoggedInto !== props.alias) {
      // If the user is not logged in then we reset their data when they start at the website.
      props.resetUser();
    }

    if (
        query.get("firstName") ||
        query.get("lastName") ||
        query.get("companyName") ||
        query.get("email") ||
        query.get("addressLine1") ||
        query.get("city") ||
        query.get("province") ||
        query.get("country") ||
        query.get("postcode") ||
        query.get("shippingAddressLine1") ||
        query.get("shippingCity") ||
        query.get("shippingProvince") ||
        query.get("shippingCountry") ||
        query.get("shippingPostcode")
    ) {
      props.updateDetails({
        firstName: query.get("firstName") || "",
        lastName: query.get("lastName") || "",
        companyName: query.get("companyName") || "",
        email: query.get("email") || "",
        password: "",
        mobile: query.get("mobile") || "",
        landline: query.get("landline") || "",
        addressLine1: query.get("addressLine1") || "",
        addressLine2: query.get("addressLine2") || "",
        addressLine3: query.get("addressLine3") || "",
        city: query.get("city") || "",
        province: query.get("province") || "",
        country: query.get("country") || "GB",
        postcode: query.get("postcode") || "",
        shippingAddressLine1: query.get("shippingAddressLine1") || "",
        shippingAddressLine2: query.get("shippingAddressLine2") || "",
        shippingAddressLine3: query.get("shippingAddressLine3") || "",
        shippingCity: query.get("shippingCity") || "",
        shippingProvince: query.get("shippingProvince") || "",
        shippingCountry: query.get("shippingCountry") || "GB",
        shippingPostcode: query.get("shippingPostcode") || "",
      });
      const newLocation = history.location;
      const newSearch = new URLSearchParams(history.location.search);
      newSearch.delete("firstName");
      newSearch.delete("lastName");
      newSearch.delete("companyName");
      newSearch.delete("email");
      newSearch.delete("addressLine1");
      newSearch.delete("addressLine2");
      newSearch.delete("addressLine3");
      newSearch.delete("city");
      newSearch.delete("province");
      newSearch.delete("country");
      newSearch.delete("postcode");
      newSearch.delete("shippingAddressLine1");
      newSearch.delete("shippingAddressLine2");
      newSearch.delete("shippingAddressLine3");
      newSearch.delete("shippingCity");
      newSearch.delete("shippingProvince");
      newSearch.delete("shippingCountry");
      newSearch.delete("shippingPostcode");
      newLocation.search = newSearch.toString();
      history.push(newLocation);
    } else if (window.REACT_APP_DEV_MODE === "true" &&
                  props.portalStorefrontAliasLoggedInto !== props.storefront.alias) {
      const testEmail = moment().valueOf() + "@example.com";
      setTimeout(() => {
        props.updateDetails({
          firstName: props.firstName || "Test",
          lastName: props.lastName || "Test",
          companyName: props.companyName || "Test company",
          email: props.email || testEmail,
          password: "Test@1234",
          mobile: '',
          landline: '',
          addressLine1: "Test address line 1",
          addressLine2: "",
          addressLine3: "",
          city: "Test city",
          province: "Test province",
          country: "GB",
          postcode: "Test postcode",
          shippingAddressLine1: "Test shipping address line 1",
          shippingAddressLine2: "",
          shippingAddressLine3: "",
          shippingCity: "Test shipping city",
          shippingProvince: "Test shipping province",
          shippingCountry: "GB",
          shippingPostcode: "Test shipping postcode",
        });
        props.updatePaymentMethod({
          cardHolder: "Test",
          cardNumber: "4242424242424242",
          cvc: "424",
          expiryMonth: "12",
          expiryYear: "42",
        });
      });
    }
  };

  const isCartEmpty = () => cart.length === 0;
  const isNoneCheckedOut = () => props.checkedOut.length === 0;

  // Customer ID is required if the customer is not logged in.
  const missingCustomerId = () => !props.customerId && props.portalStorefrontAliasLoggedInto !== props.alias;

  const redirectComponent = (
    <Redirect to={{ pathname: `/store/${props.alias}/` }} />
  );

  const location = useLocation();
  let isPreview = location.pathname === `/store/${props.alias}/preview`;

  const handleCheckout = () => {
    getNextPageFromStep(0)();
  };

  const loadPlans = useCallback(async () => {
    try {
      setPlansLoading(true);
      const plans = await fetchFlexcheckPlans({coupons: [source]});
      dispatch(updateAvailablePlans(plans));
    } catch (err) {
      setPlansError(true);
    } finally {
      setPlansLoading(false);
    }
  }, [dispatch, source]);

  useEffect(() => {
    loadPlans();
  }, [loadPlans]);

  useEffect(() => {
    switchBackgroundToUser();
  }, []);

  return (
    <div className="user-container">
      <Helmet>
        <title>{props.storefront.name}</title>
      </Helmet>
      <Header
        showCurrencySelect={props.storefront.trading && props.currentStep === 0}
        showCartButton={props.storefront.trading && [0, 1, 2, 3].includes(props.currentStep)}
        showLogin={!isPreview && props.storefront.allowCustomerPortal}
        isPreview={isPreview}
        showStorefrontLink={false}
      />
      <CartDrawer
        handleCheckout={handleCheckout}
        showControls={props.currentStep === 0 || props.currentStep === 1}
      ></CartDrawer>
      {!props.storefront.trading && !isPreview ? (
        <div className="store-closed">
          <div className="message-container">
            <span>{t('storeClosed', {storeName: props.storefront.name})}</span>
          </div>
        </div>
      ) : (
        <Switch>
          <Route
            exact
            path={`/store/${props.alias}/`}
            render={() => {
              setTimeout(() => {
                clearUserData();
                console.log("Cleared");
              });
              return (<Redirect to={`/store/${props.alias}/plans`}/>);
            }}
          />
          <Route
              exact
              path={`/store/${props.alias}/plans`}
              render={() => {
                const step = 0;
                setTimeout(() => props.setCurrentStep(step));
                return (
                    <Home
                        loading={plansLoading}
                        error={plansError}
                        goToNextPage={getNextPageFromStep(step)}
                        urlSelectedPlanId={query.get("selectedPlanId")}
                    />
                );
              }}
          />
          <Route
            exact
            path={`/store/${props.alias}/group/:path+`}
            render={() => {
              const step = 0;
              setTimeout(() => props.setCurrentStep(step));
              return <PlanGroup goToNextPage={getNextPageFromStep(step)} />;
            }}
          />
          <Route
            exact
            path={`/store/${props.alias}/details`}
            render={() => {
              const step = 1;
              const nextPage = getNextPageFromStep(step);

              if (props.bfAccountId && props.customerId) {
                // If customer entity has already been created, simply go to next page
                console.log("Redirecting due to bfAccountId and customerId");
                return (<Redirect to={getNextPagePathFromStep(step)} />);
              }

              setTimeout(() => props.setCurrentStep(step));
              return isCartEmpty() ? (
                redirectComponent
              ) : (
                <Details nextPage={nextPage} />
              );
            }}
          />
          <Route
            exact
            path={`/store/${props.alias}/payment-method`}
            render={() => {
              const step = 2;
              setTimeout(() => props.setCurrentStep(step));
              const redirect = isCartEmpty() || missingCustomerId();
              if (redirect) {
                console.log("Redirecting: " + isCartEmpty() + " " + missingCustomerId());
              }
              return redirect ? redirectComponent :
                  (<PaymentMethod nextPage={getNextPageFromStep(step)} />);
            }}
          />
          <Route
            exact
            path={`/store/${props.alias}/confirm`}
            render={() => {
              const step = 3;
              setTimeout(() => props.setCurrentStep(step));
              return (isCartEmpty() && isNoneCheckedOut()) || missingCustomerId() ? (
                redirectComponent
              ) : (
                <Confirm nextPage={getNextPageFromStep(step)} />
              );
            }}
          />
          <Route
            exact
            path={`/store/${props.alias}/complete`}
            render={() => {
              const step = 4;
              setTimeout(() => props.setCurrentStep(step));
              return isCartEmpty() && isNoneCheckedOut() ? (
                redirectComponent
              ) : (
                <Complete
                  goHome={getNextPageFromStep(step)}
                  goToLogin={() => history.push(`/store/${props.alias}/login`)}
                  goToPortal={() =>
                    history.push(`/store/${props.alias}/portal`)
                  }
                />
              );
            }}
          />
          <Route
            exact
            path={`/store/${props.alias}/preview`}
            render={() => {
              const step = -1;
              setTimeout(() => props.setCurrentStep(step));
              return <Preview />;
            }}
          />
          <Route
            render={() => {
              isPreview = true;
              return redirectComponent;
            }}
          />
        </Switch>
      )}
      <AlertStack />
    </div>
  );
};

export default connector(UserApp);
