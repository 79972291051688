import {
    AccountActionTypes,
    AccountState,
    UPDATE_ACCOUNT_DETAILS,
    UPDATE_BILLING_ADDRESS,
    UPDATE_DELIVERY_ADDRESS,
    UPDATE_PAYMENT_METHODS
} from "./types";
import {SavedPaymentMethod} from "../../shared/user/types";

const initialState: AccountState = {
  paymentMethods: [],
  accountDetails: {
    firstName: '',
    lastName: '',
    email: '',
    customFields: []
  }
};

const findNewPrimaryPaymentMethod = (paymentMethods: Array<SavedPaymentMethod>): (SavedPaymentMethod | undefined) => {
  return paymentMethods.find(pm => pm.isPrimary) || undefined;
}

export function accountReducer(state: AccountState = initialState, action: AccountActionTypes): AccountState {
  switch (action.type) {
    case UPDATE_BILLING_ADDRESS:
      return { ...state, billingAddress: action.payload };
    case UPDATE_DELIVERY_ADDRESS:
      return { ...state, deliveryAddress: action.payload };
    case UPDATE_PAYMENT_METHODS:
      return { ...state, paymentMethods: action.payload, primaryPaymentMethod: findNewPrimaryPaymentMethod(action.payload) };
    case UPDATE_ACCOUNT_DETAILS:
      return { ...state, accountDetails: action.payload };
    default:
      return state;
  }
}
