// State

export interface StepsState {
  currentStep: number;
  totalSteps: number;
  showCart: () => void;
}

// Actions

export const NEXT_STEP = 'NEXT_STEP';
export const SET_CURRENT_STEP = 'SET_CURRENT_STEP';
export const UPDATE_SHOW_CART = 'UPDATE_SHOW_CART';

interface UpdateShowCartAction {
  type: typeof UPDATE_SHOW_CART,
  payload: () => void
}
interface NextStepAction {
  type: typeof NEXT_STEP;
}
interface SetCurrentStepAction {
  type: typeof SET_CURRENT_STEP;
  payload: number;
}

export type StepsActionTypes = NextStepAction | SetCurrentStepAction | UpdateShowCartAction;
