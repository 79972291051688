import React from "react";
import MerchantPage from "../../MerchantPage";
import SettingsForm from "../../SettingsForm";
import StorefrontUrlDetails from "../../StorefrontUrlDetails";

const Settings = () => {
  return (
    <MerchantPage
      title="Settings"
      description="Configure your storefront details and operations"
      className="settings-page"
    >
      <StorefrontUrlDetails />
      <SettingsForm />
    </MerchantPage>
  );
};

export default Settings;
