import {
  UPDATE_BF_ACCOUNT_ID,
  UPDATE_CUSTOM_FIELD_INSTANCES,
  UPDATE_CUSTOMER_ID_AND_BF_ACCOUNT_ID,
  UPDATE_DETAILS,
  UPDATE_PAYMENT_METHOD,
  UserDetailsActionTypes,
  UserDetailsState
} from "./types";
import {RESET_USER} from "../resetUser";

const initialState: UserDetailsState = {
    details: {
      firstName: '',
      lastName: '',
      companyName: '',
      email: '',
      password: '',
      mobile: '',
      landline: '',
      addressLine1: '',
      addressLine2: '',
      addressLine3: '',
      city: '',
      province: '',
      country: '',
      postcode: '',
      shippingAddressLine1: '',
      shippingAddressLine2: '',
      shippingAddressLine3: '',
      shippingCity: '',
      shippingProvince: '',
      shippingCountry: '',
      shippingPostcode: ''
    },
    paymentMethod: {
      cardHolder: '',
      cardNumber: '',
      cvc: '',
      expiryMonth: '',
      expiryYear: ''
    },
    customerId: '',
    bfAccountId: '',
    customFieldInstances: []
}

export function userDetailsReducer(state: UserDetailsState = initialState, action: UserDetailsActionTypes): UserDetailsState {
  switch (action.type) {
    case UPDATE_DETAILS:
      return { ...state, details: action.payload };
    case UPDATE_PAYMENT_METHOD:
      return { ...state, paymentMethod: action.payload };
    case UPDATE_BF_ACCOUNT_ID:
      return { ...state, bfAccountId: action.payload };
    case UPDATE_CUSTOMER_ID_AND_BF_ACCOUNT_ID:
      return { ...state, customerId: action.payload[0], bfAccountId: action.payload[1] };
    case UPDATE_CUSTOM_FIELD_INSTANCES:
      return { ...state, customFieldInstances: action.payload };
    case RESET_USER:
      return initialState;
    default:
      return state;
  }
}
