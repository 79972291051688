import {CouponActionTypes, CouponState, REMOVE_COUPON, UPDATE_COUPON_VALUE} from "./types";
import {RESET_USER} from "../resetUser";

const initialState: CouponState = {
  couponValue: ''
}

export function couponReducer(state: CouponState = initialState, action: CouponActionTypes): CouponState {
  switch (action.type) {
    case UPDATE_COUPON_VALUE:
      return { ...state, couponValue: action.payload };
    case REMOVE_COUPON:
      return { ...state, couponValue: initialState.couponValue};
    case RESET_USER:
      return initialState;
    default:
      return state;
  }
}
