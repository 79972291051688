//https://redux.js.org/style-guide/#allow-many-reducers-to-respond-to-the-same-action
export const RESET_USER = 'RESET_USER';
export interface ResetUserAction {
    type: typeof RESET_USER;
}
export function resetUser(): ResetUserAction {
    return {
        type: RESET_USER
    }
}
