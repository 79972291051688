import React, { FC } from "react";
import gfm from "remark-gfm";
import ReactMarkdown from "react-markdown";

interface Props {
  content: string;
  className?: string;
}

const PlanCardMarkdown: FC<Props> = ({ content, className }) => {
  return (
    <ReactMarkdown
      className={className}
      plugins={[gfm]}
      children={content}
      linkTarget="_blank"
    />
  );
};

export default PlanCardMarkdown;
