import {
  CLOSE_PANEL,
  CLOSE_PANEL_FINISH,
  CLOSE_PANEL_START,
  OPEN_PANEL,
  OPEN_PANEL_FINISH,
  OPEN_PANEL_START,
  PanelActionTypes,
  PanelOptions,
} from "./types";

const openPanelStart = (panelOptions: PanelOptions): PanelActionTypes => {
  return {
    type: OPEN_PANEL_START,
    payload: panelOptions,
  };
};

const closePanelStart = (): PanelActionTypes => {
  return {
    type: CLOSE_PANEL_START,
  };
};

const openPanel = (panelOptions: PanelOptions): PanelActionTypes => {
  return {
    type: OPEN_PANEL,
    payload: panelOptions,
  };
};

const closePanel = (): PanelActionTypes => {
  return {
    type: CLOSE_PANEL,
  };
};

const openPanelFinish = (): PanelActionTypes => {
  return {
    type: OPEN_PANEL_FINISH,
  };
};

const closePanelFinish = (): PanelActionTypes => {
  return {
    type: CLOSE_PANEL_FINISH,
  };
};

export {
  openPanelStart,
  closePanelStart,
  openPanel,
  closePanel,
  openPanelFinish,
  closePanelFinish,
};
