import React from "react";

import {RootState} from "../store";
import {connect, ConnectedProps} from "react-redux";
import {Tab, TabGroup, TabPanel} from "./shoelace";

import './ImageUpload.scss';
import ImageUploadFromUrl from "./ImageUploadFromUrl";
import ImageUploadFromFile from "./ImageUploadFromFile";
import {LookAndFeelContext} from "./LookAndFeelForm";

export type UserImageType = 'background' | 'logo' | 'plan';

const mapState = (state: RootState) => ({
  bfOrganizationId: state.main.storefront.storefront.bfOrganizationId
});

const mapDispatch = {
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
  onSuccess: (url: string) => void,
  userImageType: UserImageType,
  initialSrc?: string
}

interface State {
  isUploadedImage: boolean,
  fileName?: string
}

class ImageUploadTabs extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    let isUploadedImage = false;

    if (props.initialSrc?.includes(props.bfOrganizationId)) {
      // The image URL contains the org id, so we can assume it's been uploaded by the user (since we include the org id in all user-uploaded image file names)
      isUploadedImage = true;
    }

    this.state = {
      isUploadedImage: isUploadedImage,
      fileName: isUploadedImage
        ? props.initialSrc?.split(props.bfOrganizationId)[1].substr(1) // Get original filename from end of bucket filename, and strip file extension
        : undefined
    }
  }

  render() {
    return (
      <LookAndFeelContext.Consumer>
        {context => <TabGroup>
          <Tab slot="nav" panel="url" active={!this.state.isUploadedImage}>Use URL</Tab>
          <Tab slot="nav" panel="file" active={this.state.isUploadedImage}>Upload File</Tab>
          <TabPanel name="url">
            <ImageUploadFromUrl
              initialSrc={this.state.isUploadedImage ? '' : this.props.initialSrc}
              onSuccess={this.props.onSuccess}
              reloadPreview={context.reloadPreview}
            />
          </TabPanel>
          <TabPanel name="file">
            <ImageUploadFromFile
              initialSrc={this.state.isUploadedImage ? this.props.initialSrc : undefined}
              fileName={this.state.fileName}
              onSuccess={this.props.onSuccess}
              reloadPreview={context.reloadPreview}
              setSaveButtonDisabled={context.setSaveButtonDisabled}
              userImageType={this.props.userImageType}
            />
          </TabPanel>
        </TabGroup>}
      </LookAndFeelContext.Consumer>
    )
  }
}

export default connector(ImageUploadTabs);
