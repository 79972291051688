import { FC } from "react";
import { Subscription } from "../../store/portal/subscription/types";
import { PlanGroup } from "../../store/user/planGroup/types";
import styles from "./SubscriptionLink.module.scss";
import { Icon } from "../shoelace";

interface Props {
  subscription: Subscription;
  planGroup?: PlanGroup;
  goToSubscriptionPage: (id: string) => void;
}

const SubscriptionLink: FC<Props> = ({
  subscription,
  planGroup,
  goToSubscriptionPage,
}) => {
  const handleClick = () => {
    goToSubscriptionPage(subscription.id);
  };
  return (
    <button className={styles["subscription-link"]} onClick={handleClick}>
      <img
        className={styles.img}
        src={planGroup?.cardImg || subscription.plan.imageUrl}
        alt=""
      />
      <span>{planGroup?.bfPlanPathPrefix || subscription.name}</span>
      <Icon className={styles.icon} name="chevron-right" />
    </button>
  );
};

export default SubscriptionLink;
