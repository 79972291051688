import {formatPeriod} from "../utils/utils";
import {
  ApiAccount,
  ApiInvoice,
  ApiInvoiceStatus,
  ApiPaymentMethod,
  ApiPlan,
  ApiSubscription,
  CartQuote,
  CompositeQuote,
  CustomField,
  ErrorCode
} from "./types";
import {Plan, PlanWithoutQuote} from "../store/shared/plan/types";
import moment from "moment";
import {SavedPaymentMethod} from "../store/shared/user/types";
import {Subscription, SubscriptionStatus} from "../store/portal/subscription/types";
import {Payment, PaymentStatus} from "../store/portal/payment/types";
import {AccountDetails} from "../store/portal/account/types";
import {
  getCustomizedPricingComponentsFromApiSubscription,
  planToCustomizedPlanWithoutQuote
} from "../store/shared/plan/utils";
import {Cart, CartIdMap, CartLine} from "../store/user/cart/types";
import {Moment} from "moment/moment";

// Quote data is not always needed, such as when displaying plan data for an existing subscription
export const apiPlanToPlanWithoutQuote = (apiPlan: ApiPlan, disabled: boolean = false): PlanWithoutQuote => ({
  id: apiPlan.id,
  name: apiPlan.definition.displayName || apiPlan.definition.name,
  description: apiPlan.definition.description || '',
  period: formatPeriod(apiPlan.definition.duration, apiPlan.definition.durationPeriod),
  fixedTerm: { periods: apiPlan.definition.fixedTerm?.periods },
  baseCurrency: apiPlan.definition.currency.toUpperCase(),
  bfId: apiPlan.definition.id,
  bfPlanPath: apiPlan.bfPlanPath,
  displayIndex: apiPlan.index,
  hidden: apiPlan.hidden,
  disabled,
  cardText: apiPlan.cardText,
  colour: apiPlan.colour,
  pricing: apiPlan.definition.pricing,
  trialDescription: apiPlan.definition.trialDescription ?? "",
  hasUsage: apiPlan.definition.hasUsage ?? false,
  oneOffPayment: apiPlan.definition.oneOffPayment ?? false,
  imageUrl: apiPlan.imageUrl,
  metadata: apiPlan.definition.metadata,
  taxStatus: apiPlan.definition.taxStatus,
  localisedTax: apiPlan.definition.localisedTax
})

export const apiPlanToPlan = (apiPlan: ApiPlan, quote: CompositeQuote, disabled: boolean = false): Plan => ({
  ...apiPlanToPlanWithoutQuote(apiPlan, disabled),
  price: {
    initialPrice: quote.initialQuote?.subtotal,
    discountedInitialPrice: quote.initialQuote?.total,
    pricePerPeriod: quote.recurringQuote?.subtotal,
    discountedPricePerPeriod: quote.recurringQuote?.total,
    initialPriceExcludingTax: quote.initialQuote?.subtotalExcludingTax,
    discountedInitialPriceExcludingTax: quote.initialQuote?.totalExcludingTax,
    pricePerPeriodExcludingTax: quote.recurringQuote?.subtotalExcludingTax,
    discountedPricePerPeriodExcludingTax: quote.recurringQuote?.totalExcludingTax,
    taxPerPeriod: quote.recurringQuote?.tax,
    immediatePayment: quote.initialQuote?.immediatePayment,
    immediateTax: quote.initialQuote?.immediateTax
  },
  periodLengthInMonths: moment.unix(Number(quote.initialQuote?.periodEnd)).diff(moment.unix(Number(quote.initialQuote?.periodStart)), 'months', true),
  firstPeriodEnd: quote.initialQuote?.periodEnd,
  firstRecurringPaymentDate: quote.initialQuote?.firstRecurringPaymentDate,
})

export const getSubscriptionStatus = (apiSubscription: ApiSubscription): SubscriptionStatus => {
  if (apiSubscription.locked === 'Paused') return 'paused';

  switch (apiSubscription.state) {
    case 'AwaitingPayment': return 'awaiting payment';
    case 'Cancelled': return 'cancelled';
    case 'Expired': return 'cancelled';
    case 'Failed': return 'cancelled';
    case 'Paid': return 'active';
    case 'Provisioned': return 'active';
    case 'Trial': return 'active';
  }
}

export const apiSubscriptionToSubscription = (apiSubscription: ApiSubscription): Subscription => ({
  id: apiSubscription.id,
  name: apiSubscription.planName || apiSubscription.plan.definition.displayName || apiSubscription.plan.definition.name,
  status: getSubscriptionStatus(apiSubscription),
  plan: planToCustomizedPlanWithoutQuote(
    apiPlanToPlanWithoutQuote(apiSubscription.plan),
    getCustomizedPricingComponentsFromApiSubscription(apiSubscription)
  ),
  currentPeriodEnd: apiSubscription.currentPeriodEnd ? parseInt(apiSubscription.currentPeriodEnd) : undefined,
  pricingComponentValues: apiSubscription.pricingComponentValues || [],
  resumeDate: apiSubscription.resumeDate ? parseInt(apiSubscription.resumeDate) : undefined,
  subscriptionEnd: apiSubscription.subscriptionEnd ? parseInt(apiSubscription.subscriptionEnd) : undefined
})

export const apiInvoiceStatusToPaymentStatus = (status: ApiInvoiceStatus): PaymentStatus => {
  switch (status) {
    case 'Paid': return 'paid';
    case 'Pending': return 'pending';
    case 'Unpaid': return 'unpaid';
    case 'Voided': return 'voided';
  }
}

export const apiInvoiceToPayment = (apiInvoice: ApiInvoice): Payment => {
  const subscription = apiSubscriptionToSubscription(apiInvoice.subscription);
  return {
    id: apiInvoice.id,
    subscription,
    total: apiInvoice.invoiceCost,
    date: parseInt(apiInvoice.issued),
    subscriptionName: subscription.name,
    status: apiInvoiceStatusToPaymentStatus(apiInvoice.state),
    paymentMethod: apiInvoice.cardPaymentMethod ? apiPaymentMethodToSavedPaymentMethod(apiInvoice.cardPaymentMethod) : undefined,
    paymentAttempts: apiInvoice.invoicePayments
  }
}

export const apiPaymentMethodToSavedPaymentMethod = (apiPaymentMethod: ApiPaymentMethod): SavedPaymentMethod => {
  return {
    id: apiPaymentMethod.id,
    cardHolder: apiPaymentMethod.cardHolderName ?? '',
    firstSix: apiPaymentMethod.firstSix ?? '',
    lastFour: apiPaymentMethod.lastFour ?? '',
    expiryMonth: apiPaymentMethod.expiryMonth ?? '',
    expiryYear: apiPaymentMethod.expiryYear ?? '',
    isPrimary: apiPaymentMethod.defaultPaymentMethod,
    cardType: apiPaymentMethod.cardType
  };
}

export const apiAccountToAccountDetails = (apiAccount: ApiAccount, customFields: Array<CustomField>): AccountDetails => ({
  firstName: apiAccount.profile.firstName ?? "",
  lastName: apiAccount.profile.lastName ?? "",
  email: apiAccount.profile.email ?? "",
  // Create custom field instances by mapping each custom field name to the content found in the metadata
  customFields: customFields.map(customField => ({
    customField,
    content: apiAccount.metadata[customField.name] ?? ""
  }))
})

export const cartToCartIdMap = (cart: Cart): CartIdMap => { // Assign each instance of a plan as a key-value pair
  let cartIdMap: CartIdMap = {};
  for (const line of cart) {
    cartIdMap[line.id] = line;
  }
  return cartIdMap;
}

export const getErrorCode = (code: string) => {
  switch (code) {
    case 'InvalidCoupon':
      return ErrorCode.InvalidCoupon;
    case 'InvalidEmail':
      return ErrorCode.InvalidEmail;
    case 'EmailInUse':
      return ErrorCode.EmailInUse;
    case 'UnpaidInvoiceExists':
      return ErrorCode.UnpaidInvoiceExists;
    case 'PlanDoesNotExist':
      return ErrorCode.PlanDoesNotExist;
    default:
      return -1;
  }
}

function perMonth(taxOnly: boolean, discounted: boolean) {
  return function(cartLine: CartLine) {
    // Always includes tax because it is shown on the confirmation page.

    let value = discounted ?
        cartLine.plan.price.discountedPricePerPeriod : cartLine.plan.price.pricePerPeriod;
    if (taxOnly) {
    const valueExcludingTax = discounted ?
        cartLine.plan.price.discountedPricePerPeriodExcludingTax : cartLine.plan.price.pricePerPeriodExcludingTax;
      value = value - valueExcludingTax;
    }
    return value / cartLine.plan.periodLengthInMonths;
  };
}

export const cartToQuote = (cart: Cart): Promise<CartQuote> => {
  const cartPlans = Object.values(cartToCartIdMap(cart)); //I guess this de-duplicates by line id?

  const add = (a: number, b: number) => a+b;
  const sortDate = (a: Moment, b: Moment) => a.unix()-b.unix();
  return new Promise<CartQuote>((resolve, reject) => {

    resolve({
      recurringAveragePricePerMonth: cartPlans
          .filter(plan => !plan.plan.oneOffPayment)
          .map(perMonth(false, false))
          .reduce(add, 0),
      discountedRecurringAveragePricePerMonth: cartPlans
          .filter(plan => !plan.plan.oneOffPayment)
          .map(perMonth(false, true))
          .reduce(add, 0),
      discountedRecurringAverageTaxPerMonth: cartPlans
          .filter(plan => !plan.plan.oneOffPayment)
          .map(perMonth(true, true))
          .reduce(add, 0),
      immediatePayment: cartPlans
          .map(plan => plan.plan.price.immediatePayment)
          .reduce(add, 0),
      immediateTax: cartPlans
          .map(plan => plan.plan.price.immediateTax)
          .reduce(add, 0),
      firstRecurringPaymentDate: cartPlans
          .map(plan => moment.unix(Number(plan.plan.firstRecurringPaymentDate)))
          .sort(sortDate)[0],
      isOneOff: !cartPlans.some(plan => !plan.plan.oneOffPayment)
    });
  });
}

