import React from "react";
import { RootState } from "../../store";
import { connect, ConnectedProps } from "react-redux";
import { Link } from "react-router-dom";
import { isBeforeNow } from "../../store/persisted/style/types";

import './LogoImage.scss'

const mapState = (state: RootState) => ({
  usingTemporaryStyling: state.temporaryStyle.usingTemporaryStyling,
  tempLogoImageUrl: state.temporaryStyle.logoImageUrl,
  perLogoImageUrl: state.main.storefront.storefront.logoUrl,
  storefront: state.main.storefront.storefront,
});

const mapDispatch = {};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
  link?: string;
}

type State = {
  logoSrc: string | undefined;
};

class LogoImage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      logoSrc: this.getLogoSrc(props),
    };
  }

  private getLogoSrc = (props: Props) => {
    return (
      (!isBeforeNow(props.usingTemporaryStyling)
        ? props.tempLogoImageUrl
        : undefined) ||
      props.perLogoImageUrl ||
      undefined
    );
  };

  private handleLogoError = () => {
    this.setState({ logoSrc: undefined });
  };

  private getLogoElement = () => (
    <div className="header-logo-holder">
      {this.state.logoSrc ? (
        <img
          src={this.state.logoSrc}
          onError={this.handleLogoError}
          alt="Header logo"
          // TODO: remove id, looks like it's just being used for styling
          id="flexcheck-header-logo"
        />
      ) : (
        <h1 className='title'>
          {this.props.storefront.name}
        </h1>
      )}
    </div>
  );

  render() {
    return this.props.link ? (
      <Link className="header-logo-link" to={this.props.link}>
        {this.getLogoElement()}
      </Link>
    ) : (
      this.getLogoElement()
    );
  }
}

export default connector(LogoImage);
