import React, { FC } from "react";
import { useSelector } from "react-redux";
import { Button, Icon } from "./shoelace";
import { addTrailingSlash } from "../utils/utils";
import { selectStorefrontAlias } from "../store/storefront/selectors";

import "./PreviewCheckoutPanel.scss";
interface Props {
  reloadPreview: () => void;
}

const PreviewCheckoutPanel: FC<Props> = ({
  reloadPreview,
}) => {
  const alias = useSelector(selectStorefrontAlias);

  return (
    <div className="preview-checkout-panel-container">
      <div className="preview-checkout-panel-title-bar">
        <h2 className="preview-checkout-panel-title">Preview</h2>
        <Button
          className="preview-reload-button neutral tiny icon"
          onClick={reloadPreview}
        >
          <Icon name="arrow-repeat" label="Reload preview" />
        </Button>
      </div>
      <div className="preview-checkout-panel">
        {/*TODO: Replace with sl-responsive-media to see if it works */}
        {/*<ResponsiveEmbed>*/}
        <iframe
          title="Preview"
          src={`${addTrailingSlash(
            window.REACT_APP_FLEXCHECK_DOMAIN
          )}store/${alias}/preview`}
          style={{ width: "100%", height: "500px" }}
          id="preview-iframe"
        ></iframe>
        {/*</ResponsiveEmbed>*/}
      </div>
    </div>
  );
};

export default PreviewCheckoutPanel;
