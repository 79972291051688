interface MenuRoute {
  path: string;
  label: string;
  icon: string;
}

enum ROUTES {
  MERCHANT_PLANS = '/merchant/plans',
  MERCHANT_ADD_PLAN = '/merchant/add-plan',
  MERCHANT_EDIT_PLAN = '/merchant/edit-plan',
  MERCHANT_STYLE = '/merchant/look-feel',
  MERCHANT_SETTINGS = '/merchant/settings',
  MERCHANT_LOGIN = '/login'
}

const MERCHANT_MENU_ROUTES: MenuRoute[] = [
  {
    path: "/merchant/plans",
    label: "Plans",
    icon: 'briefcase-fill'
  },
  {
    path: "/merchant/look-feel",
    label: "Style",
    icon: 'easel-fill'
  },
  {
    path: "/merchant/settings",
    label: "Settings",
    icon: 'gear-fill'
  }
];

export { ROUTES, MERCHANT_MENU_ROUTES };
