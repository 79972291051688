// State

export interface StyleState {
  backgroundImageUrl: string,
  logoImageUrl: string,
  usingTemporaryStyling: number | null
}

export function isBeforeNow(tempStylingUntil: number | null) {
  if (!tempStylingUntil) {
    return false;
  }
  return tempStylingUntil <= new Date().getTime();
}

// Actions

export const UPDATE_BACKGROUND = 'UPDATE_BACKGROUND';
export const UPDATE_LOGO = 'UPDATE_LOGO';

interface UpdateBackgroundAction {
  type: typeof UPDATE_BACKGROUND;
  payload: {
    url: string,
    temporary: boolean
  };
}
interface UpdateLogoAction {
  type: typeof UPDATE_LOGO;
  payload: {
    url: string,
    temporary: boolean
  };
}

export type StyleActionTypes = UpdateBackgroundAction | UpdateLogoAction;
