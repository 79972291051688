import {ApiPaymentMethod} from "./types";
import {
  apiAccountToAccountDetails,
  apiInvoiceToPayment,
  apiPaymentMethodToSavedPaymentMethod,
  apiSubscriptionToSubscription
} from "./conversions";
import {store} from "../index";
import {updateSubscriptions} from "../store/portal/subscription/actions";
import {updatePayments} from "../store/portal/payment/actions";
import {
  updateAccountDetails,
  updateBillingAddress,
  updateDeliveryAddress,
  updatePaymentMethods
} from "../store/portal/account/actions";
import {apiGetAccount, apiGetPaymentMethods, apiGetPayments, apiGetSubscriptions} from "./portalEndpoints";
import {updateBfAccountId} from "../store/user/userDetails/actions";
import {loggedInPaymentMethod} from "../store/user/cart/actions";

export const fetchSubscriptions = async () => {
  let response = await apiGetSubscriptions();
  store.dispatch(updateSubscriptions(
      response.data.map(s => apiSubscriptionToSubscription(s))
  ));
}

const fetchPayments = async () => {
  let response = await apiGetPayments();
  store.dispatch(updatePayments(
      response.data.map(p => apiInvoiceToPayment(p))
  ));
}

function updateWithDefaultPaymentMethodId(paymentMethods: Array<ApiPaymentMethod> | undefined) {
  if (!paymentMethods) {
    return;
  }
  for (const paymentMethod of paymentMethods) {
    if (paymentMethod.id && paymentMethod.state === 'Active' && paymentMethod.defaultPaymentMethod) {
      store.dispatch(loggedInPaymentMethod(paymentMethod.id));
      return;
    }
  }
}

const fetchAccountData = async () => {
  let accountsResponse = await apiGetAccount();
  const account = accountsResponse.data;
  const billingAddress = account.profile.addresses?.find(address => address.primaryAddress);
  const deliveryAddress = account.profile.addresses?.find(address => address.primaryShippingAddress);
  if (billingAddress)
    store.dispatch(updateBillingAddress(billingAddress));
  if (deliveryAddress)
    store.dispatch(updateDeliveryAddress(deliveryAddress));
  const customFields = store.getState().main.storefront.customFields;
  store.dispatch(updateAccountDetails(
      apiAccountToAccountDetails(account, customFields)
  ));
  store.dispatch(updateBfAccountId(account.id));
  updateWithDefaultPaymentMethodId(account.paymentMethods);
}

export const fetchPaymentMethods = async () => {
  let paymentMethodsResponse = await apiGetPaymentMethods();
  store.dispatch(updatePaymentMethods(
      paymentMethodsResponse.data.map(p => apiPaymentMethodToSavedPaymentMethod(p))
  ));
};

export const fetchPortalData = () => {
  return Promise.all([
    fetchSubscriptions(),
    fetchPayments(),
    fetchPaymentMethods(),
    fetchAccountData()
  ])
}

