import React, {RefObject} from "react";
import {Checkbox, Input, TextArea} from "../shoelace";
import FormSectionHeader from "./FormSectionHeader";
import {CustomFieldInstance} from "../../store/portal/account/types";
import SlInputElement from "@shoelace-style/shoelace/dist/components/input/input";
import SlCheckboxElement from "@shoelace-style/shoelace/dist/components/checkbox/checkbox";

type Props = {
  customFieldInstances: Array<CustomFieldInstance>,
  updateCustomFieldContent: (name: string, content: string) => void,
  title?: string,
  isLoading: boolean
}

type State = {
}

class EditCustomFieldsForm extends React.Component<Props, State> {
  private readonly customFieldRefs: Array<RefObject<SlInputElement | SlCheckboxElement>>;

  constructor(props: Props) {
    super(props);

    this.customFieldRefs = this.props.customFieldInstances.map(c => React.createRef<SlInputElement>());
  }

  onCustomFieldInputUpdate = (index: number) => () => {
    const component = this.customFieldRefs[index].current!;
    if ('checked' in component) {
      this.props.updateCustomFieldContent(this.props.customFieldInstances[index].customField.name, component.checked.toString());
    } else {
      this.props.updateCustomFieldContent(this.props.customFieldInstances[index].customField.name, component.value);
    }
  }

  componentDidMount() {
    for (let i = 0; i < this.props.customFieldInstances.length; i++) {
      try {
        const component = this.customFieldRefs[i].current!;
        if ('checked' in component) {
          component.addEventListener('sl-change', this.onCustomFieldInputUpdate(i));
          this.props.updateCustomFieldContent(this.props.customFieldInstances[i].customField.name, component.checked.toString());
        } else {
          component.addEventListener('sl-input', this.onCustomFieldInputUpdate(i));
        }
      } catch {
        console.error(`Could not find custom field input component for "${this.props.customFieldInstances[i].customField.name}" in DOM`);
      }
    }
  }

  toFieldHtml = (field: CustomFieldInstance, i: number) => {
    if (field.customField.valueType === 'String') {
      return (<TextArea
          key={field.customField.name}
          className={`form-input light`}
          name={field.customField.name}
          ref={this.customFieldRefs[i]}
          value={field.content}
          label={field.customField.displayName ?? field.customField.name}
          disabled={this.props.isLoading}
          resize="none">
      </TextArea>);
    } else if (field.customField.valueType === 'Number') {
      return (<Input
          key={field.customField.name}
          className={`form-input light`}
          name={field.customField.name}
          ref={this.customFieldRefs[i]}
          value={field.content}
          label={field.customField.displayName ?? field.customField.name}
          type="number"
          disabled={this.props.isLoading}>
      </Input>);
    } else {
      //Boolean
      return (<Checkbox
          key={field.customField.name}
          className={`form-input light gap-above`} //gap-above because the label for a checkbox is not above it
          name={field.customField.name}
          ref={this.customFieldRefs[i]}
          value={"true"}
          disabled={this.props.isLoading}>
        {field.customField.displayName ?? field.customField.name}
      </Checkbox>);
    }
  }

  render() {
    return <>{this.props.customFieldInstances.length > 0 && this.props.title
      ? <FormSectionHeader title={this.props.title}/> : null}
      <div className={"form-section"}>
        {this.props.customFieldInstances.map(this.toFieldHtml)}
      </div></>
  }

  shouldComponentUpdate(nextProps: Readonly<Props>, nextState: Readonly<State>, nextContext: any): boolean {
    this.forceUpdate(); return true;
  }
}

export default EditCustomFieldsForm
