import {AuthActionTypes, AuthState, MERCHANT_LOGIN, MERCHANT_LOGOUT, PORTAL_LOGIN, PORTAL_LOGOUT} from "./types";
import moment from "moment";

const initialState: AuthState = {
  merchantIsLoggedIn: false
}

export function authReducer(state: AuthState = initialState, action: AuthActionTypes): AuthState {
  switch (action.type) {
    case MERCHANT_LOGIN:
      return { ...state, merchantIsLoggedIn: true };
    case MERCHANT_LOGOUT:
      return { ...state, merchantIsLoggedIn: false };
    case PORTAL_LOGIN:
      return { ...state,
        portalStorefrontAliasLoggedInto: action.payload.storefrontAlias,
        portalTokenData: action.payload.tokenData,
        portalLoginExpiryTime: moment().add(action.payload.tokenData.expires_in, 's').toDate()
      };
    case PORTAL_LOGOUT:
      return { ...state, portalStorefrontAliasLoggedInto: undefined, portalTokenData: undefined, portalLoginExpiryTime: undefined };
    default:
      return state;
  }
}
