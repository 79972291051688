import {RootState} from "../../../store";
import {connect, ConnectedProps} from "react-redux";
import React from "react";
import {getUserBackgroundStyle, objectToCssString} from "../../../utils/utils";
import {isBeforeNow} from "../../../store/persisted/style/types";

const mapState = (state: RootState) => ({
  usingTemporaryStyling: state.temporaryStyle.usingTemporaryStyling,
  tempBackgroundUrl: state.temporaryStyle.backgroundImageUrl,
});

const mapDispatch = {
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
}
interface State {
}

class Preview extends React.Component<Props, State> {
  componentDidMount() {
    const bg = isBeforeNow(this.props.usingTemporaryStyling) && this.props.tempBackgroundUrl != null ? undefined : this.props.tempBackgroundUrl
    document.body.setAttribute('style', objectToCssString(getUserBackgroundStyle(bg)));
  }

  componentWillUnmount() {
    document.body.setAttribute('style', objectToCssString(getUserBackgroundStyle()));
  }

  render() {
    return null;
  }
}

export default connector(Preview);
