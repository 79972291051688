import {
  Storefront,
  StorefrontActionTypes, StorefrontUiOptions,
  UPDATE_CUSTOM_FIELDS,
  UPDATE_SQUARE_CONFIG,
  UPDATE_STOREFRONT, UPDATE_UI_OPTIONS
} from "./types";
import {CustomField, SquareConfig} from "../../api/types";

export function updateStorefront(storefront: Storefront): StorefrontActionTypes {
  return {
    type: UPDATE_STOREFRONT,
    payload: storefront
  }
}

export function updateSquareConfig(squareConfig: SquareConfig): StorefrontActionTypes {
  return {
    type: UPDATE_SQUARE_CONFIG,
    payload: squareConfig
  }
}

export function updateCustomFields(customFields: Array<CustomField>): StorefrontActionTypes {
  return {
    type: UPDATE_CUSTOM_FIELDS,
    payload: customFields
  }
}

export function updateUiOptions(options: StorefrontUiOptions): StorefrontActionTypes {
  return {
    type: UPDATE_UI_OPTIONS,
    payload: options
  }
}
