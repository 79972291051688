import {CustomizedPlan, CustomizedPlanWithoutQuote, CustomizedPricingComponent, Plan, PlanWithoutQuote} from "./types";
import {
  ApiSubscription,
  CompositeQuote,
  Pricing,
  PricingComponent,
  PricingLevelType,
  PricingModelType,
  StandalonePricingComponent
} from "../../../api/types";

// Converts a multi-dimensional pricing object into a flat array of standalone pricing components
export const pricingToPricingComponentList = (pricing = {} as Pricing): Array<StandalonePricingComponent> => {
    let components: Array<StandalonePricingComponent> = [];
    let pricingModelType: PricingModelType;

    for (pricingModelType in pricing) {
      let currentPricingModel = pricing[pricingModelType];
      let pricingLevelType: keyof typeof currentPricingModel;

      for (pricingLevelType in currentPricingModel) {
        let pricingComponents: Array<PricingComponent> = currentPricingModel[pricingLevelType];

        if (pricingComponents?.length > 0) {
          const pricingLevelTypeRef = pricingLevelType;
          const pricingModelTypeRef = pricingModelType;
          components.push(...(pricingComponents.map((component: PricingComponent) => {
            return {...component, modelType: pricingModelTypeRef, levelType: pricingLevelTypeRef} as StandalonePricingComponent;
          })));
        }
      }
    }
    return components;
}

export function findPricingComponentByName<T extends StandalonePricingComponent | CustomizedPricingComponent>(
    name: string,
    pricingComponentList: Array<T>
): T | undefined {
  return pricingComponentList.find(component => component.name === name);
}

export const customisableComponents = (components: Array<StandalonePricingComponent>): Array<StandalonePricingComponent> => {
  return components.filter(component => component.levelType !== 'flat' && component.modelType !== 'usage');
}

export const excludeComponents = (
  components: Array<StandalonePricingComponent>,
  modelsToExclude: Array<PricingModelType>,
  levelsToExclude: Array<PricingLevelType>
): Array<StandalonePricingComponent> =>
  components.filter(component => !modelsToExclude.includes(component.modelType) && !levelsToExclude.includes(component.levelType));

// Given a quote, update a plan to reflect the pricing from the quote
export const updatePlanWithQuote = <T extends Plan | CustomizedPlan>(plan: T, quote: CompositeQuote, bfAccountId?: string): T => {
  return {...plan,
    price: {
      initialPrice: quote.initialQuote.subtotal,
      discountedInitialPrice: quote.initialQuote.total,
      pricePerPeriod: quote.recurringQuote.subtotal,
      taxPerPeriod: quote.recurringQuote.tax,
      discountedPricePerPeriod: quote.recurringQuote.total,
      initialPriceExcludingTax: quote.initialQuote.subtotalExcludingTax,
      discountedInitialPriceExcludingTax: quote.initialQuote.totalExcludingTax,
      pricePerPeriodExcludingTax: quote.recurringQuote.subtotalExcludingTax,
      discountedPricePerPeriodExcludingTax: quote.recurringQuote.totalExcludingTax,
      immediatePayment: quote.initialQuote.immediatePayment,
      immediateTax: quote.initialQuote.immediateTax,
    },
    bfAccountId: bfAccountId
  };
}

// Converts a base plan to a plan with customized pricing components
export const planToCustomizedPlan = (plan = {} as Plan, customizations: Array<CustomizedPricingComponent> = [], couponCodes: Array<string> = []): CustomizedPlan => {
  return {...plan, customizedPricingComponents: customizations, couponCodes};
}
export const planToCustomizedPlanWithoutQuote = (plan: PlanWithoutQuote, customizations: Array<CustomizedPricingComponent>): CustomizedPlanWithoutQuote => {
  return {...plan, customizedPricingComponents: customizations} as CustomizedPlanWithoutQuote;
}

export const getCustomizedPricingComponentsFromApiSubscription = (apiSubscription: ApiSubscription) => {
  // Get pricing component list from plan
  const defaultPricingComponents = customisableComponents(pricingToPricingComponentList(apiSubscription.plan.definition.pricing));

  let customizedPricingComponents: Array<CustomizedPricingComponent> = [];

  // Scan list and compare each one to the subscription's pricing component values
  for (let planPc of defaultPricingComponents) {
    const subscriptionPcv = apiSubscription.pricingComponentValues?.find(pcv => pcv.pricingComponentName === planPc.name)
    if (subscriptionPcv && subscriptionPcv.value !== undefined && "defaultQuantity" in planPc) {
      // If the values are different, create a CustomizedPricingComponent to represent the change
      if (planPc.defaultQuantity !== subscriptionPcv.value) {
        customizedPricingComponents.push({
          ...planPc,
          chosenQuantity: subscriptionPcv.value
        })
      }
    }
  }
  return customizedPricingComponents;
}

// Adds a single customized pricing component to an existing list
// If the component has already been customized, update the customization to the new value
// If the new customization is equal to the default value of the component, remove the component from the customization list
export const upsertCustomization = (customizedPricingComponents: Array<CustomizedPricingComponent>, customization: CustomizedPricingComponent, removeIfEqualTo?: number) => {
  const tempComponents = [...customizedPricingComponents];
  const index = tempComponents.findIndex(component => component.name === customization.name);
  if (~index) {
    if (removeIfEqualTo !== undefined && customization.chosenQuantity === removeIfEqualTo) {
      tempComponents.splice(index, 1);
    } else {
      tempComponents[index] = customization;
    }
  } else {
    tempComponents.push(customization);
  }
  return tempComponents;
}

// Updates a customized plan's list of customizations
export const upsertCustomizationToPlan = <T extends CustomizedPlan | CustomizedPlanWithoutQuote>(plan: T, customization: CustomizedPricingComponent): T => ({
  ...plan,
  customizedPricingComponents: upsertCustomization(
    plan.customizedPricingComponents,
    customization,
    "defaultQuantity" in customization ? customization.defaultQuantity : undefined
  )
})
