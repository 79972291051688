import React from "react";
import {connect, ConnectedProps} from 'react-redux';
import {RootState} from '../store';

import './CurrencySelect.scss';

import {Button, Dropdown, Menu, MenuItem} from './shoelace';
import {getCurrencySymbol} from "../utils/utils";
import {updateCurrency} from "../store/user/plan/actions";

const mapState = (state: RootState) => ({
  availablePlans: state.main.user.plan.availablePlans,
  availableCurrencies: state.main.user.plan.availableCurrencies,
  couponValue: state.main.user.coupon.couponValue,
  currency: state.main.user.plan.currency,
  cart: state.main.user.cart.cart,
  allowCart: state.main.storefront.storefront.allowCart
});

const mapDispatch = {
  updateCurrency: updateCurrency
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
}

type State = {
}

class CurrencySelect extends React.Component<Props, State> {

  shouldComponentUpdate(nextProps: Readonly<Props>, nextState: Readonly<State>, nextContext: any): boolean {
    this.forceUpdate(); return true;
  }

  render() {
    if (this.props.availableCurrencies.length < 2) return null;
    return (
      <Dropdown className="currency-select">
        <Button slot="trigger" caret className="currency-select-button small" disabled={this.props.allowCart && this.props.cart.length > 0} title="To switch currency, please first empty your cart.">
          {this.props.currency}
          <span slot="prefix"><b>{getCurrencySymbol(this.props.currency)}</b></span>
        </Button>
        <Menu>
          {this.props.availableCurrencies.map(currency => {
            return (
              <MenuItem className="currency-select-menu-item" onClick={() => this.props.updateCurrency(currency)} key={currency}>
                {currency}
                <span slot="prefix"><b>{getCurrencySymbol(currency)}</b></span>
              </MenuItem>
            )
          })}
        </Menu>
      </Dropdown>
    );
  }
}

export default connector(CurrencySelect);
