import {PaymentMethod} from "../../shared/user/types";
import {CustomFieldInstance} from "../../portal/account/types";
import {ResetUserAction} from "../resetUser";

// State

export interface Details {
  firstName: string,
  lastName: string,
  companyName: string,
  email: string,
  password: string
  mobile: string
  landline: string

  addressLine1: string
  addressLine2: string
  addressLine3: string
  city: string
  province: string
  country: string
  postcode: string

  shippingAddressLine1: string
  shippingAddressLine2: string
  shippingAddressLine3: string
  shippingCity: string
  shippingProvince: string
  shippingCountry: string
  shippingPostcode: string
}

export interface UserDetailsState {
  details: Details,
  paymentMethod: PaymentMethod,
  customerId: string,
  bfAccountId: string,
  customFieldInstances: Array<CustomFieldInstance>
}

// Actions

export const UPDATE_DETAILS = 'UPDATE_DETAILS';
export const UPDATE_PAYMENT_METHOD= 'UPDATE_PAYMENT_METHOD';
export const UPDATE_CUSTOMER_ID_AND_BF_ACCOUNT_ID = 'UPDATE_CUSTOMER_ID_AND_ACCOUNT_ID';
export const UPDATE_BF_ACCOUNT_ID = 'UPDATE_BF_ACCOUNT_ID';
export const UPDATE_CUSTOM_FIELD_INSTANCES = 'UPDATE_CUSTOM_FIELD_INSTANCES';

interface UpdateDetailsAction {
  type: typeof UPDATE_DETAILS;
  payload: Details;
}

export interface UpdatePaymentMethodAction {
  type: typeof UPDATE_PAYMENT_METHOD;
  payload: PaymentMethod;
}

interface UpdateCustomerIdAndBfAccountIdAction {
  type: typeof UPDATE_CUSTOMER_ID_AND_BF_ACCOUNT_ID;
  payload: [string, string];
}

interface UpdateBfAccountIdAction {
  type: typeof UPDATE_BF_ACCOUNT_ID;
  payload: string;
}

interface UpdateCustomFieldInstancesAction {
  type: typeof UPDATE_CUSTOM_FIELD_INSTANCES;
  payload: Array<CustomFieldInstance>;
}

export type UserDetailsActionTypes = UpdateDetailsAction | UpdatePaymentMethodAction | UpdateCustomerIdAndBfAccountIdAction | UpdateBfAccountIdAction | UpdateCustomFieldInstancesAction | ResetUserAction;
