import React, {ReactNode} from "react";
import 'react-square-payment-form/lib/default.css'
import {SqError} from "react-square-payment-form/lib/components/models";

interface Props {
  error?: SqError,
  children?: ReactNode
}

type State = {}

class SquarePaymentMethodForm extends React.Component<Props, State> {
  render() {
    return (
      <div className={`square-input-wrapper ${this.props.error ? 'error':''}`}>
        {this.props.children}
        {this.props.error
          ? <span className="error-text">{this.props.error.message}</span>
          : null}
      </div>
    )
  }
}

export default SquarePaymentMethodForm;
