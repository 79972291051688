import React, {FC, useCallback, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {api} from "../api";
import {Icon, Spinner} from "./shoelace";
import arrayMove from "array-move";
import {updateFlexcheckPlans as updateFlexcheckPlansAction} from "../store/merchant/plan/actions";
import {showAlert as showAlertAction} from "../store/shared/alert/actions";
import {AlertType} from "../store/shared/alert/types";
import clsx from "clsx";
import {selectFlexcheckPlans} from "../store/merchant/plan/selectors";
import useIntersectionObserver from "../hooks/useIntersectionObserver";
import SortablePlansList from "./SortablePlansList";

import "./PlansList.scss";

interface Props {
  plansLoading: boolean;
  goToEditPlan: (planPath: string) => void;
  setPlansLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const PlansList: FC<Props> = ({ plansLoading, setPlansLoading }) => {
  const plans = useSelector(selectFlexcheckPlans);
  const dispatch = useDispatch();

  const showAlert = useCallback(
    (alert) => {
      dispatch(showAlertAction(alert));
    },
    [dispatch]
  );

  const updateFlexcheckPlans = useCallback(
    (plans) => {
      dispatch(updateFlexcheckPlansAction(plans));
    },
    [dispatch]
  );

  const ref = useRef<HTMLTableCellElement>(null);
  const isIntersecting = useIntersectionObserver(ref, { threshold: 0.9 });

  const handleSortStart = ({ node }: { node: Node }) => {
    // When user drags row, a ghost copy of the row is created
    // This function matches the styles of the original to the ghost copy
    const tds = document.querySelectorAll("body > .plans-sortable-row")[0]
      .childNodes;
    node.childNodes.forEach((node, idx) => {
      const td = tds[idx] as HTMLElement;
      const nodeElement = node as HTMLElement;
      td.style.width = `${nodeElement.offsetWidth}px`;
      td.style.backgroundColor = window
        .getComputedStyle(nodeElement, null)
        .getPropertyValue("background-color");
    });
  };

  const handleSortEnd = ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }) => {
    const newOrderPlans = arrayMove(plans, oldIndex, newIndex).map(
      (plan, index) => {
        plan.displayIndex = index;
        return plan;
      }
    );
    updateFlexcheckPlans(newOrderPlans);
    api
      .put(
        "plan/reorder",
        newOrderPlans.map((plan) => plan.id)
      )
      .then(
        (response) => {},
        (error) => {
          // Show error message, but leave plans in new order since them jumping back to their original position is visually jarring
          // The true order will be shown again on page reload
          showAlert({
            type: AlertType.Error,
            message: [
              <b>There was an error reordering the plans.</b>,
              <br />,
              "The new order has not been saved. Please reload the page and try again.",
            ],
          });
        }
      );
  };

  return (
    <div className={clsx("plans-list", isIntersecting && "scrolled")}>
      <div className="plans-table-container">
        <table className="plans-table">
          <thead>
            <tr className="plans-table-header-row">
              <th className="drag-icon"></th>
              <th className="enabled">
                <div className="th-container">
                  <Icon name="eye-fill"></Icon>
                </div>
              </th>
              <th className="product">Product</th>
              <th className="plan">Plan</th>
              <th className="price">Price</th>
              <th ref={ref} className="controls"></th>
            </tr>
          </thead>
          <SortablePlansList
            plans={plans}
            onSortStart={handleSortStart}
            onSortEnd={handleSortEnd}
            useDragHandle
            lockAxis="y"
            lockToContainerEdges
            lockOffset="0%"
          />
        </table>
      </div>
      <div className="plans-table-overlay">
        {plansLoading ? (
          <div className="plans-table-overlay-loading">
            <Spinner className="plans-table-spinner"></Spinner>
          </div>
        ) : null}
        {plans.length === 0 && !plansLoading ? (
          <div className="plans-table-overlay-no-plans">
            <span>
              <b>No plans added yet.</b>
              <br />
              Add a plan from Billforward by clicking "Add Plan" above.
            </span>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default PlansList;
