import React, { FC, useState } from "react";
import { slide as BurgerMenu } from "react-burger-menu";
import { useSelector } from "react-redux";
import { Icon } from "./shoelace";
import { Link } from "react-router-dom";
import LogoImage from "./shared/LogoImage";
import { selectStorefront } from "../store/storefront/selectors";
import { selectMenuItems } from "../store/portal/navigation/selectors";

import "./PortalTopBar.scss";

interface Props {
  currentRoute: string;
}

const PortalTopBar: FC<Props> = ({ currentRoute }) => {
  const storefront = useSelector(selectStorefront);
  const menuItems = useSelector(selectMenuItems);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const toggleMenu = () => setIsMenuOpen((state) => !state);
  const isCurrentRoute = (path: string) => {
    return currentRoute === path;
  };

  const getMenuItems = () => (
    <>
      {menuItems(storefront.alias).map((item) => (
        <Link
          className={`portal-menu-item ${
            isCurrentRoute(item.path) ? "active" : ""
          }`}
          to={item.path}
          key={item.name}
          onClick={closeMenu}
        >
          <Icon slot="prefix" name={item.icon} />
          {item.name}
        </Link>
      ))}
    </>
  );

  return (
    <>
      <div className="portal-top-bar flexcheck-header">
        <div className="portal-top-bar-logo-section">
          <LogoImage link={`/store/${storefront.alias}`} />
        </div>
        <div className="portal-menu">{getMenuItems()}</div>
        <div className="portal-button">
          <button className="portal-burger-menu-button" onClick={toggleMenu}>
            <Icon name="list" />
          </button>
        </div>
      </div>
      <BurgerMenu
        isOpen={isMenuOpen}
        onClose={toggleMenu}
        customBurgerIcon={false}
        customCrossIcon={false}
        itemListElement="div"
      >
        {getMenuItems()}
      </BurgerMenu>
    </>
  );
};

export default PortalTopBar;
