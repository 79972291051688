import React from "react";
import StepContainer from "../../StepContainer";

import './Details.scss';
import {RootState} from "../../../store";
import {connect, ConnectedProps} from "react-redux";
import DetailsForm from "../../DetailsForm";
import CartSummary from "../../CartSummary";

const mapState = (state: RootState) => ({
  currency: state.main.user.plan.currency,
  currentStep: state.main.user.steps.currentStep,
  totalSteps: state.main.user.steps.totalSteps,
  details: state.main.user.userDetails.details,
  alias: state.main.storefront.storefront.alias,
  cart: state.main.user.cart.cart
});

const mapDispatch = {
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
  nextPage: Function
}

const Details = (props: Props) => {
  return (
    <div className="page">
      <StepContainer currentStep={props.currentStep} totalSteps={props.totalSteps} stepTitle={"Your Details"} backLink={`/store/${props.alias}/`}>
        <span>You are buying:</span>
        <CartSummary cartToShow={props.cart} showTax={true}/>
        <span>You’ll be given the chance to review your purchase at the end of the checkout process.</span>

        <DetailsForm nextPage={props.nextPage}/>
      </StepContainer>
    </div>
  );
}

export default connector(Details);
