import { RootState } from "../..";

export const selectFlexcheckPlans = (state: RootState) =>
  state.main.merchant.plan.flexcheckPlans;

export const selectSelectedPlanToAdd = (state: RootState) =>
  state.main.merchant.plan.selectedPlanToAdd;

export const selectBfPlans = (state: RootState) =>
  state.main.merchant.plan.billforwardPlans;

export const selectBfProducts = (state: RootState) =>
  state.main.merchant.plan.billforwardProducts;
