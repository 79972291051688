import React, { FC } from "react";
import MerchantPage from "../../MerchantPage";
import { Button } from "../../shoelace";
import { useHistory } from "react-router-dom";
import PlansList from "../../PlansList";
import { ROUTES } from "../../../config/routes";

interface Props {
  plansLoading: boolean;
  setPlansLoading: React.Dispatch<React.SetStateAction<boolean>>;
  fetchBfPlans: () => void;
}

const Plans: FC<Props> = ({ plansLoading, setPlansLoading, fetchBfPlans }) => {
  const history = useHistory();

  const goToEditPlan = (planPath: string) => {
    history.push(`/merchant/edit-plan?planPath=${planPath}`);
  };

  return (
    <MerchantPage
      title="Plans"
      description="Select the plans your customers will see"
      button={
        <Button
          className="primary small"
          onClick={() => {
            fetchBfPlans();
            history.push(ROUTES.MERCHANT_ADD_PLAN);
          }}
        >
          Add Plan
        </Button>
      }
    >
      <PlansList
        plansLoading={plansLoading}
        setPlansLoading={setPlansLoading}
        goToEditPlan={goToEditPlan}
      />
    </MerchantPage>
  );
};

export default Plans;
