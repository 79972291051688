// State

import {PortalLoginResponse} from "../../../api/types";

export interface AuthState {
  merchantIsLoggedIn: boolean,
  portalStorefrontAliasLoggedInto?: string, // The alias of the storefront that the user is currently logged into, or null/undefined if not logged in
  portalTokenData?: PortalLoginResponse,
  portalLoginExpiryTime?: Date
}

// Actions

export const MERCHANT_LOGIN = 'MERCHANT_LOGIN';
export const MERCHANT_LOGOUT = 'MERCHANT_LOGOUT';
export const PORTAL_LOGIN = 'PORTAL_LOGIN';
export const PORTAL_LOGOUT = 'PORTAL_LOGOUT';

interface MerchantLoginAction {
  type: typeof MERCHANT_LOGIN;
}

interface MerchantLogoutAction {
  type: typeof MERCHANT_LOGOUT;
}

interface PortalLoginAction {
  type: typeof PORTAL_LOGIN,
  payload: {
    storefrontAlias: string,
    tokenData: PortalLoginResponse
  }
}

interface PortalLogoutAction {
  type: typeof PORTAL_LOGOUT;
}

export type AuthActionTypes = MerchantLoginAction | MerchantLogoutAction | PortalLoginAction | PortalLogoutAction;
