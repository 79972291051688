const emailRegex = /\S+@\S+\.\S+/;
export const validateEmail = (value: string) => {
  return emailRegex.test(value);
}

const phoneRegex = /^\+?[\d\s.()-]+$/;
export const validatePhone = (value: string) => {
  return phoneRegex.test(value);
}

const bfPasswordRegex = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[;:(){}<>,./?'*_@#$!%^&+=-])(?=\S+$).{8,}$/;
export const validateBfPassword = (value: string) => {
  return bfPasswordRegex.test(value);
}

const fcPasswordRegex = /^.{8,}$/; // 8+ characters
export const validateFlexcheckPassword = (value: string) => {
  return fcPasswordRegex.test(value)
}

export const isNotEmpty = (value: string) => value.length > 0;

const cardNumberRegex = /^\d+$/;
export const validateCardNumber = (value: string) => {
  return cardNumberRegex.test(value);
}

const cvcRegex = /^\d+$/;
export const validateCVC = (value: string) => {
  return cvcRegex.test(value);
}

export const validateExpiry = (maxValue: number) => (value: string) => {
  let expiryMonthRegex = /\d{2}/;
  if (expiryMonthRegex.test(value)) {
    let valueNum: number = +value;
    return valueNum > 0 && valueNum <= maxValue;
  }
  return false;
}

const codeRegex = new RegExp("^[0-9]{6,}$");
export const validateOTPCode = (value?: string) => {
  return value === undefined || value === null || value === '' || codeRegex.test(value);
}

export const validationMessages = {
  bfPassword: 'Passwords have a minimum length of 8 characters and must contain at least one lower case letter, uppercase letter, number and special character (;:(){}<>,./?\'*_@#$!%^&+=-). No white spaces are allowed.',
  flexcheckPassword: 'Password must be 8 or more characters in length.'
}
