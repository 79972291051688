import React, { FC } from "react";

import "./StatusBadge.scss";

export type StatusStyle = "success" | "warning" | "danger";

interface Props {
  text: string;
  statusStyle: StatusStyle;
}

const StatusBadge: FC<Props> = ({ text, statusStyle }) => {
  return <span className={`status-badge ${statusStyle}`}>{text}</span>;
};

export default StatusBadge;
