import React from "react";
import { Checkbox, Input } from "./shoelace";
import { RootState } from "../store";
import { connect, ConnectedProps } from "react-redux";
import LoadingButton from "./shared/LoadingButton";
import { apiLogin } from "../api";
import FormBase, { BaseState, InputTypeGenerator } from "./shared/FormBase";
import { showAlert } from "../store/shared/alert/actions";
import clsx from "clsx";
import { merchantLogin } from "../store/persisted/auth/actions";
import {
  getAlertFromApiErrorResponse,
  isAcceptConditionsFromApiErrorResponse,
  isOTPFromApiErrorResponse,
} from "../utils/utils";
import { isNotEmpty, validateOTPCode } from "../utils/validation";

import "./shoelace/Input.scss";
import "./LoginForm.scss";

const mapState = (state: RootState) => ({
  otpRequired: false,
});

const mapDispatch = {
  showAlert: showAlert,
  merchantLogin: merchantLogin,
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
  nextPage: Function;
}

const typeGenerator = new InputTypeGenerator([
  { name: "username", type: "text" },
  { name: "password", type: "text" },
  { name: "code", type: "text" },
  { name: "acceptPolicies", type: "checkbox" },
]);
type InputName = typeof typeGenerator.InputName.type;

interface LoginState extends BaseState<InputName> {
  otpRequired: boolean;
  acceptPoliciesError: boolean;
}

class MerchantLoginForm extends FormBase<Props, LoginState, InputName> {
  getInitialState(baseState: BaseState<InputName>): LoginState {
    return { ...baseState, otpRequired: false, acceptPoliciesError: false };
  }

  onSubmitValidForm() {
    this.setState({ isLoading: true });
    apiLogin({
      username: this.state.formData.username,
      password: this.state.formData.password,
      code: this.state.formData.code,
      "accept-policies": this.state.formData.acceptPolicies,
    }).then(
      (response) => {
        this.props.merchantLogin();
        this.props.nextPage();
      },
      (error) => {
        if (isOTPFromApiErrorResponse(error.response)) {
          this.setState({ otpRequired: true, isLoading: false });
          return false;
        }
        if (isAcceptConditionsFromApiErrorResponse(error.response)) {
          this.setState({ acceptPoliciesError: true, isLoading: false });
          return false;
        }
        this.props.showAlert(
          getAlertFromApiErrorResponse(
            error,
            "There was a problem logging you in."
          )
        );
        this.setState({ isLoading: false });
        return false;
      }
    );
    return true;
  }

  constructor(props: Props) {
    super(props, typeGenerator, {
      setValid: {
        username: isNotEmpty,
        password: isNotEmpty,
        code: validateOTPCode,
        acceptPolicies: () => true,
      },
      invalidMessages: {
        username: "Username cannot be blank.",
        password: "Password cannot be blank.",
        code: "OTP code must be numeric.",
        acceptPolicies: "Please accept the conditions of use",
      },
      initialFormData: {
        username: "",
        password: "",
        code: "",
        acceptPolicies: "false",
      },
    });
  }

  render() {
    return (
      <form className="login-form" ref={this.formRef}>
        <div className="form-section">
          <Input
            className={`form-input light ${
              this.state.touched["username"] ? "touched" : ""
            }`}
            name="username"
            ref={this.inputRefs["username"]}
            value={this.state.formData["username"]}
            label="Username"
            disabled={this.state.isLoading}
          >
            <div slot="help-text">{this.invalidMessages["username"]}</div>
          </Input>
          <Input
            className={`form-input light ${
              this.state.touched["password"] ? "touched" : ""
            }`}
            name="password"
            ref={this.inputRefs["password"]}
            value={this.state.formData["password"]}
            label="Password"
            disabled={this.state.isLoading}
            type="password"
            togglePassword
          >
            <div slot="help-text">{this.invalidMessages["password"]}</div>
          </Input>
          <Input
            className={`form-input light ${
              this.state.touched["code"] ? "touched" : ""
            }`}
            style={this.state.otpRequired ? {} : { display: "none" }}
            name="code"
            ref={this.inputRefs["code"]}
            value={this.state.formData["code"]}
            label="OTP Code"
            disabled={this.state.isLoading}
          >
            <div slot="help-text">{this.invalidMessages["code"]}</div>
          </Input>
          <div className="checkbox-container">
            <Checkbox
              name="acceptPolicies"
              ref={this.inputRefs.acceptPolicies}
              value={this.state.formData.acceptPolicies}
              onChange={() =>
                this.setState((state) => ({
                  ...state,
                  formData: {
                    ...state.formData,
                    acceptPolicies:
                      state.formData.acceptPolicies === "true"
                        ? "false"
                        : "true",
                  },
                }))
              }
            >
              <div className={clsx(this.state.acceptPoliciesError && "error")}>
                Accept the&nbsp;
                <a href="https://www.billforward.io/terms-and-conditions-of-use/">
                  conditions of use
                </a>{" "}
                - only required on first sign in and conditions change
              </div>
            </Checkbox>
          </div>
        </div>
        <div className="form-footer">
          <LoadingButton submit isLoading={this.state.isLoading} noCustomStyle>
            Login
          </LoadingButton>
        </div>
      </form>
    );
  }
}

export default connector(MerchantLoginForm);
