import React, {ReactNode} from "react";

import {connect, ConnectedProps} from "react-redux";
import {Redirect} from "react-router-dom";
import {RootState} from "../store";
import {api, setDefaultAliasParam} from "../api";
import SpinnerPage from "./shared/SpinnerPage";
import checkCart from "./shared/CartChecker";
import {fetchStorefront} from "../api/storefront";
import i18next from "i18next";

const mapState = (state: RootState) => ({
  merchantIsLoggedIn: state.main.user.auth.merchantIsLoggedIn,
  storefront: state.main.storefront.storefront,
  cart: state.main.user.cart
});

const mapDispatch = {
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
  children?: ReactNode,
  alias: string,
  containsPortal: boolean
}

type State = {
  isLoading: boolean,
  redirectTo: string | undefined
}

class StorefrontFetchWrapper extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isLoading: true,
      redirectTo: undefined,
    }
  }

  private isStorefrontDifferent = () => {
    return !this.props.storefront?.alias || this.props.storefront.alias !== this.props.alias
  }

  private redirectIfPortalRequestedButIsDisabled = (allowCustomerPortal: boolean) => {
    this.setState({
      isLoading: false,
      redirectTo: this.props.containsPortal && !allowCustomerPortal ? `/store/${this.props.storefront.alias}` : undefined // Don't allow portal pages to be accessed if the customer portal is disabled for this storefront
    });
  }

  componentDidMount() {
    if (this.isStorefrontDifferent()) {
      fetchStorefront(this.props.alias).then(response => {
        setDefaultAliasParam(response.alias);
        checkCart(this.props.cart);
        //TODO: Should clear user data too?
        (i18next.options.backend! as any).queryStringParams = api.defaults.params;
        console.log((i18next.options.backend! as any).queryStringParams);
        i18next.reloadResources()
            .finally(() => this.redirectIfPortalRequestedButIsDisabled(response.allowCustomerPortal))
      }, error => {
        this.setState({
          isLoading: false,
          redirectTo: "/page-not-found"
        });
      });
    } else {
      setDefaultAliasParam(this.props.alias);
      checkCart(this.props.cart);
      console.log((i18next.options.backend! as any).queryStringParams);
      i18next.reloadResources()
          .finally(() => this.redirectIfPortalRequestedButIsDisabled(this.props.storefront.allowCustomerPortal));
    }
  }

  render() {
    return (
      this.state.isLoading
        ? <SpinnerPage></SpinnerPage>
        : this.state.redirectTo !== undefined
        ? <Redirect to={{pathname: this.state.redirectTo}}/>
        : this.props.children
    )
  };
}

export default connector(StorefrontFetchWrapper);
