import React, { FC } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import clsx from "clsx";
import CurrencySelect from "./CurrencySelect";
import LogoImage from "./shared/LogoImage";
import { Badge, Button, Icon } from "./shoelace";
import {
  selectStorefrontAlias,
  selectStorefrontAllowCart,
} from "../store/storefront/selectors";
import { selectUserCart, selectShowCart } from "../store/user/cart/selectors";

import "./Header.scss";

interface Props {
  showCurrencySelect: boolean;
  showCartButton: boolean;
  isPreview: boolean;
  showLogin: boolean;
  showStorefrontLink: boolean;
}

const Header: FC<Props> = ({
  isPreview,
  showLogin,
  showStorefrontLink,
  showCurrencySelect,
  showCartButton,
}) => {
  const storefrontAlias = useSelector(selectStorefrontAlias);
  const cart = useSelector(selectUserCart);
  const allowCart = useSelector(selectStorefrontAllowCart);
  const showCart = useSelector(selectShowCart);

  return (
    <div className={clsx("flexcheck-header", isPreview && "preview")}>
      <LogoImage link={`/store/${storefrontAlias}`} />
      <section className="controls">
        <div>
          {showLogin ? (
            <Link to={`/store/${storefrontAlias}/login`}>
              <Button
                onClick={showCart}
                className="login-button light small icon"
              >
                <Icon name="person-circle" />
                Account
              </Button>
            </Link>
          ) : showStorefrontLink ? (
            <Link to={`/store/${storefrontAlias}`}>
              <Button
                onClick={showCart}
                className="storefont-link-button light small icon"
              >
                <Icon name="bag-fill" />
                Store
              </Button>
            </Link>
          ) : null}
        </div>
        {showCurrencySelect && <CurrencySelect />}
        <div className="cart-container">
          {showCartButton && allowCart && (
            <Button onClick={showCart} className="cart-button light icon">
              {cart.length > 0 && (
                <Badge variant="danger" pill>
                  {cart.length}
                </Badge>
              )}
              <Icon name="cart4" />
            </Button>
          )}
        </div>
      </section>
    </div>
  );
};

export default Header;
