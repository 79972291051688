import React from "react";

import {RootState} from "../store";
import {connect, ConnectedProps} from "react-redux";
import OptionsPanel from "./OptionsPanel";
import OptionDropdown from "./OptionDropdown";
import LoadingButton from "./shared/LoadingButton";
import './EditPlanForm.scss';
import ImageUploadTabs from "./ImageUploadTabs";
import {updateBackground, updateLogo} from "../store/persisted/style/actions";
import {apiPutStorefront} from "../api";
import {AlertType} from "../store/shared/alert/types";
import {showAlert} from "../store/shared/alert/actions";
import {getAlertFromApiErrorResponse} from "../utils/utils";
import {updateStorefront} from "../store/storefront/actions";
import {Prompt} from "react-router";

const mapState = (state: RootState) => ({
  storefront: state.main.storefront.storefront,
  backgroundImageUrl: state.temporaryStyle.backgroundImageUrl,
  logoImageUrl: state.temporaryStyle.logoImageUrl
});

const mapDispatch = {
  updateBackground: updateBackground,
  updateLogo: updateLogo,
  showAlert: showAlert,
  updateStorefront: updateStorefront
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
  reloadPreview: () => void
}

interface State {
  isLoading: boolean,
  saveButtonDisabled: boolean
}

type LookAndFeelContextType = {
  reloadPreview: () => void,
  setSaveButtonDisabled: (disabled: boolean) => void
}
export const LookAndFeelContext = React.createContext<LookAndFeelContextType>({
  reloadPreview: () => {},
  setSaveButtonDisabled: () => {}
});


class LookAndFeelForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isLoading: false,
      saveButtonDisabled: false
    }
  }

  private setBackgroundImage = (url: string) => {
    this.props.updateBackground(url, true);
  }
  private setLogoImage = (url: string) => {
    this.props.updateLogo(url, true);
  }
  private syncStorefrontImagesToTempImages = () => {
    this.props.updateBackground(this.props.storefront.backgroundImageUrl || '', false);
    this.props.updateLogo(this.props.storefront.logoUrl || '', false);
  }

  private onSubmit() {
    this.setState({isLoading: true});
    apiPutStorefront({
      backgroundImageUrl: this.props.backgroundImageUrl,
      logoUrl: this.props.logoImageUrl
    }).then(response => {
      this.props.updateStorefront(response.data);
      this.props.showAlert({
        type: AlertType.Success,
        message: 'Your changes have been saved.'
      });
      this.setState({isLoading: false});
    }, error => {
      this.props.showAlert(getAlertFromApiErrorResponse(error.response, 'There was an error saving your styling choices.'));
      this.setState({isLoading: false});
    });
  }

  private setSaveButtonDisabled = (disabled: boolean) => {
    this.setState({saveButtonDisabled: disabled});
  }

  componentDidMount() {
    this.syncStorefrontImagesToTempImages();
  }

  componentWillUnmount() {
    // Since this is not guaranteed to be called we have a separate mechanism to handle a timeout for the temp images
    this.syncStorefrontImagesToTempImages();
  }

  private userHasMadeLAFChanges = () => {
    return (this.props.storefront.backgroundImageUrl || "") !== (this.props.backgroundImageUrl || "")
        || (this.props.storefront.logoUrl || "") !== (this.props.logoImageUrl || "");
  }

  render() {
    return (
      <>
        <Prompt
          when={this.userHasMadeLAFChanges()}
          message='You have unsaved look and feel changes. Are you sure you want to leave this page?'
        />
        <LookAndFeelContext.Provider value={{
          reloadPreview: this.props.reloadPreview,
          setSaveButtonDisabled: this.setSaveButtonDisabled
        }}>
          <div className="look-and-feel-form">
            <div className="look-and-feel-form-content">
              <OptionsPanel>
                <OptionDropdown title="Background Image">
                  <ImageUploadTabs initialSrc={this.props.storefront.backgroundImageUrl || ''} onSuccess={this.setBackgroundImage} userImageType="background"></ImageUploadTabs>
                </OptionDropdown>
                <OptionDropdown title="Front Page Logo">
                  <ImageUploadTabs initialSrc={this.props.storefront.logoUrl || ''} onSuccess={this.setLogoImage} userImageType="logo"></ImageUploadTabs>
                </OptionDropdown>
              </OptionsPanel>
            </div>
            <div className={'save-button-container'}>
              <LoadingButton onSubmit={() => this.onSubmit()} isLoading={this.state.isLoading} disabled={this.state.saveButtonDisabled} noCustomStyle>Save</LoadingButton>
            </div>
          </div>
        </LookAndFeelContext.Provider>
      </>
    )
  }
}

export default connector(LookAndFeelForm);
