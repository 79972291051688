import React, {FC} from "react";
import PlanCardMini from "./PlanCardMini";
import {CustomizedPlan} from "../store/shared/plan/types";
import {Cart, CartLine} from "../store/user/cart/types";

import "./CartSummary.scss";

interface Props {
  showDetails?: boolean;
  hidePrice?: boolean;
  fullList?: boolean;
  hideViewButton?: boolean;
  showTax: boolean;
  cartToShow: Cart;
}

const CartSummary: FC<Props> = (props) => {
  const getCartPlanSummary = (): CustomizedPlan => {
    return {
      name: `${props.cartToShow.length} plans`,
      couponCodes: [],
      baseCurrency: "USD",
      bfId: "",
      bfPlanPath: "",
      cardText: "",
      colour: "",
      customizedPricingComponents: [],
      description: "",
      disabled: false,
      displayIndex: 0,
      firstPeriodEnd: "",
      fixedTerm: {},
      firstRecurringPaymentDate: "",
      oneOffPayment: false,
      hidden: false,
      id: "",
      period: "",
      periodLengthInMonths: 0,
      price: {
        discountedInitialPrice: 0,
        discountedPricePerPeriod: 0,
        initialPrice: 0,
        pricePerPeriod: 0,
        discountedInitialPriceExcludingTax: 0,
        discountedPricePerPeriodExcludingTax: 0,
        initialPriceExcludingTax: 0,
        pricePerPeriodExcludingTax: 0,
        taxPerPeriod: 0,
        immediatePayment: 0,
        immediateTax: 0,
      },
      pricing: {},
      trialDescription: "",
      hasUsage: false,
      taxStatus: "inclusive",
      localisedTax: false
    };
  };

  return props.fullList ? (
    <div>
      {props.cartToShow.map((cartLine: CartLine, i) => (
        <PlanCardMini
          plan={cartLine.plan}
          key={i}
          showDetails={props.showDetails}
          hidePrice={props.hidePrice}
          hideViewButton={props.hideViewButton}
          showTax={props.showTax}
        />
      ))}
    </div>
  ) : props.cartToShow.length === 1 ? (
    <PlanCardMini
      plan={props.cartToShow[0].plan}
      showDetails={props.showDetails}
      hidePrice={props.hidePrice}
      hideViewButton={props.hideViewButton}
      showTax={props.showTax}
    />
  ) : (
    <PlanCardMini
      plan={getCartPlanSummary()}
      hidePrice
      multiplePlans
      hideViewButton={props.hideViewButton}
      showTax={false}
    />
  );
};

export default CartSummary;
