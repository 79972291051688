import {Button, Dropdown, Menu, MenuItem, Spinner} from "../shoelace";
import React from "react";
import {RootState} from "../../store";
import {showAlert} from "../../store/shared/alert/actions";
import {connect, ConnectedProps} from "react-redux";
import {getAlertFromApiErrorResponse} from "../../utils/utils";

import './SquareLocationSelect.scss';
import {SquareLocation} from "../../api/types";

import {fetchSquareConfigAndLocations} from "../../api/storefront";

const mapState = (state: RootState) => ({
  squareConfig: state.main.storefront.squareConfig
});

const mapDispatch = {
  showAlert: showAlert
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
  updateSquareLocation: (locationId: string | null, initial: boolean) => void
}

interface State {
  currentLocation: SquareLocation | null,
  availableLocations: Array<SquareLocation>,
  isLoading: boolean
}

class SquareLocationSelect extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      currentLocation: null,
      availableLocations: [],
      isLoading: true
    }
  }

  private onSelect = (location: SquareLocation | null) => {
    this.setState({
      currentLocation: location
    });
    this.props.updateSquareLocation(location?.id || null, false);
  }

  componentDidMount() {
    fetchSquareConfigAndLocations()
      .then(response => {
        this.setState({
          availableLocations: response.data,
          isLoading: false
        });
        if (this.state.currentLocation === null && response.data.length > 0) {
          const location = response.data.find(location => location.id === this.props.squareConfig.locationId) || null;
          this.setState({
            currentLocation: location
          });
          this.props.updateSquareLocation(location?.id || null, true);
        }
      }, error => {
        this.props.showAlert(getAlertFromApiErrorResponse(error, 'The was a problem retrieving the Square configuration.'));
        this.setState({isLoading: false});
      });
  }

  render() {
    return (
      this.state.isLoading
        ? <Spinner></Spinner>
        : <div>
          <Dropdown className="square-location-dropdown">
            {this.state.currentLocation
              ? <Button slot="trigger" caret className="square-location-button icon">
                <span slot="prefix"><b>{this.state.currentLocation.country}</b></span>
                {this.state.currentLocation.name}
              </Button>
              : <Button slot="trigger" caret className="square-location-button icon">
                Select location...
              </Button>}
            <Menu>
              {this.state.availableLocations.map(location => (
                <MenuItem className="square-location-menu-item" onClick={() => this.onSelect(location)}
                          key={location.id}>
                  <span slot="prefix"><b>{location.country}</b></span>
                  {location.name}
                </MenuItem>
              ))}
            </Menu>
          </Dropdown>
        </div>
    );
  }
}

export default connector(SquareLocationSelect);
