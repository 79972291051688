import React from "react";

import {RootState} from "../../../store";
import {connect, ConnectedProps} from "react-redux";
import PortalPage from "../../PortalPage";
import PortalPanel from "../../portal/PortalPanel";

import './PaymentDetail.scss'
import ControlsCard from "../../portal/ControlsCard";
import PriceText from "../../PriceText";
import {Payment} from "../../../store/portal/payment/types";
import {Button, Icon} from "../../shoelace";
import SquareButton from "../../shared/SquareButton";
import StatusBadge from "../../portal/StatusBadge";
import {getStyleFromPaymentStatus} from "../../../store/portal/payment/utils";
import moment from "moment";
import LabelledContainer from "../../portal/LabelledContainer";
import PaymentMethodCard from "../../portal/PaymentMethodCard";
import PaymentRetryDialog from "../../portal/PaymentRetryDialog";
import {apiGetInvoicePdf} from "../../../api/portalEndpoints";
import {showAlert} from "../../../store/shared/alert/actions";
import {getAlertFromApiErrorResponse} from "../../../utils/utils";
import {ApiInvoicePayment} from "../../../api/types";
import {Link} from "react-router-dom";

const mapState = (state: RootState) => ({
  storefront: state.main.storefront.storefront,
  payments: state.main.portal.payment.payments,
  primaryPaymentMethod: state.main.portal.account.primaryPaymentMethod
});

const mapDispatch = {
  showAlert: showAlert
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
  payment: Payment
}

type State = {
  isRetryOpen: boolean,
  isPdfLoading: boolean
}

class PaymentDetail extends React.Component<Props, State> {
  private readonly lastPaymentAttempt?: ApiInvoicePayment;

  constructor(props: Props) {
    super(props);

    this.state = {
      isRetryOpen: false,
      isPdfLoading: false
    }

    const numberOfPaymentAttempts = props.payment.paymentAttempts.length
    if (numberOfPaymentAttempts > 0) {
      this.lastPaymentAttempt = props.payment.paymentAttempts[numberOfPaymentAttempts - 1];
    }
  }

  private onClickPdf = () => {
    this.setState({isPdfLoading: true});
    apiGetInvoicePdf(this.props.payment.id).then(response => {
      const file = new Blob([response.data], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      this.setState({isPdfLoading: false});
    }, error => {
      this.props.showAlert(getAlertFromApiErrorResponse(error, 'There was a problem downloading the invoice PDF.'));
      this.setState({isPdfLoading: false});
    })
  }

  shouldComponentUpdate(nextProps: Readonly<Props>, nextState: Readonly<State>, nextContext: any): boolean {
    this.forceUpdate();
    return true;
  }

  render() {
    return (
      <PortalPage title="Payment Details" className="payment-detail-page">
        <PortalPanel
          breadcrumbs={[{
            title: 'Billing History',
            link: `/store/${this.props.storefront.alias}/portal/billing-history`
          }, {
            title: `Payment (${this.props.payment.subscriptionName ?? 'Untitled'})`
          }]}
          topRight={<StatusBadge text={this.props.payment.status}
                                 statusStyle={getStyleFromPaymentStatus(this.props.payment.status)}/>}
        >
          <ControlsCard
            colour={this.props.payment.subscription.plan.colour}
            details={[{
              label: 'Total',
              value: (<PriceText
                currentPrice={this.props.payment.total}
                currency={this.props.payment.subscription.plan.baseCurrency}
              />)
            }, {
              label: 'Issued on',
              value: this.props.payment.date ? moment.unix(this.props.payment.date).format('Do MMMM YYYY') : 'Not yet issued'
            }]}
            controls={
              <>
                <SquareButton className="primary custom smaller-icon" label="Retry" onClick={() => this.setState({isRetryOpen: true})} disabled={this.props.payment.status === 'paid'}>
                  <Icon name="arrow-repeat"/>
                </SquareButton>
                <SquareButton className="primary custom smaller-icon" label="PDF" onClick={this.onClickPdf} isLoading={this.state.isPdfLoading}>
                  <Icon name="file-earmark-text-fill"/>
                </SquareButton>
              </>
            }
          />
          <div className="payment-detail-overview-section">
            <LabelledContainer
              label="Payment Details"
              includeCard
              className="payment-detail-panel big-label max-height"
            >
              <LabelledContainer
                label="Payment Method Used"
                className="payment-detail-sub-panel set-width max-height"
              >
                <PaymentMethodCard paymentMethod={this.props.payment.paymentMethod}/>
              </LabelledContainer>
              <LabelledContainer
                label={this.props.payment.status === 'paid' ? 'Paid on' : 'Last Payment Attempt'}
                className="payment-detail-sub-panel payment-detail-date max-height"
              >
                {this.lastPaymentAttempt?.paymentReceived
                  ? moment.unix(parseInt(this.lastPaymentAttempt.paymentReceived)).format('Do MMMM YYYY')
                  : 'Unknown date'
                }
              </LabelledContainer>
            </LabelledContainer>
          </div>
          <Link
            to={`/store/${this.props.storefront.alias}/portal/subscriptions/view/${this.props.payment.subscription.id}`}><Button
            className="secondary custom small full-width">View Subscription</Button></Link>
          <PaymentRetryDialog
            payment={this.props.payment}
            closeDialog={() => this.setState({isRetryOpen: false})}
            open={this.state.isRetryOpen}/>
        </PortalPanel>
      </PortalPage>
    );
  }
}

export default connector(PaymentDetail);
