import { combineReducers } from "redux";
import { merchantReducer } from "./merchant";
import { storefrontReducer } from "./storefront/reducers";
import { portalReducer } from "./portal";
import storage from "redux-persist/lib/storage";
import storageSession from "redux-persist/lib/storage/session";
import { persistReducer } from "redux-persist";
import { planReducer } from "./user/plan/reducers";
import { cartReducer } from "./user/cart/reducers";
import { stepsReducer } from "./user/steps/reducers";
import { userDetailsReducer } from "./user/userDetails/reducers";
import { couponReducer } from "./user/coupon/reducers";
import { alertReducer } from "./shared/alert/reducers";
import { styleReducer } from "./persisted/style/reducers";
import { authReducer } from "./persisted/auth/reducers";
import { planGroupReducer } from "./user/planGroup/reducers";
import { sourceReducer } from "./persisted/source/reducer";
import panelReducer from "./panel/reducers";

// Configure store
const rootConfig = {
  key: "root",
  storage,
  whitelist: ["temporaryStyle"],
};

const temporaryStyleStorageConfig = {
  key: "temporaryStyle",
  storage: storageSession,
};

const cartStorageConfig = {
  key: "cart",
  storage: storageSession,
};

const userDetailsStorageConfig = {
  key: "userDetails",
  storage: storageSession,
};

const authStorageConfig = {
  key: "auth",
  storage: storage,
};

const sourceStorageConfig = {
  key: "source",
  storage: storageSession,
};

const userReducer = combineReducers({
  plan: planReducer,
  planGroup: planGroupReducer,
  cart: persistReducer(cartStorageConfig, cartReducer),
  steps: stepsReducer,
  userDetails: persistReducer(userDetailsStorageConfig, userDetailsReducer),
  auth: persistReducer(authStorageConfig, authReducer),
  coupon: couponReducer,
  alert: alertReducer,
  source: persistReducer(sourceStorageConfig, sourceReducer),
});

const mainReducer = combineReducers({
  user: userReducer,
  merchant: merchantReducer,
  storefront: storefrontReducer,
  portal: portalReducer,
  panel: panelReducer,
});

const rootReducer = combineReducers({
  main: mainReducer,
  temporaryStyle: persistReducer(temporaryStyleStorageConfig, styleReducer),
});

export const rootPersistedReducer = persistReducer(rootConfig, rootReducer);

export type RootState = ReturnType<typeof rootReducer>;
