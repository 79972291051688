import {PaymentActionTypes, PaymentState, UPDATE_PAYMENTS} from "./types";

const initialState: PaymentState = {
  payments: []
}

export function paymentReducer(state: PaymentState = initialState, action: PaymentActionTypes): PaymentState {
  switch (action.type) {
    case UPDATE_PAYMENTS:
      return { ...state, payments: action.payload };
    default:
      return state;
  }
}
