import React, { FC, ReactNode, useCallback, useState } from "react";
import { Icon, Tooltip } from "./shoelace";
import AnimateHeight from "react-animate-height";
import clsx from "clsx";

import "./OptionDropdown.scss";

interface Props {
  title: String;
  children?: ReactNode;
  arrowReplacement?: ReactNode;
  tooltipText?: string;
  className?: string;
  icon?: string;
  iconImg?: ReactNode;
}

const OptionDropdown: FC<Props> = ({title, children, arrowReplacement, tooltipText, className, icon, iconImg}) => {
  const [open, setOpen] = useState(false);
  const openable = arrowReplacement === undefined;
  const toggleOpen = useCallback(() => {
    if (openable) {
      setOpen((previousState) => !previousState);
    }
  }, [openable]);

  return (
    <div className={clsx("option-dropdown", open && "open", className)}>
      <div
        className={clsx("summary-section", openable && "openable")}
        onClick={toggleOpen}
      >
        <div className="title-container">
          {icon ? (
            <Icon name={icon} className="title-icon" />
          ) : (
            iconImg
          )}
          <span className="title">{title}</span>
          {tooltipText ? (
            <Tooltip content={tooltipText}>
              <Icon className="help-icon" name="question-circle" />
            </Tooltip>
          ) : null}
        </div>
        {openable ? (
          <div className="dropdown-arrow-icon-container">
            <Icon name="caret-right-fill" />
          </div>
        ) : (
          arrowReplacement
        )}
      </div>
      <AnimateHeight
        style={{ flexShrink: 0 }}
        duration={200}
        height={openable && open ? "auto" : 0}
      >
        <div className={clsx("content-section", openable && open && "open")}>
          {children}
        </div>
      </AnimateHeight>
    </div>
  );
};

export default OptionDropdown;
