import React from "react";

import {RootState} from "../store";
import {connect, ConnectedProps} from "react-redux";
import {Input} from "./shoelace";
import LoadingButton from "./shared/LoadingButton";
import FormBase, {BaseState, InputTypeGenerator} from "./shared/FormBase";
import {apiRequestPasswordReset} from "../api";
import {PasswordResetStatus} from "./pages/portal/PasswordReset";

const mapState = (state: RootState) => ({
});

const mapDispatch = {
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
  setStatus: (status: PasswordResetStatus, statusMessage: string) => void
}
type State = BaseState<InputName> & {
  isEmailSent: boolean
}

const typeGenerator = new InputTypeGenerator([
  {name: "email", type: "text"}
]);
type InputName = typeof typeGenerator.InputName.type;

class PasswordResetRequestForm extends FormBase<Props, State, InputName> {
  getInitialState(baseState: BaseState<InputName>): State {
    return {...baseState, isEmailSent: false};
  }

  onSubmitValidForm() {
    this.setState({isLoading: true});
    apiRequestPasswordReset(this.state.formData.email).then(response => {
      this.setState({isLoading: false, isEmailSent: true});
      this.props.setStatus('success', 'The email has been sent.')
    }, error => {
      this.setState({isLoading: false});
      if (error.response.data.message.startsWith("No customer found")) {
        this.props.setStatus('error', 'This email is not associated with a Flexcheck account.')
      } else {
        this.props.setStatus('error', 'There was a problem trying to send the email.')
      }
    });

    return true;
  }

  constructor(props: Props) {
    super(
      props,
      typeGenerator,
      {
        setValid: {
          email: (value: string) => {
            let emailRegex = /\S+@\S+\.\S+/;
            return emailRegex.test(value);
          },
        },
        invalidMessages: {
          email: 'Please enter a valid email address.',
        },
        initialFormData: {
          email: ''
        }
      },
    );
  }

  render() {
    return (
      <form ref={this.formRef}>
        <div className={"form-section"}>
          <div className={"form-input-row"}>
            <Input
              className={`form-input light fixed-position-help-text ${this.state.touched['email'] ? 'touched':''}`}
              name={'email'}
              ref={this.inputRefs['email']}
              value={this.state.formData['email']}
              label={'Email'}
              disabled={this.state.isLoading || this.state.isEmailSent}>
              <div slot="help-text">{this.invalidMessages['email']}</div>
            </Input>
            <LoadingButton submit className={"small submit"} isLoading={this.state.isLoading} onSubmit={this.onSubmit}>{this.state.isEmailSent ? 'Resend' : 'Send'}</LoadingButton>
          </div>
        </div>
      </form>
    );
  }
}

export default connector(PasswordResetRequestForm);
