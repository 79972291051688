import React from "react";
import { Button, Dropdown, Menu, MenuItem } from "../shoelace";
import { RootState } from "../../store";
import { connect, ConnectedProps } from "react-redux";
import countryList from "country-list";
import "flag-icons/css/flag-icons.min.css";
import style from "./CountryDropdown.module.scss";
import clsx from "clsx";

const mapState = (state: RootState) => ({});

const mapDispatch = {};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
  country: string;
  disabled?: boolean;
  setCountry: (country: string) => void;
}

type State = {};

class CountryDropdown extends React.Component<Props, State> {
  onSubmitValidForm(): boolean {
    return true;
  }

  private setCountry = (code: string) => {
    this.setState({ country: code });
    this.props.setCountry(code);
  };

  render() {
    return (
      <Dropdown disabled={this.props.disabled} className={clsx('form-input', 'light', style.country)}>
        <Button slot="trigger" caret className="country-button icon">
          <span
            className={`fi fi-${this.props.country?.toLowerCase()} flag-icon flag-icon-${this.props.country?.toLowerCase()}`}
          ></span>
          {countryList.getName(this.props.country)}
        </Button>
        <Menu>
          {countryList
            .getData()
            .sort((a, b) =>
              a.name.toLowerCase().localeCompare(b.name.toLowerCase())
            )
            .map((country) => (
              <MenuItem
                className="country-menu-item"
                onClick={() => this.setCountry(country.code)}
                key={country.code}
              >
                {country.name}
              </MenuItem>
            ))}
        </Menu>
      </Dropdown>
    );
  }
}

export default connector(CountryDropdown);
