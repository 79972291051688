import {
    AccountActionTypes,
    AccountDetails,
    UPDATE_ACCOUNT_DETAILS,
    UPDATE_BILLING_ADDRESS,
    UPDATE_DELIVERY_ADDRESS,
    UPDATE_PAYMENT_METHODS
} from "./types";
import {SavedPaymentMethod} from "../../shared/user/types";
import {ApiAddress} from "../../../api/types";

export function updateBillingAddress(address: ApiAddress): AccountActionTypes {
  return {
    type: UPDATE_BILLING_ADDRESS,
    payload: address
  }
}

export function updateDeliveryAddress(address: ApiAddress): AccountActionTypes {
  return {
    type: UPDATE_DELIVERY_ADDRESS,
    payload: address
  }
}

export function updatePaymentMethods(paymentMethods: Array<SavedPaymentMethod>): AccountActionTypes {
  return {
    type: UPDATE_PAYMENT_METHODS,
    payload: paymentMethods
  }
}

export function updateAccountDetails(accountDetails: AccountDetails): AccountActionTypes {
  return {
    type: UPDATE_ACCOUNT_DETAILS,
    payload: accountDetails
  }
}
