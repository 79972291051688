import React from "react";

import {RootState} from "../../../store";
import {connect, ConnectedProps} from "react-redux";
import PortalLoginForm from "../../PortalLoginForm";
import PortalLoginPage from "../../PortalLoginPage";

const mapState = (state: RootState) => ({
});

const mapDispatch = {
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
  nextPage: Function
}
interface State {}

class Login extends React.Component<Props, State> {
  render() {
    return (
      <PortalLoginPage>
        <PortalLoginForm nextPage={this.props.nextPage}></PortalLoginForm>
      </PortalLoginPage>
    );
  }
}

export default connector(Login);
