import {Dispatch, Middleware} from "redux";
import {CART_ACTIONS, CartActionTypes, CartLine, CartState, CLEAR_CART} from "./types";
import {RootState} from "../../index";
import {apiCreateCart, apiReplaceCart} from "../../../api";
import {updateCardId} from "./actions";
import {ApiCartLine, ApiPricingComponentQuantity, CartResponse, PostCartData} from "../../../api/types";
import {CustomizedPricingComponent} from "../../shared/plan/types";
import {setCurrentStep} from "../steps/actions";
import {UPDATE_CUSTOMER_ID_AND_BF_ACCOUNT_ID} from "../userDetails/types";
import {getQuoteForCart} from "../../../api/quotes";

const DEBUG = false;

function cartToApi(cart: CartState): PostCartData {
    return {
        cartContents: cart.cart.map(lineToApi)
    };
}

function pricingComponentToApi(component: CustomizedPricingComponent): ApiPricingComponentQuantity {
    return {
        pricingComponent: component.name,
        quantity: component.chosenQuantity
    };
}

function lineToApi(line: CartLine): ApiCartLine {
    return {
        id: line.id,
        bfPlan: line.plan.bfId,
        coupons: line.plan.couponCodes,
        pricingComponents: line.plan.customizedPricingComponents.map(pricingComponentToApi),
        subscriptionStatus: undefined
    };
}

async function saveCart(cart: CartState, dispatch: Dispatch): Promise<CartResponse> {
    let result;
    if (cart.cartId === '') {
        result = await apiCreateCart(cartToApi(cart));
        if (result.status >= 200 && result.status <= 299) {
            dispatch(updateCardId(result.data.id));
        }
    } else {
        result = await apiReplaceCart(cart.cartId, cartToApi(cart));
    }
    return result.data
}

async function deleteCart(cartId: string) {
    return await apiReplaceCart(cartId, {cartContents: []});
}

export const cartWatcher: Middleware<{}, RootState> = storeApi => next => action => {
    const previousState = storeApi.getState()
    const result = next(action);
    const state = storeApi.getState()

    if (CART_ACTIONS.includes(action.type)) {
        const cartAction = action as CartActionTypes;
        let promised;
        if (cartAction.type === CLEAR_CART) {
            promised = deleteCart(previousState.main.user.cart.cartId);
            storeApi.dispatch(setCurrentStep(0));
        } else {
            promised = saveCart(state.main.user.cart, storeApi.dispatch);
        }
        if (DEBUG) {
            console.log("Attempting to save cart state to server.");
            promised.then(console.log).catch(console.error);
        } else {
            promised.catch(console.error);
        }
    } else if (action.type === UPDATE_CUSTOMER_ID_AND_BF_ACCOUNT_ID) {
        const promised = getQuoteForCart(state.main.user.cart.cart, state.main.user.userDetails.bfAccountId);
        if (DEBUG) {
            console.log("Attempting to update quote for cart.");
            promised.then(console.log).catch(console.error);
        } else {
            promised.catch(console.error);
        }
    } else if (DEBUG) {
        console.log(action.type + " is not one of the cart actions. Ignoring.");
    }

    return result;
};
