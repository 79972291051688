import React, { FC, ReactNode } from "react";
import clsx from "clsx";

import "./LabelledContainer.scss";

interface Props {
  label: string;
  LabelTag?: 'h3' | 'h4' | 'h5' | 'h6';
  nested?: boolean;
  includeCard?: boolean;
  children?: ReactNode;
  className?: string;
  selected?: boolean;
  onSelect?: Function;
  icon?: ReactNode;
}

const LabelledContainer: FC<Props> = ({
  label,
  LabelTag = 'h3',
  nested,
  includeCard,
  children,
  className,
  selected,
  onSelect,
  icon,
}) => {
  const Tag = onSelect ? 'div' : 'div'
  return (
    <div className={clsx("labelled-container", className)}>
      <LabelTag className={clsx("labelled-container-label", nested && 'nested')}>{label}</LabelTag>
      {includeCard ? (
        <Tag
          className={clsx(
            "labelled-container-card",
            onSelect && "selectable",
            selected && "selected"
          )}
          onClick={() => {
            if (onSelect) onSelect();
          }}
        >
          <div className="labelled-container-card-icon">{icon}</div>
          {children}
        </Tag>
      ) : (
        children
      )}
    </div>
  );
};

export default LabelledContainer;
