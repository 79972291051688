import React, { FC, useEffect, useState } from "react";
import { Button, Dialog, Radio, RadioGroup } from "../shoelace";
import { enGB } from "date-fns/locale";
import { DatePickerCalendar } from "react-nice-dates";
import "react-nice-dates/build/style.css";
import { Subscription } from "../../store/portal/subscription/types";
import { showAlert } from "../../store/shared/alert/actions";
import moment from "moment";
import { apiPauseSubscription } from "../../api/portalEndpoints";
import { AlertType } from "../../store/shared/alert/types";
import { getAlertFromApiErrorResponse } from "../../utils/utils";
import LoadingButton from "../shared/LoadingButton";
import SlDialogElement from "@shoelace-style/shoelace/dist/components/dialog/dialog";

import "./SubscriptionPauseDialog.scss";

interface Props {
  subscription: Subscription;
  isLoading: boolean
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  closeDialog: () => void;
  open: boolean;
}

const SubscriptionPauseDialog: FC<Props> = ({
  subscription,
  isLoading,
  setIsLoading,
  closeDialog,
  open,
}) => {
  const [resumeDate, setResumeDate] = useState<Date>();

  const dialogRef = React.useRef<SlDialogElement>(null);

  const onConfirmPauseSubscription = () => {
    setIsLoading(true);
    apiPauseSubscription(subscription.id, {
      resume: resumeDate,
    }).then(
      (response) => {
        showAlert({
          type: AlertType.Success,
          message: "Your subscription has been paused.",
        });
        setIsLoading(false);
        closeDialog();
      },
      (error) => {
        showAlert(
          getAlertFromApiErrorResponse(
            error,
            "There was a problem pausing your subscription."
          )
        );
        setIsLoading(false);
      }
    );
  };

  useEffect(() => {
    dialogRef.current!.addEventListener("sl-hide", () => closeDialog());
  }, [dialogRef, closeDialog]);

  return (
    <Dialog
      label="Pause Subscription"
      className="subscription-pause-dialog"
      open={open}
      ref={dialogRef}
    >
      <div className="subscription-pause-dialog-content">
        <div className="pause-sentence">
          <span>I want to pause my subscription until...</span>
        </div>
        <div className="resume-date-options">
          <RadioGroup className="resume-date-radio-group">
            <Radio
              value={"1"}
              checked={resumeDate === undefined}
              onClick={() => setResumeDate(undefined)}
            >
              I resume it myself
            </Radio>
            <Radio
              value={"2"}
              checked={resumeDate !== undefined}
              onClick={() => setResumeDate(new Date())}
            >
              a certain date
            </Radio>
          </RadioGroup>
          <div className={`resume-date-picker ${resumeDate ? "" : "faded"}`}>
            <DatePickerCalendar
              locale={enGB}
              date={resumeDate}
              onDateChange={(date) => setResumeDate(date ?? undefined)}
              minimumDate={new Date()}
            />
          </div>
        </div>
        <div className="pause-sentence">
          {resumeDate ? (
            <span>
              Your subscription will be <b>paused</b> until{" "}
              <b>{moment(resumeDate).format("Do MMMM YYYY")}</b>, when it will
              resume automatically.
            </span>
          ) : (
            <span>
              Your subscription will be <b>paused</b> until you resume it{" "}
              <b>manually</b> from your account.
            </span>
          )}
        </div>
        <div className="pause-sentence">
          <span>
            You won't be charged anything while your subscription is paused.
          </span>
        </div>
      </div>
      <div slot="footer" className="dialog-buttons">
        <Button className={"neutral custom"} onClick={closeDialog}>
          back
        </Button>
        <LoadingButton
          className={"pause"}
          onSubmit={onConfirmPauseSubscription}
          isLoading={isLoading}
        >
          Pause
        </LoadingButton>
      </div>
    </Dialog>
  );
};

export default SubscriptionPauseDialog;
