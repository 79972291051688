import React, { FC } from "react";
import { useDispatch } from "react-redux";
import MerchantPage from "../../MerchantPage";
import EditPlanForm, {EditPlanFormData} from "../../EditPlanForm";
import {Button} from "../../shoelace";
import {Plan} from "../../../store/shared/plan/types";
import {api} from "../../../api";
import {useHistory} from "react-router";
import {showAlert as showAlertAction} from "../../../store/shared/alert/actions";
import {AlertType} from "../../../store/shared/alert/types";
import {getBfAppUrl} from "../../../utils/utils";

interface Props {
  plan: Plan
}

const EditPlan: FC<Props> = ({ plan }) => {
  const history = useHistory();
  const dispatch = useDispatch()

  // TODO: This assumes the error looks like this
  const showAlert = (error: any) => {
    dispatch(showAlertAction({
      type: AlertType.Error,
      message: `There was an error saving changes to this plan: ${error.response.data.message}`
    }))
  }

  const onSubmit = async (formData: EditPlanFormData) => {
    return api.put('plan', {
      bfPlanPath: plan.bfPlanPath,
      cardText: formData.cardText,
      colour: formData.cardColor,
      imageUrl: formData.imageUrl
    }).then(() => {
      history.push('/merchant/plans');
    }, error => {
      showAlert(error);
    });
  }

  return (
    <MerchantPage
      className={"edit-plan-page"}
      title={"Edit Plan"}
      subtitle={plan.bfPlanPath}
      description={"Tailor how this plan appears to your customers"}
      button={
        <Button className="secondary small" href={`${getBfAppUrl()}#/plans/view/${plan.bfId}`} target="_blank">View in Billforward</Button>
      }>
      <EditPlanForm plan={plan} onSubmit={onSubmit}/>
    </MerchantPage>
  )
}

export default EditPlan;
