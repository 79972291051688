import React, {FC} from "react";
import {CustomizedPlan} from "../store/shared/plan/types";
import {useSelector} from "react-redux";
import {Button, Icon} from "./shoelace";
import PriceText from "./PriceText";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import {selectShowCart, selectUserCart} from "../store/user/cart/selectors";
import clsx from "clsx";
import {selectSource} from "../store/persisted/source/selectors";

import "./PlanCardMini.scss";
import {
  discountedInitialPrice,
  discountedPerPeriodPrice,
  initialPrice,
  initialPriceShownIsDifferentToRecurringPrice,
  perPeriodPrice
} from "../utils/price";
import TaxText from "./TaxText";

interface Props {
  plan: CustomizedPlan;
  showDetails?: boolean;
  hidePrice?: boolean;
  multiplePlans?: boolean;
  hideViewButton?: boolean;
  showTax: boolean;
}

const PlanCardMini: FC<Props> = (props) => {
  const showCart = useSelector(selectShowCart);
  const cart = useSelector(selectUserCart);
  const source = useSelector(selectSource);

  const generateBlockBorderImage = () => {
    const gradientArray = ["linear-gradient(to bottom, "];
    const colors = cart.map((cartLine) => cartLine.plan.colour || "white");
    gradientArray.push(
      `${colors[0]} ${Number(100 / colors.length).toFixed()}%, `
    );
    for (let i = 1; i < colors.length - 1; i++) {
      gradientArray.push(
        `${colors[i]} ${Number((100 / colors.length) * i).toFixed()}% ${Number(
          (100 / colors.length) * (i + 1)
        ).toFixed()}%, `
      );
    }
    gradientArray.push(
      `${colors[colors.length - 1]} ${Number(
        (100 / colors.length) * (colors.length - 1)
      ).toFixed()}%) 1`
    );
    return gradientArray.join("");
  };

  const getBorderStyle = () => {
    return props.multiplePlans
      ? {
          borderLeft: `double transparent var(--border-radius-large)`,
          borderImage: generateBlockBorderImage(),
        }
      : {
          borderLeft: `solid ${
            props.plan.colour || "#ffffff00"
          } var(--border-radius-large)`,
        };
  };

  const initialPriceDiff = initialPriceShownIsDifferentToRecurringPrice(props.plan);

  const showCouponCode =
    props.plan.couponCodes.length > 0 && props.plan.couponCodes[0] !== source;

  return (
    <div
      className={clsx(
        "plan-card-mini",
        !props.showDetails && "no-details",
        props.multiplePlans && "gradient-border"
      )}
      style={getBorderStyle()}
    >
      <div className="plan-card-content">
        <div
          className={`plan-card-mini-header ${
            props.multiplePlans ? "multiple-plans" : ""
          }`}
        >
          <div className="title-section">
            <h3 className="title">{props.plan.name}</h3>
            {props.plan.trialDescription.length !== 0 ? (
              <div className="trial">{props.plan.trialDescription}</div>
            ) : null}
            {showCouponCode && (
              <div className="coupon">
                <Icon name="tag-fill" />
                {props.plan.couponCodes[0]}
              </div>
            )}
          </div>
          <div className="price-section" hidden={props.multiplePlans}>
            {props.hidePrice ? null : (
              <div className="price-listing">
                {initialPriceDiff && (
                  <>
                    <PriceText
                      oldPrice={initialPrice(props.plan)}
                      currentPrice={discountedInitialPrice(props.plan)}
                      currency={props.plan.baseCurrency}
                      rightAlign={true}
                    />
                    <span className="period">first {props.plan.period}</span>
                    <TaxText plan={props.plan} type={"initial"} showTax={props.showTax}/>
                  </>
                )}
                <PriceText
                  oldPrice={perPeriodPrice(props.plan)}
                  currentPrice={discountedPerPeriodPrice(props.plan)}
                  currency={props.plan.baseCurrency}
                  rightAlign={true}
                />
                <TaxText plan={props.plan} type={"per_period"} showTax={props.showTax}/>
                {props.plan.oneOffPayment ? null : (
                  <span className="period">per {props.plan.period}</span>
                )}
              </div>
            )}
          </div>
          <div
            className="cart-button-section"
            hidden={!props.multiplePlans || props.hideViewButton}
          >
            <Button className="small secondary" onClick={showCart}>
              View
            </Button>
          </div>
        </div>
        {props.showDetails && props.plan.cardText ? (
          <div className="plan-card-mini-details details-section">
            <div className={"column"}>
              <ReactMarkdown
                className="details-markdown"
                plugins={[gfm]}
                children={props.plan.cardText}
                linkTarget="_blank"
              />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default PlanCardMini;
