export const SET_SOURCE = "SET_SOURCE";
export const REMOVE_SOURCE = "REMOVE_SOURCE";

export interface SourceState {
  source: string;
}

interface SetSourceAction {
  type: typeof SET_SOURCE;
  payload: {
    source: string;
  };
}

interface RemoveSourceAction {
  type: typeof REMOVE_SOURCE;
}

export type SourceActionTypes = SetSourceAction | RemoveSourceAction;
