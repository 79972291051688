import React, { FC, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Button, Dialog } from "../shoelace";
import { Subscription } from "../../store/portal/subscription/types";
import moment from "moment";
import { apiCancelSubscription } from "../../api/portalEndpoints";
import { showAlert } from "../../store/shared/alert/actions";
import { AlertType } from "../../store/shared/alert/types";
import { getAlertFromApiErrorResponse } from "../../utils/utils";
import LoadingButton from "../shared/LoadingButton";
import SlDialogElement from "@shoelace-style/shoelace/dist/components/dialog/dialog";

import "./SubscriptionCancelDialog.scss";
import {fetchSubscriptions} from "../../api/portalCompositeCalls";

interface Props {
  subscription: Subscription;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  closeDialog: () => void;
  open: boolean;
}

const SubscriptionCancelDialog: FC<Props> = ({
  subscription,
  isLoading,
  setIsLoading,
  closeDialog,
  open,
}) => {
  const dispatch = useDispatch();
  const dialogRef = React.useRef<SlDialogElement>(null);

  const onConfirmCancelSubscription = () => {
    setIsLoading(true);
    apiCancelSubscription(subscription.id).then(
      (response) => {
        dispatch(
          showAlert({
            type: AlertType.Success,
            message: "Your subscription has been cancelled.",
          })
        );
        setIsLoading(false);
        return fetchSubscriptions().then(() => closeDialog());
      },
      (error) => {
        dispatch(
          showAlert(
            getAlertFromApiErrorResponse(
              error,
              "There was a problem cancelling your subscription."
            )
          )
        );
        setIsLoading(false);
      }
    );
  };

  useEffect(() => {
    dialogRef.current!.addEventListener("sl-hide", () => closeDialog());
  }, [dialogRef, closeDialog]);

  return (
    <Dialog
      label="Cancel Subscription"
      className="subscription-cancel-dialog"
      open={open}
      ref={dialogRef}
    >
      <div className="subscription-cancel-dialog-content">
        <div className="cancel-sentence">
          <span>
            Your subscription will be cancelled on{" "}
            <b>
              {moment
                .unix(subscription.currentPeriodEnd ?? moment().valueOf())
                .format("Do MMMM YYYY")}
            </b>
            .
          </span>
        </div>
        <div className="cancel-sentence">
          <span>Are you sure you want to cancel your subscription?</span>
        </div>
      </div>
      <div slot="footer" className="dialog-buttons">
        <Button className={"neutral custom"} onClick={closeDialog}>
          No, go back
        </Button>
        <LoadingButton
          className={"error"}
          onSubmit={onConfirmCancelSubscription}
          isLoading={isLoading}
        >
          Yes, cancel
        </LoadingButton>
      </div>
    </Dialog>
  );
};

export default SubscriptionCancelDialog;
