// Wrap custom elements from shoelace here so that they are exported as React components

import {setBasePath} from '@shoelace-style/shoelace/dist/utilities/base-path';
import {
    SlAlert,
    SlBadge,
    SlButton,
    SlButtonGroup,
    SlCheckbox,
    SlColorPicker,
    SlDetails,
    SlDialog,
    SlDrawer,
    SlDropdown,
    SlIcon,
    SlIconButton,
    SlInput,
    SlMenu,
    SlMenuItem,
    SlRadio,
    SlRadioGroup,
    SlSelect,
    SlSpinner,
    SlSwitch,
    SlTab,
    SlTabGroup,
    SlTabPanel,
    SlTextarea,
    SlTooltip
} from '@shoelace-style/shoelace/dist/react';

import '@shoelace-style/shoelace/dist/themes/light.css';
import './shoelace/Alert.scss';
import './shoelace/Button.scss';
import './shoelace/Form.scss';
import './shoelace/Input.scss';
import './shoelace/Checkbox.scss';
import './shoelace/ColorPicker.scss';
import './shoelace/Dialog.scss';
import './shoelace/Dropdown.scss';
import './shoelace/Menu.scss';
import './shoelace/Spinner.scss';
import './shoelace/Details.scss';
import './shoelace/Switch.scss';
import './shoelace/Tooltip.scss';
import './shoelace/Select.scss';
import './shoelace/TabGroup.scss';
import './shoelace/PreviousVersion.scss';

function setupShoelace() {
    setBasePath('https://cdn.jsdelivr.net/npm/@shoelace-style/shoelace@2.0.0-beta.80/dist/');
}

const Button = SlButton;
const Alert = SlAlert;
const Badge = SlBadge;
const ButtonGroup = SlButtonGroup;
const Checkbox = SlCheckbox;
const ColorPicker = SlColorPicker;
const Details = SlDetails;
const Dialog = SlDialog;
const Drawer = SlDrawer;
const Dropdown = SlDropdown;
const Icon = SlIcon;
const IconButton = SlIconButton;
const Input = SlInput;
const Menu = SlMenu;
const MenuItem = SlMenuItem;
const Radio = SlRadio;
const RadioGroup = SlRadioGroup;
// SlResponsiveMedia; //TODO
const Select = SlSelect;
/**
 * Apply classes and styles to THIS element, NOT nested elements using ::part()
 */
const Spinner = SlSpinner;
const Switch = SlSwitch;
const TabGroup = SlTabGroup;
const Tab = SlTab;
const TabPanel = SlTabPanel;
const TextArea = SlTextarea;
const Tooltip = SlTooltip;

export { setupShoelace, Alert, Badge, Button, ButtonGroup, Checkbox, ColorPicker, Details, Dialog, Drawer, Dropdown,
    Icon, IconButton, Input, Menu, MenuItem, Radio, RadioGroup, Select, Spinner, Switch,
    TabGroup, Tab, TabPanel, TextArea, Tooltip };
