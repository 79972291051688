import React from "react";
import './PublicButton.scss'
import {useLocation} from "react-router-dom";

type Props = {}

const PublicButton = (props: Props) => {
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();

  return(
    <a id="flexcheck-button" href={query.get('link') || ''} target="_blank" rel="noreferrer">{query.get('text')}</a>
  );
}

export default PublicButton;
