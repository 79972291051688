import { RootState } from "../..";

export const selectUserCart = (state: RootState) => state.main.user.cart.cart;

export const selectShowCart = (state: RootState) =>
  state.main.user.steps.showCart;

export const selectPortalStorefrontAliasLoggedInto = (state: RootState) =>
  state.main.user.auth.portalStorefrontAliasLoggedInto;

export const selectIsMerchantLoggedIn = (state: RootState) =>
  state.main.user.auth.merchantIsLoggedIn;
