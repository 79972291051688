import {store} from "../../index";
import {clearCart} from "../../store/user/cart/actions";
import {CartState} from "../../store/user/cart/types";
import {apiGetCart} from "../../api";

const checkCart = function (cart: CartState) {
    if (!cart || !cart.cartId) {
        return;
    }
    apiGetCart(cart.cartId)
        .then(result => {
            const remoteIds = new Set<string>();
            for (const remoteLine of result.data.cartContents) {
                remoteIds.add(remoteLine.id);
            }

            let missingFromRemote = false;
            const localIds = new Set<string>();
            for (const localLine of cart.cart) {
                if (!remoteIds.has(localLine.id)) {
                    missingFromRemote = true;
                }
                localIds.add(localLine.id);
            }

            let missingFromLocal = false;
            remoteIds.forEach(remoteId => {
                if (!localIds.has(remoteId)) {
                    missingFromLocal = true;
                }
            });

            if (missingFromLocal) {
                console.error("The browser is missing some card lines that the server has");
                store.dispatch(clearCart());
            }
            if (missingFromRemote) {
                console.error("The server is missing some card lines that the browser has");
                store.dispatch(clearCart());
            }
        })
        .catch(() => {
            store.dispatch(clearCart());
        });
};

export default checkCart;
