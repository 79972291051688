import React, {ReactNode, RefObject} from "react";

import './WarningDialog.scss';
import {Button, Dialog} from "./shoelace";
import LoadingButton from "./shared/LoadingButton";
import SlDialogElement from "@shoelace-style/shoelace/dist/components/dialog/dialog";

type ButtonText = {
  confirm?: string,
  cancel: string
}

interface Props {
  title?: string,
  buttonText: ButtonText,
  onConfirm: () => Promise<unknown>,
  children?: ReactNode
}

type State = {
  isLoading: boolean
}

class WarningDialog extends React.Component<Props, State> {
  private readonly dialog: RefObject<SlDialogElement>;

  constructor(props: Props) {
    super(props);
    this.dialog = React.createRef<SlDialogElement>();

    this.state = {
      isLoading: false
    }
  }

  public showDialog = () => {
    this.dialog.current!.show();
  }
  public hideDialog = () => {
    this.dialog.current!.hide();
  }

  private onConfirmWithLoading = () => {
    this.setState({isLoading: true});
    this.props.onConfirm().then(response => {
      this.setState({isLoading: false});
      this.hideDialog();
    }, error => {
      this.setState({isLoading: false});
      this.hideDialog();
    });
  }

  render() {
    return (
      <Dialog label={this.props.title} className="warning-dialog" ref={this.dialog} noHeader>
        {this.props.children}
        <div slot="footer" className="dialog-buttons apart">
          <LoadingButton className={"error small"} onSubmit={() => this.onConfirmWithLoading()} isLoading={this.state.isLoading} noCustomStyle>{this.props.buttonText.confirm}</LoadingButton>
          <Button className={"neutral small"} onClick={this.hideDialog} disabled={this.state.isLoading}>{this.props.buttonText.cancel}</Button>
        </div>
      </Dialog>
    );
  }
}

export default WarningDialog;
