import { RootState } from "..";

export const selectPanelState = (state: RootState) => state.main.panel;

export const selectPanelIsOpen = (state: RootState) => state.main.panel.isOpen;

export const selectPanelType = (state: RootState) => state.main.panel.panelType;

export const selectPanelPlanId = (state: RootState) => state.main.panel.planId;

export const selectPanelIsLoading = (state: RootState) =>
  state.main.panel.isLoading;
