import {
  REMOVE_SOURCE,
  SET_SOURCE,
  SourceActionTypes,
  SourceState,
} from "./types";

const initialState: SourceState = {
  source: "",
};

export function sourceReducer(
  state: SourceState = initialState,
  action: SourceActionTypes
): SourceState {
  switch (action.type) {
    case SET_SOURCE:
      return { source: action.payload.source };
    case REMOVE_SOURCE:
      return { source: "" };
    default:
      return state;
  }
}
