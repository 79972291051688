import {
  PlanGroupActionTypes,
  PlanGroupState,
  RESET_PLAN_GROUP,
  UPDATE_PLAN_GROUP,
  UPDATE_PLAN_GROUPS,
} from "./types";

const initialState: PlanGroupState = {
  planGroups: [],
  planGroup: null
};

export const planGroupReducer = (
  state = initialState,
  action: PlanGroupActionTypes
) => {
  switch (action.type) {
    case UPDATE_PLAN_GROUP:
      return { ...state, planGroup: action.payload };
    case UPDATE_PLAN_GROUPS:
      return { ...state, planGroups: action.payload };
      case RESET_PLAN_GROUP:
        return { ...state, planGroup: null }
    default:
      return state;
  }
};
