import React, { FC, ReactNode } from "react";

import "./ControlsCard.scss";

type ControlsCardDetail = {
  label: string;
  value: string | ReactNode;
};

interface Props {
  details: Array<ControlsCardDetail>;
  colour?: string;
  controls?: ReactNode;
}

const getBorderStyle = (colour?: string) => ({
  borderLeft: `solid ${colour ?? "#ffffff00"} var(--border-radius-large)`,
});

const ControlsCard: FC<Props> = ({ details, colour, controls }) => {

  return (
    <div className="controls-card" style={getBorderStyle(colour)}>
      <div className="controls-card-content">
        <div className="controls-card-details-section">
          {details.map((detail) =>
            detail.label && detail.value ? (
              <div className="controls-card-detail" key={detail.label}>
                <span className="controls-card-detail-label">
                  {detail.label}
                </span>
                <span className="controls-card-detail-value">
                  {detail.value}
                </span>
              </div>
            ) : null
          )}
        </div>
        <div className="controls-card-controls-section">{controls}</div>
      </div>
    </div>
  );
};

export default ControlsCard;
