import { StorefrontType, StorefrontUiOptions } from "../store/storefront/types";
import {
  validateBfPassword,
  validateFlexcheckPassword,
  validationMessages,
} from "./validation";
import React from "react";
import { getBfAppUrl } from "./utils";
import { apiCreateBfOrg } from "../api";
import { store } from "../index";
import { Icon } from "../components/shoelace";
import PlanCard from "../components/PlanCard";
import PlanCardMarkdown from "../components/PlanCardMarkdown";

const bonusPlanCardContent = `**Custom-made solution designed to meet the unique needs of enterprise customers.**
- Unlimited Team Members
- Unlimited Customers
- Custom Transaction Limits
- Unlimited Subscriptions
- Unlimited Transactions
- Custom API Solutions
- Dedicated Support Team
- All integrations from the "Growth" plan + **Salesforce**
- Custom integrations available`

const BF_ORG_OPTIONS: StorefrontUiOptions = {
  password: {
    validate: validateBfPassword,
    validationMessage: validationMessages.bfPassword,
  },
  style: {
    generateCustomBackgroundCss: (url: string) => ({
      "background-image": `url('https://s3-us-west-1.amazonaws.com/billforward.logo.images/bottom-curve-image.svg'), linear-gradient(#FFFFFF7F, #FFFFFF7F), url('${url}')`,
      "background-size": `100% 250px, cover, cover, cover`,
      "background-repeat": `no-repeat`,
      "background-position": `top 700px center, top 950px center, top center, top center`,
      "background-attachment": `local, local, fixed, fixed`,
    }),
  },
  bonusPlanCard: (
    <PlanCard
      title="Enterprise"
      btnText="Contact us"
      handleBtnClick={() =>
        window.open("https://www.billforward.io/request-a-demo", "_blank")
      }
    >
      <PlanCardMarkdown
        content={bonusPlanCardContent}
      />
    </PlanCard>
  ),
  onSuccessfulPurchase: () =>
    apiCreateBfOrg(store.getState().main.user.userDetails.customerId, {
      password: store.getState().main.user.userDetails.details.password,
    }),
  completedPurchase: {
    onComplete: () => {
      window.location.href = getBfAppUrl();
    },
    renderCompletedMessage: () => (
      <div className="billforward-complete">
        <div className="complete-environments-section">
          <span>You can access your environments here:</span>
          <div className="environments-grid">
            <div className="environments-row">
              <span className="environment-name">
                <Icon className="icon" name={"gear-wide-connected"} />
                Production:
              </span>
              <a
                className="environment-url"
                href="https://app.billforward.net"
                target="_blank"
                rel="noreferrer"
              >
                https://app.billforward.net
              </a>
            </div>
            <div className="environments-row">
              <span className="environment-name">
                <Icon className="icon" name={"box"} />
                Sandbox:
              </span>
              <a
                className="environment-url"
                href="https://app-sandbox.billforward.net"
                target="_blank"
                rel="noreferrer"
              >
                https://app-sandbox.billforward.net
              </a>
            </div>
          </div>
        </div>
        <span>
          We've sent an email to{" "}
          <b>{store.getState().main.user.userDetails.details.email}</b> with
          your access details.
        </span>
        <span>
          If you have any problems, feel free to contact our friendly customer
          support - we’ll be happy to assist you!{" "}
        </span>
        <div className="complete-catchphrase-container">
          <span className="complete-catchphrase">BUILD LESS. BILL MORE.</span>
        </div>
      </div>
    ),
    fullList: false,
    showPrice: false,
  },
};

const defaultBackgroundStyleGenerator = (url: string) => ({
  "background-image": `url('${url}')`,
  "background-size": `cover, cover`,
  "background-repeat": `no-repeat`,
  "background-position": `top center, top center`,
  "background-attachment": `fixed, fixed`,
});

const APPLOCKER_ORG_OPTIONS: StorefrontUiOptions = {
  password: {
    validate: validateFlexcheckPassword,
    validationMessage: validationMessages.flexcheckPassword,
  },
  style: {
    generateCustomBackgroundCss: defaultBackgroundStyleGenerator,
  },
  completedPurchase: {
    fullList: true,
    showPrice: true,
  },
};

export const CUSTOMER_ORG_OPTIONS: StorefrontUiOptions = {
  password: {
    validate: validateFlexcheckPassword,
    validationMessage: validationMessages.flexcheckPassword,
  },
  style: {
    generateCustomBackgroundCss: defaultBackgroundStyleGenerator,
    customShoelacePrimaryColor: {
      hue: 203,
      saturation: 100,
    },
  },
  completedPurchase: {
    fullList: true,
    showPrice: true,
  },
};

export const getStorefrontUiOptionsByStorefrontType = (
  type: StorefrontType
): StorefrontUiOptions => {
  switch (type) {
    case "BfAccountingOrg":
      return BF_ORG_OPTIONS;
    case "ApplockerOrg":
      return APPLOCKER_ORG_OPTIONS;
    default:
      return CUSTOMER_ORG_OPTIONS;
  }
};
