import React, { FC } from "react";
import { ApiAddress } from "../../api/types";

import styles from "./ShortAddress.module.scss";

interface Props {
  address?: ApiAddress;
}

const ShortAddress: FC<Props> = ({ address }) => {
  return address ? (
    <div className={styles['short-address']}>
      <p>{address.addressLine1}</p>
      <p>{address.city || address.province || address.country}</p>
      <p>{address.postcode}</p>
    </div>
  ) : (
    <div className={styles['short-address']}>
      <p>No address found</p>
    </div>
  );
};

export default ShortAddress;
