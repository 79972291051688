import {StyleActionTypes, StyleState, UPDATE_BACKGROUND, UPDATE_LOGO} from "./types";

const initialState: StyleState = {
  backgroundImageUrl: '',
  logoImageUrl: '',
  usingTemporaryStyling: null
}

function tempStylingUntil(): number {
  //This is to handle the case where the component is never unloaded (page crashes or user navigates away) we
  //  don't want to continue showing the temporary image on ALL Flexcheck storefronts in the future.
  return new Date().getTime() + 1000 * 60 * 2; //2 mins to load the preview
}

export function styleReducer(state: StyleState = initialState, action: StyleActionTypes): StyleState {
  switch (action.type) {
    case UPDATE_BACKGROUND:
      return { ...state, backgroundImageUrl: action.payload.url, usingTemporaryStyling: action.payload.temporary ? tempStylingUntil() : null };
    case UPDATE_LOGO:
      return { ...state, logoImageUrl: action.payload.url, usingTemporaryStyling: action.payload.temporary ? tempStylingUntil() : null };
    default:
      return state;
  }
}
