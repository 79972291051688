import React, {FC, useState} from "react";
import {useSelector} from "react-redux";
import PortalPage from "../../PortalPage";
import PortalPanel from "../../portal/PortalPanel";
import {Subscription} from "../../../store/portal/subscription/types";
import {Tab, TabGroup, TabPanel} from "../../shoelace";
import StatusBadge from "../../portal/StatusBadge";
import SubscriptionOverviewSection from "../../portal/SubscriptionOverviewSection";
import SubscriptionCancelDialog from "../../portal/SubscriptionCancelDialog";
import SubscriptionPauseDialog from "../../portal/SubscriptionPauseDialog";
import SubscriptionResumeDialog from "../../portal/SubscriptionResumeDialog";
import {getStyleFromSubscriptionStatus} from "../../../store/portal/subscription/utils";
import {selectStorefront} from "../../../store/storefront/selectors";
import SpinnerPage from "../../shared/SpinnerPage";
import {selectPlanGroups} from '../../../store/user/planGroup/selectors'
import {isPlanInGroup} from '../../../utils/utils'

import "./SubscriptionDetail.scss";
import SubscriptionManageTabPanel from "../../portal/SubscriptionManageTabPanel";

interface Props {
  subscription: Subscription;
}

const SubscriptionDetail: FC<Props> = ({ subscription }) => {
  const storefront = useSelector(selectStorefront);
  const planGroups = useSelector(selectPlanGroups)
  const [isLoading, setIsLoading] = useState(false);
  const [isPauseOpen, setIsPauseOpen] = useState(false);
  const [isResumeOpen, setIsResumeOpen] = useState(false);
  const [isCancelOpen, setIsCancelOpen] = useState(false);

  const planGroup=planGroups.find(
    (planGroup) =>
        isPlanInGroup(subscription.plan, planGroup)
  )

  if (isLoading) return <SpinnerPage />;

  return (
    <PortalPage
      title="Subscription Details"
      className="subscription-detail-page"
    >
      <PortalPanel
        breadcrumbs={[
          {
            title: "Subscriptions",
            link: `/store/${storefront.alias}/portal/subscriptions`,
          },
          {
            title: subscription.name ?? "Untitled",
          },
        ]}
        topRight={
          <StatusBadge
            text={subscription.status}
            statusStyle={getStyleFromSubscriptionStatus(subscription.status)}
          />
        }
      >
        {planGroup?.heroImg || subscription.plan.imageUrl ?
        <div className="image-container">
          <img src={planGroup?.heroImg || subscription.plan.imageUrl} alt={subscription.name} />
        </div> : null}
        <div className="subscription-detail-tabs">
          <TabGroup>
            <Tab slot="nav" panel="overview">
              Overview
            </Tab>
            <Tab slot="nav" panel="manage">
              Manage
            </Tab>
            <TabPanel name="overview">
              <SubscriptionOverviewSection subscription={subscription} />
            </TabPanel>
            <SubscriptionManageTabPanel subscription={subscription}
                                        setIsResumeOpen={setIsResumeOpen}
                                        setIsPauseOpen={setIsPauseOpen}
                                        setIsCancelOpen={setIsCancelOpen} />
          </TabGroup>
        </div>
        <SubscriptionPauseDialog
          subscription={subscription}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          closeDialog={() => setIsPauseOpen(false)}
          open={isPauseOpen}
        />
        <SubscriptionResumeDialog
          subscription={subscription}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          closeDialog={() => setIsResumeOpen(false)}
          open={isResumeOpen}
        />
        <SubscriptionCancelDialog
          subscription={subscription}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          closeDialog={() => setIsCancelOpen(false)}
          open={isCancelOpen}
        />
      </PortalPanel>
    </PortalPage>
  );
};

export default SubscriptionDetail;
