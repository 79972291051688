import React from "react";

import StepContainer from "../../StepContainer";

import {connect, ConnectedProps} from "react-redux";
import {Button} from "../../shoelace";

import './Complete.scss';
import CartSummary from "../../CartSummary";
import gfm from "remark-gfm";
import ReactMarkdown from "react-markdown";
import {clearCart} from "../../../store/user/cart/actions";
import {RootState} from "../../../store";
import {resetUser} from "../../../store/user/resetUser";
import {Trans, useTranslation} from "react-i18next";

const mapState = (state: RootState) => ({
  currency: state.main.user.plan.currency,
  currentStep: state.main.user.steps.currentStep,
  totalSteps: state.main.user.steps.totalSteps,
  userDetails: state.main.user.userDetails.details,
  completedPurchaseOptions: state.main.storefront.uiOptions.completedPurchase,
  checkedOut: state.main.user.cart.checkedOut,
  purchaseMessage: state.main.storefront.storefront.purchaseMessage,
  allowCustomerPortal: state.main.storefront.storefront.allowCustomerPortal,
  portalStorefrontAliasLoggedInto: state.main.user.auth.portalStorefrontAliasLoggedInto,
  currentStorefrontAlias: state.main.storefront.storefront.alias
});

const mapDispatch = {
  resetUser: resetUser,
  clearCart: clearCart
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
  goHome: Function,
  goToLogin: Function,
  goToPortal: Function
}

const Complete = (props: Props) => {
  const onConfirm = () => {
    if (props.completedPurchaseOptions.onComplete) {
      props.completedPurchaseOptions.onComplete();
    } else {
      if (props.allowCustomerPortal && props.currentStorefrontAlias === props.portalStorefrontAliasLoggedInto) {
        //If user is logged in then we just clear the cart when we're done, not the user details
        props.clearCart();
        props.goToPortal();
      } else {
        props.resetUser();
        props.allowCustomerPortal ? props.goToLogin() : props.goHome();
      }
    }
  }

  const oneOff = !props.checkedOut.some(cartLine => !cartLine.plan.oneOffPayment);

  const {t} = useTranslation();
  const email = props.userDetails.email;

  return (
    <div className="page">
      <StepContainer currentStep={props.currentStep} totalSteps={props.totalSteps} stepTitle={"Congratulations!"}>
        <span>You just bought:</span>
        <CartSummary fullList={props.completedPurchaseOptions.fullList}
                     hidePrice={!props.completedPurchaseOptions.showPrice}
                     cartToShow={props.checkedOut}
                     showTax={true}/>
        {props.completedPurchaseOptions.renderCompletedMessage
        ? props.completedPurchaseOptions.renderCompletedMessage()
          : <div className="complete-message">
              <span>
                <Trans i18nKey={oneOff ? "paymentRegisteredUnderEmail" : "subscriptionRegisteredUnderEmail"}
                       t={t}
                       count={props.checkedOut.length}>
                  Your subscriptions are registered under the email <b>{{email}}</b>.
                </Trans>
              </span>
          </div>}
        <ReactMarkdown plugins={[gfm]} children={props.purchaseMessage || ''} linkTarget="_blank"/>
        <div className="step-container-footer">
          <Button className="submit" onClick={onConfirm}>{props.allowCustomerPortal && !props.completedPurchaseOptions.onComplete ? 'Continue to Login' : 'Home'}</Button>
        </div>
      </StepContainer>
    </div>
  );
}

export default connector(Complete);
