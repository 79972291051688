// State
import {CustomizedPlanWithoutQuote} from "../../shared/plan/types";
import {PricingComponentValue} from "../../../api/types";

export type SubscriptionStatus = 'active' | 'awaiting payment' | 'paused' | 'cancelled';

export type Subscription = {
  plan: CustomizedPlanWithoutQuote,
  id: string,
  name: string,
  status: SubscriptionStatus,
  currentPeriodEnd?: number,
  resumeDate?: number,
  subscriptionEnd?: number,
  pricingComponentValues: Array<PricingComponentValue>
}

export interface SubscriptionState {
  subscriptions: Array<Subscription>
}

// Actions

export const UPDATE_SUBSCRIPTIONS = 'UPDATE_SUBSCRIPTIONS';

interface UpdateSubscriptionsAction {
  type: typeof UPDATE_SUBSCRIPTIONS;
  payload: Array<Subscription>;
}

export type SubscriptionActionTypes = UpdateSubscriptionsAction;
