import {AlertActionTypes, REMOVE_ALERT, SHOW_ALERT, ShowAlertActionPayload} from "./types";

export function showAlert(payload: ShowAlertActionPayload): AlertActionTypes {
  return {
    type: SHOW_ALERT,
    payload
  }
}

export function removeAlert(id: string): AlertActionTypes {
  return {
    type: REMOVE_ALERT,
    payload: id
  }
}
