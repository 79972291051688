import React from "react";

import "./PlanCard.scss";
import "./PlanCardSkeleton.scss";

const PlanCardSkeleton = () => {
  return (
    <div className="plan-card upper plan-card-main skeleton">
      <div className="image" />
      <div className="content">
        <div className="title-section" />
        <div className="price-section" />
      </div>
    </div>
  );
};

export default PlanCardSkeleton;
