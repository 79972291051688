import {KeyedPromiseFunctions, PromiseResponsesMap} from "./types";

/* Generic function to chain multiple promise-returning functions together.
 * Each function will await the response of the previous one before being executed.
 * Behaviour on fulfilment/rejection can be specified.
 */
export async function chainPromises<T>(
    keyedFunctions: KeyedPromiseFunctions<T>,
    onFulfilled: (key: string, response: any) => void = () => {
    },
    onRejected: (key: string, error: any) => void = () => {
    }
) {
  for (let keyedFunc of keyedFunctions) {
    try {
      const result = await keyedFunc.func();
      onFulfilled(keyedFunc.key, result);
    } catch (error) {
      onRejected(keyedFunc.key, error);
    }
  }
}

export function sleep(delay: number) {
  return new Promise((resolve) => setTimeout(resolve, delay))
}

// Get all responses once all promises are resolved
export async function chainPromisesAndGetResponses<T>(functions: KeyedPromiseFunctions<T>,
                                                      successCallback: (key: string) => void) {
  let responses: PromiseResponsesMap<T> = {};
  await chainPromises(
      functions,
      (key, response) => {
        responses[key] = {success: true, body: response};
        successCallback(key);
      },
      (key_1, error) => responses[key_1] = {success: false, body: error}
  );
  return responses;
}
