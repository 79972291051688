import {NEXT_STEP, SET_CURRENT_STEP, StepsActionTypes, UPDATE_SHOW_CART} from "./types";

export function nextStep(): StepsActionTypes {
  return {
    type: NEXT_STEP
  }
}

export function setCurrentStep(step: number): StepsActionTypes {
  return {
    type: SET_CURRENT_STEP,
    payload: step
  }
}

export function updateShowCart(showCart: () => void): StepsActionTypes {
  return {
    type: UPDATE_SHOW_CART,
    payload: showCart
  }
}
