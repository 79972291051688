import React, {RefObject} from "react";
import {connect, ConnectedProps} from "react-redux";
import {Button, Icon, Input, Spinner} from "./shoelace";
import clsx from 'clsx'
import {showAlert} from "../store/shared/alert/actions";
import SlInputElement from "@shoelace-style/shoelace/dist/components/input/input";

import './ImageUpload.scss';

const mapDispatch = {
  showAlert: showAlert
};

const connector = connect(null, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
  onSuccess: (url: string) => void,
  reloadPreview: () => void,
  initialSrc?: string
}

interface State {
  imageFilename: string,
  imageSrc: string,
  isLoading: boolean
}

class ImageUploadFromUrl extends React.Component<Props, State> {
  private readonly urlInputRef: RefObject<SlInputElement>;

  constructor(props: Props) {
    super(props);

    this.urlInputRef = React.createRef<SlInputElement>();

    this.state = {
      imageFilename: '',
      imageSrc: this.props.initialSrc || '',
      isLoading: false
    }
  }

  private removeFile = () => {
    this.setState({imageSrc: '', imageFilename: ''});
    this.props.onSuccess('')
    this.props.reloadPreview()
  }

  private onImageError = () => {
    this.setState({
      imageFilename: '',
      isLoading: false
    });
  }
  private onImageLoad = () => {
    this.setState({imageFilename: this.state.imageSrc, isLoading: false});
    this.props.onSuccess(this.state.imageSrc);
    this.props.reloadPreview();
  }

  componentDidMount() {
    this.urlInputRef.current!.addEventListener('sl-input', () => {
      this.setState({
        imageSrc: this.urlInputRef.current!.value,
        isLoading: true
      });
    });
  }


  shouldComponentUpdate(nextProps: Readonly<Props>, nextState: Readonly<State>, nextContext: any): boolean {
    this.forceUpdate(); return true;
  }

  render() {
    return (
      <div className="image-upload-container">
        <div className="image-upload-input">
          <Input className="light url-input" label="Image URL" ref={this.urlInputRef} value={this.state.imageSrc}></Input>
        </div>
        <div className="image-upload-preview-section">
          {this.state.imageSrc.length === 0 ? null :
              <div className="image-upload-filename">
                {this.state.imageFilename.length === 0 && !this.state.isLoading
                    && <span>
                  <Icon name="exclamation-triangle" className="warning-icon"></Icon>
                  The image URL is invalid
                </span>
                }
                {this.state.imageFilename.length === 0 ? null :
                    <Button className="plan-remove-button error small icon" onClick={this.removeFile}>
                      <Icon name="x" className="x-icon"></Icon>
                    </Button>
                }
              </div>
          }
          <div className={clsx('image-upload-preview', this.state.isLoading && 'full-height')}>
            {this.state.isLoading
              ? <div className="image-upload-preview-overlay">
                <Spinner className="image-upload-preview-spinner"></Spinner>
              </div>
              : null
            }
            <img src={this.state.imageSrc} onError={this.onImageError} onLoad={this.onImageLoad} hidden={this.state.imageFilename.length === 0} alt="Preview"></img>
          </div>
        </div>
      </div>
    )
  }
}

export default connector(ImageUploadFromUrl);
