import {
  PlanActionTypes,
  UPDATE_AVAILABLE_PLANS,
  UPDATE_CURRENCY
} from "./types";
import {Currency, Plan} from "../../shared/plan/types";

export function updateCurrency(newCurrency: Currency): PlanActionTypes {
  return {
    type: UPDATE_CURRENCY,
    payload: newCurrency
  }
}

export function updateAvailablePlans(newAvailablePlans: Array<Plan>): PlanActionTypes {
  return {
    type: UPDATE_AVAILABLE_PLANS,
    payload: newAvailablePlans
  }
}
