import {NEXT_STEP, SET_CURRENT_STEP, StepsActionTypes, StepsState, UPDATE_SHOW_CART} from "./types";

const initialState: StepsState = {
  currentStep: 0,
  totalSteps: 3,
  showCart: () => {}
}

export function stepsReducer(state: StepsState = initialState, action: StepsActionTypes): StepsState {
  switch (action.type) {
    case NEXT_STEP:
      return {...state, currentStep: state.currentStep + 1};
    case SET_CURRENT_STEP:
      return {...state, currentStep: action.payload};
    case UPDATE_SHOW_CART:
      return {...state, showCart: action.payload};
    default:
      return state;
  }
}
