import React, {ReactNode} from "react";
import {Redirect, Route, RouteComponentProps, Router, Switch, useHistory,} from "react-router-dom";
import {useSelector} from "react-redux";
import UserApp from "./UserApp";
import MerchantApp from "./MerchantApp";
import MerchantLogin from "./components/pages/merchant/Login";
import PortalLogin from "./components/pages/portal/Login";
import {resetDefaultParams} from "./api";
import StorefrontFetchWrapper from "./components/StorefrontFetchWrapper";
import PageNotFound from "./components/pages/PageNotFound";
import ErrorBoundary from "./components/shared/ErrorBoundary";
import PublicButton from "./components/shared/PublicButton";
import {
  switchBackgroundToBlank,
  switchBackgroundToMerchant,
  switchBackgroundToMerchantLogin,
  switchBackgroundToPortal,
  switchBackgroundToUser,
} from "./utils/style";
import PortalApp from "./PortalApp";
import PasswordReset from "./components/pages/portal/PasswordReset";
import {portalIsLoggedIn} from "./utils/utils";
import {setupShoelace} from "./components/shoelace";
import useQuery from "./hooks/useQuery";
import {selectIsMerchantLoggedIn, selectPortalStorefrontAliasLoggedInto,} from "./store/user/cart/selectors";

import "./App.scss";

setupShoelace();

const renderMerchant = () => {
  resetDefaultParams();
  switchBackgroundToMerchant();
  return <MerchantApp />;
};

const renderPortal = (alias: string) => {
  switchBackgroundToPortal();
  return (
    <StorefrontFetchWrapper alias={alias} containsPortal={true}>
      <PortalApp />
    </StorefrontFetchWrapper>
  );
};

const renderPortalLoginPage = (
  alias: string,
  page: ReactNode,
  portalStorefrontAliasLoggedInto: string | undefined
) => {
  switchBackgroundToUser();
  return (
    <StorefrontFetchWrapper alias={alias} containsPortal={true}>
      {portalIsLoggedIn(alias, portalStorefrontAliasLoggedInto) ? (
        <Redirect to={{ pathname: `/store/${alias}/portal` }} />
      ) : (
        page
      )}
    </StorefrontFetchWrapper>
  );
};

const App = () => {
  const merchantIsLoggedIn = useSelector(selectIsMerchantLoggedIn);
  const portalStorefrontAliasLoggedInto = useSelector(selectPortalStorefrontAliasLoggedInto);
  const query = useQuery();
  const history = useHistory();

  return (
    <Router history={history}>
      <ErrorBoundary>
        <Switch>
          <Route path="/merchant" render={renderMerchant} />

          <Route
            path={["/store/:alias/portal", "/store/:alias/portal/*"]}
            render={(routeProps: RouteComponentProps<{ alias: string }>) =>
              renderPortal(routeProps.match.params.alias)
            }
          />

          <Route
            path="/store/:alias/login/checkout"
            render={(routeProps: RouteComponentProps<{ alias: string }>) =>
              renderPortalLoginPage(
                routeProps.match.params.alias,
                <PortalLogin
                  nextPage={() => {
                    history.push(
                      `/store/${routeProps.match.params.alias}/confirm`
                    );
                  }}
                />,
                portalStorefrontAliasLoggedInto
              )
            }
          />

          <Route
            path="/store/:alias/login"
            render={(routeProps: RouteComponentProps<{ alias: string }>) =>
              renderPortalLoginPage(
                routeProps.match.params.alias,
                <PortalLogin
                  nextPage={() => {
                    history.push(
                      `/store/${routeProps.match.params.alias}/portal`
                    );
                  }}
                />,
                portalStorefrontAliasLoggedInto
              )
            }
          />

          <Route
            path="/store/:alias/password-reset"
            render={(routeProps: RouteComponentProps<{ alias: string }>) => {
              const code = query.get("code") ?? undefined;
              return renderPortalLoginPage(
                routeProps.match.params.alias,
                <PasswordReset code={code} />,
                portalStorefrontAliasLoggedInto
              );
            }}
          />

          <Route
            exact
            path="/login"
            render={() => {
              resetDefaultParams();
              switchBackgroundToMerchantLogin();
              return merchantIsLoggedIn ? (
                <Redirect to={{ pathname: "/merchant" }} />
              ) : (
                <MerchantLogin
                  nextPage={() => {
                    history.push("/merchant");
                  }}
                />
              );
            }}
          />
          <Route
            exact
            path="/page-not-found"
            render={() => {
              resetDefaultParams();
              switchBackgroundToMerchantLogin();
              return <PageNotFound goToLogin={() => history.push("/login")} />;
            }}
          />
          <Route
            path="/button"
            render={() => {
              resetDefaultParams();
              switchBackgroundToBlank();
              return <PublicButton />;
            }}
          />
          <Route
            path="/store/:alias"
            render={(routeProps: RouteComponentProps<{ alias: string }>) => {
              return (
                <StorefrontFetchWrapper
                  alias={routeProps.match.params.alias}
                  containsPortal={false}
                >
                  <UserApp />
                </StorefrontFetchWrapper>
              );
            }}
          />
          <Route
            render={() => {
              return <Redirect to="/login" />;
            }}
          />
        </Switch>
      </ErrorBoundary>
    </Router>
  );
};

export default App;
