import {CouponActionTypes, REMOVE_COUPON, UPDATE_COUPON_VALUE} from "./types";

export function updateCouponValue(newCouponValue: string): CouponActionTypes {
  return {
    type: UPDATE_COUPON_VALUE,
    payload: newCouponValue
  }
}

export function removeCoupon(): CouponActionTypes {
  return {
    type: REMOVE_COUPON
  }
}