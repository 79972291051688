import React, {useCallback, useEffect, useState} from "react";
import {connect, ConnectedProps, useDispatch, useSelector} from "react-redux";
import MerchantTopBar from "./components/MerchantTopBar";
import MerchantSidebar from "./components/MerchantSidebar";
import {Switch, useHistory} from "react-router";
import {Redirect, Route} from "react-router-dom";
import Plans from "./components/pages/merchant/Plans";
import AddPlan from "./components/pages/merchant/AddPlan";
import EditPlan from "./components/pages/merchant/EditPlan";
import {Plan} from "./store/shared/plan/types";
import LookAndFeel from "./components/pages/merchant/LookAndFeel";
import Settings from "./components/pages/merchant/Settings";
import {merchantLogout} from "./store/persisted/auth/actions";
import {api, apiLogout} from "./api";
import LoginCheckWrapper from "./components/MerchantLoginCheckWrapper";
import {AlertType} from "./store/shared/alert/types";
import {showAlert, showAlert as showAlertAction} from "./store/shared/alert/actions";
import {ROUTES} from "./config/routes";
import {selectFlexcheckPlans} from "./store/merchant/plan/selectors";
import useQuery from "./hooks/useQuery";
import useWindowDimensions from "./hooks/useWindowDimensions";
import {LARGE_SCREEN} from "./config/constants";
import {
  updateBillforwardPlans as updateBillforwardPlansAction,
  updateFlexcheckPlans as updateFlexcheckPlansAction,
} from "./store/merchant/plan/actions";
import {getAlertFromApiErrorResponse, getBasicAlert} from "./utils/utils";
import {BfApiPlan} from "./api/types";
import SidePanel from "./components/shared/SidePanel";

import "./MerchantApp.scss";
import {fetchFlexcheckPlans, getCompositeQuote} from "./api/quotes";

const mapDispatch = {
  merchantLogout: merchantLogout,
  showAlert: showAlert,
};

const connector = connect(undefined, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {}

const MerchantApp = (props: Props) => {
  const [isMenuShowing, setIsMenuShowing] = useState(false);
  const [plansLoading, setPlansLoading] = useState(true);
  const flexcheckPlans = useSelector(selectFlexcheckPlans);
  const query = useQuery();
  const history = useHistory();
  const windowDimensions = useWindowDimensions();
  const dispatch = useDispatch();

  useEffect(() => {
    if (windowDimensions.width >= LARGE_SCREEN) {
      setIsMenuShowing(true);
    }
  }, [windowDimensions.width]);

  const showMenu = () => {
    setIsMenuShowing(true);
  };

  const hideMenu = () => {
    setIsMenuShowing(false);
  };

  const getPlanFromPath = (path: string | null) => {
    return flexcheckPlans.find((x) => x.bfPlanPath === path);
  };

  const handleLogout = () => {
    apiLogout().then(
      (_) => {
        props.merchantLogout();
        history.push(ROUTES.MERCHANT_LOGIN);
      },
      (_) => {
        props.showAlert({
          type: AlertType.Error,
          message: "There was a problem logging you out.",
        });
      }
    );
  };

  const redirectToPlans = <Redirect to={{ pathname: ROUTES.MERCHANT_PLANS }} />;

  const updateFlexcheckPlans = useCallback(
    (plans) => {
      dispatch(updateFlexcheckPlansAction(plans));
    },
    [dispatch]
  );

  const updateBillforwardPlans = useCallback(
    (plans) => {
      dispatch(updateBillforwardPlansAction(plans));
    },
    [dispatch]
  );

  const showAlert = useCallback(
    (alert) => {
      dispatch(showAlertAction(alert));
    },
    [dispatch]
  );

  const fetchFlexcheckPlansIfNeeded = useCallback(async () => {
    setPlansLoading(true);
    try {
      const plans = await fetchFlexcheckPlans({ includeHidden: true, coupons: [] });
      updateFlexcheckPlans(plans);
    } catch (error: any) {
      showAlert(
        getAlertFromApiErrorResponse(
          error.response,
          "There was an error fetching the plans list."
        )
      );
    } finally {
      setPlansLoading(false);
    }
  }, [showAlert, updateFlexcheckPlans]);

  useCallback(async () => {
    try {
      updateBillforwardPlans([]);
      const fcPlans = fetchFlexcheckPlansIfNeeded();
      const plansResponse = await api.get("plan/allBillforward");
      const plans: BfApiPlan[] = plansResponse.data;
      const plansFromQuotes: { isError: boolean; plan: BfApiPlan }[] =
        await Promise.all(
          plans.map((plan) =>
            getCompositeQuote({ planId: plan.id, coupons: [] }).then(
              (quote) => ({ isError: false, plan: { ...plan, quote } }),
              () => ({ isError: true, plan })
            )
          )
        );
      updateBillforwardPlans(plansFromQuotes.map((p) => p.plan));
      if (plansFromQuotes.find((p) => p.isError) !== undefined) {
        showAlert(
          getBasicAlert(
            "There was a problem retrieving pricing data for some plans.",
            "You can still add any plan, but some prices may not display correctly."
          )
        );
      }
      await fcPlans;
    } catch (error) {
      showAlert({
        type: AlertType.Error,
        message: "There was an error retrieving the plans from Billforward.",
      });
    }
  }, [fetchFlexcheckPlansIfNeeded, showAlert, updateBillforwardPlans]);

  const fetchBfPlans = async () => {
    try {
      setPlansLoading(true);
      updateBillforwardPlans([]);
      const plansResponse = await api.get("plan/allBillforward");
      const plans: BfApiPlan[] = plansResponse.data;
      updateBillforwardPlans(plans);
      setPlansLoading(false);
    } catch (error) {
      showAlert({
        type: AlertType.Error,
        message: "There was an error retrieving the plans from Billforward.",
      });
    }
  };

  useEffect(() => {
    fetchBfPlans();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchFlexcheckPlansIfNeeded()
  }, [fetchFlexcheckPlansIfNeeded]);

  return (
    <div className="merchant-container">
      <MerchantSidebar
        isShowing={isMenuShowing}
        hideMenu={hideMenu}
        handleLogout={handleLogout}
      />
      <MerchantTopBar showMenu={showMenu} />
      <SidePanel />
      <div className="merchant-page-container">
        <LoginCheckWrapper>
          <Switch>
            <Route
              exact
              path={ROUTES.MERCHANT_PLANS}
              render={() => {
                return (
                  <Plans
                      fetchBfPlans={fetchBfPlans}
                      plansLoading={plansLoading}
                      setPlansLoading={setPlansLoading}
                  />
                );
              }}
            />
            <Route
              exact
              path={ROUTES.MERCHANT_ADD_PLAN}
              render={() => {
                return <AddPlan plansLoading={plansLoading} />;
              }}
            />
            <Route
              exact
              path={ROUTES.MERCHANT_EDIT_PLAN}
              render={() => {
                const plan: Plan | undefined = getPlanFromPath(
                  query.get("planPath")
                );
                return plan !== undefined ? (
                  <EditPlan plan={plan} />
                ) : (
                  <Redirect to={{ pathname: "/merchant/plans" }} />
                );
              }}
            />
            <Route
              exact
              path={ROUTES.MERCHANT_STYLE}
              render={() => {
                return <LookAndFeel />;
              }}
            />
            <Route
              exact
              path={ROUTES.MERCHANT_SETTINGS}
              render={() => {
                return <Settings />;
              }}
            />
            <Route
              render={() => {
                return redirectToPlans;
              }}
            />
          </Switch>
        </LoginCheckWrapper>
      </div>
    </div>
  );
};

export default connector(MerchantApp);
