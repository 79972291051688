import LabelledContainer from "./LabelledContainer";
import moment from "moment";
import { Link } from "react-router-dom";
import PaymentMethodCard from "./PaymentMethodCard";
import React, { FC } from "react";
import { useSelector } from "react-redux";
import { Subscription } from "../../store/portal/subscription/types";
import { selectPayments } from "../../store/portal/payment/selectors";
import { formatPrice } from "../../utils/utils";
import { selectStorefront } from "../../store/storefront/selectors";
import { selectPrimaryPaymentMethod } from "../../store/portal/account/selectors";

import "./SubscriptionOverviewSection.scss";

interface Props {
  subscription: Subscription;
}

const SubscriptionOverviewSection: FC<Props> = ({ subscription }) => {
  const storefront = useSelector(selectStorefront);
  const primaryPaymentMethod = useSelector(selectPrimaryPaymentMethod);
  const payments = useSelector(selectPayments);
  const lastPayment = payments.length > 0 ? payments[0] : null;

  const getDateLabel = (): string => {
    switch (subscription.status) {
      case "active":
        return "Next Payment Date";
      case "paused":
        return "Resume Date";
      case "cancelled":
        return "Subscription End Date";
      default:
        return "";
    }
  };

  const getDateContent = () => {
    switch (subscription.status) {
      case "active":
        return subscription.currentPeriodEnd
          ? moment.unix(subscription.currentPeriodEnd).format("Do MMM YYYY")
          : "Unknown date";
      case "paused":
        return (
          <>
            <div className="next-payment-date-paused">
              Your subscription is paused until{" "}
              {subscription.resumeDate ? null : "you resume it."}
            </div>
            {subscription.resumeDate
              ? moment.unix(subscription.resumeDate).format("Do MMM YYYY")
              : null}
          </>
        );
      case "cancelled":
        return subscription.subscriptionEnd
          ? moment.unix(subscription.subscriptionEnd).format("Do MMM YYYY")
          : "Unknown date";
    }
  };

  return (
    <div className="subscription-detail-overview-section">
      <LabelledContainer label="Billing">
        <h4 className="subscription-detail-mini-header">Billed every</h4>
        <p className="subscription-detail-content">
          {subscription.plan.period}
        </p>
        <h4 className="subscription-detail-mini-header">Last payment</h4>
        <p className="subscription-detail-content">
          {formatPrice(lastPayment?.total || 0, subscription.plan.baseCurrency)}
        </p>
      </LabelledContainer>
      <LabelledContainer
        label={getDateLabel()}
        className="subscription-detail-panel"
      >
        <div className="next-payment-date-section">
          <div className="next-payment-date-top-row">
            <div className="next-payment-date">{getDateContent()}</div>
          </div>
        </div>
        <Link
        className='button-link'
          to={`/store/${storefront.alias}/portal/billing-history?subscriptionName=${subscription.name}`}
        >
          View Past Payments
        </Link>
      </LabelledContainer>
      <LabelledContainer
        label="Payment Method"
        className="subscription-detail-panel"
      >
        <PaymentMethodCard paymentMethod={primaryPaymentMethod} />
        <Link
        className='button-link'
          to={`/store/${storefront.alias}/portal/account?section=paymentMethods`}
        >
          Change Payment Method
        </Link>
      </LabelledContainer>
    </div>
  );
};

export default SubscriptionOverviewSection;
