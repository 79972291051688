import React from "react";
import { RootState } from "../../store";
import { connect, ConnectedProps } from "react-redux";
import PriceText from "../PriceText";
import { Subscription } from "../../store/portal/subscription/types";
import StatusBadge from "./StatusBadge";
import { Payment } from "../../store/portal/payment/types";
import { getStyleFromSubscriptionStatus } from "../../store/portal/subscription/utils";
import { PlanGroup } from "../../store/user/planGroup/types";

import "./SubscriptionCard.scss";

const mapState = (state: RootState) => ({
  payments: state.main.portal.payment.payments,
});

const mapDispatch = {};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
  subscription: Subscription;
  planGroup?: PlanGroup;
  goToSubscriptionPage: (subscriptionId: string) => void;
  preview?: boolean;
}

type State = {
  isLoading: boolean;
  lastPayment: Payment | null;
};

class SubscriptionCard extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const payments = props.payments.filter(
      (p) => p.subscription.id === props.subscription.id
    );

    this.state = {
      isLoading: false,
      lastPayment: payments.length > 0 ? payments[0] : null,
    };
  }

  render() {
    return (
      <div
        className={`subscription-card subscription-card-main ${
          this.props.preview ? "preview" : ""
        }`}
        style={
          this.props.preview
            ? {}
            : {
                borderTop: `solid ${
                  this.props.subscription.plan.colour || "#ffffff00"
                } var(--border-radius-large)`,
              }
        }
      >
        <div className="subscription-card-content">
          <div className="title-section">
            <h3 className="title">
              {this.props.planGroup?.bfPlanPathPrefix ||
                this.props.subscription.name}
            </h3>
            <StatusBadge
              text={this.props.subscription.status}
              statusStyle={getStyleFromSubscriptionStatus(
                this.props.subscription.status
              )}
            />
          </div>
          <div className="middle-section">
            {this.props.subscription.plan.imageUrl ? (
              <img
                className="subscription-image"
                src={
                  this.props.planGroup?.cardImg ||
                  this.props.subscription.plan.imageUrl
                }
                alt=""
              />
            ) : (
              <div className="subscription-image-placeholder" />
            )}
            <div className="details-section">
              <div className="subscription-detail">
                <p className="subscription-detail-label">Billed every</p>
                <p className="subscription-detail-value">
                  {this.props.subscription.plan.period}
                </p>
              </div>
              <div className="subscription-detail">
                <p className="subscription-detail-label">Last payment</p>
                <div className="subscription-detail-value">
                  <PriceText
                    currentPrice={this.state.lastPayment?.total || 0}
                    currency={this.props.subscription.plan.baseCurrency}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="bottom-section">
            <button
              className="manage-button"
              onClick={() =>
                this.props.goToSubscriptionPage(this.props.subscription.id)
              }
            >
              Manage
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default connector(SubscriptionCard);
