import React, {FC, useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Subscription} from "../../store/portal/subscription/types";
import PricingComponentQuantityInput from "../PricingComponentQuantityInput";
import {upsertCustomization,} from "../../store/shared/plan/utils";
import PriceText from "../PriceText";
import {CustomizedPricingComponent} from "../../store/shared/plan/types";
import {CompositeUpgradeQuote, Quote, QuoteQuantity, StandalonePricingComponent,} from "../../api/types";
import {showAlert as showAlertAction} from "../../store/shared/alert/actions";
import {getAlertFromApiErrorResponse} from "../../utils/utils";
import pluralize from "pluralize";
import {Button, Icon, Spinner} from "../shoelace";
import SubscriptionUpgradeDialog from "./SubscriptionUpgradeDialog";
import {debounce, DebouncedFunction} from "ts-debounce";
import {selectStorefront} from "../../store/storefront/selectors";

import "./SubscriptionManageSection.scss";
import {getCompositeUpgradeQuote} from "../../api/quotes";

interface Props {
  subscription: Subscription;
  setIsResumeOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsPauseOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsCancelOpen: React.Dispatch<React.SetStateAction<boolean>>;
  pricingComponentsList: StandalonePricingComponent[];
  customizablePricingComponentsList: StandalonePricingComponent[];
  initialSubscriptionQuantities: InitialQuantitiesMap
}

export type QuoteQuantitiesMap = {
  [name: string]: QuoteQuantity;
};

export type InitialQuantitiesMap = {
  [name: string]: number | undefined;
};

const SubscriptionManageSection: FC<Props> = ({
                                                subscription,
                                                setIsResumeOpen,
                                                setIsPauseOpen,
                                                setIsCancelOpen,
                                                pricingComponentsList,
                                                customizablePricingComponentsList,
                                                initialSubscriptionQuantities
                                              }) => {
  const storefront = useSelector(selectStorefront);
  const dispatch = useDispatch();
  const [currentCustomizations, setCurrentCustomizations] = useState<
      CustomizedPricingComponent[]
  >([]);
  const [initialQuote, setInitialQuote] = useState<Quote>();
  const [upgradeQuote, setUpgradeQuote] = useState<CompositeUpgradeQuote>();
  const [quantitiesFromQuote, setQuantitiesFromQuote] =
      useState<QuoteQuantitiesMap>({});
  const [isDebouncing, setIsDebouncing] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isCheckoutOpen, setIsCheckoutOpen] = useState(false);

  const showAlert = useCallback(
      (alert) => {
        dispatch(showAlertAction(alert));
      },
      [dispatch]
  );

  const getUpgradeQuote = useCallback(
      (
          customizations: Array<CustomizedPricingComponent>,
          initial: boolean = false
      ): Promise<boolean> => {
        setIsLoading(true);
        return getCompositeUpgradeQuote({
          subscriptionId: subscription.id,
          quantities: customizations,
          coupons: [] //We don't currently support coupons on an upgrade.
        }).then(
            (response) => {
              processNewQuote(response);
              if (initial) {
                setInitialQuote(response.recurringQuote);
              }
              setIsDebouncing(false);
              setIsLoading(false);
              return true;
            },
            (error) => {
              showAlert(
                  getAlertFromApiErrorResponse(
                      error,
                      "There was a problem fetching the upgrade quote."
                  )
              );
              setIsDebouncing(false);
              setIsLoading(false);
              return false;
            }
        );
      },
      [showAlert, subscription.id]
  );

  const getUpgradeQuoteDebounced: DebouncedFunction<
      (
          customizations: Array<CustomizedPricingComponent>,
          initial?: boolean
      ) => Promise<boolean>
  > = debounce(getUpgradeQuote, 500);

  const processNewQuote = (quote: CompositeUpgradeQuote) => {
    let newQuantities: QuoteQuantitiesMap = {};
    for (let quantity of quote.recurringQuote.quantities) {
      newQuantities[quantity.pricingComponentName] = quantity;
    }
    setQuantitiesFromQuote(newQuantities);
    setUpgradeQuote(quote);
  };

  const updatePricingComponentQuantity = async (
      componentName: string,
      newQuantity: number,
      immediate: boolean
  ) => {
    const component = customizablePricingComponentsList.find(
        (c) => c.name === componentName
    );
    const newCurrentCustomizations = upsertCustomization(
        currentCustomizations,
        {
          ...component,
          chosenQuantity: newQuantity,
        } as CustomizedPricingComponent,
        initialSubscriptionQuantities[componentName]
    );

    if (immediate) {
      return getUpgradeQuote(newCurrentCustomizations);
    }

    setIsDebouncing(true);
    getUpgradeQuoteDebounced.cancel();
    let success = await getUpgradeQuoteDebounced(newCurrentCustomizations);
    setCurrentCustomizations(newCurrentCustomizations);
    return success;
  };

  const getPricingComponentEntry = (
      pricingComponent: StandalonePricingComponent,
      customizable: boolean = false
  ) => {
    const quantity = quantitiesFromQuote[pricingComponent.name];
    const totalCost = quantity ? quantity.finalCost : 0;
    const costPerUnit =
        quantity && quantity.quantity > 0 ? totalCost / quantity.quantity : 0;

    return (
        <React.Fragment key={pricingComponent.name}>
          <div className="component-name" style={{gridColumn: pricingComponent.unit || customizable ? 1 : 'span 2'}}>
            {pricingComponent.displayName ?? pricingComponent.name}
          </div>
          {pricingComponent.unit || customizable ?
              <>
                <PricingComponentQuantityInput
                    pricingComponent={pricingComponent}
                    customInitialQuantity={
                      initialSubscriptionQuantities[pricingComponent.name]
                    }
                    updateQuantity={(newQuantity: number, immediate: boolean) =>
                        updatePricingComponentQuantity(
                            pricingComponent.name,
                            newQuantity,
                            immediate
                        )
                    }
                    key={pricingComponent.name}
                    isLoading={isLoading}
                    fixed={!customizable}
                />
                <div className="component-price-per-unit">
                  <PriceText
                      currentPrice={costPerUnit}
                      currency={subscription.plan.baseCurrency}
                      isLoading={isLoading || isDebouncing}
                  />
                  <div className="component-unit">
                    per {pluralize.singular(pricingComponent.unit)}
                  </div>
                </div>
              </>
              : null}
          <div className="component-price-total"
               style={{gridColumn: pricingComponent.unit || customizable ? 1 : 'span 2'}}>
            <PriceText
                currentPrice={totalCost}
                currency={subscription.plan.baseCurrency}
                isLoading={isLoading || isDebouncing}
            />
            <div className="component-unit">total</div>
          </div>
        </React.Fragment>
    );
  };

  useEffect(() => {
    getUpgradeQuote([], true);
  }, [getUpgradeQuote]);

  return (
      <div className="subscription-detail-manage-section">
        <div className="subscription-detail-manage-top-section">
          <div className="subscription-detail-price-summary">
            <div className="subscription-detail-current-price">
              <div className="price-label">Current Price</div>
              <div className="price-text-and-spinner">
                {!initialQuote ? (
                    <Spinner className="price-spinner custom"/>
                ) : (
                    <PriceText
                        currentPrice={initialQuote.total}
                        currency={subscription.plan.baseCurrency}
                    />
                )}
              </div>
              <div className="period-text">per {subscription.plan.period}</div>
            </div>
            {customizablePricingComponentsList.length > 0 ?
                <>
                  <div className="price-arrow">
                    <Icon name="arrow-right"/>
                  </div>
                  <div
                      className={`subscription-detail-new-price ${
                          initialQuote?.total !== upgradeQuote?.recurringQuote.total
                              ? "different"
                              : ""
                      }`}
                  >
                    <div className="price-label">New Price</div>
                    <div className="price-text-and-spinner">
                      <PriceText
                          currentPrice={upgradeQuote?.recurringQuote.total || 0}
                          currency={subscription.plan.baseCurrency}
                          isLoading={isLoading || isDebouncing}
                      />
                      {isLoading || !upgradeQuote ? (
                          <Spinner className="price-spinner custom"/>
                      ) : null}
                    </div>
                    <div className="period-text">per {subscription.plan.period}</div>
                  </div>
                </> : null}
          </div>

          {customizablePricingComponentsList.length > 0 ?
              <Button
                  className="primary custom"
                  onClick={() => setIsCheckoutOpen(true)}
                  disabled={
                      currentCustomizations.length === 0 || isDebouncing || isLoading
                  }
              >
                Go to checkout
              </Button> : null}
        </div>
        <div className="subscription-detail-pricing-components-grid">
          {pricingComponentsList.length === 0 ? (
              <span className="no-components-message">
            This subscription has no pricing components.
          </span>
          ) : (
              customizablePricingComponentsList.map((pricingComponent) =>
                  getPricingComponentEntry(pricingComponent, true)
              )
          )}
          {pricingComponentsList.map((p) =>
              customizablePricingComponentsList.find((c) => c.name === p.name)
                  ? null
                  : getPricingComponentEntry(p)
          )}
        </div>
        {upgradeQuote ? (
            <SubscriptionUpgradeDialog
                subscription={subscription}
                initialSubscriptionQuantities={initialSubscriptionQuantities}
                customizations={currentCustomizations}
                quantitiesFromQuote={quantitiesFromQuote}
                open={isCheckoutOpen}
                upgradeQuote={upgradeQuote}
                onCancel={() => setIsCheckoutOpen(false)}
            />
        ) : null}
        {storefront.showSubscriptionControls && (
            <div className="subscription-management-controls">
              <p>Change your subscription status</p>
              <section>
                {subscription.status === "paused" ? (
                    <button className="pause" onClick={() => setIsResumeOpen(true)}>
                      Resume
                    </button>
                ) : (
                    <button
                        className="pause"
                        onClick={() => setIsPauseOpen(true)}
                        disabled={subscription.status === "cancelled"}
                    >
                      Pause
                    </button>
                )}
                <button
                    className="cancel"
                    onClick={() => setIsCancelOpen(true)}
                    disabled={subscription.status === "cancelled"}
                >
                  Cancel
                </button>
              </section>
            </div>
        )}
      </div>
  );
};

export default SubscriptionManageSection;
