// State

import {Currency, Plan} from "../../shared/plan/types";

export interface PlanState {
  currency: Currency;
  availableCurrencies: Array<Currency>;
  availablePlans: Array<Plan>;
}

// Actions

export const UPDATE_CURRENCY = 'UPDATE_CURRENCY';
export const UPDATE_AVAILABLE_PLANS = 'UPDATE_AVAILABLE_PLANS';

interface UpdateCurrencyAction {
  type: typeof UPDATE_CURRENCY;
  payload: Currency;
}

interface UpdateAvailablePlansAction {
  type: typeof UPDATE_AVAILABLE_PLANS;
  payload: Array<Plan>;
}

export type PlanActionTypes =
    UpdateCurrencyAction
  | UpdateAvailablePlansAction;
