import React from "react";

import './PaymentCard.scss';

import {RootState} from "../../store";
import {connect, ConnectedProps} from "react-redux";
import PriceText from "../PriceText";
import StatusBadge from "./StatusBadge";
import {Payment} from "../../store/portal/payment/types";
import moment from "moment";
import {getStyleFromPaymentStatus} from "../../store/portal/payment/utils";

const mapState = (state: RootState) => ({
});

const mapDispatch = {
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
  payment: Payment,
  goToPaymentPage: (paymentId: string) => void,
  isMini?: boolean
}

type State = {
  isLoading: boolean
}

class PaymentCard extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isLoading: false
    };
  }

  private getBorderStyle = () => ({borderLeft: `solid ${this.props.payment.subscription.plan.colour ?? '#ffffff00'} var(--border-radius-large)`});

  render() {
    return (
      <div className={`payment-card ${this.props.isMini ? 'payment-card-mini' : ''}`} style={this.getBorderStyle()} onClick={() => this.props.goToPaymentPage(this.props.payment.id)}>
        <div className="payment-card-content">
          <div className="payment-card-top-section">
            <div className="payment-card-date">
              {this.props.payment.date ? moment.unix(this.props.payment.date).format("Do MMM YYYY") : 'Not yet issued'}
            </div>
            <div className="payment-card-status">
              <StatusBadge text={this.props.payment.status} statusStyle={getStyleFromPaymentStatus(this.props.payment.status)}/>
            </div>
          </div>
          <div className="payment-card-bottom-section">
            <span className="payment-card-title">{this.props.payment.subscriptionName}</span>
            <div className="payment-card-price">
              <PriceText
                currentPrice={this.props.payment.total}
                currency={this.props.payment.subscription.plan.baseCurrency}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connector(PaymentCard);
