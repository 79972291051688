import React, { FC } from "react";
import CheckIcon from "../assets/icons/check-icon.svg";
import clsx from "clsx";

import "./MerchantCheckbox.scss";

interface Props {
  active: boolean;
  handleClick?: () => void;
}

const MerchantCheckbox: FC<Props> = ({ active, handleClick }) => {
  return (
    <button
      onClick={handleClick}
      className={clsx("merchant-checkbox", active && "active")}
    >
      <span>
        <img
          src={CheckIcon}
          className="merchant-checkbox-icon"
          alt="Checkbox"
        />
      </span>
    </button>
  );
};

export default MerchantCheckbox;
