import React, {RefObject} from "react";
import {connect, ConnectedProps} from "react-redux";
import {Button, Dialog} from "../shoelace";
import {RootState} from "../../store";

import './PaymentRetryDialog.scss';
import {apiRetryPayment} from "../../api/portalEndpoints";
import {showAlert} from "../../store/shared/alert/actions";
import {AlertType} from "../../store/shared/alert/types";
import {getAlertFromApiErrorResponse} from "../../utils/utils";
import LoadingButton from "../shared/LoadingButton";
import PaymentMethodCard from "./PaymentMethodCard";
import {Payment} from "../../store/portal/payment/types";
import SlDialogElement from "@shoelace-style/shoelace/dist/components/dialog/dialog";

const mapState = (state: RootState) => ({
  primaryPaymentMethod: state.main.portal.account.primaryPaymentMethod
});

const mapDispatch = {
  showAlert: showAlert
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
  payment: Payment,
  closeDialog: () => void,
  open: boolean
}

type State = {
  isLoading: boolean
}

class PaymentRetryDialog extends React.Component<Props, State> {
  private readonly dialogRef: RefObject<SlDialogElement>;

  constructor(props: Props) {
    super(props);

    this.state = {
      isLoading: false
    }

    this.dialogRef = React.createRef<SlDialogElement>();
  }

  private onConfirmRetry = () => {
    this.setState({isLoading: true});
    apiRetryPayment(this.props.payment.id).then(response => {
      switch (response.data.state) {
        case "Paid":
          showAlert({
            type: AlertType.Success,
            message: 'Payment taken successfully.'
          }); break;
        case "Pending":
          showAlert({
            type: AlertType.Info,
            message: 'Payment is pending.'
          }); break;
        case "Unpaid":
          showAlert({
            type: AlertType.Warning,
            message: 'Payment was unsuccessful.'
          }); break;
        case "Voided":
          showAlert({
            type: AlertType.Warning,
            message: 'The payment has been voided.'
          }); break;
      }
      this.setState({isLoading: false});
      this.props.closeDialog();
    }, error => {
      showAlert(getAlertFromApiErrorResponse(error, 'There was a problem attempting the payment.'));
      this.setState({isLoading: false});
    });
  };

  componentDidMount() {
    this.dialogRef.current!.addEventListener('sl-hide', () => this.props.closeDialog());
  }

  render() {
    return (
      <Dialog label="Retry Payment" className="payment-retry-dialog" open={this.props.open} ref={this.dialogRef}>
        <div className="payment-retry-dialog-content">
          <div className="retry-sentence">
            <span>You are about to reattempt payment with the following payment method:</span>
          </div>
          <PaymentMethodCard paymentMethod={this.props.primaryPaymentMethod} className="set-width" customMissingMessage="No primary payment method found"/>
          <div className="cancel-sentence">
            <span>Would you like to make this payment?</span>
          </div>
        </div>
        <div slot="footer" className="dialog-buttons">
          <Button className={"neutral custom"} onClick={this.props.closeDialog}>Back</Button>
          <LoadingButton className={"primary custom"} onSubmit={this.onConfirmRetry} isLoading={this.state.isLoading}>Pay</LoadingButton>
        </div>
      </Dialog>
    );
  }
}

export default connector(PaymentRetryDialog);

