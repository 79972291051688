export const OPEN_PANEL_START = "OPEN_PANEL_START";
export const CLOSE_PANEL_START = "CLOSE_PANEL_START";
export const OPEN_PANEL = "OPEN_PANEL";
export const CLOSE_PANEL = "CLOSE_PANEL";
export const OPEN_PANEL_FINISH = "OPEN_PANEL_FINISH";
export const CLOSE_PANEL_FINISH = "CLOSE_PANEL_FINISH";

export type PanelType = "Edit Plan" | "Add Plan";
export interface PanelOptions {
  panelType: PanelType;
  planId?: string;
}

interface OpenPanelStartAction {
  type: typeof OPEN_PANEL_START;
  payload: PanelOptions;
}

interface ClosePanelStartAction {
  type: typeof CLOSE_PANEL_START;
}

interface OpenPanelAction {
  type: typeof OPEN_PANEL;
  payload: PanelOptions;
}

interface ClosePanelAction {
  type: typeof CLOSE_PANEL;
}

interface OpenPanelFinishAction {
  type: typeof OPEN_PANEL_FINISH;
}

interface ClosePanelFinishAction {
  type: typeof CLOSE_PANEL_FINISH;
}

export type PanelActionTypes =
  | OpenPanelStartAction
  | ClosePanelStartAction
  | OpenPanelAction
  | ClosePanelAction
  | OpenPanelFinishAction
  | ClosePanelFinishAction;

export interface PanelState {
  isOpen: boolean;
  planId?: string | null;
  panelType?: PanelType | null;
  isLoading: boolean;
}

export enum PanelTypes {
  EditPlan = "Edit Plan",
  AddPlan = "Add Plan",
}
