import React, { FC, ReactNode } from "react";
import { SavedPaymentMethod } from "../../store/shared/user/types";
import creditCardType from "credit-card-type";
import { Icon } from "../shoelace";
import VisaIcon from "../../assets/icons/credit-cards/visa.svg";
import MastercardIcon from "../../assets/icons/credit-cards/mastercard.svg";
import AmexIcon from "../../assets/icons/credit-cards/amex.svg";

import "./PaymentMethodCard.scss";
import clsx from 'clsx'

interface Props {
  paymentMethod: SavedPaymentMethod | undefined;
  className?: string;
  children?: ReactNode;
  onClick?: () => void;
  customMissingMessage?: string;
}

const ValidCardTypes = ["visa", "mastercard", "american-express"];
type ValidCardType = (typeof ValidCardTypes)[number];
type CardIconMap = {
  [type in ValidCardType]: any;
};

const PaymentMethodCard: FC<Props> = ({
  paymentMethod,
  className,
  children,
  onClick,
  customMissingMessage,
}) => {
  //10 isn't right here but who's counting and it looks better :)
  const formatCardNumber = (lastFour: string) => `${"*".repeat(10)}${lastFour}`;

  const cardIconMap: CardIconMap = {
    visa: VisaIcon,
    mastercard: MastercardIcon,
    "american-express": AmexIcon,
  };

  const getCardIconElement = () => {
    const cardType = paymentMethod?.cardType?.toLowerCase();
    if (cardType && ValidCardTypes.includes(cardType)) {
      return <img src={cardIconMap[cardType]} alt="card icon" />;
    }

    const firstSix = paymentMethod?.firstSix;
    let ccTypes = creditCardType(firstSix || "");
    if (ccTypes.length !== 1) return <Icon name="credit-card-fill" />;
    else {
      let ccType = ccTypes[0].type;
      return ValidCardTypes.includes(ccType) ? (
        <img src={cardIconMap[ccType]} alt="card icon" />
      ) : (
        <Icon name="credit-card-fill" />
      );
    }
  };

  const Tag = onClick ? 'button' : 'div';

  return (
    <Tag
      className={clsx('payment-method-card', onClick && 'clickable', className)}
      onClick={onClick}
    >
      <div className="payment-method-card-content">
        {paymentMethod ? (
          <>
            <div className="payment-method-card-icon">
              {getCardIconElement()}
            </div>
            <div className="payment-method-card-number">
              {formatCardNumber(paymentMethod.lastFour)}
            </div>
            <div className="payment-method-card-expiry">
              {paymentMethod.expiryMonth}/{paymentMethod.expiryYear}
            </div>
          </>
        ) : (
          <span>{customMissingMessage ?? "No payment method selected"}</span>
        )}
      </div>
      {children}
    </Tag>
  );
};

export default PaymentMethodCard;
