import {
  PlanActionTypes,
  UPDATE_BILLFORWARD_PLANS,
  UPDATE_FLEXCHECK_PLANS,
  UPDATE_ONE_BILLFORWARD_PLAN,
  UPDATE_PLAN_HIDDEN,
  UPDATE_SELECTED_PLAN_TO_ADD
} from "./types";
import {Plan} from "../../shared/plan/types";
import {BfApiPlan, CompositeQuote} from "../../../api/types";

export function updatePlanHidden(bfId: string, isHidden: boolean): PlanActionTypes {
  return {
    type: UPDATE_PLAN_HIDDEN,
    payload: {bfId, isHidden}
  }
}

export function updateFlexcheckPlans(flexcheckPlans: Array<Plan>): PlanActionTypes {
  return {
    type: UPDATE_FLEXCHECK_PLANS,
    payload: flexcheckPlans
  }
}

export function updateBillforwardPlans(billforwardPlans: Array<BfApiPlan>): PlanActionTypes {
  return {
    type: UPDATE_BILLFORWARD_PLANS,
    payload: billforwardPlans
  }
}

export function updateOneBillforwardPlanFromQuote(quote: CompositeQuote, planId: string): PlanActionTypes {
  return {
    type: UPDATE_ONE_BILLFORWARD_PLAN,
    payload: quote,
    planId: planId
  }
}

export function updateSelectedPlanToAdd(plan: BfApiPlan): PlanActionTypes {
  return {
    type: UPDATE_SELECTED_PLAN_TO_ADD,
    payload: plan
  }
}
