import React, { FC, ReactNode } from "react";
import { Icon } from "./shoelace";
import { Link } from "react-router-dom";

import "./StepContainer.scss";

interface Props {
  currentStep: number;
  totalSteps: number;
  stepTitle: string;
  backLink?: string;
  children?: ReactNode;
}

const StepContainer: FC<Props> = ({
  currentStep,
  totalSteps,
  stepTitle,
  backLink,
  children,
}) => {
  return (
    <div className="step-container">
      <div className="step-container-header">
        {backLink && (
          <Link className="back custom" to={backLink}>
            <Icon name="chevron-left" /> <span>Go back</span>
          </Link>
        )}
        {currentStep > totalSteps ? (
          <span className="step-count">Purchase complete</span>
        ) : (
          <span className="step-count">
            Step {currentStep} of {totalSteps}
          </span>
        )}
        <h2 className="step-title">{stepTitle}</h2>
      </div>
      <div className="step-container-content">{children}</div>
    </div>
  );
};

export default StepContainer;
