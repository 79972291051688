import i18n, {BackendModule, ReadCallback, Services} from 'i18next';
import {initReactI18next} from 'react-i18next';
import en from './en.json';
import HttpApi, {HttpBackendOptions} from 'i18next-http-backend';
import {api} from "../api";
import resourcesToBackend from 'i18next-resources-to-backend';
import ChainedBackend, {ChainedBackendOptions} from 'i18next-chained-backend';

class StorefrontBackend implements BackendModule<HttpBackendOptions> {
  static type: "backend" = "backend";
  httpBackend: HttpApi
  type: "backend";

  constructor(services?: any, options?: HttpBackendOptions) {
    this.httpBackend = new HttpApi(services, options);
    this.type = "backend";
  }

  init(services: Services, backendOptions: HttpBackendOptions): void {
    this.httpBackend.init(services, backendOptions);
  }

  read(language: string, namespace: string, callback: ReadCallback): void {
    this.httpBackend.options.queryStringParams = api.defaults.params;
    this.httpBackend.read(language, namespace, callback);
  }
}

i18n.use(initReactI18next)
    .use(ChainedBackend)
    .init<ChainedBackendOptions>({
      lng: 'en',
      resources: {
        en: en,
      },
      fallbackLng: 'en',
      // interpolation: {
      //   escapeValue: false,
      // },
      debug: true,
      ns: ["flexcheck"],
      defaultNS: "flexcheck",
      fallbackNS: "flexcheck",
      react: {
        transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p', 'b', 'span'],
      },
      backend: {
        backends: [
          StorefrontBackend,
          resourcesToBackend(en),
        ],
        backendOptions: [{
          loadPath: api.defaults.baseURL + "translation/{{lng}}/{{ns}}",
        }],
      }
    });

export default i18n;
