import React from "react";
import {RootState} from "../../store";
import {connect, ConnectedProps} from "react-redux";
import {showAlert} from "../../store/shared/alert/actions";
import {bfApi} from "../../api";
import {getAlertFromApiErrorResponse} from "../../utils/utils";
import {CardConnectAuthCaptureRequest, CardConnectPreAuthResponse, PreAuthRequest} from "../../api/types";
import {ShowAlertActionPayload} from "../../store/shared/alert/types";
import {debounce} from "ts-debounce";
import LoadingButton from "../shared/LoadingButton";
import {PaymentMethodFormContext} from "./PaymentMethodSwitch";

const mapState = (state: RootState) => ({
    currency: state.main.user.plan.currency,
    userDetails: state.main.user.userDetails.details,
    cart: state.main.user.cart.cart,
    bfAccountId: state.main.user.userDetails.bfAccountId,
    bfPublicToken: state.main.storefront.storefront.publicToken
});

const mapDispatch = {
    showAlert: showAlert
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
    onSuccessfulSubmit: (data?: any) => void
}

interface State {
    isLoading: boolean;
    hasError: boolean;
    iframeSrc: string;
    tokenizedCardNumber: string;
    expiry: string;
}

const createIFrameSrc = (site: string) => {
    if (/^[a-zA-Z0-9_-]*$/.test(site)) {
        const cardConnectIframeStyle = encodeURIComponent(
            ".error {border-color: red;}" +
            "#tokenform { font-family:var(--default-font-family);}"
        );

        return "https://" + site + ".cardconnect.com/itoke/ajax-tokenizer.html" +
            "?autofocus=true&enhancedresponse=true&unique=true&usecvv=true&useexpiry=true&tokenizewheninactive=true&css=" +
            cardConnectIframeStyle;
    }
    throw Error("Cannot use the supplied site.");
};

class CardConnectPaymentForm extends React.Component<Props, State> {
    private readonly handleCardConnectMessage: EventListener;

    constructor(props: Props) {
        super(props);
        this.state = {
            isLoading: true,
            hasError: false,
            iframeSrc: '',
            tokenizedCardNumber: '',
            expiry: ''
        };
        this.handleCardConnectMessage = (ev: Event) => this.cardConnectMessage(ev as MessageEvent);
    }

    componentWillUnmount() {
        window.removeEventListener("message", this.handleCardConnectMessage)
    }

    componentDidMount() {
        window.addEventListener("message", this.handleCardConnectMessage);

        const preAuth: PreAuthRequest = {
            billForwardPublicToken: this.props.bfPublicToken,
            gateway: "CardConnect",
            '@type': "TokenizationPreAuthRequest"
        };

        bfApi.post('v1/tokenization/pre-auth', {
            headers: {
                "Authorization": "Bearer " + this.props.bfPublicToken,
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(preAuth)
        }).then(response => {
            response.json().then(output => {
                const response = output.results[0] as CardConnectPreAuthResponse;
                this.setState({
                    iframeSrc: createIFrameSrc(response.site),
                    isLoading: false
                });
            });
        }, error => {
            this.handleError(getAlertFromApiErrorResponse(error, 'There was a problem preparing your payment method.'));
        });
    }

    private handleError: (error: ShowAlertActionPayload) => void =
        debounce((error: ShowAlertActionPayload) => {
            this.props.showAlert(error);
            this.setState({
                hasError: true,
                isLoading: false
            });
        }, 2000, {isImmediate: true});

    private cardConnectMessage(ev: MessageEvent) {
        if (!ev.origin.endsWith(".cardconnect.com")) {
            return;
        }

        const data = (ev.data instanceof Object) ? ev.data : JSON.parse(ev.data);
        if (data.token) {
            this.setState({
                isLoading: false,
                tokenizedCardNumber: data.token,
                expiry: data.expiry
            });
        }
    }

    private onSubmit() {
        const cardConnectCapture: CardConnectAuthCaptureRequest = {
            "@type": "CardConnectAuthCaptureRequest",
            gateway: "CardConnect",
            accountID: this.props.bfAccountId,
            defaultPaymentMethod: true,
            tokenizedCardNumber: this.state.tokenizedCardNumber,
            expiry: this.state.expiry
        };
        bfApi.post("v1/tokenization/auth-capture", {
            headers: {
                "Authorization": "Bearer " + this.props.bfPublicToken,
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(cardConnectCapture)
        }).then(results => {
            results.json()
                .then(json => {
                    this.props.onSuccessfulSubmit(json.results[0].id)
                });
        }, error => {
            this.handleError(getAlertFromApiErrorResponse(error, 'There was a problem processing your payment method.'));
        });
    }

    render() {
        return (
            <form className={"payment-method-form"}>
                <div className={"form-section"}>
                    <p>By entering your details, you are providing cardholder consent for storage of those details for payments that you authorise.</p>
                    <iframe src={this.state.iframeSrc} title="tokenFrame" id="tokenFrame" name="tokenFrame" style={{border:0, overflow:'hidden'}} />
                </div>
                <div className={"form-footer"}>
                    <PaymentMethodFormContext.Consumer>
                        {context =>
                          <LoadingButton onSubmit={() => this.onSubmit()} submit noCustomStyle isLoading={this.state.isLoading}>{context.buttonText}</LoadingButton>
                        }
                    </PaymentMethodFormContext.Consumer>

                </div>
            </form>
        )
    }
}

export default connector(CardConnectPaymentForm);
