import React, {RefObject} from "react";
import {Details as DetailsType} from "../store/user/userDetails/types";
import {Checkbox} from "./shoelace";
import {RootState} from "../store";
import {updateCustomerIdAndBfAccountId, updateDetails,} from "../store/user/userDetails/actions";
import {connect, ConnectedProps} from "react-redux";
import LoadingButton from "./shared/LoadingButton";
import {api} from "../api";
import {getErrorCode} from "../api/conversions";
import {ErrorCode} from "../api/types";
import TermsAndConditionsDialog from "./TermsAndConditionsDialog";
import FormBase, {BaseState, InputTypeGenerator} from "./shared/FormBase";
import {AlertMessage, AlertType} from "../store/shared/alert/types";
import {showAlert} from "../store/shared/alert/actions";
import {isNotEmpty, validateEmail, validatePhone} from "../utils/validation";
import EditCustomFieldsForm from "./portal/EditCustomFieldsForm";
import {CustomFieldInstance} from "../store/portal/account/types";
import {Redirect} from "react-router-dom";
import {debounce} from "ts-debounce";
import styles from "./DetailsForm.module.scss"
import CountryDropdown from "./shared/CountryDropdown";
import {termsConditions} from "../store/storefront/types";
import type SlCheckboxElement from "@shoelace-style/shoelace/dist/components/checkbox/checkbox";
import clsx from "clsx";
import ProvinceDropdown, {
  asSupportedCountry,
  isSupportedCountry,
  isValidProvinceValue
} from "./shared/ProvinceDropdown";
import OpenInWindowIcon from '../assets/icons/up-right-from-square.svg';

const openInWindow = <img src={OpenInWindowIcon} alt="Opens in a new window" style={{width: '1em', height: '1em', verticalAlign: '-0.125em'}}/>

const mapState = (state: RootState) => ({
  userDetails: state.main.user.userDetails.details,
  bfAccountId: state.main.user.userDetails.bfAccountId,
  customerId: state.main.user.userDetails.customerId,
  customFieldsFromUser: state.main.user.userDetails.customFieldInstances,
  storefront: state.main.storefront.storefront,
  storefrontPasswordOptions: state.main.storefront.uiOptions.password,
  customFieldsFromStorefront: state.main.storefront.customFields,
  cartId: state.main.user.cart.cartId
});

const mapDispatch = {
  updateDetails: updateDetails,
  updateCustomerIdAndBfAccountId: updateCustomerIdAndBfAccountId,
  showAlert: showAlert,
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
  nextPage: Function;
}

const typeGenerator = new InputTypeGenerator([
  { name: "firstName", type: "text" },
  { name: "lastName", type: "text" },
  { name: "companyName", type: "text" },
  { name: "email", type: "text" },
  { name: "confirmEmail", type: "text" },
  { name: "password", type: "text" },
  { name: "confirmPassword", type: "text" },
  { name: "landline", type: "text" },
  { name: "addressLine1", type: "text" },
  { name: "addressLine2", type: "text" },
  { name: "addressLine3", type: "text" },
  { name: "city", type: "text" },
  { name: "province", type: "text" },
  { name: "postcode", type: "text" },
  { name: "shippingAddressLine1", type: "text" },
  { name: "shippingAddressLine2", type: "text" },
  { name: "shippingAddressLine3", type: "text" },
  { name: "shippingCity", type: "text" },
  { name: "shippingProvince", type: "text" },
  { name: "shippingPostcode", type: "text" },
]);
type InputName = typeof typeGenerator.InputName.type;

type State = BaseState<InputName> & {
  customFieldInstances: Array<CustomFieldInstance>;
  shouldRedirectToLogin: boolean;
  foundEmailAddress: boolean;
  emailLookedUp: string;
  country: string;
  shippingCountry: string;
  invalidTC: boolean;
  shippingSameAsBilling: boolean;
  invalidProvince: boolean;
  invalidShippingProvince: boolean;
};

const validateEmailWithBackend = (
  form: DetailsForm,
  value: string
): boolean => {
  const valid = validateEmail(value);

  if (!valid) {
    return false;
  }

  if (
    !form.props.storefront.allowCustomerPortal &&
    form.props.storefront.type !== "BfAccountingOrg"
  ) {
    return valid;
  }

  if (form.props.bfAccountId && form.props.userDetails.email === value) {
    form.setState({ foundEmailAddress: false, isLoading: false });
    return true;
  }

  if (value !== form.state.emailLookedUp) {
    debounce((email: string) => {
      // I had the line below to prevent the "next" button if we were still looking up any duplicate emails,
      //   but it made it impossible to type because the caret moved. I decided it wasn't worth the hassle to fix.
      // form.setState({isLoading: true});
      api.post("customer/find/" + email).then((result) => {
        const found = result && result.status === 200 && result.data;
        if (found) {
          form.state.formData.email = value;
          form.props.updateDetails(
            form.state.formData as unknown as DetailsType
          );
        }
        form.setState({
          foundEmailAddress: found,
          isLoading: false,
          emailLookedUp: email,
        });
        return !found; //If duplicate found then not valid
      });
    }, 200)(value);
  }
  return true;
};

const propsCountry = (props: Props) =>
    props.storefront.disableCountry ?
        props.storefront.defaultCountry :
        props.userDetails.country?.length > 0 ?
          props.userDetails.country :
          props.storefront.defaultCountry;

const propsShippingCountry = (props: Props) =>
    props.storefront.disableCountry ?
        props.storefront.defaultCountry :
        props.userDetails.shippingCountry?.length > 0 ?
            props.userDetails.shippingCountry :
            propsCountry(props);

const isShipping = (props: Props) =>
    props.storefront.captureAddress && props.storefront.secondaryAddress != null;

class DetailsForm extends FormBase<Props, State, InputName> {
  private readonly tcsDialogs: RefObject<TermsAndConditionsDialog>[];
  private readonly termConditionInputs: RefObject<SlCheckboxElement>[];

  private changeShippingSameAsBilling = () =>
      this.setState({shippingSameAsBilling: !this.state.shippingSameAsBilling});

  getInitialState(baseState: BaseState<InputName>, props: Props): State {
    return {
      ...baseState,
      customFieldInstances: props.customFieldsFromStorefront.map((c) => {
        return (
          props.customFieldsFromUser.find(
            (instance) => c.name === instance.customField.name
          ) ?? {
            customField: c,
            content: "",
          }
        );
      }),
      shouldRedirectToLogin: false,
      foundEmailAddress: false,
      emailLookedUp: "",
      country: props.storefront.captureAddress ? propsCountry(props) : "",
      shippingCountry: isShipping(props) ?
          propsCountry(props) :
          "",
      invalidTC: false,
      shippingSameAsBilling: true,
      invalidProvince: false,
      invalidShippingProvince: false
    };
  }

  validateTermsConditions(allowSettingInvalid: boolean) {
    const tcList = termsConditions(this.props.storefront);

    const invalid = tcList.map((tc, index) =>
        !this.termConditionInputs[index].current?.checked && tc.required
    ).some(b => b);

    if (allowSettingInvalid || !invalid) {
      this.setState({invalidTC: invalid});
    }

    if (invalid) {
      return null;
    }

    const tcMap: Record<string, boolean> = {};
    tcList.forEach((tc, index) =>
        tcMap[tc.name] = this.termConditionInputs[index].current?.checked ?? false);
    return tcMap;
  }

  private checkProvince() {
    const shippingProvinceInvalid = isShipping(this.props) &&
        !this.state.shippingSameAsBilling &&
        !isValidProvinceValue(this.state.formData.shippingCountry, this.state.formData.shippingProvince);
    const provinceInvalid = this.props.storefront.captureAddress &&
        !isValidProvinceValue(this.state.formData.country, this.state.formData.province);

    this.setState({invalidProvince: provinceInvalid, invalidShippingProvince: shippingProvinceInvalid});
    return !shippingProvinceInvalid && !provinceInvalid;
  }

  onSubmitValidForm() {
    //Validate terms and conditions separately to save complicating FormBase even more.
    const validTCs = this.validateTermsConditions(true);
    if (validTCs == null) {
      return false;
    }

    if (!this.checkProvince()) {
      return false;
    }

    if (this.state.foundEmailAddress) {
      this.setState({shouldRedirectToLogin: true});
    } else {
      // Create customer entity via API
      const shippingAddress = this.state.shippingSameAsBilling ?
          null :
          {
            addressLine1: this.state.formData.shippingAddressLine1,
            addressLine2: this.state.formData.shippingAddressLine2,
            addressLine3: this.state.formData.shippingAddressLine3,
            city: this.state.formData.shippingCity,
            province: this.state.formData.shippingProvince,
            country: this.state.shippingCountry,
            postcode: this.state.formData.shippingPostcode,
          };
      this.setState({ isLoading: true });
      api
        .post("customer", {
          email: this.state.formData.email,
          firstName: this.state.formData.firstName,
          lastName: this.state.formData.lastName,
          companyName: this.state.formData.companyName,
          password: this.state.formData.password,
          landline: this.state.formData.landline,
          address: {
            addressLine1: this.state.formData.addressLine1,
            addressLine2: this.state.formData.addressLine2,
            addressLine3: this.state.formData.addressLine3,
            city: this.state.formData.city,
            province: this.state.formData.province,
            country: this.state.country,
            postcode: this.state.formData.postcode,
          },
          shippingAddress: shippingAddress,
          customFields: this.convertCustomFieldsToMap(
            this.state.customFieldInstances
          ),
          termsConditions: validTCs,
          cartId: this.props.cartId
        })
        .then(
          (response) => {
            this.props.updateDetails(
              this.state.formData as unknown as DetailsType
            );
            this.setState({ isLoading: false }, () => {
                  this.props.updateCustomerIdAndBfAccountId(response.data.id, response.data.bfAccountId);
                  this.props.nextPage();
                });
          },
          (error) => {
            let errorMessage: AlertMessage;
            switch (getErrorCode(error.response.data.code)) {
              case ErrorCode.EmailInUse:
                this.props.updateDetails(
                  this.state.formData as unknown as DetailsType
                );
                this.setState({
                  shouldRedirectToLogin: true,
                  isLoading: false,
                });
                return false;
              case ErrorCode.InvalidEmail:
                errorMessage = [
                  <b>The email address you entered is invalid.</b>,
                  <br />,
                  "Please use a valid email address.",
                ];
                break;
              default:
                errorMessage = [
                  <b>There was an error creating your profile.</b>,
                  <br />,
                  error.response.data.message,
                ];
            }
            this.props.showAlert({
              type: AlertType.Error,
              message: errorMessage,
            });
            this.setState({ isLoading: false });
            return false;
          }
        );
    }

    return true;
  }

  constructor(props: Props) {
    super(props, typeGenerator, {
      setValid: {
        firstName: isNotEmpty,
        lastName: isNotEmpty,
        companyName: (value) =>
          props.storefront.captureCompany ? isNotEmpty(value) : true,
        email: (value: string) => validateEmailWithBackend(this, value),
        confirmEmail: (value: string) => (!this.props.storefront.confirmEmail || value === this.state.formData.email),
        password: props.storefront.allowCustomerPortal
          ? props.storefrontPasswordOptions.validate
          : () => true,
        confirmPassword: (value: string) =>
          value === this.state.formData.password,
        landline: (value) => value ? validatePhone(value) : true,
        addressLine1: (value) =>
          props.storefront.captureAddress ? isNotEmpty(value) : true,
        addressLine2: () => true,
        addressLine3: () => true,
        city: (value) =>
          props.storefront.captureAddress ? isNotEmpty(value) : true,
        province: (value) => true,
        postcode: (value) =>
          props.storefront.captureAddress ? isNotEmpty(value) : true,
        shippingAddressLine1: (value) =>
            isShipping(props) && !this.state.shippingSameAsBilling ? isNotEmpty(value) : true,
        shippingAddressLine2: () => true,
        shippingAddressLine3: () => true,
        shippingCity: (value) =>
            isShipping(props) && !this.state.shippingSameAsBilling ? isNotEmpty(value) : true,
        shippingProvince: (value) => true,
        shippingPostcode: (value) =>
            isShipping(props) && !this.state.shippingSameAsBilling ? isNotEmpty(value) : true,
      },
      invalidMessages: {
        firstName: "Please enter your first name.",
        lastName: "Please enter your last name.",
        companyName: "Please enter your company name.",
        email: "Please enter a valid email address.",
        confirmEmail: "The email addresses do not match.",
        password: props.storefrontPasswordOptions.validationMessage,
        confirmPassword: "The passwords do not match.",
        landline: "Please enter a valid phone number.",
        addressLine1: "Please fill in the first address line.",
        city: "Please enter a valid city.",
        province: "Please enter a valid province, state or county.",
        postcode: `Please enter a valid ${
          propsCountry(props) === "US" ? "zip" : "post"
        } code.`,
        shippingAddressLine1: "Please fill in the first address line.",
        shippingCity: "Please enter a valid city.",
        shippingProvince: "Please enter a valid province, state or county.",
        shippingPostcode: `Please enter a valid ${
            propsShippingCountry(props) === "US" ? "zip" : "post"
        } code.`,
      },
      initialFormData: {
        ...props.userDetails,
        country: propsCountry(props),
        shippingCountry: propsCountry(props),
        confirmEmail: props.userDetails.email,
        password: props.storefront.allowCustomerPortal
          ? props.userDetails.password
          : "",
        confirmPassword: props.storefront.allowCustomerPortal
          ? props.userDetails.password
          : "",
      },
    });
    this.tcsDialogs = termsConditions(this.props.storefront)
          .map(() => React.createRef<TermsAndConditionsDialog>());
    this.termConditionInputs = termsConditions(this.props.storefront)
          .map(() => React.createRef<SlCheckboxElement>());
  }

  private updateCustomFieldFormData = (name: string, content: string) => {
    this.setState((state) => ({
      customFieldInstances: state.customFieldInstances.map((c) =>
        c.customField.name !== name ? c : { ...c, content }
      ),
    }));
  };

  private convertCustomFieldsToMap = (
    customFields: Array<CustomFieldInstance>
  ) => {
    let contentMap: { [name: string]: string | undefined } = {};
    customFields.forEach((field) => {
      contentMap[field.customField.name] = field.content;
    });

    return contentMap;
  };

  private setCountry = (country: string) => {
    this.setState({ country: country },
        () => this.checkProvince());
  };

  private setShippingCountry = (country: string) => {
    this.setState({ shippingCountry: country },
                  () => this.checkProvince());
  };

  private setProvince = (province: string) => {
    this.setState({ formData: {...this.state.formData, province: province }},
        () => this.checkProvince());
  };

  private setShippingProvince = (province: string) => {
    this.setState({ formData: {...this.state.formData, shippingProvince: province }},
        () => this.checkProvince());
  };

  render() {
    return (
        <form className={"details-form"} ref={this.formRef}>
          {this.state.shouldRedirectToLogin ? (
              <Redirect
                  to={"/store/" + this.props.storefront.alias + "/login/checkout"}
              />
          ) : null}
          <div className={"form-section"}>
            {this.getInputComponent("firstName", "First Name")}
            {this.getInputComponent("lastName", "Last Name")}
            {this.getInputComponent("companyName", "Company Name", {
              hidden: !this.props.storefront.captureCompany,
            })}
          </div>
          <div className={"form-section"}>
            <EditCustomFieldsForm
                customFieldInstances={this.state.customFieldInstances}
                updateCustomFieldContent={this.updateCustomFieldFormData}
                isLoading={this.state.isLoading}
            />
          </div>
          <div className={"form-section"}>
            {this.getInputComponent("email", "Email")}
            {this.state.foundEmailAddress ? (
                this.props.storefront.allowCustomerPortal ? (
                    <button
                        className={"button-as-link"}
                        onClick={() => this.setState({shouldRedirectToLogin: true})}
                    >
                      Your e-mail address was found, please click here to login.
                    </button>
                ) : (
                    <span>
                Your e-mail address was found and duplicate e-mail addresses are
                not allowed on this store.
              </span>
                )
            ) : null}
            {this.getInputComponent("confirmEmail", "Confirm Email", {
              hidden: !this.props.storefront.confirmEmail
            })}
            {this.getInputComponent("landline", "Phone Number")}
          </div>
          {this.props.storefront.allowCustomerPortal ||
            this.props.storefront.type === "BfAccountingOrg" ? (
            //We only add the password to the page if required because otherwise Chrome complains about not being able to save the password.
              <div className={"form-section"}>
                {this.getInputComponent("password", "Password", {
                  type: "password",
                })}
                {this.getInputComponent("confirmPassword", "Confirm Password", {
                  type: "password",
                })}
              </div>
          ) : null}
          <div style={{display: this.props.storefront.captureAddress ? '' : 'none'}} className={clsx("form-section", styles['billing-address'], (isShipping(this.props) ? styles['has-shipping'] : ""))}>
            {isShipping(this.props) ? <span>Billing Address</span> : null}
            {this.getInputComponent("addressLine1", "Address Line 1")}
            {this.getInputComponent("addressLine2", "Address Line 2")}
            {this.getInputComponent("addressLine3", "Address Line 3")}
            {this.getInputComponent("city", "City")}
            {isSupportedCountry(this.state.country) ?
                (
                    <div className="form-section">
                      <div className={`country-dropdown-container ${this.state.invalidProvince ? "invalid" : ""}`}>
                        <span className="country-label">State</span>
                        <ProvinceDropdown
                            country={asSupportedCountry(this.state.country)}
                            province={this.state.formData.province}
                            setProvince={this.setProvince}
                        />
                      </div>
                      <div className="invalid-message"
                           hidden={!this.state.invalidProvince}>
                        Please provide a valid State
                      </div>
                    </div>
                ) :
                this.getInputComponent("province", "Province")
            }
            <div className="form-section">
              <div className="country-dropdown-container">
                <span className="country-label">Country</span>
                <CountryDropdown
                    country={this.state.country}
                    setCountry={this.setCountry}
                    disabled={this.props.storefront.disableCountry}
                />
              </div>
            </div>
            <div className="form-section">
              {this.getInputComponent(
                  "postcode",
                  this.state.country === "US" ? "Zip code" : "Post code"
              )}
            </div>
          </div>
          <div style={{display: isShipping(this.props) ? '' : 'none'}}
               className={clsx("form-section", styles['shipping-address'], (isShipping(this.props) ? styles['has-shipping'] : ""))}>
            <div>{this.props.storefront.secondaryAddress}</div>
            <Checkbox checked={this.state.shippingSameAsBilling}
                      onSlChange={this.changeShippingSameAsBilling}
                      className={"form-section"}>
              Same as above
            </Checkbox>
            <div style={{display: !this.state.shippingSameAsBilling ? '' : 'none'}} className={"form-section"}>
              {this.getInputComponent("shippingAddressLine1", "Address Line 1")}
              {this.getInputComponent("shippingAddressLine2", "Address Line 2")}
              {this.getInputComponent("shippingAddressLine3", "Address Line 3")}
              {this.getInputComponent("shippingCity", "City")}
              {isSupportedCountry(this.state.shippingCountry) ?
                  (
                      <div className="form-section">
                        <div
                            className={`country-dropdown-container ${this.state.invalidShippingProvince ? "invalid" : ""}`}>
                          <span className="country-label">State</span>
                          <ProvinceDropdown
                              country={asSupportedCountry(this.state.shippingCountry)}
                              province={this.state.formData.shippingProvince}
                              setProvince={this.setShippingProvince}
                          />
                        </div>
                        <div className="invalid-message"
                             hidden={!this.state.invalidShippingProvince}>
                          Please provide a valid State
                        </div>
                      </div>
                  ) :
                  this.getInputComponent("shippingProvince", "Province")
              }
              <div className="form-section">
                <div className="country-dropdown-container">
                  <span className="country-label">Country</span>
                  <CountryDropdown
                      country={this.state.shippingCountry}
                      setCountry={this.setShippingCountry}
                      disabled={this.props.storefront.disableCountry}
                  />
                </div>
              </div>
              <div className="form-section">
                {this.getInputComponent(
                    "shippingPostcode",
                    this.state.shippingCountry === "US" ? "Zip code" : "Post code"
                )}
              </div>
            </div>
          </div>
          {termsConditions(this.props.storefront).map((tc, index) => (
              <div className={"form-section"} key={tc.name}>
                <Checkbox
                    className={"form-checkbox"}
                    name={"termsAndConditions" + index}
                    ref={this.termConditionInputs[index]}
                    onSlChange={() => this.validateTermsConditions(false)}
                >{tc.title}&nbsp;</Checkbox>
                {tc.linkText.trim().length === 0 || tc.contents.trim().length === 0 ? null :
                  <>
                    {tc.contents.startsWith("http:/") ?
                        <a title="Opens in a new window"
                           className={"button-as-link tcs-link"}
                           target={"termsConditions" + index}
                           href={tc.contents}
                        >{tc.linkText}&nbsp;{openInWindow}</a> :
                        <button className={"button-as-link tcs-link"}
                                onClick={this.tcsDialogs[index].current?.showDialog}
                        >{tc.linkText}</button>}
                    {tc.contents.startsWith("http:/") ?
                        null :
                        tc.contents.startsWith("https://") ?
                        <TermsAndConditionsDialog ref={this.tcsDialogs[index]} src={tc.contents} name={tc.name}
                                                  className={"tc-iframe"}/> :
                        <TermsAndConditionsDialog ref={this.tcsDialogs[index]} markdownText={tc.contents} name={tc.name}
                                                  className={"tc-markdown"}/>
                    }
                  </>
                }
              </div>
          ))}
          <div className="checkbox-invalid-text terms-conditions-help-text"
               hidden={!this.state.invalidTC ? true : undefined}>
            You must read and accept all the terms and conditions to proceed.
          </div>
          <div className={"form-footer"}>
            <LoadingButton
                submit
                noCustomStyle
                isLoading={this.state.isLoading}
                disabled={this.state.foundEmailAddress}
            >
              Next
            </LoadingButton>
          </div>
        </form>
    );
  }
}

export default connector(DetailsForm);
