import React, { FC, useEffect } from "react";
import { Button, Dialog } from "../shoelace";
import "react-nice-dates/build/style.css";

import "./SubscriptionPauseDialog.scss";
import { Subscription } from "../../store/portal/subscription/types";
import { showAlert } from "../../store/shared/alert/actions";
import moment from "moment";
import { apiResumeSubscription } from "../../api/portalEndpoints";
import { AlertType } from "../../store/shared/alert/types";
import { getAlertFromApiErrorResponse } from "../../utils/utils";
import LoadingButton from "../shared/LoadingButton";
import SlDialogElement from "@shoelace-style/shoelace/dist/components/dialog/dialog";

interface Props {
  subscription: Subscription;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  closeDialog: () => void;
  open: boolean;
}

const SubscriptionResumeDialog: FC<Props> = ({
  subscription,
  isLoading,
  setIsLoading,
  closeDialog,
  open,
}) => {
  const dialogRef = React.useRef<SlDialogElement>(null);

  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1); // Get tomorrow's date
  tomorrow.setHours(0, 0, 0, 0); // Get the start of tomorrow

  const onConfirmPauseSubscription = () => {
    setIsLoading(true);
    apiResumeSubscription(subscription.id).then(
      (response) => {
        showAlert({
          type: AlertType.Success,
          message: "Your subscription has been resumed.",
        });
        setIsLoading(false);
        closeDialog();
      },
      (error) => {
        showAlert(
          getAlertFromApiErrorResponse(
            error,
            "There was a problem resuming your subscription."
          )
        );
        setIsLoading(false);
      }
    );
  };

  useEffect(() => {
    dialogRef.current!.addEventListener("sl-hide", () => closeDialog());
  }, [dialogRef, closeDialog]);

  return (
    <Dialog
      label="Resume Subscription"
      className="subscription-pause-dialog"
      open={open}
      ref={dialogRef}
    >
      <div className="subscription-pause-dialog-content">
        {subscription.resumeDate ? (
          <div className="pause-sentence current-resumption">
            <span>
              Your subscription is currently scheduled to resume on{" "}
              <b>
                {moment.unix(subscription.resumeDate).format("Do MMMM YYYY")}
              </b>
              .
            </span>
          </div>
        ) : null}
        <div className="pause-sentence">
          <span>Are you sure you want to resume your subscription?</span>
        </div>
      </div>
      <div slot="footer" className="subscription-pause-dialog-buttons">
        <Button className={"neutral custom"} onClick={closeDialog}>
          back
        </Button>
        <LoadingButton
          className={"pause"}
          onSubmit={onConfirmPauseSubscription}
          isLoading={isLoading}
        >
          Resume
        </LoadingButton>
      </div>
    </Dialog>
  );
};

export default SubscriptionResumeDialog;
