import {
  Storefront,
  StorefrontActionTypes,
  StorefrontState,
  StorefrontUiOptions,
  UPDATE_CUSTOM_FIELDS,
  UPDATE_SQUARE_CONFIG,
  UPDATE_STOREFRONT,
  UPDATE_UI_OPTIONS
} from "./types";
import {SquareConfig} from "../../api/types";
import {CUSTOMER_ORG_OPTIONS} from "../../utils/storefrontUiOptions";

const initialState: StorefrontState = {
  storefront: {} as Storefront,
  squareConfig: {} as SquareConfig,
  customFields: [],
  uiOptions: CUSTOMER_ORG_OPTIONS as StorefrontUiOptions
}

export function storefrontReducer(state: StorefrontState = initialState, action: StorefrontActionTypes): StorefrontState {
  switch (action.type) {
    case UPDATE_STOREFRONT:
      return { ...state, storefront: action.payload };
    case UPDATE_SQUARE_CONFIG:
      return { ...state, squareConfig: action.payload };
    case UPDATE_CUSTOM_FIELDS:
      return { ...state, customFields: action.payload };
    case UPDATE_UI_OPTIONS:
      return { ...state, uiOptions: action.payload };
    default:
      return state;
  }
}
