import React, {RefObject} from "react";
import {connect, ConnectedProps} from "react-redux";
import {Dialog} from "./shoelace";
import {RootState} from "../store";

import './CheckoutStatusDialog.scss';
import {showAlert} from "../store/shared/alert/actions";
import {KeyPlanSuccess} from "../store/shared/plan/types";
import StatusBadge from "./portal/StatusBadge";
import type SlDialogElement from "@shoelace-style/shoelace/dist/components/dialog/dialog"

const mapState = (state: RootState) => ({
});

const mapDispatch = {
  showAlert: showAlert
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
  checkoutStatuses: Array<KeyPlanSuccess>
  closeDialog: () => void,
  open: boolean
}

type State = {
}

class CheckoutStatusDialog extends React.Component<Props, State> {
  private readonly dialogRef: RefObject<SlDialogElement>;

  constructor(props: Props) {
    super(props);

    this.dialogRef = React.createRef<SlDialogElement>();
  }

  componentDidUpdate() {
    //This shouldn't be needed
    if (this.props.open)
      this.dialogRef.current!.show();
  }

  componentDidMount() {
    this.dialogRef.current!.addEventListener('sl-hide', () => this.props.closeDialog());
  }

  render() {
    return (
      <Dialog label="Checkout Status" className="checkout-status-dialog" open={this.props.open} ref={this.dialogRef}>
        <div className="checkout-status-dialog-content">
          {this.props.checkoutStatuses.map(item => <div className="checkout-status-line" key={item.key}>
            <div>{item.plan.name}</div>
            <div className="checkout-status-line-border"/>
            {item.success
              ? <StatusBadge statusStyle="success" text="Succeeded"/>
              : <StatusBadge statusStyle="danger"  text="Failed"/>
            }
          </div>)}
        </div>
        <div slot="footer" className="dialog-buttons"></div>
      </Dialog>
    );
  }
}

export default connector(CheckoutStatusDialog);

