import React, {FC, useCallback, useEffect, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {BfApiPlan} from "../api/types";
import {Spinner} from "./shoelace";
import {updateSelectedPlanToAdd as updateSelectedPlanToAddAction} from "../store/merchant/plan/actions";
import {Product} from "../store/shared/plan/types";
import ProductRow from "./ProductRow";
import {selectBfPlans, selectBfProducts,} from "../store/merchant/plan/selectors";
import useIntersectionObserver from "../hooks/useIntersectionObserver";
import clsx from "clsx";

import "./PlansAddList.scss";

interface Props {
  plansLoading: boolean;
}

const PlansAddList: FC<Props> = ({ plansLoading }) => {
  const dispatch = useDispatch();
  const bfPlans = useSelector(selectBfPlans);
  const products = useSelector(selectBfProducts);

  const updateSelectedPlanToAdd = useCallback(
    (plan) => {
      dispatch(updateSelectedPlanToAddAction(plan));
    },
    [dispatch]
  );

  useEffect(() => {
    updateSelectedPlanToAdd({} as BfApiPlan);
  }, [updateSelectedPlanToAdd]);

  const tableHeadRef = useRef<HTMLTableCellElement>(null);
  const isIntersecting = useIntersectionObserver(tableHeadRef, {
    threshold: 0.99,
  });

  return (
    <div
      className={clsx(
        "plans-list plans-add-list",
        isIntersecting && "scrolled"
      )}
    >
      <div className="plans-table-container">
        <table className="plans-table">
          <thead>
            <tr className="plans-table-header-row">
              <th className="enabled"></th>
              <th className="path">Path</th>
              <th ref={tableHeadRef} className="name">
                Name
              </th>
            </tr>
          </thead>
          {products.map((product: Product, _: any) => (
            <ProductRow product={product} key={product.name} />
          ))}
        </table>
        <div className="plans-table-overlay">
          {plansLoading ? (
            <div className="plans-table-overlay-loading">
              <Spinner className="plans-table-spinner"></Spinner>
            </div>
          ) : null}
          {bfPlans.length === 0 && !plansLoading ? (
            <div className="plans-table-overlay-no-plans">
              <span>
                <b>You have no plans in Billforward.</b>
                <br />
                You need to create a plan in the Billforward app before adding
                it to Flexcheck.
              </span>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default PlansAddList;
