import { REMOVE_SOURCE, SET_SOURCE, SourceActionTypes } from "./types";

export function setSource(source: string): SourceActionTypes {
  return {
    type: SET_SOURCE,
    payload: {
      source,
    },
  };
}

export function removeSource(): SourceActionTypes {
  return {
    type: REMOVE_SOURCE,
  };
}
