import React from "react";
import BillForward from 'billforward-js';
import {PaymentMethod as PaymentMethodType} from "../../store/shared/user/types";
import {RootState} from "../../store";
import {updatePaymentMethod} from "../../store/user/userDetails/actions";
import {connect, ConnectedProps} from "react-redux";
import LoadingButton from "../shared/LoadingButton";
import FormBase, {BaseState, InputTypeGenerator} from "../shared/FormBase";
import {showAlert} from "../../store/shared/alert/actions";
import {addTrailingSlash, getBasicAlert} from "../../utils/utils";
import {PaymentMethodFormContext} from "./PaymentMethodSwitch";
import {isNotEmpty, validateCardNumber, validateCVC, validateExpiry} from "../../utils/validation";

const mapState = (state: RootState) => ({
  initialPaymentMethod: state.main.user.userDetails.paymentMethod,
  bfAccountId: state.main.user.userDetails.bfAccountId,
  bfPublicToken: state.main.storefront.storefront.publicToken,
  paymentGateway: state.main.storefront.storefront.paymentGateway
});

const mapDispatch = {
  updatePaymentMethod: updatePaymentMethod,
  showAlert: showAlert
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
  onSuccessfulSubmit: (data?: any) => void
}

const typeGenerator = new InputTypeGenerator([
  {name: "cardHolder", type: "text"},
  {name: "cardNumber", type: "text"},
  {name: "cvc", type: "text"},
  {name: "expiryMonth", type: "text"},
  {name: "expiryYear", type: "text"}
]);
type InputName = typeof typeGenerator.InputName.type;

class PaymentMethodForm extends FormBase<Props, BaseState<InputName>, InputName> {
  getInitialState(baseState: BaseState<InputName>): BaseState<InputName> {
    return baseState;
  }

  onSubmitValidForm() {
    this.setState({isLoading: true});
    let cardDetails = {
      'cardholder-name': this.state.formData.cardHolder,
      'cvc': this.state.formData.cvc,
      'number': this.state.formData.cardNumber,
      'exp-month': this.state.formData.expiryMonth,
      'exp-year': `20${this.state.formData.expiryYear}`,
      'use-as-default-payment-method': 'true'
    };

    let callback = (data: any, error: any) => {
      if (error) {
        this.props.showAlert(getBasicAlert('There was an error processing your payment method.', error.message));
        this.setState({isLoading: false});
        return false;
      } else {
        this.props.updatePaymentMethod(this.state.formData as unknown as PaymentMethodType);
        this.setState({isLoading: false});
        this.props.onSuccessfulSubmit(data.id);
      }
    };

    try {
      BillForward.captureCard(cardDetails, this.props.paymentGateway, this.props.bfAccountId, callback);
    } catch(error) {
      console.error(error);
      this.props.showAlert(getBasicAlert('There was an error submitting your card details.'));
      this.setState({isLoading: false});
      return false;
    }
    return true;
  }

  onTextInputUpdateExtension(name: InputName) {
    if (name === 'expiryMonth' && this.state.formData.expiryMonth.length === 2) {
      window.setTimeout(() => this.inputRefs.expiryYear.current!.focus(), 0);
    }
    if (name === 'expiryYear' && this.state.formData.expiryYear.length === 0) {
      window.setTimeout(() => this.inputRefs.expiryMonth.current!.focus(), 0);
    }
  }

  constructor(props: Props) {
    super(
      props,
      typeGenerator,
      {
        setValid: {
          cardHolder: isNotEmpty,
          cardNumber: validateCardNumber,
          cvc: validateCVC,
          expiryMonth: validateExpiry(12),
          expiryYear: validateExpiry(99)
        },
        invalidMessages: {
          cardHolder: 'Please enter the name of the card holder.',
          cardNumber: 'Please enter a valid card number.',
          cvc: 'Please enter a valid CVC (found on the back of your card).',
          expiryMonth: 'Please enter a valid expiry date.',
          expiryYear: 'Please enter a valid expiry date.'
        },
        initialFormData: {...props.initialPaymentMethod}
      }
    );

    const bfAPIKey = this.props.bfPublicToken;
    const bfAPIURL = window.REACT_APP_BFJS_API_URL;
    // eslint-disable-next-line
    BillForward.useAPI(`${addTrailingSlash(bfAPIURL)}v1/`, bfAPIKey);
  }

  render() {
    return (
      <form className={"payment-method-form"} ref={this.formRef}>
        <div className={"form-section"}>
          {this.getInputComponent('cardHolder', 'Card Holder')}
          {this.getInputComponent('cardNumber', 'Card Number')}
          {this.getInputComponent('cvc', 'CVC', {class:'cvc'})}
          <div className={"expiry-date"}>
            {this.getInputComponent('expiryMonth', 'Expiry', {class: 'expiry-month', placeholder: 'MM', maxLength: 2, customHelpText: null})}
            <span className="divider">&nbsp;/&nbsp;</span>
            {this.getInputComponent('expiryYear', ' ', {class: 'expiry-year always-show-help-text', placeholder: 'YY', maxLength: 2, customHelpText:
                <div slot="help-text">
                  {(!this.state.isValid.expiryYear && this.state.touched.expiryYear) || (!this.state.isValid.expiryMonth && this.state.touched.expiryMonth) ? this.invalidMessages.expiryYear : ''}
                </div>
            })}
          </div>
        </div>
        <div className={"form-footer"}>
          <PaymentMethodFormContext.Consumer>
            {context =>
              <LoadingButton noCustomStyle onSubmit={() => this.onSubmit()} isLoading={this.state.isLoading}>{context.buttonText}</LoadingButton>
            }
          </PaymentMethodFormContext.Consumer>
        </div>
      </form>
    )
  }
}

export default connector(PaymentMethodForm);
