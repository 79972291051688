import {CustomizedPlan, Plan} from "../store/shared/plan/types";

export function initialPriceShownIsDifferentToRecurringPrice(plan?: Plan) {
  if (!plan) {
    return false;
  }

  if (plan.taxStatus === "inclusive") {
    return plan.price.initialPrice !== plan.price.pricePerPeriod ||
        plan.price.discountedInitialPrice !== plan.price.discountedPricePerPeriod;
  }
  return plan.price.initialPriceExcludingTax !== plan.price.pricePerPeriodExcludingTax ||
    plan.price.discountedInitialPriceExcludingTax !== plan.price.discountedPricePerPeriodExcludingTax;
}

export function initialPrice(plan?: CustomizedPlan) {
  if (!plan) {
    return undefined;
  }

  if (plan.taxStatus === "inclusive") {
    return plan.price.initialPrice;
  }
  return plan.price.initialPriceExcludingTax;
}

export function discountedInitialPrice(plan?: CustomizedPlan) {
  if (!plan) {
    console.error("Plan is missing");
    return 0;
  }

  if (plan.taxStatus === "inclusive") {
    return plan.price.discountedInitialPrice;
  }
  return plan.price.discountedInitialPriceExcludingTax;
}

export function perPeriodPrice(plan?: CustomizedPlan) {
  if (!plan) {
    return undefined;
  }

  if (plan.taxStatus === "inclusive") {
    return plan.price.pricePerPeriod;
  }
  return plan.price.pricePerPeriodExcludingTax;
}

export function discountedPerPeriodPrice(plan?: CustomizedPlan) {
  if (!plan) {
    console.error("Plan is missing");
    return 0;
  }

  if (plan.taxStatus === "inclusive") {
    return plan.price.discountedPricePerPeriod;
  }
  return plan.price.discountedPricePerPeriodExcludingTax;
}
