import React, {ReactNode, RefObject} from "react";
import {Input} from "../shoelace";
import {StringUnion, StringUnionFunction} from "../../utils/utils";
import type SlInputElement from '@shoelace-style/shoelace/dist/components/input/input';
import type SlCheckboxElement from '@shoelace-style/shoelace/dist/components/checkbox/checkbox';
import type SlSelectElement from '@shoelace-style/shoelace/dist/components/select/select';

/*
 Abstract class representing a generic form with arbitrary input fields.
 Fields can be either text input, checkbox input or select input (i.e. Input, Checkbox or Select from Shoelace components)
 Adding other input types will require developing this class some more.

 The InputName type represents a union type of string literals, with each literal being the name of an input field.
 It must be generated at runtime, prior the instantiation of an implementation of this class.
 This is because we need to iterate over the inputs at various points, such as adding event listeners or validating all inputs.

 The InputName type is generated by creating an InputTypeGenerator (which is also required as a constructor argument).
 Given a variable `typeGenerator: InputTypeGenerator` you must pass `typeof typeGenerator.InputName.type` as the type of the generic InputName.
 You must also pass the same `typeGenerator` as a constructor argument.
 Passing any other type as InputName will result in runtime errors, but not necessarily compilation errors.
*/
type InputComponent = SlInputElement | SlCheckboxElement | SlSelectElement | HTMLInputElement;

interface InputComponentOptions {
  type?: "number" | "text" | "time" | "date" | "datetime-local" | "email" | "password" | "search" | "tel" | "url",
  class?: string,
  placeholder?: string,
  disabled?: boolean,
  maxLength?: number,
  minValue?: number,
  maxValue?: number,
  customHelpText?: ReactNode
  hidden?: boolean
}

export interface BaseState<InputName extends StringUnion["type"]> {
  isValid: Booleans<InputName>,
  touched: Booleans<InputName>,
  formData: Strings<InputName>,
  isLoading: boolean
}

const InputType = StringUnionFunction('text', 'checkbox', 'select');

export type InputDefinition = {
  name: string,
  type: typeof InputType.type
}

export class InputTypeGenerator {
  public readonly TextInputName: StringUnion;
  public readonly CheckboxInputName: StringUnion;
  public readonly SelectInputName: StringUnion;
  public readonly InputName: StringUnion;

  constructor(inputs: Array<InputDefinition>) {
    this.TextInputName = StringUnionFunction(...inputs.filter(x => x.type === 'text').map(x => x.name));
    this.CheckboxInputName = StringUnionFunction(...inputs.filter(x => x.type === 'checkbox').map(x => x.name));
    this.SelectInputName = StringUnionFunction(...inputs.filter(x => x.type === 'select').map(x => x.name));
    this.InputName = StringUnionFunction(...[...this.TextInputName.values, ...this.CheckboxInputName.values, ...this.SelectInputName.values]);
  }
}

export interface FormBaseParameters<InputName extends StringUnion["type"]> {
  setValid: Validators<InputName>,
  invalidMessages: Strings<InputName>,
  initialFormData: Strings<InputName>
}

type Inputs<Name extends StringUnion["type"], T> = {
  [inputName in Name]: T
};

type Booleans<Name extends StringUnion["type"]> = Inputs<Name, boolean>;
type Strings<Name extends StringUnion["type"]> = Inputs<Name, string>;
type Refs<Name extends StringUnion["type"]> = Inputs<Name, RefObject<InputComponent>>;
type Validators<Name extends StringUnion["type"]> = Inputs<Name, (value: string) => boolean>;

abstract class FormBase<
  Props,
  State extends BaseState<InputName>,
  InputName extends StringUnion["type"]
  > extends React.Component<Props, State>
{
  private readonly typeGenerator: InputTypeGenerator;
  protected readonly setValid: Validators<InputName>;
  protected readonly invalidMessages: Strings<InputName>;
  protected readonly formRef: RefObject<HTMLFormElement>;
  protected readonly inputRefs: Refs<InputName>;
  private formSubmitted: boolean = false;
  private readonly createInputsObject: <T>(generator: (inputName: InputName) => T) => Inputs<InputName, T>;

  abstract getInitialState(baseState: BaseState<InputName>, props?: Props,): State; // Should combine BaseState and any additional state properties into a new State object
  abstract onSubmitValidForm(): boolean; // Called when user submits form and all fields are valid

  protected onTextInputUpdateExtension?(name: InputName): void;
  protected onAnyInputUpdateExtension?(name: InputName): void;

  protected constructor(props: Props, typeGenerator: InputTypeGenerator, formBaseParameters: FormBaseParameters<InputName>) {
    super(props);

    this.typeGenerator = typeGenerator;
    this.setValid = formBaseParameters.setValid;
    this.invalidMessages = formBaseParameters.invalidMessages;

    this.createInputsObject = function <T>(generator: (inputName: InputName) => T) {
      let obj: any = {};
      for (let literal of this.typeGenerator.InputName.values) {
        obj[literal] = generator(literal as InputName);
      }
      return obj as Inputs<InputName, T>;
    };

    this.formRef = React.createRef<HTMLFormElement>();
    this.inputRefs = this.createInputsObject(() => React.createRef<InputComponent>());

    this.state = this.getInitialState({
      isValid: this.createInputsObject<boolean>(() => false),
      touched: this.createInputsObject<boolean>(() => false),
      formData: formBaseParameters.initialFormData,
      isLoading: false
    }, props);
  }

  private readonly setToTouched = (name: InputName): void => {
    this.setState({touched: {...this.state.touched, [name]: true}});
    this.performValidation();
  }

  private readonly updateAllIsValid = (): void => {
    this.setState( {isValid: this.createInputsObject(name => this.setValid[name](this.state.formData[name]))});
  }

  private readonly updateFormData = (name: InputName, value: string): void => {
    this.setState({formData: {...this.state.formData, [name]: value}}); // Set form value to the value that user has typed
    this.performValidation();
  }

  private readonly performValidation = (): void => {
    if (!this.formRef.current) {
      console.warn('Form not current.');
      return;
    }
    this.updateAllIsValid();
    for (let fieldName in this.inputRefs) {
      let name = fieldName as InputName;
      // Set HTML validity of each DOM element
      try {
        this.inputRefs[name].current!.setCustomValidity(this.state.isValid[name] ? '' : this.invalidMessages[name]);
      } catch(e) {
        // If the element is not shown it should always be valid so we show an error if not.
        if (!this.state.isValid[name]) {
          console.info(`Could not set validity of "${name}" input element.`);
        }
      }
    }
  }

  protected readonly onSubmit = (): boolean => {
    this.performValidation();
    if (Object.values(this.state.isValid).every(Boolean)) { // All fields are valid
      this.formSubmitted = this.onSubmitValidForm();
      return this.formSubmitted;
    } else {
      // Some fields are not valid - show field error messages
      this.setState({
        touched: this.createInputsObject(() => true)
      });
      return false;
    }
  }

  private readonly getOnTextInputUpdateCallback = (name: InputName) => () => {
    const component: SlInputElement = this.inputRefs[name].current as SlInputElement;
    this.updateFormData(name, component.value);
    if (this.onTextInputUpdateExtension) {
      this.onTextInputUpdateExtension(name);
    }
    if (this.onAnyInputUpdateExtension) {
      this.onAnyInputUpdateExtension(name);
    }
  }

  private readonly getOnCheckboxUpdateCallback = (name: InputName) => () => {
    const component: SlCheckboxElement = this.inputRefs[name].current as SlCheckboxElement;
    this.updateFormData(name, component.checked.toString());
    if (this.onAnyInputUpdateExtension) {
      this.onAnyInputUpdateExtension(name);
    }
  }

  private readonly getOnSelectUpdateCallback = (name: InputName) => () => {
    const component: SlSelectElement = this.inputRefs[name].current as SlSelectElement;
    const value = component.value as string;
    this.updateFormData(name, value);
    if (this.onAnyInputUpdateExtension) {
      this.onAnyInputUpdateExtension(name);
    }
  }

  private readonly getOnInputBlurCallBack = (name: InputName) => () => {
    if (!this.formSubmitted)
      this.setToTouched(name);
  }

  componentDidMount() {
    try {
      this.formRef.current!.onsubmit = (event: Event) => {
        event.preventDefault();
        this.onSubmit();
      }
    } catch(e) {
      console.error('Could not find Form component in DOM', e);
    }
    for (let nameString in this.inputRefs) {
      const name: InputName = nameString as InputName;
      try {
        const component: InputComponent = this.inputRefs[name].current!;

        if (this.typeGenerator.CheckboxInputName.guard(name)) {
          component.addEventListener('sl-change', this.getOnCheckboxUpdateCallback(name));
        } else if (this.typeGenerator.TextInputName.guard(name)) {
          component.addEventListener('sl-input', this.getOnTextInputUpdateCallback(name));
        } else if (this.typeGenerator.SelectInputName.guard(name)) {
          component.addEventListener('sl-change', this.getOnSelectUpdateCallback(name));
        }
        component.addEventListener('sl-blur', this.getOnInputBlurCallBack(name));
      } catch {
        // This is expected if the component is hidden - for example if we are not taking an address.
        console.info(`Could not find component ${name} in DOM`);
      }
    }
    setTimeout(this.performValidation);
  }

  shouldComponentUpdate(nextProps: Readonly<Props>, nextState: Readonly<State>, nextContext: any): boolean {
    this.forceUpdate();
    return true;
  }

  protected readonly getInputComponent = (name: InputName, displayName: string, options: InputComponentOptions = {}) => {
    return (
      <Input
        className={`form-input light ${this.state.touched[name] ? 'touched':''} ${options.class ? options.class : ''} form-input-named-${name}`}
        name={name}
        ref={this.inputRefs[name]}
        value={this.state.formData[name]}
        label={displayName}
        disabled={this.state.isLoading || options.disabled}
        type={options.type}
        togglePassword={options.type === 'password'}
        placeholder={options.placeholder}
        maxlength={options.maxLength}
        min={options.minValue}
        max={options.maxValue}
        // Using boolean false does not hide input
        hidden={options.hidden ? true : undefined}
        ariaHidden={options.hidden ? "true" : undefined}>
        {options.customHelpText !== undefined ?
          options.customHelpText :
          <div slot="help-text">{this.invalidMessages[name]}</div>
        }
      </Input>
    )
  }
}

export default FormBase;
