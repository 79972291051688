import React from "react";
import {Checkbox} from "./shoelace";
import {RootState} from "../store";
import {connect, ConnectedProps} from "react-redux";
import FormBase, {BaseState, InputTypeGenerator} from "./shared/FormBase";

import './StorefrontEmbedForm.scss';

const mapState = (state: RootState) => ({
  storefront: state.main.storefront.storefront
});

const mapDispatch = {
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
  updateProps: (newProps: EmbedProps) => void,
  initialProps: EmbedProps
}

export type EmbedProps = {
  width: number,
  height: number,
  fullWidth: boolean
}

const typeGenerator = new InputTypeGenerator([
  {name: "width", type: "text"},
  {name: "height", type: "text"},
  {name: "fullWidth", type: "checkbox"}
]);
type InputName = typeof typeGenerator.InputName.type;

class StorefrontEmbedForm extends FormBase<Props, BaseState<InputName>, InputName> {
  getInitialState(baseState: BaseState<InputName>): BaseState<InputName> {
    return baseState;
  }

  onSubmitValidForm() {
    this.props.updateProps({
      width: parseInt(this.state.formData.width),
      height: parseInt(this.state.formData.height),
      fullWidth: this.state.formData.fullWidth === 'true'
    });
    return true;
  }

  protected onAnyInputUpdateExtension(name: InputName) {
    this.onSubmit();
  }

  constructor(props: Props) {
    const numericValueRegex = /^[0-9]+$/;
    super(
      props,
      typeGenerator,
      {
        setValid: {
          width: (value: string) => {
            return value.length > 0 && numericValueRegex.test(value);
          },
          height: (value: string) => {
            return value.length > 0 && numericValueRegex.test(value);
          },
          fullWidth: (value: string) => {
            return true;
          }
        },
        invalidMessages: {
          width: 'Please type a whole number.',
          height: 'Please type a whole number.'
        },
        initialFormData: {
          width: props.initialProps.width.toString(),
          height: props.initialProps.height.toString(),
          fullWidth: props.initialProps.fullWidth.toString()
        }
      },
    );
  }

  render() {
    return (
      <form className={"storefront-embed-form"} ref={this.formRef}>
        <div className={"form-section"}>
          <div className={"width-section"}>
            {this.getInputComponent('width', 'Width (pixels)', {
              type: 'number',
              minValue: 0,
              disabled: this.state.formData.fullWidth === 'true'
            })}
            <Checkbox className={`form-checkbox ${this.state.touched.fullWidth ? 'touched':''}`} name="fullWidth" ref={this.inputRefs.fullWidth}>
              Full width
            </Checkbox>
          </div>
          {this.getInputComponent('height', 'Height (pixels)', {type: 'number', minValue: 0})}
        </div>
      </form>
    )
  }
}

export default connector(StorefrontEmbedForm);
