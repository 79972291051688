import {combineReducers} from "redux";
import {navigationReducer} from "./navigation/reducers";
import {planReducer} from "./plan/reducers";
import {alertReducer} from "../shared/alert/reducers";

export const merchantReducer = combineReducers({
  navigation: navigationReducer,
  plan: planReducer,
  alert: alertReducer
} );
