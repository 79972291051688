import {Storefront} from "../store/storefront/types";
import {
  apiGetCustomFields,
  apiGetPlanGroup,
  apiGetPlanGroupByPath,
  apiGetSquareConfig,
  apiGetSquareLocations,
  apiGetStorefront,
  apiPutSquareConfig,
  apiPutStorefront
} from "./index";
import {store} from "../index";
import {updateCustomFields, updateSquareConfig, updateStorefront, updateUiOptions} from "../store/storefront/actions";
import {getStorefrontUiOptionsByStorefrontType} from "../utils/storefrontUiOptions";
import {showAlert} from "../store/shared/alert/actions";
import {AlertType} from "../store/shared/alert/types";
import {PutSquareConfigData, PutStorefrontData} from "./types";
import {PlanGroup} from "../store/user/planGroup/types";

export const fetchStorefront = (alias: string | undefined = undefined, showErrors: boolean = true): Promise<Storefront> => {
  return Promise.all([
    apiGetStorefront(alias),
    apiGetCustomFields(alias)]
  ).then(([storefrontResponse, customFieldsResponse]) => {
    const storefront = storefrontResponse.data as Storefront;
    store.dispatch(updateStorefront(storefront));
    store.dispatch(updateUiOptions(getStorefrontUiOptionsByStorefrontType(storefront.type)));

    const customFields = customFieldsResponse.data.customFields;
    store.dispatch(updateCustomFields(customFields));

    if (storefront.paymentGateway === 'Square') {
      // We need to provide the alias here since we haven't set the default "storefront" param yet
      return apiGetSquareConfig(storefront.alias).then(response => {
        store.dispatch(updateSquareConfig(response.data));
        return storefront;
      }, error => {
        store.dispatch(showAlert({
          type: AlertType.Error,
          message: 'There was an error loading the Square configuration.'
        }));
        return storefront;
      });
    } else {
      return storefront;
    }
  }, error => {
    if (showErrors) {
      store.dispatch(showAlert({
        type: AlertType.Error,
        message: 'There was an error loading the storefront.'
      }));
    }
    return Promise.reject(error);
  });
}
export const fetchPlanGroups = async (): Promise<PlanGroup[]> => {
  const planGroup = await apiGetPlanGroup()
  return planGroup.data
}
export const fetchPlanGroup = async (planGroupPath: string): Promise<PlanGroup> => {
  const planGroup = await apiGetPlanGroupByPath(planGroupPath)
  return planGroup.data
}
export const saveStorefrontSettings = async (storefrontData: PutStorefrontData, squareConfigData?: PutSquareConfigData): Promise<Storefront> => {
  const saveStorefront = () => apiPutStorefront(storefrontData).then(response => {
    store.dispatch(updateStorefront(response.data));
    return response.data;
  });
  const saveSquareConfig = () => apiPutSquareConfig({locationId: squareConfigData!!.locationId}).then(response => {
    store.dispatch(updateSquareConfig(response.data));
    return response.data;
  });

  if (squareConfigData) {
    const results = await Promise.all([saveStorefront(), saveSquareConfig()]);
    return results[0];
  } else {
    return saveStorefront();
  }
}
export const fetchSquareConfigAndLocations = async () => {
  const fetchSquareConfig = () => apiGetSquareConfig().then(response => {
    store.dispatch(updateSquareConfig(response.data));
  });

  const results = await Promise.all([apiGetSquareLocations(), fetchSquareConfig()]);
  return results[0];
}
