import {getStorefrontCss, getUserBackgroundStyle, objectToCssString,} from "./utils";

const blankBackgroundStyle = {
  'width': 'fit-content',
  'height': 'fit-content',
  'block-size': 'fit-content',
  'min-height': '0',
  'overflow': 'hidden'
}

export const switchBackgroundToMerchant = () => {}

export const switchBackgroundToUser = () => {
  const oldStyle = document.body.getAttribute('style');
  const newStyle = objectToCssString(getUserBackgroundStyle());
  if (oldStyle !== newStyle) {
    document.body.setAttribute('style', newStyle);
  }
}
export const switchBackgroundToPortal = () => {
    document.body.setAttribute('style', '');
}
export const switchBackgroundToMerchantLogin = () => {}

export const switchBackgroundToBlank = () => {
  document.body.setAttribute('style', objectToCssString(blankBackgroundStyle));
}

export const updateStorefrontCss = () => {
  const css = getStorefrontCss();
  if (css) {
    if (css.includes("{")) {
      const styleEl = document.createElement("style");
      styleEl.textContent = css;
      document.head.appendChild(styleEl);
    } else {
      document.documentElement.setAttribute('style', css);
    }
  }
}
