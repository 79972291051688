import {CustomizedPricingComponent} from "../store/shared/plan/types";
import {Moment} from "moment";
import {TermOrCondition} from "../store/storefront/types";

// Errors

export enum ErrorCode {
  InvalidCoupon,
  InvalidEmail,
  EmailInUse,
  UnpaidInvoiceExists,
  PlanDoesNotExist
}

export type ApiError = {
  data: {
    exception: string
    message: string
    path: string
    status: number
    timestamp: string
    code?: string
  }
}

// Promises

export type KeyedPromiseFunctions<ResponseType> = Array<{
  key: string
  func: () => Promise<ResponseType>
}>

export type KeyedPromiseRequests<RequestType> = Array<{
  key: string
  body: RequestType
}>

export type PromiseResponsesMap<ResponseType> = {
  [key: string]: {
    success: boolean
    body: ResponseType
  }
}

// Pricing

type PricingTier = {
  bucketSize?: number
  from: number
  to?: number
  price: number
  type: 'bucket' | 'fixed' | 'unit'
}
export type PricingModelType = 'advance' | 'arrears' | 'setup' | 'usage' | 'aggregation'
export type PricingLevelType = 'flat' | 'tiered' | 'volume'
type BasePricingComponent = {
  displayName?: string
  name: string
  unit: string
}
interface FlatUsagePricingComponent extends BasePricingComponent {
  price: number
}
interface FlatPricingComponent extends BasePricingComponent {
  price: number
  defaultQuantity: number
}
interface TieredUsagePricingComponent extends BasePricingComponent {
  tiers: Array<PricingTier>
}
interface TieredPricingComponent extends BasePricingComponent {
  tiers: Array<PricingTier>
  defaultQuantity: number
}
type PricingModel = {
  flat?: Array<FlatPricingComponent>
  tiered?: Array<TieredPricingComponent>
  volume?: Array<TieredPricingComponent>
}
type UsagePricingModel = {
  flat?: Array<FlatUsagePricingComponent>
  tiered?: Array<TieredUsagePricingComponent>
  volume?: Array<TieredUsagePricingComponent>
}
export type Pricing = {
  [type in 'advance' | 'arrears' | 'setup']?: PricingModel
} & {
  usage?: UsagePricingModel
  aggregation?: any
}
export type PricingComponent = FlatUsagePricingComponent | FlatPricingComponent | TieredUsagePricingComponent | TieredPricingComponent;
export type StandalonePricingComponent = PricingComponent & {
  modelType: PricingModelType
  levelType: PricingLevelType
}

export type ApiPricingComponentQuantity = {
  pricingComponent: string
  quantity: number
}

// Plans

export interface ApiPlan {
  definition: BfApiPlan
  id: string
  index: number
  hidden: boolean
  bfPlanPath: string
  cardText: string
  colour: string
  imageUrl?: string
}

export interface BfMetadata {
  details?: string
  apiConfigurationId?: string
}

export interface BfApiPlan {
  id: string
  description: string
  path : string
  metadata?: BfMetadata
  pricing : Pricing
  fixedTerm?: { periods?: number }
  name : string
  displayName? : string
  duration : number
  currency : string
  durationPeriod : string
  organizationID : string
  quote?: CompositeQuote
  trialDescription? : string
  hasUsage? : boolean
  oneOffPayment? : boolean
  taxStatus: "inclusive" | "exclusive"
  localisedTax: boolean
}

// Subscriptions

export type ApiSubscriptionStatus = 'AwaitingPayment' | 'Cancelled' | 'Expired' | 'Failed' | 'Paid' | 'Provisioned' | 'Trial';

export interface PricingComponentValue {
  pricingComponentId?: string
  pricingComponentName?: string
  value?: number
}

interface SubscriptionExecutionResponse {
  invoiceID?: string
  invoiceType?: "Subscription" | "Trial" | "Charge" | "FinalArrears" | "Amendment" | "Aggregated" | "AffiliateCommission"
  currency?: string
  outstandingAmount?: number
  executions?: Array<SubscriptionExecutionResponse>
}

export interface ApiSubscription {
  id: string
  plan: ApiPlan
  accountId?: string
  active?: boolean
  state: ApiSubscriptionStatus
  currentPeriodStart?: string
  currentPeriodEnd?: string
  productRatePlanId?: string
  pricingComponentValues?: Array<PricingComponentValue>
  metadata?: any
  initialPeriodStart?: string
  subscriptionEnd?: string
  executionInfo?: SubscriptionExecutionResponse
  versionNumber?: number
  versionStart?: string
  planName?: string
  locked?: string
  resumeDate?: string
}

// Cart

export type ApiCartLine = {
  id: string
  bfPlan: string
  coupons: Array<string>
  pricingComponents: Array<ApiPricingComponentQuantity>
  subscriptionStatus: ApiSubscriptionStatus | undefined
}

export interface CartResponse {
  id: string
  paymentMethodId?: string
  cartContents: Array<ApiCartLine>
}

export interface PostCartData {
  paymentMethodId?: string
  cartContents?: Array<ApiCartLine>
}

// Invoices

export type ApiInvoiceStatus = "Paid" | "Unpaid" | "Pending" | "Voided"
type ApiInvoiceType = "Subscription" | "Trial" | "Charge" | "FinalArrears" | "Amendment" | "Aggregated" | "AffiliateCommission"

export interface ApiInvoicePayment {
  id: string
  crmID?: string
  changedBy?: string
  paymentID: string
  invoiceID: string
  organizationID: string
  currency: string
  gateway?: string
  nominalAmount: number
  actualAmount: number
  refundedAmount: number
  paymentReceived?: string
  created: string
  updated: string
}

export interface ApiInvoice {
  id: string
  accountID: string
  subscriptionID: string
  invoiceCost: number
  nonDiscountedCost: number
  discountAmount: number
  periodStart: string
  periodEnd: string
  issued: string
  due: string
  state: ApiInvoiceStatus
  type: ApiInvoiceType
  invoicePayments: Array<ApiInvoicePayment>
  cardPaymentMethod?: ApiPaymentMethod
  creditNoteAmount?: number
  subscription: ApiSubscription
}

// Payment Methods

type ApiPaymentMethodState = "Pending" | "Active" | "Expiring" |"Expired"

export interface ApiPaymentMethod {
  id: string
  name?: string
  description?: string
  cardHolderName?: string
  expiryDate?: string
  cardType?: string
  firstSix?: string
  lastFour?: string
  state?: ApiPaymentMethodState
  expiryMonth?: string
  expiryYear?: string
  defaultPaymentMethod: boolean
}

// Accounts

export interface ApiAddress {
  addressLine1: string
  addressLine2?: string
  addressLine3?: string
  city: string
  province: string
  country: string
  postcode: string
  primaryAddress?: boolean
  primaryShippingAddress?: boolean
}

export interface ApiAccount {
  id: string
  crmId?: string
  organizationId: string
  profile: ApiProfile
  paymentMethods?: Array<ApiPaymentMethod>
  metadata?: any
  successfulSubscriptions?: number
}

export interface ApiProfile {
  accountID?: string
  organizationId?: string
  email?: string
  firstName?: string
  lastName?: string
  companyName?: string
  addresses?: Array<ApiAddress>
  mobile?: string
  landline?: string
  fax?: string
  dob?: string
  correspondenceLanguage?: string
  applyTax?: boolean
  changedBy?: string
}

// Quotes

export type Quote = {
  total: number
  totalExcludingTax: number
  subtotal: number
  subtotalExcludingTax: number
  tax: number
  discount: number
  discountExcludingTax: number
  quantities: Array<QuoteQuantity>
  periodStart: string
  periodEnd: string
  quoteFor: 'Migration' | 'InitialPeriod' | 'Upgrade' | 'RecurringPeriod'
  immediatePayment: number
  immediateTax: number
  firstRecurringPaymentDate: string
}

export type CompositeQuote = {
  initialQuote: Quote
  recurringQuote: Quote
}

export type CompositeUpgradeQuote = {
  upgradeQuote: Quote
  recurringQuote: Quote
}

export type CartQuote = {
  recurringAveragePricePerMonth: number
  discountedRecurringAveragePricePerMonth: number
  discountedRecurringAverageTaxPerMonth: number
  firstRecurringPaymentDate: Moment
  immediatePayment: number //immediatePayment always includes tax because it is what the customer is about to pay
  immediateTax: number
  isOneOff: boolean
}

export type QuoteQuantity = {
  cost: number
  costExcludingTax: number
  discount: number
  discountExcludingTax: number
  discounts: any[]
  existingQuantity: number
  finalCost: number
  finalCostExcludingTax: number
  pricingComponentID: string
  pricingComponentName: string
  pricingComponentType: string
  quantity: number
  tax: number
}

// Custom Fields

export type CustomField = {
  id: string
  name: string
  displayName?: string
  valueType: 'String' | 'Boolean' | 'Number'
}

// Request Types

export type GetPlansParams = {
  planGroupPath?: string
  includeHidden?: boolean
  restrictingCoupon?: string
  coupons: Array<string | null>
}

export type QuoteForType = 'InitialPeriod' | 'RecurringPeriod' | 'Upgrade';

export type GetQuoteData = {
  planId: string
  coupons: Array<string>
  quantities?: Array<CustomizedPricingComponent>
  quoteFor?: QuoteForType
  includeTrial?: boolean
  populatePaymentInfo?: boolean
  accountId?: string
}

export type GetUpgradeQuoteData = {
  subscriptionId: string
  coupons: string[]
  quantities?: Array<CustomizedPricingComponent>
  includeTrial?: boolean
  populatePaymentInfo?: boolean
}

export type PostCheckoutData = {
  cartId: string
  cartLineId: string
  paymentMethodId?: string
}

export type PutStorefrontData = {
  name?: string
  logoUrl?: string
  backgroundImageUrl?: string
  termsConditions?: string | TermOrCondition[]
  purchaseMessage?: string
  allowCart?: boolean
  allowCoupons?: boolean
  captureCompany?: boolean
  captureAddress?: boolean
  allowCustomerPortal?: boolean
  alias?: string
  trading?: boolean
  paymentGateway?: string
}

export type UploadImageData = {
  name: string
  image: any
}

type LoginParams = {
  username: string
  password: string
}

export type PortalLoginParams = LoginParams & {
  storefront: string
}

export type MerchantLoginParams = LoginParams & {
  code?: string
  'accept-policies'?: string
}

export type PostCreateOrgsData = {
  password: string
}

export interface PutAccountData {
  email?: string
  firstName?: string
  lastName?: string
  companyName?: string
  billingAddress?: ApiAddress
  shippingAddress?: ApiAddress
  customFields?: {
    [name: string]: string | undefined
  }
}

export type UpgradeSubscriptionData = Array<{
  name: string
  value: number
}>

export type PauseSubscriptionData = {
  resume?: Date
  newSubscriptionStart?: Date
  newSubscriptionState?: 'Paid' | 'AwaitingPayment'
}
export type ResumeSubscriptionData = {
  resume?: Date
  newSubscriptionStart?: Date
  newSubscriptionState?: 'Paid' | 'AwaitingPayment'
}

export type ResetPasswordData = {
  code: string
  newPassword: string
}

// Response Types

export type GatewayResponse = {
  liveGateways: Array<string>
  deletedGateways: Array<string>
}

export type BfCreateOrgsResponse = {
  prod: string
  sandbox: string
}

export type CheckoutResponse = {
  subscriptionIds: Array<string>
}

export type SquareConfig = {
  created: string
  id: string
  locationId: string
  applicationId: string
  updated: string
}

export type PutSquareConfigData = {
  locationId: string
}

export type SquareLocation = {
  businessName: string
  capabilities: Array<"CREDIT_CARD_PROCESSING" | string>
  country: string
  id: string
  merchantId: string
  name: string
  status: "ACTIVE" | string
}

interface PreAuthResponse {
  organizationID: string
}

export interface ShuttlePreAuthResponse extends PreAuthResponse {
  signature: string
  instanceKey: string
  hydratedOptions: string
}

export interface CardConnectPreAuthResponse extends PreAuthResponse {
  site: string;
}

type gatewayType = 'Balanced' | 'Braintree' | 'Cybersource' | 'Paypal' | 'Stripe' | 'Shuttle' | 'AuthorizeNet' |
    'Spreedly' | 'SagePay' | 'GoCardless' | 'TrustCommerce' | 'Payvision' | 'Epx' | 'Kash' | 'Zooz' | 'Square' |
    'CardConnect';

export interface PreAuthRequest {
  '@type': string
  configurationID?: string
  gatewayID?: string
  gateway: gatewayType
  organizationID?: string
  billForwardURL?: string
  billForwardPublicToken: string
}

export interface ShuttlePreAuthRequest extends PreAuthRequest {
  '@type': 'ShuttlePreAuthRequest'
  signatureBody: string
  gateway: 'Shuttle'
}
export interface AuthCaptureRequest {
  '@type': string
  accountID: string
  gateway: gatewayType
  defaultPaymentMethod: boolean
}

export interface ShuttleAuthCaptureRequest extends AuthCaptureRequest {
  '@type': 'ShuttleAuthCaptureRequest'
  gateway: 'Shuttle'
  shuttlePaymentMethod: string
  shuttleInstanceId: string
}

export interface CardConnectAuthCaptureRequest extends AuthCaptureRequest {
  '@type': 'CardConnectAuthCaptureRequest'
  gateway: 'CardConnect'
  tokenizedCardNumber: string
  expiry: string
}

export type PortalLoginResponse = {
  "access_token": string
  "expires_in"?: number
  "refresh_expires_in"?: number
  "refresh_token"?: string
  "token_type"?: string
  "not-before-policy"?: number
  "session_state"?: string
  "scope"?: string
}

export type GetCustomFieldsResponse = {
    customFields: Array<CustomField>
}

export type UpgradeSubscriptionResponse = {
  values: Array<{
    pricingComponentName: string
    subscriptionId: string
    oldValue?: number
    newValue?: number
    state?: string
  }>
}
