import React from "react";
import ReactDOMServer from 'react-dom/server';

import {RootState} from "../store";
import {connect, ConnectedProps} from "react-redux";
import './StorefrontUrlDetails.scss';
import {showAlert} from "../store/shared/alert/actions";
import OptionsPanel from "./OptionsPanel";
import {addTrailingSlash, getAlertFromApiErrorResponse} from "../utils/utils";
import CopyableTextArea from "./shared/CopyableTextArea";
import StorefrontUrlButtonForm from "./StorefrontUrlButtonForm";
import OptionDropdown from "./OptionDropdown";
import {Alert, Button, Dropdown, Icon, Menu, MenuItem, Spinner, Tab, TabGroup, TabPanel} from "./shoelace";
import {Plan} from "../store/shared/plan/types";
import {updateFlexcheckPlans} from "../store/merchant/plan/actions";
import StorefrontEmbedForm, {EmbedProps} from "./StorefrontEmbedForm";
import {fetchFlexcheckPlans} from "../api/quotes";

const mapState = (state: RootState) => ({
  storefront: state.main.storefront.storefront,
  flexcheckPlans: state.main.merchant.plan.flexcheckPlans
});

const mapDispatch = {
  showAlert: showAlert,
  updateFlexcheckPlans: updateFlexcheckPlans,
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
}

interface State {
  buttonText: string,
  embedProps: EmbedProps,
  currentLinkPlan: Plan | null,
  isLoading: boolean
}

class StorefrontUrlDetails extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      buttonText: 'go to store',
      embedProps: {
        width: 600,
        height: 600,
        fullWidth: false
      },
      currentLinkPlan: null,
      isLoading: false
    }
  }

  componentDidMount() {
    if (this.props.flexcheckPlans.length === 0) {
      this.setState({isLoading: true});
      fetchFlexcheckPlans({ coupons: [] })
        .then(convertedPlans => {
          this.props.updateFlexcheckPlans(convertedPlans);
        }, error => {
          this.props.showAlert(getAlertFromApiErrorResponse(error.response, 'There was an error retrieving the plans list.'));
        }).finally(() => this.setState({isLoading: false}));
    }
  }

  render () {
    const storefrontUrl = `${addTrailingSlash(window.REACT_APP_FLEXCHECK_DOMAIN)}store/${this.props.storefront.alias}`;
    const button = <iframe title="Button" src={`${addTrailingSlash(window.REACT_APP_FLEXCHECK_DOMAIN)}button?text=${this.state.buttonText}&link=${storefrontUrl}`}></iframe>
    const iframe = this.state.embedProps.fullWidth
            ? <div>
                <div style={{position:'relative',paddingTop:`${this.state.embedProps.height}px`}}>
                  <iframe title="Storefront" src={storefrontUrl} allowFullScreen
                      style={{position:'absolute',top:'0',left:'0',width:'100%',height:'100%',border:'none'}}></iframe>
                </div>
              </div>
            : <iframe title="Storefront" src={storefrontUrl} style={{border:'none'}} allowFullScreen width={this.state.embedProps.width} height={this.state.embedProps.height}></iframe>

    return (
      <div className="storefront-url-details">
        <OptionsPanel title="Your Storefront URL">
          <div className="storefront-url-container">
            <span>Your storefront URL is:</span>
            <CopyableTextArea text={storefrontUrl} link/>
            <br/>
            <span>Place this link anywhere to direct customers to your storefront.</span>
          </div>
          <OptionDropdown title="Link to Specific Plan" icon="cart-plus-fill">
            {this.state.isLoading ?
              <Spinner></Spinner> :
              <div className="storefront-url-specific-plan-container">
                {!this.props.flexcheckPlans || this.props.flexcheckPlans.length === 0 ?
                  <span>No plans configured yet.</span> :
                  <div className="plan-dropdown-container">
                    <span className="plan-message">When a customer clicks this link, the selected plan will be automatically added to their cart.</span>
                    <Dropdown className="plan-dropdown">
                      {this.state.currentLinkPlan !== null
                        ? <Button slot="trigger" caret className="plan-button">
                          {this.state.currentLinkPlan.bfPlanPath}
                        </Button>
                        : <Button slot="trigger" caret className="plan-button">
                          Select plan...
                        </Button>}
                      <Menu>
                        {this.props.flexcheckPlans.map(plan => (
                          <MenuItem className="plan-menu-item" onClick={() => this.setState({currentLinkPlan: plan})}
                                    key={plan.bfPlanPath}>
                            {plan.bfPlanPath}
                          </MenuItem>
                        ))}
                      </Menu>
                    </Dropdown>
                  </div>
                }
                { this.state.currentLinkPlan
                  ? <CopyableTextArea text={`${storefrontUrl}?selectedPlanId=${this.state.currentLinkPlan.bfId}`} link></CopyableTextArea>
                  : null }
              </div>
            }
          </OptionDropdown>
          <OptionDropdown title="Embed Storefront" icon="code-slash">
            <div>You can embed your Flexcheck storefront directly into a webpage, so your customers can shop without having to leave your website.</div>
            <div className="embed-help-message">
              <Icon className="help-icon" name="question-circle"/>
              <a href="https://www.billforward.io/knowledge/flexcheck-embed" target="_blank" rel="noreferrer">See this article</a>
              &nbsp;for more guidance on how to embed Flexcheck.
            </div>
            <TabGroup>
              <Tab slot="nav" panel="storefront">Entire Storefront</Tab>
              <Tab slot="nav" panel="button">Button Hyperlink</Tab>
              <TabPanel name="storefront">
                {this.state.isLoading
                  ? <Spinner></Spinner>
                  : <div className="storefront-url-embed-container">
                    <span className="code-copy-message">Copy this code into your website to embed your storefront directly into the page.</span>
                    <div className="code-copy-elements">
                      <CopyableTextArea text={ReactDOMServer.renderToStaticMarkup(iframe)?.replace('&amp;','&')}></CopyableTextArea>
                      <div className="iframe-container">
                        <div className="dropdown-section">
                          <span className="title">Options</span>
                          <StorefrontEmbedForm initialProps={this.state.embedProps} updateProps={props => this.setState({embedProps: props})}/>
                        </div>
                        <div className="dropdown-section">
                          <span className="title">Preview</span>
                          <div className="dropdown-body">
                            {this.props.storefront.trading
                              ? iframe
                              : <Alert variant="warning" open>
                                  <Icon slot="icon" name="exclamation-triangle"></Icon>
                                  To see the preview, your storefront needs to be <b>Open For Business.</b>&nbsp;
                                  You can change this in the Settings below.
                            </Alert>}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </TabPanel>
              <TabPanel name="button">
                <div className="storefront-url-button-container">
                  <span className="code-copy-message">Copy this code into your website to display a button that opens your storefront.</span>
                  <div className="code-copy-elements">
                    <CopyableTextArea text={ReactDOMServer.renderToStaticMarkup(button)?.replace('&amp;','&')}></CopyableTextArea>
                    <div className="button-container">
                      <div className="dropdown-section">
                        <span className="title">Options</span>
                        <StorefrontUrlButtonForm initialButtonText={this.state.buttonText} updateButtonText={text => this.setState({buttonText: text})}/>
                      </div>
                      <div className="dropdown-section">
                        <span className="title">Preview</span>
                        {button}
                      </div>
                    </div>
                  </div>
                </div>
              </TabPanel>
            </TabGroup>
          </OptionDropdown>
        </OptionsPanel>
      </div>
    )
  }
}

export default connector(StorefrontUrlDetails);
