import React, {FC, useCallback, useEffect, useState} from "react";
import {Icon, Input} from "./shoelace";
import {useDispatch, useSelector} from "react-redux";
import LoadingButton from "./shared/LoadingButton";
import {removeCoupon, updateCouponValue} from "../store/user/coupon/actions";
import {getErrorCode} from "../api/conversions";
import {ErrorCode} from "../api/types";
import {AlertType} from "../store/shared/alert/types";
import {showAlert} from "../store/shared/alert/actions";
import {updateAvailablePlans} from "../store/user/plan/actions";
import {getAlertFromApiErrorResponse} from "../utils/utils";
import type SlInputElement from "@shoelace-style/shoelace/dist/components/input/input";
import {selectCouponValue} from "../store/user/coupon/selectors";

import "./CouponPanel.scss";
import {selectSource} from "../store/persisted/source/selectors";
import {fetchFlexcheckPlans} from "../api/quotes";
import {useTranslation} from "react-i18next";

const CouponPanel: FC = () => {
  const {t} = useTranslation();
  const couponValue = useSelector(selectCouponValue);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [formCouponValue, setFormCouponValue] = useState(couponValue);
  const source = useSelector(selectSource);
  const couponInput = React.useRef<SlInputElement>(null);

  const onSubmit = () => {
    setIsLoading(true);
    fetchFlexcheckPlans({ coupons: [formCouponValue, source], restrictingCoupon: formCouponValue }).then(
      (convertedPlans) => {
        dispatch(updateCouponValue(formCouponValue));
        dispatch(updateAvailablePlans(convertedPlans));
        setIsLoading(false);
      },
      (error) => {
        const errorMessage =
          getErrorCode(error.response.data.code) === ErrorCode.InvalidCoupon ? (
            <b>The coupon code you entered is invalid.</b>
          ) : (
            <b>There was an error submitting the coupon you entered.</b>
          );
        dispatch(
          showAlert({
            type: AlertType.Error,
            message: errorMessage,
          })
        );
        setIsLoading(false);
      }
    );
  };

  const onRemoveCoupon = () => {
    setIsLoading(true);
    fetchFlexcheckPlans({coupons: [source]}).then(
      (convertedPlans) => {
        dispatch(updateAvailablePlans(convertedPlans));
        dispatch(removeCoupon());
        setIsLoading(false);
      },
      (error) => {
        dispatch(
          showAlert(
            getAlertFromApiErrorResponse(
              error.response,
              "There was an error removing the coupon."
            )
          )
        );
        setIsLoading(false);
      }
    );
  };

  const onCouponInputChange = useCallback(() => {
    if (couponInput.current) {
      setFormCouponValue(couponInput.current.value.trim());
    }
  }, [couponInput]);

  const attachListeners = useCallback(() => {
    couponInput.current?.addEventListener("sl-input", onCouponInputChange);
  }, [couponInput, onCouponInputChange]);

  useEffect(() => {
    attachListeners();
  }, [attachListeners]);

  // componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
  //   // If a coupon has just been removed, the input element has just been re-rendered. We need to add the event listener again
  //   if (prevProps.couponValue !== '' && couponValue === '') {
  //     attachListeners();
  //   }
  // }

  return (
    <div className="coupon-panel-container">
      {couponValue ? (
        <div className="coupon-panel">
          <div className="coupon-text-container">
            <span className="coupon-text">
              <Icon
                className="coupon-icon"
                name="tag-fill"
                slot="prefix"
              ></Icon>{" "}
              <b>{couponValue}</b> applied
            </span>
          </div>
          <div className="coupon-remove-button-container">
            <LoadingButton
              className="coupon-remove-button error small"
              isLoading={isLoading}
              onSubmit={onRemoveCoupon}
            >
              Remove
            </LoadingButton>
          </div>
        </div>
      ) : (
        <div className="coupon-panel">
          <div className="coupon-text-container">
            <span className="coupon-text">{t('Do you have a coupon?')}</span>
          </div>
          <div className="coupon-input-container">
            <Input
              className="coupon-input dark always-show-help-text"
              ref={couponInput}
              placeholder="Coupon code"
              disabled={isLoading}
            >
              <Icon
                className="coupon-icon"
                name="tag-fill"
                slot="prefix"
              ></Icon>
            </Input>
          </div>
          <div className="coupon-apply-button-container">
            <LoadingButton
              className="coupon-apply-button secondary small"
              isLoading={isLoading}
              onSubmit={onSubmit}
            >
              Apply
            </LoadingButton>
          </div>
        </div>
      )}
    </div>
  );
};

export default CouponPanel;
