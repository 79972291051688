import React, { FC, useCallback, useEffect, useState } from "react";
import CouponPanel from "../../CouponPanel";
import PlansGrid from "../../PlansGrid";
import { useDispatch, useSelector } from "react-redux";
import { updateCurrency } from "../../../store/user/plan/actions";
import { CustomizedPlan, Plan } from "../../../store/shared/plan/types";
import { planToCustomizedPlan } from "../../../store/shared/plan/utils";
import { showAlert } from "../../../store/shared/alert/actions";
import { AlertType } from "../../../store/shared/alert/types";
import { addToCart, clearCart } from "../../../store/user/cart/actions";
import { selectAvailablePlans } from "../../../store/user/plan/selectors";
import { selectCouponValue } from "../../../store/user/coupon/selectors";
import {
  selectStorefront,
  selectStorefrontAllowCart,
  selectStorefrontAllowCoupons,
} from "../../../store/storefront/selectors";
import {
  resetPlanGroup,
  updatePlanGroups,
} from "../../../store/user/planGroup/actions";
import { useRouteMatch } from "react-router";
import PlanGroupGrid from "../../PlanGroupGrid";
import { selectSource } from "../../../store/persisted/source/selectors";
import {fetchPlanGroups} from "../../../api/storefront";

interface Props {
  loading: boolean;
  error: boolean;
  urlSelectedPlanId: string | null;
  goToNextPage: () => void;
}

const Home: FC<Props> = ({
  loading,
  error,
  urlSelectedPlanId,
  goToNextPage,
}) => {
  const availablePlans = useSelector(selectAvailablePlans);
  const couponCode = useSelector(selectCouponValue);
  const allowCart = useSelector(selectStorefrontAllowCart);
  const allowCoupons = useSelector(selectStorefrontAllowCoupons);
  const storefront = useSelector(selectStorefront);
  const source = useSelector(selectSource);
  const [planGroupsLoading, setPlanGroupsLoading] = useState(true);
  const [planGroupsError, setPlanGroupsError] = useState(false);
  const dispatch = useDispatch();

  const storefrontDisplayType = storefront.displayType;

  const isOnGroupPage = useRouteMatch(
    `/store/${storefront.alias}/group/:planGroup`
  );

  const handleAddToCart = useCallback(
    (plan: CustomizedPlan) => {
      if (allowCart) {
        dispatch(addToCart(plan, [couponCode, source]));
        dispatch(
          showAlert({
            type: AlertType.Success,
            message: [<b>{plan.name}</b>, " has been added to your cart."],
          })
        );
      } else {
        dispatch(clearCart());
        dispatch(addToCart(plan, [couponCode, source]));
        goToNextPage();
      }
    },
    [allowCart, couponCode, source, goToNextPage, dispatch]
  );

  const addSelectedPlanToCart = useCallback(
    (plans: Plan[], id: Plan["bfId"]) => {
      const selectedPlan = plans.find((plan) => plan.bfId === id);
      if (selectedPlan) {
        dispatch(updateCurrency(selectedPlan.baseCurrency));
        handleAddToCart(planToCustomizedPlan(selectedPlan, []));
      }
    },
    [handleAddToCart, dispatch]
  );

  const loadPlanGroups = useCallback(async () => {
    try {
      const planGroups = await fetchPlanGroups();
      dispatch(updatePlanGroups(planGroups));
    } catch (err) {
      setPlanGroupsError(true);
    } finally {
      setPlanGroupsLoading(false);
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(resetPlanGroup());
    loadPlanGroups();
  }, [loadPlanGroups, dispatch]);

  useEffect(() => {
    if (urlSelectedPlanId) {
      addSelectedPlanToCart(availablePlans, urlSelectedPlanId);
    }
  }, [urlSelectedPlanId, availablePlans, addSelectedPlanToCart]);

  return (
    <div className="page">
      {isOnGroupPage || storefrontDisplayType !== "ByGroup" ? (
        <PlansGrid
          handleAddToCart={handleAddToCart}
          error={error}
          loading={loading}
        />
      ) : (
        <PlanGroupGrid loading={planGroupsLoading} error={planGroupsError} />
      )}
      {allowCoupons ? <CouponPanel /> : null}
    </div>
  );
};

export default Home;
