import {combineReducers} from "redux";
import {navigationReducer} from "./navigation/reducers";
import {subscriptionReducer} from "./subscription/reducers";
import {paymentReducer} from "./payment/reducers";
import {accountReducer} from "./account/reducers";

export const portalReducer = combineReducers({
  navigation: navigationReducer,
  subscription: subscriptionReducer,
  payment: paymentReducer,
  account: accountReducer
} );
