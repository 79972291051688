import React, {RefObject} from "react";

import {RootState} from "../../store";
import {connect, ConnectedProps} from "react-redux";
import {Button, Icon, Tooltip} from "../shoelace";
import CopyToClipboard from 'react-copy-to-clipboard';
import type SlTooltipElement from "@shoelace-style/shoelace/dist/components/tooltip/tooltip"

import './CopyableTextArea.scss';

const mapState = (state: RootState) => ({
});

const mapDispatch = {
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
  text: string,
  link?: string | boolean,
  isHtml?: boolean
}

interface State {
  recentlyCopied: boolean
}

class CopyableTextArea extends React.Component<Props, State> {
  private readonly tooltipRef: RefObject<SlTooltipElement>;
  constructor(props: Props) {
    super(props);
    this.state = {
      recentlyCopied: false
    }

    this.tooltipRef = React.createRef<SlTooltipElement>();
  }

  private onCopy = () => {
    this.setState({recentlyCopied: true});
  }

  shouldComponentUpdate(nextProps: Readonly<Props>, nextState: Readonly<State>, nextContext: any): boolean {
    this.forceUpdate(); return true;
  }

  componentDidMount() {
    this.tooltipRef.current!.addEventListener('sl-show', () => this.setState({recentlyCopied: false}));
  }

  render() {
    return (
      <div className="copyable-text-area">
        <Tooltip placement="left" content={this.state.recentlyCopied ? 'Copied!' : 'Copy'} ref={this.tooltipRef}>
          <CopyToClipboard text={this.props.text} onCopy={this.onCopy}>
            <Button className="copy-button tiny icon light border">
              <Icon name="clipboard"></Icon>
            </Button>
          </CopyToClipboard>
        </Tooltip>
        <pre><code className="copyable-text-area-content">
          {this.props.link
            ? <a href={this.props.link === true ? this.props.text : this.props.link} target="_blank" rel="noreferrer">{this.props.text}</a>
            : this.props.text
          }
        </code></pre>
      </div>
    )
  }
}

export default connector(CopyableTextArea);
