// State

import {Subscription} from "../subscription/types";
import {SavedPaymentMethod} from "../../shared/user/types";
import {ApiInvoicePayment} from "../../../api/types";

export type PaymentStatus = 'paid' | 'pending' | 'unpaid' | 'voided';

export type Payment = {
  subscription: Subscription,
  id: string,
  total: number,
  date: number,
  status: PaymentStatus,
  subscriptionName: string,
  paymentMethod?: SavedPaymentMethod,
  paymentAttempts: Array<ApiInvoicePayment>
}

export interface PaymentState {
  payments: Array<Payment>
}

// Actions

export const UPDATE_PAYMENTS = 'UPDATE_PAYMENTS';

interface UpdatePaymentsAction {
  type: typeof UPDATE_PAYMENTS;
  payload: Array<Payment>;
}

export type PaymentActionTypes = UpdatePaymentsAction;
