import React, { FC, ReactNode, useState } from "react";
import { Badge, Button, Icon } from "../shoelace";
import AnimateHeight from "react-animate-height";
import { Link } from "react-router-dom";
import clsx from "clsx";

import "./PortalPanel.scss";

type Breadcrumb = {
  title: string;
  link?: string;
};

interface Props {
  title?: string;
  breadcrumbs?: Array<Breadcrumb>;
  link?: string;
  filters?: ReactNode;
  filtersActive?: number;
  children?: ReactNode;
  topRight?: ReactNode;
  className?: string;
}

const PortalPanel: FC<Props> = ({
  title,
  breadcrumbs,
  link,
  filters,
  filtersActive,
  topRight,
  children,
  className,
}) => {
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  const toggleFiltersOpen = () => {
    setIsFiltersOpen((state) => !state);
  };

  const getFilterCountBadge = () =>
    !filtersActive || filtersActive === 0 ? null : (
      <Badge variant="danger" pill>
        {filtersActive}
      </Badge>
    );

  return (
    <div className={clsx("portal-panel", className)}>
      <div className="portal-panel-header">
        {breadcrumbs ? (
          <div className="portal-panel-breadcrumbs">
            {breadcrumbs.map((crumb, i) => {
              const isLast = breadcrumbs && i === breadcrumbs.length - 1;
              return (
                <div
                  className="portal-panel-breadcrumb-section"
                  key={crumb.title}
                >
                  {crumb.link ? (
                    <Link
                      to={crumb.link ?? null}
                      className={`portal-panel-breadcrumb ${
                        isLast ? "" : "small"
                      }`}
                    >
                      {crumb.title}
                    </Link>
                  ) : (
                    <span
                      className={clsx('portal-panel-breadcrumb', !isLast && 'small')}
                    >
                      {crumb.title}
                    </span>
                  )}
                  {isLast ? null : <Icon name="caret-right-fill" />}
                </div>
              );
            })}
          </div>
        ) : (
          <h2 className="portal-panel-title portal-panel-only-title">
            {title}
          </h2>
        )}
        <div className="portal-panel-top-right">
          {filters ? (
            <>
              <Button
                className={`portal-panel-filter-button secondary small custom rotate-caret ${
                  isFiltersOpen ? "open" : ""
                }`}
                caret
                onClick={toggleFiltersOpen}
              >
                <Icon slot="prefix" name="funnel-fill" />
                Filters
                {getFilterCountBadge()}
              </Button>
              <Button
                className={`portal-panel-filter-button secondary tiny icon custom ${
                  isFiltersOpen ? "open" : ""
                }`}
                onClick={toggleFiltersOpen}
              >
                <Icon name="funnel-fill" />
                {getFilterCountBadge()}
              </Button>
            </>
          ) : null}
          {topRight}
        </div>
      </div>
      {filters && <AnimateHeight duration={100} height={isFiltersOpen ? "auto" : 0}>
        <div className="portal-panel-filters">
          <div className="portal-panel-filters-content">{filters}</div>
        </div>
      </AnimateHeight>}
      <div className="portal-panel-content">{children}</div>
    </div>
  );
};

export default PortalPanel;
