import React, { FC } from "react";
import { ReactComponent as Logo } from "../assets/bf_logo.svg";

import "./LoginHeader.scss";


const LoginHeader: FC = () => {
  return (
    <header className="login-header">
      <Logo className="logo" />
      <h1 className="title">Flexcheck</h1>
    </header>
  );
};

export default LoginHeader;
