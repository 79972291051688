import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import clsx from "clsx";
import { Icon } from "./shoelace";
import { MERCHANT_MENU_ROUTES } from "../config/routes";
import {
  selectStorefront,
  selectStorefrontAlias,
  selectStorefrontTrading,
} from "../store/storefront/selectors";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { LARGE_SCREEN } from "../config/constants";
import { updateSelectedPlanToAdd } from "../store/merchant/plan/actions";
import { BfApiPlan } from "../api/types";
import styles from "./MerchantSidebar.module.scss";
import { closePanelStart } from "../store/panel/actions";

interface Props {
  isShowing: boolean;
  hideMenu: () => void;
  handleLogout: () => void;
}

const MerchantSidebar: FC<Props> = ({ isShowing, hideMenu, handleLogout }) => {
  const storefront = useSelector(selectStorefront);
  const storefrontAlias = useSelector(selectStorefrontAlias);
  const trading = useSelector(selectStorefrontTrading);
  const location = useLocation();
  const windowDimensions = useWindowDimensions();
  const currentPath = location.pathname;
  const dispatch = useDispatch();

  const closeSidePanel = () => {
    dispatch(updateSelectedPlanToAdd({} as BfApiPlan));
    dispatch(closePanelStart());
  };

  return (
    <aside
      className={clsx(styles["merchant-sidebar"], isShowing && styles.showing)}
    >
      <button className={styles["merchant-close-button"]} onClick={hideMenu}>
        <Icon name="x-lg" />
      </button>
      <div className={styles["merchant-menu-title"]}>
        {storefront.logoUrl ? (
          <img
            className={styles["merchant-logo"]}
            src={storefront.logoUrl}
            alt=""
          />
        ) : (
          storefrontAlias
        )}
      </div>
      <div
        className={clsx(
          styles["trading-status"],
          trading ? styles.open : styles.closed
        )}
      >
        <Icon name="circle-fill" />
        <span className={styles["trading-status-text"]}>
          store: {trading ? "Open" : "Closed"}
        </span>
      </div>
      <ul className={styles["merchant-sidebar-menu"]}>
        {MERCHANT_MENU_ROUTES.map((route) => {
          return (
            <li key={route.label} className={styles["menu-item-container"]}>
              <Link
                onClick={() => {
                  if (windowDimensions.width < LARGE_SCREEN) {
                    hideMenu();
                  }
                  closeSidePanel();
                }}
                to={route.path}
                className={clsx(
                  styles["merchant-menu-item"],
                  route.path === currentPath && styles.active
                )}
                key={route.path}
              >
                <Icon slot="prefix" name={route.icon} />
                <span className={styles["menu-item-name"]}>{route.label}</span>
              </Link>
            </li>
          );
        })}
        <li className={clsx(styles["menu-item-container"], styles.advanced)}>
          Advanced options:
          <a
            className={clsx(
              styles["merchant-menu-item"],
              styles["merchant-menu-link"]
            )}
            href={window.REACT_APP_BFJS_API_URL}
          >
            <Icon name="box-arrow-up-right" />
            <span className={styles["menu-item-name"]}>Billforward</span>
          </a>
        </li>
        <li className={styles["menu-item-container"]}>
          <button
            className={clsx(
              styles["merchant-menu-item"],
              styles["merchant-menu-button"]
            )}
            onClick={handleLogout}
          >
            <Icon slot="prefix" name="box-arrow-left" />
            <span className={styles["menu-item-name"]}>Log out</span>
          </button>
        </li>
      </ul>
    </aside>
  );
};

export default MerchantSidebar;
