import {ResetUserAction} from "../../user/resetUser";

// State

export type AlertMessage = string | JSX.Element | Array<string | JSX.Element>

export enum AlertType {
  Error,
  Warning,
  Success,
  Info
}

export interface Alert {
  type: AlertType,
  message: AlertMessage,
  id: string
}

export interface AlertState {
  lastAlert: Alert
}

// Actions
export const SHOW_ALERT = 'SHOW_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';

export type ShowAlertActionPayload = {
  type: AlertType,
  message: AlertMessage
}

interface ShowAlertAction {
  type: typeof SHOW_ALERT;
  payload: ShowAlertActionPayload;
}

interface RemoveAlertAction {
  type: typeof REMOVE_ALERT;
  payload: string;
}

export type AlertActionTypes = ShowAlertAction | RemoveAlertAction | ResetUserAction;
