import React, {FC} from "react";
import {useSelector} from "react-redux";
import PlanCard from "./PlanCard";
import {CustomizedPlan, Plan} from "../store/shared/plan/types";
import {Icon} from "./shoelace";
import PlanCardSkeleton from "./PlanCardSkeleton";
import PlanCardMarkdown from "./PlanCardMarkdown";
import {selectAvailablePlans, selectCurrency,} from "../store/user/plan/selectors";
import {selectBonusPlanCard, selectStorefront,} from "../store/storefront/selectors";
import {selectPlanGroup, selectPlanGroups,} from "../store/user/planGroup/selectors";
import {isPlanInGroup} from "../utils/utils";
import {PlanGroup} from "../store/user/planGroup/types";
import {useRouteMatch} from "react-router";

import "./PlansGrid.scss";

interface Props {
  handleAddToCart: (plan: CustomizedPlan) => void;
  error?: boolean;
  loading: boolean;
}

const getHasPlanGroup = (plan: Plan, planGroups: PlanGroup[]) => {
  return !!planGroups.find(
    (planGroup) =>
        isPlanInGroup(plan, planGroup)
  );
};

// TODO: PlansGrid should just be given plans to render, not do any logic

const PlansGrid: FC<Props> = ({ loading, error, handleAddToCart }) => {
  const availablePlans = useSelector(selectAvailablePlans);
  const planGroups = useSelector(selectPlanGroups);
  const currency = useSelector(selectCurrency);
  const bonusPlanCard = useSelector(selectBonusPlanCard);
  const planGroup = useSelector(selectPlanGroup);
  const storefront = useSelector(selectStorefront);

  const isOnGroupPage = useRouteMatch(
    `/store/${storefront.alias}/group/:planGroup`
  );

  const plansToRender = availablePlans
    .filter(
      (plan: Plan) =>
        plan.baseCurrency === currency &&
        (planGroup?.bfPlanPathPrefix
          ? isPlanInGroup(plan, planGroup)
          : true)
    )
    .sort((planA: Plan, planB: Plan) => {
      return planA.displayIndex - planB.displayIndex;
    });

  if (loading) {
    return (
      <div className="plans-grid">
        {[0, 1, 2, 3, 4, 5].map(key => <PlanCardSkeleton key={key} />)}
      </div>
    );
  }

  if (error || plansToRender.length === 0) {
    return (
      <div className="plans-grid plans-grid-error">
        <Icon className="error-icon" name="exclamation-circle-fill" />
        {error ? (
          <span className="error-message">
            There was a problem fetching the available plans. Please try again
            later.
          </span>
        ) : (
          <span className="error-message">
            {availablePlans.length === 0
              ? "No plans are available to purchase."
              : "No plans are available for this currency."}
          </span>
        )}
      </div>
    );
  }


  return (
    <div className="plans-grid">
      {plansToRender.map((plan) => {
        const hasPlanGroup = getHasPlanGroup(plan, planGroups);

        return (
          <PlanCard
            plan={plan}
            handleBuy={handleAddToCart}
            key={plan.bfId}
            showBuyBtn={storefront.displayType !== "ByGroup" || !hasPlanGroup || !!isOnGroupPage}
            showMoreBtn={
              storefront.displayType !== "ByPlan" &&
              !isOnGroupPage &&
              hasPlanGroup
            }
          >
            <PlanCardMarkdown
              className="details-markdown"
              content={plan.cardText}
            />
          </PlanCard>
        );
      })}
      {bonusPlanCard}
    </div>
  );
};

export default PlansGrid;
