import {PricingComponent} from "../api/types";
import React, {RefObject} from "react";
import {Checkbox, Input} from "./shoelace";
import {RootState} from "../store";
import {connect, ConnectedProps} from "react-redux";
import type SlInputElement from "@shoelace-style/shoelace/dist/components/input/input";
import SlCheckboxElement from "@shoelace-style/shoelace/dist/components/checkbox/checkbox";

import './PricingComponentQuantityInput.scss';

const mapState = (state: RootState) => ({
});

const mapDispatch = {
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

type InputOrCheckbox = SlInputElement | SlCheckboxElement

interface Props extends PropsFromRedux {
  pricingComponent: PricingComponent,
  customInitialQuantity?: number,
  updateQuantity: (newQuantity: number, immediate: boolean) => Promise<boolean>,
  isLoading?: boolean,
  fixed?: boolean
  label?: string
}
interface State {
  quantity: string,
  lastValidQuantity: string
}

class PricingComponentQuantityInput extends React.Component<Props, State> {
  private readonly quantityRef: RefObject<InputOrCheckbox>;
  private readonly minQuantity: number;
  private readonly maxQuantity: number | undefined;
  private readonly displayType: 'fixed' | 'checkbox' | 'input';
  private readonly initialQuantity: string;

  constructor(props: Props) {
    super(props);

    this.initialQuantity = this.props.customInitialQuantity !== undefined
      ? this.props.customInitialQuantity.toString()
      : "defaultQuantity" in props.pricingComponent && props.pricingComponent.defaultQuantity
        ? props.pricingComponent.defaultQuantity.toString()
        : "0"

    this.state = {
      quantity: this.initialQuantity,
      lastValidQuantity: this.initialQuantity
    }

    this.quantityRef = React.createRef<InputOrCheckbox>();
    this.minQuantity = this.getMinQuantity();
    this.maxQuantity = this.getMaxQuantity();

    if (this.props.fixed || this.minQuantity === this.maxQuantity) {
      this.displayType = 'fixed';
    } else if (this.minQuantity === 0 && this.maxQuantity === 1) {
      this.displayType = 'checkbox';
    } else {
      this.displayType = 'input';
    }
  }

  private getMaxQuantity = () => {
    if ("tiers" in this.props.pricingComponent) {
      const hasMaxQuantity = this.props.pricingComponent.tiers.filter(tier => tier.to === undefined || tier.to === null).length === 0;
      if (hasMaxQuantity) {
        const tierMaxQuantities = this.props.pricingComponent.tiers.map(tier => (tier.to as number));
        return Math.max.apply(null, tierMaxQuantities);
      }
    }
    return undefined;
  }

  private getMinQuantity = () => {
    if ("tiers" in this.props.pricingComponent) {
      const tierMinQuantities = this.props.pricingComponent.tiers.map(tier => (tier.from as number));
      return Math.min.apply(null, tierMinQuantities);
    }
    return 0;
  }

  componentDidMount() {
    if (this.displayType === 'input') {
      this.quantityRef.current!.addEventListener('sl-input', () => {
        const newQuantity = this.quantityRef.current!.value;
        this.handleNewQuantity(newQuantity);
      });
    }

    if (this.displayType === 'checkbox') {
      this.quantityRef.current!.addEventListener('sl-change', () => {
        const elem = this.quantityRef.current! as SlCheckboxElement;
        const newQuantity = elem.checked ? elem.value : "0";
        this.handleNewQuantity(newQuantity);
      });
    }
  }

  private handleNewQuantity(newQuantity: string) {
    this.setState({quantity: newQuantity});
    this.props.updateQuantity(Number(newQuantity), this.displayType === 'checkbox').then(success => {
      if (success) {
        this.setState({lastValidQuantity: newQuantity});
      } else {
        this.setState(state => ({quantity: state.lastValidQuantity}));
      }
    });
  }

  shouldComponentUpdate(nextProps: Readonly<Props>, nextState: Readonly<State>, nextContext: any): boolean {
    this.forceUpdate();
    return true;
  }

  render() {
    return (<div className="pricing-component-quantity-input-container">
      {!this.props.label || this.displayType !== 'fixed' ? null :
          <div className="quantity-label">{this.props.label}</div>
      }
      {this.displayType !== 'fixed' ? null :
          <div className="quantity-fixed">{this.state.quantity} {this.props.pricingComponent.unit}</div>
      }
      {this.displayType !== 'input' ? null :
          <Input
              name="quantity"
              className={`quantity-input light ${this.state.quantity !== this.initialQuantity ? 'different' : ''}`}
              type="number"
              min={this.getMinQuantity()}
              max={this.getMaxQuantity()}
              value={this.state.quantity}
              ref={this.quantityRef}
              disabled={this.props.isLoading}>
            <div slot="suffix" className="quantity-unit">{this.props.pricingComponent.unit}</div>
            {!this.props.label ? null :
                <div slot="label" className="quantity-label">{this.props.label}</div>
            }
          </Input>
      }
      {this.displayType !== 'checkbox' ? null :
          <Checkbox
              className={`quantity-input light ${this.state.quantity !== this.initialQuantity ? 'different' : ''}`}
              name="checkbox"
              value={"1"}
              ref={this.quantityRef}
              checked={this.state.quantity === "1"}
              disabled={this.props.isLoading}>
            {!this.props.label ? null :
                <div className="quantity-label">{this.props.label}</div>
            }
          </Checkbox>
      }
      </div>)
  }
}

export default connector(PricingComponentQuantityInput);
