import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { applyMiddleware, createStore } from "redux";
import { persistStore } from "redux-persist";
import { Provider } from "react-redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { PersistGate } from "redux-persist/integration/react";
import thunk from "redux-thunk";
import { HelmetProvider } from "react-helmet-async";
import { rootPersistedReducer } from "./store";
import App from "./App";
import { cartWatcher } from "./store/user/cart/watchers";
import { styleWatcher } from "./store/storefront/watchers";
import { panelWatcher } from "./store/panel/watchers";

import "normalize.css";
import "./index.scss";

const middlewares = [thunk, cartWatcher, styleWatcher, panelWatcher];
export const store = createStore(
  rootPersistedReducer,
  composeWithDevTools(applyMiddleware(...middlewares))
);
const persistor = persistStore(store);

// Render
document.addEventListener("DOMContentLoaded", (event) => {
  ReactDOM.render(
    <React.StrictMode>
      <HelmetProvider>
        <Provider store={store}>
          <PersistGate persistor={persistor}>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </PersistGate>
        </Provider>
      </HelmetProvider>
    </React.StrictMode>,
    document.getElementById("root")
  );
});
