import React, { ReactNode, UIEvent } from "react";
import { Button, Icon } from "../shoelace";
import { RootState } from "../../store";
import { connect, ConnectedProps } from "react-redux";

import "./LoadingButton.scss";

const mapState = (state: RootState) => ({
  storefrontType: state.main.storefront.storefront.type,
});
const connector = connect(mapState);
type PropsFromRedux = ConnectedProps<typeof connector>;

type LoadingButtonState = {
  onSubmitWithLoading: (event: UIEvent) => void;
};

interface LoadingButtonProps extends PropsFromRedux {
  onSubmit?: Function;
  submit?: boolean;
  errorMessage?: string;
  isLoading: boolean;
  disabled?: boolean;
  className?: string;
  children?: ReactNode;
  noCustomStyle?: boolean;
}

class LoadingButton extends React.Component<
  LoadingButtonProps,
  LoadingButtonState
> {
  constructor(props: LoadingButtonProps) {
    super(props);
    // ? Why is this function in state? I don't see it being updated
    // TODO: remove from state
    this.state = {
      onSubmitWithLoading: (event: UIEvent) => {
        event.preventDefault();
        if (this.props.onSubmit) this.props.onSubmit();
        this.forceUpdate();
      },
    };
  }

  shouldComponentUpdate(): boolean {
    this.forceUpdate();
    return true;
  }

  private onSubmit = (event: UIEvent) => {
    event.preventDefault()
    this.props.onSubmit && this.props.onSubmit()
    this.forceUpdate()
  }

  render() {
    return (
      <div className="loading-button-container">
        {this.props.errorMessage && !this.props.isLoading ? (
          <Icon className="error-icon" name="exclamation-circle-fill" />
        ) : null}
        {this.props.errorMessage && !this.props.isLoading ? (
          <span className="error-message">{this.props.errorMessage}</span>
        ) : null}
        <Button
          type={this.props.submit ? "submit" : "button"}
          className={`${
            this.props.className ? this.props.className : "submit"
          } ${
            this.props.storefrontType === "BfAccountingOrg" ||
            this.props.noCustomStyle
              ? ""
              : "custom"
          }`}
          onClick={this.onSubmit}
          disabled={this.props.disabled || false}
          loading={this.props.isLoading}
        >
          {this.props.children}
        </Button>
      </div>
    );
  }
}

export default connector(LoadingButton);
