import { Button, Dropdown, Icon, Menu, MenuItem, Spinner } from "../shoelace";
import OptionDropdown from "../OptionDropdown";
import React, { FC, ReactNode, useCallback, useEffect, useState } from "react";
import { showAlert } from "../../store/shared/alert/actions";
import { useDispatch, useSelector } from "react-redux";
import {
  gatewayDirectory,
  PaymentGateway,
  PaymentGatewayName,
  PaymentGatewayStatus,
} from "../../store/storefront/types";
import { apiGetAvailableGateways } from "../../api";
import { getAlertFromApiErrorResponse, getBfAppUrl } from "../../utils/utils";
import SquareLocationSelect from "./SquareLocationSelect";
import { selectStorefront } from "../../store/storefront/selectors";

import "./PaymentGatewayOptions.scss";
import { ApiError } from "../../api/types";

interface Props {
  updatePaymentGateway: (
    gatewayName: string,
    gatewayStatus: PaymentGatewayStatus,
    initial: boolean
  ) => void;
  updateSquareLocation: (locationId: string | null, initial: boolean) => void;
}

const PaymentGatewayOptions: FC<Props> = ({
  updatePaymentGateway,
  updateSquareLocation,
}) => {
  const storefront = useSelector(selectStorefront);
  const dispatch = useDispatch();
  const [currentGateway, setCurrentGateway] = useState(
    gatewayDirectory[storefront.paymentGateway as PaymentGatewayName]
  );
  const [availableGatewaysState, setAvailableGatewaysState] = useState<
    PaymentGateway[]
  >([]);
  const [currentGatewayStatus, setCurrentGatewayStatus] =
    useState<PaymentGatewayStatus>(null);
  const [isLoading, setIsLoading] = useState(true);

  const getGatewayStatus = useCallback(
    (
      gateway: PaymentGateway,
      availableGateways: PaymentGateway[]
    ): PaymentGatewayStatus => {
      if (!gateway || gateway.name === null)
        return null;
      return availableGateways.map((g) => g.name).includes(gateway.name)
        ? "active"
        : "inactive";
    },
    []
  );

  const onSelect = (gateway: PaymentGateway) => {
    setCurrentGateway(gateway);
    setCurrentGatewayStatus(getGatewayStatus(gateway, availableGatewaysState));
    updatePaymentGateway(gateway.name, currentGatewayStatus, false);
  };

  const getGatewayImg = (gateway: PaymentGateway): ReactNode => {
    return gateway && gateway.logo ? (
      <img
        className="payment-gateway-logo"
        src={gateway.logo}
        slot="prefix"
        alt={`${gateway.name} logo`}
      />
    ) : (
      <Icon
        className="payment-gateway-icon"
        name="credit-card-2-back-fill"
        slot="prefix"
      />
    );
  };

  const getGateways = useCallback(async () => {
    try {
      const gatewaysResponse = await apiGetAvailableGateways();
      const availableGateways = gatewaysResponse.data.liveGateways
        .filter((gateway) => gatewayDirectory[gateway as PaymentGatewayName])
        .map(
          (gatewayName: string) =>
            gatewayDirectory[gatewayName as PaymentGatewayName]
        );
      if (availableGateways.length < 1) {
        throw new Error(
          "No valid payment gateways. Please connect a Stripe, Square or Shuttle payment gateway."
        );
      }

      setAvailableGatewaysState(availableGateways);
      setCurrentGatewayStatus(
        getGatewayStatus(currentGateway, availableGateways)
      );
      updatePaymentGateway(currentGateway?.name, currentGatewayStatus, true);
    } catch (error) {
      console.warn(error);
      dispatch(
        showAlert(
          getAlertFromApiErrorResponse(
            error as ApiError | Response,
            "Could not retrieve a list of available payment gateways."
          )
        )
      );
    } finally {
      setIsLoading(false);
    }
  }, [
    currentGateway,
    currentGatewayStatus,
    getGatewayStatus,
    updatePaymentGateway,
    dispatch,
  ]);

  useEffect(() => {
    getGateways();
  }, [getGateways]);

  return (
    <OptionDropdown
      icon="credit-card-2-back-fill"
      title="Payment Gateway"
      className="payment-gateway-options"
    >
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="payment-gateway-options-top-row">
          <div className="payment-gateway-options-section">
            <Dropdown className="payment-gateway-dropdown">
              {currentGateway?.name != null /* note single = checks for undefined too */ ? (
                <Button
                  slot="trigger"
                  caret
                  className="payment-gateway-button icon"
                >
                  {getGatewayImg(currentGateway)}
                  {currentGateway.name}
                </Button>
              ) : (
                <Button
                  slot="trigger"
                  caret
                  className="payment-gateway-button icon"
                >
                  Select gateway...
                </Button>
              )}
              <Menu>
                {availableGatewaysState.map((gateway) =>
                    gateway?.name != null /* note single = checks for undefined too */ ? (
                  <MenuItem
                    className="payment-gateway-menu-item"
                    onClick={() => onSelect(gateway)}
                    key={gateway.name}
                  >
                    {getGatewayImg(gateway)}
                    {gateway.name}
                  </MenuItem>
                ) : null)}
              </Menu>
            </Dropdown>
            <div className={`payment-gateway-message ${currentGatewayStatus}`}>
              {currentGatewayStatus === null ? null : (
                <Icon name="circle-fill" />
              )}
              {currentGatewayStatus === null ? (
                <span>
                  Please select a payment gateway to use for collecting
                  payments.
                </span>
              ) : (
                <span>
                  <b>{currentGateway.name}</b> is{" "}
                  {currentGatewayStatus === "active" ? "" : "not"} active.
                </span>
              )}
            </div>
          </div>
          <div className="payment-gateway-link-section">
            <Button
              className="secondary small"
              href={`${getBfAppUrl()}#/setup/personal/payment-gateways/`}
              target="_blank"
            >
              Manage Gateways
            </Button>
          </div>
        </div>
      )}
      {currentGateway?.name === "Square" ? (
        <div className="payment-gateway-options-additional">
          <span className="title">{currentGateway.name} Options</span>
          <div className="content">
            <SquareLocationSelect updateSquareLocation={updateSquareLocation} />
          </div>
        </div>
      ) : null}
    </OptionDropdown>
  );
};

export default PaymentGatewayOptions;
