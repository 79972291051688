import React from "react";
import './FormSectionHeader.scss';

const FormSectionHeader = (props: {title: string}) => (
  <div className="form-section-header">
    <div className="header-text">{props.title}</div>
    <div className="header-rule"/>
  </div>)

export default FormSectionHeader;
