import React, {ReactNode} from "react";

import {connect, ConnectedProps} from "react-redux";
import {Redirect} from "react-router-dom";
import {merchantLogout} from "../store/persisted/auth/actions";
import {updateStorefront} from "../store/storefront/actions";
import {RootState} from "../store";
import {resetDefaultParams} from "../api";
import SpinnerPage from "./shared/SpinnerPage";
import {fetchStorefront} from "../api/storefront";

const mapState = (state: RootState) => ({
  merchantIsLoggedIn: state.main.user.auth.merchantIsLoggedIn,
  storefront: state.main.storefront.storefront
});

const mapDispatch = {
  merchantLogout: merchantLogout,
  updateStorefront: updateStorefront
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
  children?: ReactNode
}

type State = {
  isLoading: boolean,
  redirectToLogin: boolean
}


class MerchantLoginCheckWrapper extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isLoading: true,
      redirectToLogin: false
    }
  }

  componentDidMount() {
    resetDefaultParams();
    if (this.props.merchantIsLoggedIn) {
      fetchStorefront(undefined, false).then(response => {
        this.setState({isLoading: false});
      }, error => {
        this.props.merchantLogout();
        this.setState({
          isLoading: false,
          redirectToLogin: true
        });
      });
    } else {
      this.setState({
        isLoading: false,
        redirectToLogin: true
      });
    }
  }

  render() {
    return (
      this.state.isLoading
        ? <SpinnerPage></SpinnerPage>
        : this.state.redirectToLogin
          ? <Redirect to={{pathname: "/login"}}/>
          : this.props.children
    )
  };
}

export default connector(MerchantLoginCheckWrapper);
