import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import ListIcon from "../assets/icons/list-icon.svg";
import PlanActiveCheckbox from "./PlanActiveCheckbox";
import { Plan } from "../store/shared/plan/types";
import { Icon } from "./shoelace";
import { formatPrice, getProductNameFromPath } from "../utils/utils";
import { useDispatch } from "react-redux";
import { openPanelStart } from "../store/panel/actions";
import { PanelTypes } from "../store/panel/types";

import "./SortablePlansList.scss";

const DragHandle = SortableHandle(() => (
  <td className="drag-icon">
    <div className="container">
      <img src={ListIcon} alt="Sort handle" />
    </div>
  </td>
));

const SortablePlanRow = SortableElement(({ plan }: { plan: Plan }) => {
  const dispatch = useDispatch();
  const openEditPlanPanel = () => {
    dispatch(
      openPanelStart({ planId: plan.id, panelType: PanelTypes.EditPlan })
    );
  };
  const periodStr = plan.oneOffPayment ? `` : ` / ${plan.period}`;
  return (
    <tr className="plans-table-row plans-sortable-row">
      <DragHandle />
      <td className="enabled">
        <PlanActiveCheckbox plan={plan} />
      </td>
      <td className="path">{getProductNameFromPath(plan.bfPlanPath)}</td>
      <td className="name">{plan.name}</td>
      {plan.price.pricePerPeriod === undefined ? (
        <td className="price price-error">
          <Icon className="error-icon" name="exclamation-circle" />
          Price not found
        </td>
      ) : (
        <td className="price">
          {formatPrice(plan.price.pricePerPeriod, plan.baseCurrency)}
          {periodStr}
        </td>
      )}
      <td className="controls">
        <div className="controls-container">
          <button
            className="plan-edit-button neutral tiny icon"
            onClick={openEditPlanPanel}
          >
            Edit plan
          </button>
        </div>
      </td>
    </tr>
  );
});

const SortablePlansList = SortableContainer(
  ({ plans }: { plans: Array<Plan> }) => {
    return (
      <tbody className={"sortable-plans-list"}>
        {plans.map((plan, i) => (
          <SortablePlanRow plan={plan} index={i} key={i} />
        ))}
      </tbody>
    );
  }
);

export default SortablePlansList;
