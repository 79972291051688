import React, {RefObject} from "react";
import {connect, ConnectedProps} from "react-redux";
import {Button, Dialog, Icon} from "../shoelace";
import {CompositeUpgradeQuote} from "../../api/types";
import {RootState} from "../../store";

import './SubscriptionUpgradeDialog.scss';
import {Subscription} from "../../store/portal/subscription/types";
import moment from "moment";
import PriceText from "../PriceText";
import {CustomizedPricingComponent} from "../../store/shared/plan/types";
import {InitialQuantitiesMap, QuoteQuantitiesMap} from "./SubscriptionManageSection";
import {apiUpgradeSubscription} from "../../api/portalEndpoints";
import {showAlert} from "../../store/shared/alert/actions";
import {getAlertFromApiErrorResponse} from "../../utils/utils";
import {AlertType} from "../../store/shared/alert/types";
import LoadingButton from "../shared/LoadingButton";
import SlDialogElement from "@shoelace-style/shoelace/dist/components/dialog/dialog";

const mapState = (state: RootState) => ({
});

const mapDispatch = {
  showAlert: showAlert
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
  subscription: Subscription,
  initialSubscriptionQuantities: InitialQuantitiesMap,
  customizations: Array<CustomizedPricingComponent>,
  upgradeQuote: CompositeUpgradeQuote,
  quantitiesFromQuote: QuoteQuantitiesMap,
  onCancel: () => void,
  open: boolean
}

type State = {
  isLoading: boolean
}

class SubscriptionUpgradeDialog extends React.Component<Props, State> {
  private readonly dialogRef: RefObject<SlDialogElement>;

  constructor(props: Props) {
    super(props);

    this.state = {
      isLoading: false
    }

    this.dialogRef = React.createRef<SlDialogElement>();
  }

  private getUpgradeCost = () => (this.props.upgradeQuote.upgradeQuote.total >= 0) ? this.props.upgradeQuote.upgradeQuote.total : 0

  private onConfirmCheckout = () => {
    this.setState({isLoading: true});
    apiUpgradeSubscription(
      this.props.subscription.id,
      this.props.customizations.map(c => ({name: c.name, value: c.chosenQuantity}))
    ).then(() => {
      this.props.showAlert({
        type: AlertType.Success,
        message: 'Your subscription has been upgraded.'
      });
      this.setState({isLoading: false});
      this.props.onCancel();
    }, error => {
      this.props.showAlert(getAlertFromApiErrorResponse(error, 'There was a problem upgrading your subscription.'))
      this.setState({isLoading: false});
    });
  };

  componentDidMount() {
    this.dialogRef.current!.addEventListener('sl-hide', () => this.props.onCancel());
  }

  render() {
    return (
      <Dialog label="Update Subscription" className="subscription-upgrade-dialog" open={this.props.open} ref={this.dialogRef}>
        <div className="subscription-upgrade-dialog-content">
          <span>You are making the following changes to your subscription:</span>
          <div className="upgrade-summary">
            {this.props.customizations.map(customization => (
              // <div className="upgrade-summary-line" key={customization.name}>
              <React.Fragment key={customization.name}>
                <span className="upgrade-summary-line-name">{customization.name}</span>
                <span
                  className="upgrade-summary-line-old-value">{this.props.initialSubscriptionQuantities[customization.name] ?? 0}</span>
                <div className="upgrade-summary-line-arrow">
                  <Icon name="arrow-right"/>
                </div>
                <span className="upgrade-summary-line-new-value">{customization.chosenQuantity}</span>
              </React.Fragment>
              // </div>
            ))}
          </div>
          <div className="upgrade-sentence">
            <span>Since it's <b>{moment.unix(this.props.subscription.currentPeriodEnd ?? moment().valueOf()).diff(moment(), 'days')} days</b> until your next billing date, you'll be charged</span>
            <PriceText
              currentPrice={this.getUpgradeCost()}
              currency={this.props.subscription.plan.baseCurrency}
              isSentence
            />
            <span>to upgrade your subscription immediately.</span>
          </div>
          <div className="upgrade-sentence">
            <span>Then you'll be charged</span>
            <PriceText
              currentPrice={this.props.upgradeQuote.recurringQuote.total}
              currency={this.props.subscription.plan.baseCurrency}
              isSentence
            />
            <span>every <b>{this.props.subscription.plan.period}</b> from your next billing date.</span>
          </div>
          <div className="upgrade-totals">
            <div className="upgrade-total">
              <span>Total To Pay Now</span>
              <div className="upgrade-total-line"/>
              <PriceText currentPrice={this.getUpgradeCost()} currency={this.props.subscription.plan.baseCurrency}/>
            </div>
            <div className="upgrade-total">
              <span>New Recurring Cost</span>
              <div className="upgrade-total-line"/>
              <PriceText currentPrice={this.props.upgradeQuote.recurringQuote.total}
                         currency={this.props.subscription.plan.baseCurrency}/>
            </div>
            <div className="upgrade-total-period-text"><span>per {this.props.subscription.plan.period}</span></div>
          </div>
        </div>
        <div slot="footer" className="dialog-buttons">
          <Button className={"neutral custom"} onClick={this.props.onCancel}>Cancel</Button>
          <LoadingButton className={"submit custom"} onSubmit={this.onConfirmCheckout} isLoading={this.state.isLoading}>Confirm and pay</LoadingButton>
        </div>
      </Dialog>
    );
  }
}

export default connector(SubscriptionUpgradeDialog);

