import React, {FC} from "react";
import {TabPanel} from "../shoelace";
import {Subscription} from "../../store/portal/subscription/types";
import SubscriptionManageSection, {InitialQuantitiesMap} from "./SubscriptionManageSection";
import {excludeComponents, pricingToPricingComponentList} from "../../store/shared/plan/utils";
import {StandalonePricingComponent} from "../../api/types";
import {useSelector} from "react-redux";
import {selectStorefront} from "../../store/storefront/selectors";


interface Props {
  subscription: Subscription;
  setIsResumeOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsPauseOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsCancelOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const SubscriptionManageTabPanel: FC<Props> = ({
                                                 subscription,
                                                 setIsResumeOpen,
                                                 setIsPauseOpen,
                                                 setIsCancelOpen
                                               }) => {

  const storefront = useSelector(selectStorefront);

  const pricingComponentsList = pricingToPricingComponentList(
      subscription.plan.pricing
  );

  //Setup is not customisable here because the subscription is already started.
  const customizablePricingComponentsList = excludeComponents(
      pricingComponentsList,
      ["setup", "usage"],
      ["flat"]
  );

  const findCustomInitialQuantityOrUndefined = (
      pricingComponentName: string
  ) => {
    let component = subscription.pricingComponentValues.find(
        (p) => p.pricingComponentName === pricingComponentName
    );
    if (component) return component.value;
  };

  const getSubscriptionQuantities = (
      pricingComponentsList: Array<StandalonePricingComponent>
  ) => {
    let quantities: InitialQuantitiesMap = {};
    for (let c of pricingComponentsList) {
      quantities[c.name] = findCustomInitialQuantityOrUndefined(c.name);
    }
    return quantities;
  };

  const initialSubscriptionQuantities = getSubscriptionQuantities(
      pricingComponentsList
  );

  if (customizablePricingComponentsList.length !== 0 ||
      storefront.showSubscriptionControls) {
    return (<TabPanel name="manage">
      <SubscriptionManageSection subscription={subscription}
                                 setIsResumeOpen={setIsResumeOpen}
                                 setIsPauseOpen={setIsPauseOpen}
                                 setIsCancelOpen={setIsCancelOpen}
                                 customizablePricingComponentsList={customizablePricingComponentsList}
                                 initialSubscriptionQuantities={initialSubscriptionQuantities}
                                 pricingComponentsList={pricingComponentsList}/>
    </TabPanel>);
  }
  return null;
};

export default SubscriptionManageTabPanel;
