import {
    ApiAccount,
    ApiInvoice,
    ApiPaymentMethod,
    ApiSubscription,
    CheckoutResponse,
    PauseSubscriptionData,
    PortalLoginParams,
    PortalLoginResponse,
    PostCheckoutData,
    PutAccountData,
    ResumeSubscriptionData,
    UpgradeSubscriptionData,
    UpgradeSubscriptionResponse
} from "./types";
import {AxiosResponse} from "axios";
import {api} from "./index";

export const apiPortalLogin = (params: PortalLoginParams): Promise<AxiosResponse<PortalLoginResponse>> => {
  return api.post('portal/login', {}, {params});
}

export const apiGetAccountId = (): Promise<AxiosResponse<string>> => {
  return api.get('portal/test/id');
}

export const apiGetPaymentMethods = (): Promise<AxiosResponse<Array<ApiPaymentMethod>>> => {
  return api.get('portal/payment-method');
}

export const apiGetSubscriptions = (): Promise<AxiosResponse<Array<ApiSubscription>>> => {
  return api.get('portal/subscription');
}

export const apiGetPayments = (): Promise<AxiosResponse<Array<ApiInvoice>>> => {
  return api.get('portal/invoice');
}

export const apiGetAccount = (): Promise<AxiosResponse<ApiAccount>> => {
  return api.get('portal/account');
}

export const apiEditAccount = (data: PutAccountData): Promise<AxiosResponse<ApiAccount>> => {
  if (data.billingAddress) data.billingAddress.primaryAddress = true;
  if (data.shippingAddress) data.shippingAddress.primaryShippingAddress = true;

  return api.patch('portal/account', data);
}


export const apiUpgradeSubscription = (subscriptionId: string, data: UpgradeSubscriptionData): Promise<AxiosResponse<UpgradeSubscriptionResponse>> => {
  return api.post(`portal/subscription/${subscriptionId}/values`, data);
}

export const apiPauseSubscription = (subscriptionId: string, data: PauseSubscriptionData): Promise<AxiosResponse<{ id: string }>> => {
  return api.post(`portal/subscription/${subscriptionId}/pause`, data);
}

export const apiResumeSubscription = (subscriptionId: string, data?: ResumeSubscriptionData): Promise<AxiosResponse<{ id: string }>> => {
  return api.post(`portal/subscription/${subscriptionId}/resume`, data);
}

export const apiCancelSubscription = (subscriptionId: string): Promise<AxiosResponse<{ id: string }>> => {
  return api.post(`portal/subscription/${subscriptionId}/cancel`);
}

export const apiRetryPayment = (paymentId: string): Promise<AxiosResponse<ApiInvoice>> => {
  return api.post(`portal/invoice/${paymentId}/pay`);
}

export const apiGetInvoicePdf = (paymentId: string): Promise<AxiosResponse<ArrayBuffer>> => {
  return api.get(`portal/invoice/${paymentId}/pdf`, {responseType: "arraybuffer"});
}

export const apiSetDefaultPaymentMethod = (paymentMethodId: string): Promise<AxiosResponse<ApiPaymentMethod>> => {
  return api.put('portal/account/payment-method', {paymentMethodId});
}

export const apiCheckoutPortalUser = (data: PostCheckoutData): Promise<AxiosResponse<CheckoutResponse>> => {
  return api.post('portal/checkout', data);
}

export const apiChangePassword = (newPassword: string): Promise<AxiosResponse<undefined>> => {
  return api.post('portal/account/change-password', {newPassword});
}
