import React from "react";

import './PricingComponentList.scss';

import {RootState} from "../store";
import {connect, ConnectedProps} from "react-redux";
import {StandalonePricingComponent} from "../api/types";
import PricingComponentQuantityInput from "./PricingComponentQuantityInput";

const mapState = (state: RootState) => ({
});

const mapDispatch = {
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
  pricing: Array<StandalonePricingComponent>,
  updateQuantity: (componentName: string, newQuantity: number) => Promise<boolean>
}

type State = {}

class PricingComponentList extends React.Component<Props, State> {
  render () {
    return (
      <div className="pricing-component-list">
        <table>
          <tbody>
          {this.props.pricing.map(pricingComponent =>
            <React.Fragment key={pricingComponent.name}><tr><td>
              <PricingComponentQuantityInput
                pricingComponent={pricingComponent}
                updateQuantity={(newQuantity: number) => this.props.updateQuantity(pricingComponent.name, newQuantity)}
                key={pricingComponent.name}
                label={pricingComponent.displayName ?? pricingComponent.name}
              />
            </td></tr></React.Fragment>)}
          </tbody>
        </table>
      </div>
    )
  }
}

export default connector(PricingComponentList);
