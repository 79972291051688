// State

import {Plan, Product} from "../../shared/plan/types";
import {BfApiPlan, CompositeQuote} from "../../../api/types";

export interface PlanState {
  flexcheckPlans: Array<Plan>;
  billforwardPlans: Array<BfApiPlan>;
  billforwardProducts: Array<Product>;
  selectedPlanToAdd: BfApiPlan;
}

interface UpdatePlanHiddenPayload {
  bfId: string,
  isHidden: boolean
}

// Actions
export const UPDATE_PLAN_HIDDEN = 'UPDATE_PLAN_HIDDEN';
export const UPDATE_FLEXCHECK_PLANS = 'UPDATE_FLEXCHECK_PLANS';
export const UPDATE_BILLFORWARD_PLANS = 'UPDATE_BILLFORWARD_PLANS';
export const UPDATE_SELECTED_PLAN_TO_ADD = 'UPDATE_SELECTED_PLAN_TO_ADD';
export const UPDATE_ONE_BILLFORWARD_PLAN = "UPDATE_ONE_BILLFORWARD_PLAN";

interface UpdatePlanHiddenAction {
  type: typeof UPDATE_PLAN_HIDDEN;
  payload: UpdatePlanHiddenPayload;
}

interface UpdateFlexcheckPlansAction {
  type: typeof UPDATE_FLEXCHECK_PLANS;
  payload: Array<Plan>;
}

interface UpdateBillforwardPlansAction {
  type: typeof UPDATE_BILLFORWARD_PLANS;
  payload: Array<BfApiPlan>;
}

interface UpdateSelectedPlanToAddAction {
  type: typeof UPDATE_SELECTED_PLAN_TO_ADD;
  payload: BfApiPlan;
}

interface UpdateOneBillforwardPlan {
  type: typeof UPDATE_ONE_BILLFORWARD_PLAN;
  payload: CompositeQuote;
  planId: string;
}

export type PlanActionTypes = UpdatePlanHiddenAction | UpdateFlexcheckPlansAction | UpdateBillforwardPlansAction | UpdateSelectedPlanToAddAction | UpdateOneBillforwardPlan;
