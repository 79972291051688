import React, { FC } from "react";
import { useSelector } from "react-redux";
import { Icon } from "./shoelace";
import PlanGroupCardSkeleton from "./PlanGroupCardSkeleton";
import { selectAvailablePlans } from "../store/user/plan/selectors";
import { selectBonusPlanCard } from "../store/storefront/selectors";
import { selectPlanGroups } from "../store/user/planGroup/selectors";
import PlanGroupCard from "./PlanGroupCard";

import styles from "./PlanGroupGrid.module.scss";
import {isPlanInGroup} from "../utils/utils";

interface Props {
  error?: boolean;
  loading: boolean;
}

const PlanGroupGrid: FC<Props> = ({ loading, error }) => {
  const availablePlans = useSelector(selectAvailablePlans);
  const planGroups = useSelector(selectPlanGroups);
  const bonusPlanCard = useSelector(selectBonusPlanCard);

  if (loading) {
    return (
      <div className={styles["plans-grid-container"]}>
        <div className={styles["plans-grid"]}>
          {[0, 1, 2, 3, 4, 5].map((key) => (
            <PlanGroupCardSkeleton key={key} />
          ))}
        </div>
      </div>
    );
  }

  if (error || planGroups.length === 0) {
    return (
      <div className="plans-grid plans-grid-error">
        <Icon className="error-icon" name="exclamation-circle-fill" />
        {error ? (
          <span className="error-message">
            There was a problem fetching the available plans. Please try again
            later.
          </span>
        ) : (
          <span className="error-message">
            {availablePlans.length === 0
              ? "No plans are available to purchase."
              : "No plans are available for this currency."}
          </span>
        )}
      </div>
    );
  }

  return (
    <div className={styles["plans-grid-container"]}>
      <div className={styles["plans-grid"]}>
        {planGroups
          .filter((planGroup) =>
            availablePlans.find((plan) => isPlanInGroup(plan, planGroup))
          )
          .map((planGroup) => (
            <PlanGroupCard
              key={planGroup.bfPlanPathPrefix}
              planGroup={planGroup}
            />
          ))}
      </div>
      {bonusPlanCard}
    </div>
  );
};

export default PlanGroupGrid;
