import axios, {AxiosResponse} from 'axios';
import {
  ApiPlan,
  BfCreateOrgsResponse,
  CartResponse,
  CheckoutResponse,
  GatewayResponse,
  GetCustomFieldsResponse,
  GetPlansParams,
  GetQuoteData,
  GetUpgradeQuoteData,
  MerchantLoginParams,
  PostCartData,
  PostCheckoutData,
  PostCreateOrgsData,
  PutSquareConfigData,
  PutStorefrontData,
  ResetPasswordData,
  SquareConfig,
  SquareLocation,
  UploadImageData
} from "./types";
import {addTrailingSlash, readCookie} from "../utils/utils";
import {Storefront} from "../store/storefront/types";
import {store} from "../index";
import {PlanGroup} from '../store/user/planGroup/types'

//TODO: Separate the functions in the api directory into files by semantics (plans, cart, auth, etc.)

const apiUrl = addTrailingSlash(window.REACT_APP_FLEXCHECK_API_URL);
export const api = axios.create({
  baseURL: apiUrl,
  withCredentials: true,
  paramsSerializer: {
    indexes: null
  }
});

// Automatically add bearer token to portal requests
api.interceptors.request.use(config => {
  if (config.url?.includes('portal')) {
    const token = store.getState().main.user.auth.portalTokenData?.access_token;
    config.headers.Authorization = token ? `Bearer ${token}` : '';
  }
  return config;
}, error => Promise.reject(error))

// If we get a 403 due to missing XSRF token, manually add the token to the header and retry the request
api.interceptors.response.use(config => {
  return config;
}, error => {
  if (error.response.status === 403) {
    const cookie = readCookie('XSRF-TOKEN');
    api.defaults.headers['X-XSRF-TOKEN'] = cookie;
    return axios.request({...error.config, headers: {...error.config.headers, 'X-XSRF-TOKEN': cookie}}).then(response => {
      return response;
    }, error2 => {
      return Promise.reject(error2);
    });
  }
  return Promise.reject(error);
});

// Boilerplate for generating fetch functions with predefined baseUrl, method, and cors=true
const fetchCors = (baseUrl: string | undefined, method: string) => (endpoint: string, init?: RequestInit | undefined): Promise<Response> =>
  fetch(`${addTrailingSlash(baseUrl)}${endpoint}`, {...init, method: method, mode: "cors"})
    .then(response => {
      return response.ok ? Promise.resolve(response) : Promise.reject(response);
    }, error => {
      return Promise.reject(error);
    })

export const bfApi = {
  get: fetchCors(window.REACT_APP_BFJS_API_URL, "GET"),
  post: fetchCors(window.REACT_APP_BFJS_API_URL,"POST")
}

/* We want to set a "storefront=<alias>" query param for most API calls.
 * The only place we don't want this is in the merchant app, where the storefront is obtained from the merchant's login info.
 * The default param is only set in StorefrontFetchWrapper.tsx, which is used when rendering all user & portal pages.
 * For the other pages, we reset the default params when rendering (see App.tsx)
*/
export const setDefaultAliasParam = (alias: string) => {
  api.defaults.params = {storefront: alias};
}
export const resetDefaultParams = () => {
  api.defaults.params = {};
}

export const filterCoupons = (coupons?: Array<string | null>): Array<string> => {
  if (coupons == null) {
    return [];
  }
  return coupons.flatMap(c => c === null || c === undefined || c.trim() === "" ? [] : [c]);
}

// Endpoints

export const apiGetPlans = (params: GetPlansParams): Promise<AxiosResponse<Array<ApiPlan>>> => {
  return api.get('plan', {
    params: {...params, coupon: params.restrictingCoupon}
  });
}

export const apiGetPlanGroup = (): Promise<AxiosResponse<PlanGroup[]>> => {
  return api.get('planGroup')
}

export const apiGetPlanGroupByPath = (planGroupPath: string): Promise<AxiosResponse<PlanGroup>> => {
  return api.get(`planGroup/${planGroupPath}`)
}

export const apiGetQuote = (data: GetQuoteData | GetUpgradeQuoteData): Promise<AxiosResponse> => {
  return api.post(
    'quote',
    {...data,
      coupons: filterCoupons(data.coupons),
      quantities: data.quantities?.map(component => {
        return {
          pricingComponent: component.name,
          quantity: component.chosenQuantity
        }
      })
    }
  );
}

export const apiCheckout = (customerId: string, data: PostCheckoutData): Promise<AxiosResponse<CheckoutResponse>> => {
  if (!customerId) {
    return Promise.reject('Missing Customer ID');
  }
  const path = `customer/${customerId}/checkout`;
  return api.post(path, data);
}

export const apiCheckSubscription = (customerId: string, subscriptionId: string): Promise<AxiosResponse<CheckoutResponse>> => {
  if (!customerId) {
    return Promise.reject('Missing Customer ID');
  }
  const path = `customer/${customerId}/subscription/${subscriptionId}`;
  return api.get(path);
}


export const apiCreateBfOrg = (customerId: string, data: PostCreateOrgsData): Promise<AxiosResponse<BfCreateOrgsResponse>> => {
  if (!customerId) {
    return Promise.reject('Missing Customer ID');
  }
  return api.post(`customer/${customerId}/createOrgs`, data);
}

export const apiGetStorefront = (alias: string | undefined = undefined): Promise<AxiosResponse<Storefront>> => {
  return api.get('storefront', {params: {storefront: alias}});
}

export const apiPutStorefront = (data: PutStorefrontData): Promise<AxiosResponse<Storefront>> => {
  return api.put('storefront', data);
}

export const apiLogin = (params: MerchantLoginParams): Promise<AxiosResponse> => {
  return api.post('user/login', {}, {params});
}

export const apiLogout = (): Promise<AxiosResponse> => {
  return api.post('user/logout');
}

export const apiSuggestAlias = (input: string): Promise<AxiosResponse<string>> => {
  return api.get('storefront/suggestAlias', {params: {input}});
}

export const apiUploadImage = (data: UploadImageData): Promise<AxiosResponse<string>> => {
  const formData = new FormData();
  formData.append("file", data.image);
  formData.append("name", data.name);
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }
  return api.post('file/uploadImage', formData, config);
}

export const apiGetAvailableGateways = (): Promise<AxiosResponse<GatewayResponse>> => {
  return api.get('gateway');
}

export const apiGetSquareConfig = (alias?: string): Promise<AxiosResponse<SquareConfig>> => {
  return alias
    ? api.get('gateway/square', {params: {storefront: alias}})
    : api.get('gateway/square')
}

export const apiPutSquareConfig = (data: PutSquareConfigData): Promise<AxiosResponse<SquareConfig>> => {
  return api.put('gateway/square', data);
}

export const apiGetSquareLocations = (): Promise<AxiosResponse<Array<SquareLocation>>> => {
  return api.get('gateway/square/locations');
}

export const apiGetCustomFields = (alias?: string): Promise<AxiosResponse<GetCustomFieldsResponse>> => {
  return alias
    ? api.get('custom-field', {params: {storefront: alias}})
    : api.get('custom-field')
}

export const apiRequestPasswordReset = (email: string): Promise<AxiosResponse<undefined>> => {
  return api.post('customer/password/request-reset', {email});
}

export const apiResetPassword = (data: ResetPasswordData): Promise<AxiosResponse<undefined>> => {
  return api.post('customer/password/reset-change', data);
}

export const apiCreateCart = (data: PostCartData): Promise<AxiosResponse<CartResponse>> => {
  return api.post('cart', data);
}

export const apiReplaceCart = (cartId: string, data: PostCartData): Promise<AxiosResponse<CartResponse>> => {
  return api.post(`cart/${cartId}`, data);
}

export const apiGetCart = (cartId: string): Promise<AxiosResponse<CartResponse>> => {
  return api.get(`cart/${cartId}`);
}

export const apiSendError = (error: any) => {
  //Ignore response as the client doesn't care if the error is received
  // noinspection JSIgnoredPromiseFromCall
  api.post('error', error);
}
