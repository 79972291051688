import React from "react";
import {Spinner} from "../shoelace";

import './SpinnerPage.scss';

const SpinnerPage = () => {
  return (
    <div className="spinner-page">
      <Spinner className="custom"></Spinner>
    </div>
  );
}

export default SpinnerPage;
