import React, { FC } from "react";
import LoginHeader from "../LoginHeader";
import { Button } from "../shoelace";

import styles from "./PageNotFound.module.scss";

interface Props {
  goToLogin: () => void;
}

const PageNotFound: FC<Props> = ({ goToLogin }) => {
  return (
    <div className="user-container">
      <LoginHeader />
      <div className="page">
        <div className={styles['page-not-found']}>
          <div>
            <span>Sorry, the page you are looking for does not exist.</span>
          </div>
          <Button className="primary" onClick={goToLogin}>
            Go To Login
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
