import {AuthActionTypes, MERCHANT_LOGIN, MERCHANT_LOGOUT, PORTAL_LOGIN, PORTAL_LOGOUT} from "./types";
import {PortalLoginResponse} from "../../../api/types";

export function merchantLogin(): AuthActionTypes {
  return {
    type: MERCHANT_LOGIN
  }
}

export function merchantLogout(): AuthActionTypes {
  return {
    type: MERCHANT_LOGOUT
  }
}

export function portalLogin(storefrontAlias: string, tokenData: PortalLoginResponse): AuthActionTypes {
  return {
    type: PORTAL_LOGIN,
    payload: {
      storefrontAlias,
      tokenData
    }
  }
}

export function portalLogout(): AuthActionTypes {
  return {
    type: PORTAL_LOGOUT
  }
}
