import {RootState} from "..";

export const selectStorefront = (state: RootState) => state.main.storefront.storefront

export const selectBfAccountId = (state: RootState) => state.main.user.userDetails.bfAccountId

export const selectStorefrontAlias = (state: RootState) =>
  state.main.storefront.storefront.alias;

export const selectStorefrontAllowCart = (state: RootState) =>
  state.main.storefront.storefront.allowCart;

  export const selectStorefrontAllowCoupons = (state: RootState) =>
  state.main.storefront.storefront.allowCoupons;

export const selectBonusPlanCard = (state: RootState) =>
  state.main.storefront.uiOptions.bonusPlanCard;

export const selectStorefrontTrading = (state: RootState) => state.main.storefront.storefront.trading

export const selectSquareConfig = (state: RootState) => state.main.storefront.squareConfig
