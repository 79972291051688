// Checkout a customer by calling one endpoint per plan
import {KeyedPromiseRequests, PostCheckoutData} from "./types";
import {chainPromisesAndGetResponses, sleep} from "./chainPromises";
import {apiCheckout, apiCheckSubscription} from "./index";
import {apiCheckoutPortalUser} from "./portalEndpoints";

interface ErrorType {
  message: string;
  code: string;
  status: number;
}

export const checkoutIndividualPlans = (checkoutRequests: KeyedPromiseRequests<PostCheckoutData>,
                                        customerId: string,
                                        successCallback: (key: string) => void) => {
  return chainPromisesAndGetResponses(
      checkoutRequests.map(request => ({
        key: request.key,
        func: async () => {
          try {
            return await apiCheckout(customerId, request.body);
          } catch (error: any) {
            if (error.response.status === 402) {
              const subscriptionId = (error.response.data as ErrorType).message;
              let tried = 0;
              while (tried < 100) {
                await sleep(100 + (tried * 150));

                try {
                  return await apiCheckSubscription(customerId, subscriptionId);
                } catch (error2: any) {
                  if (error.response.status !== 402) {
                    throw error2;
                  }
                  tried++;
                }
              }
            }
            throw error;
          }
        }
      })),
      successCallback
  );
}
// Checkout a portal user by calling one endpoint per plan
export const checkoutPortalUserIndividualPlans = (checkoutRequests: KeyedPromiseRequests<PostCheckoutData>,
                                                  successCallback: (key: string) => void) => {
  return chainPromisesAndGetResponses(
      checkoutRequests.map(request => ({
        key: request.key,
        func: () => apiCheckoutPortalUser(request.body)
      })),
      successCallback
  );
}
