import React, {FC, ReactNode, useEffect, useState} from "react";
import {Button, Details, Icon} from "./shoelace";
import {CustomizedPlan, CustomizedPricingComponent, Plan,} from "../store/shared/plan/types";
import {useSelector} from "react-redux";
import PriceText from "./PriceText";
import PricingComponentList from "./PricingComponentList";
import {
  customisableComponents,
  findPricingComponentByName,
  planToCustomizedPlan,
  pricingToPricingComponentList,
  upsertCustomizationToPlan,
} from "../store/shared/plan/utils";
import LoadingButton from "./shared/LoadingButton";
import clsx from "clsx";
import {selectBfAccountId, selectStorefrontAlias, selectStorefrontAllowCart,} from "../store/storefront/selectors";
import {selectCouponValue} from "../store/user/coupon/selectors";
import {Link} from "react-router-dom";
import {getGroupPlanPathFromBfPlanPath} from "../utils/utils";

import "./PlanCard.scss";
import {updateQuoteForCustomizedPlan} from "../api/quotes";
import {
  discountedInitialPrice,
  discountedPerPeriodPrice,
  initialPrice,
  initialPriceShownIsDifferentToRecurringPrice,
  perPeriodPrice
} from "../utils/price";

interface Props {
  plan?: Plan;
  title?: string;
  preview?: boolean;
  priceLoading?: boolean;
  showBuyBtn?: boolean;
  showMoreBtn?: boolean;
  btnText?: string;
  children?: ReactNode;
  handleBuy?: (plan: CustomizedPlan) => void;
  handleBtnClick?: () => void
}

const ZERO_WIDTH_SPACE_CHARACTER_CODE = 8203;

const PlanCard: FC<Props> = ({
  title,
  btnText,
  plan,
  preview,
  priceLoading,
  showBuyBtn = true,
  showMoreBtn = false,
  children,
  handleBuy,
  handleBtnClick,
}) => {
  const storefrontAlias = useSelector(selectStorefrontAlias);
  const allowCart = useSelector(selectStorefrontAllowCart);
  const couponCode = useSelector(selectCouponValue);
  const [customizedPlan, setCustomizedPlan] = useState<CustomizedPlan>();
  const bfAccountId = useSelector(selectBfAccountId);
  const [isLoading, setIsLoading] = useState(true);

  const showDetailsByDefault = plan?.showDetailsDefault ?? true;
  const [showDetails, setShowDetails] = useState(showDetailsByDefault);

  // Force state update on initial render and if plan changes
  // to fix bug where plan price is not updating when adding a coupon
  useEffect(() => {
    setCustomizedPlan(planToCustomizedPlan(plan))
    setIsLoading(false)
  }, [plan])

  if (!customizedPlan) return null

  const pricingComponentsList = pricingToPricingComponentList(plan?.pricing);
  const customizablePricingComponentsList = customisableComponents(
    pricingComponentsList
  );
  const updatePricingComponentQuantity = (
    componentName: string,
    newQuantity: number
  ) => {
    let newCustomizedPlan = upsertCustomizationToPlan(customizedPlan, {
      ...findPricingComponentByName(componentName, pricingComponentsList),
      chosenQuantity: newQuantity,
    } as CustomizedPricingComponent);
    setIsLoading(true);
    return updateQuoteForCustomizedPlan(newCustomizedPlan, [couponCode], bfAccountId).then(
      (result) => {
        /*
         * I'm slightly concerned that if the user clicks fast (or the network is slow) then the results of
         * updateQuoteForCustomizedPlan could come back to us in the wrong order, but I think that would be fine
         * because the line below should update the customised plan which will make the quantity the user selected jump
         * back to the latest retrieved value.
         * Not a great experience but at least the customer won't end up buying something they didn't expect.
         */
        setCustomizedPlan(result);
        setIsLoading(false);
        return true;
      },
      () => {
        setIsLoading(false);
        return false;
      }
    );
  };
  const isEnterprise = !plan && title && btnText && handleBtnClick
  const initialPriceDiff = initialPriceShownIsDifferentToRecurringPrice(plan);
  const customizablePricing = customizablePricingComponentsList.length > 0;
  const isPreview = preview;
  // Check plan card text is not just whitespace
  const showMiddleSection = customizablePricing || isEnterprise || (plan?.cardText && (plan.cardText.trim().charCodeAt(0) !== ZERO_WIDTH_SPACE_CHARACTER_CODE))
  const handleSubmit = () => {
    if (handleBtnClick)
      handleBtnClick();
    else if (handleBuy) {
      // I started writing some code to verify that the value shown on screen is the same as the most recently quoted
      // value but that is stored inside the PricingComponentQuantityInput component which is hard to get at and
      // doesn't seem right to me.
      handleBuy(customizedPlan);
    }
  }

  return (
    <article
      className={clsx(
        "plan-card",
        isPreview && "preview",
        !showMiddleSection && "middle-hidden"
      )}
      style={{
        borderTop: `solid ${
          plan?.colour || "var(--plan-card-border-color)"
        } var(--plan-card-top-border-width)`,
      }}
    >
      {plan?.imageUrl && <img src={plan?.imageUrl} alt="" />}
      <div className="content">
        <section className="title-section">
          <h2 className="title">{title || plan?.name}</h2>
          <span className="description">{plan?.description}</span>
        </section>
        {showMiddleSection && (
          <section className="middle-section">
            {customizablePricing && (
              <Details
                className="pricing-component-list-container"
                summary="Customize"
              >
                <PricingComponentList
                  pricing={customizablePricingComponentsList}
                  updateQuantity={updatePricingComponentQuantity}
                />
              </Details>
            )}
            {children ? (
              <>
                {showDetailsByDefault ? null : (
                  <Button
                    caret
                    variant="text"
                    className={clsx(
                      "text flip-caret",
                      showDetails && "open",
                      "details-button"
                    )}
                    onClick={() =>
                      setShowDetails((prevShowDetails) => !prevShowDetails)
                    }
                  >
                    Subscription details
                  </Button>
                )}
                <section
                  className={clsx(
                    "details-section",
                    customizablePricing && "with-customize-pricing",
                    showDetails && "show-details"
                  )}
                >
                  {children}
                </section>
              </>
            ) : null}
          </section>
        )}
        <section
          className={clsx(
            "price-section",
            !showMiddleSection && "middle-hidden"
          )}
        >
          {customizedPlan?.price?.pricePerPeriod === undefined && !title ? (
            <div className="quote-error">
              <Icon name="exclamation-octagon-fill" className="error-icon" />
              <span className="error-text">
                This plan is unavailable for now.
              </span>
            </div>
          ) : (
            <>
            {!isEnterprise && (<div className="price-container">
              {initialPriceDiff && (
                <div className="price-listing">
                  <PriceText
                    oldPrice={initialPrice(customizedPlan)}
                    currentPrice={discountedInitialPrice(customizedPlan)}
                    currency={customizedPlan?.baseCurrency}
                    isLoading={isLoading || priceLoading}
                    staySmall={initialPriceDiff}
                  />
                  <span
                    className={`period ${initialPriceDiff ? "slim" : null}`}
                  >
                    first {customizedPlan?.period}
                  </span>
                </div>
              )}
              <div className="price-listing">
                <PriceText
                  oldPrice={perPeriodPrice(customizedPlan)}
                  currentPrice={discountedPerPeriodPrice(customizedPlan)}
                  currency={customizedPlan?.baseCurrency}
                  isLoading={isLoading || priceLoading}
                  staySmall={initialPriceDiff}
                />
                {plan?.oneOffPayment ? (
                  <div className="empty-period-spacer" />
                ) : (
                  <p className={clsx("period", initialPriceDiff && "slim")}>
                    per {customizedPlan?.period}
                  </p>
                )}
              </div>
            </div>)}
            </>
          )}
          <section className="button-section">
            {showMoreBtn && (
              <Link
                to={`/store/${storefrontAlias}/group/${getGroupPlanPathFromBfPlanPath(
                  plan?.bfPlanPath
                )}`}
              >
                <Button className={!showBuyBtn ? "primary" : "secondary"}>
                  More info
                </Button>
              </Link>
            )}
            {showBuyBtn && (
              <LoadingButton
                className="primary"
                disabled={plan?.disabled || isPreview}
                isLoading={isLoading}
                onSubmit={handleSubmit}
              >
                {btnText || plan?.ctaText || (allowCart ? "Add to cart" : "Buy")}
              </LoadingButton>
            )}
          </section>
        </section>
      </div>
    </article>
  );
};

export default PlanCard;
