import {ResetUserAction} from "../resetUser";

// State

export interface CouponState {
  couponValue: string
}

// Actions

export const UPDATE_COUPON_VALUE = 'UPDATE_COUPON_VALUE';
export const REMOVE_COUPON = 'REMOVE_COUPON';

interface UpdateCouponValueAction {
  type: typeof UPDATE_COUPON_VALUE;
  payload: string;
}

interface RemoveCoupon {
  type: typeof REMOVE_COUPON;
}

export type CouponActionTypes = UpdateCouponValueAction | RemoveCoupon | ResetUserAction;
