import React from "react";

import {RootState} from "../../../store";
import {connect, ConnectedProps} from "react-redux";
import PortalLoginPage from "../../PortalLoginPage";
import PasswordResetRequestForm from "../../PasswordResetRequestForm";
import PasswordResetChangeForm from "../../PasswordResetChangeForm";
import {Alert, Icon} from "../../shoelace";

const mapState = (state: RootState) => ({
});

const mapDispatch = {
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

export type PasswordResetStatus = 'success' | 'error'

interface Props extends PropsFromRedux {
  code?: string
}
interface State {
  status?: PasswordResetStatus,
  statusMessage?: string
}

class PasswordReset extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      status: undefined,
      statusMessage: undefined
    }
  }
  private setStatus = (status: PasswordResetStatus, statusMessage: string) => {
    this.setState({status, statusMessage});
  }

  private getType = (status: PasswordResetStatus) => {
    switch (status) {
      case 'error': return 'danger';
      case "success": return 'success';
    }
  }

  private getIcon = (status: PasswordResetStatus) => {
    switch (status) {
      case 'error':
        return 'exclamation-octagon';
      case 'success':
        return 'check2-circle';
    }
  }

  shouldComponentUpdate(nextProps: Readonly<Props>, nextState: Readonly<State>, nextContext: any): boolean {
    this.forceUpdate(); return true;
  }

  render() {
    return (
      <PortalLoginPage>
        <div className={"login-form"}>
          <div className={"login-form-title"}>Password Reset</div>
          {this.props.code
            ? <>
                <div className={"login-form-text"}>Enter a new password below.</div>
                <PasswordResetChangeForm code={this.props.code} setStatus={this.setStatus}/>
              </>
            : <>
                <div className={"login-form-text"}>Enter the email you signed up with and we’ll send you a link to reset your password.</div>
                <PasswordResetRequestForm setStatus={this.setStatus}/>
              </>
          }
          <div className="reset-status-section">
            {this.state.status
              ? <Alert variant={this.getType(this.state.status)} open>
                  <Icon slot="icon" name={this.getIcon(this.state.status)}/>
                  <div>{this.state.statusMessage}</div>
                </Alert>
              : null
            }
          </div>
        </div>
      </PortalLoginPage>
    );
  }
}

export default connector(PasswordReset);
