import React from "react";

import StepContainer from "../../StepContainer";

import './PaymentMethod.scss';
import {RootState} from "../../../store";
import {connect, ConnectedProps} from "react-redux";
import {useHistory} from "react-router";
import CartSummary from "../../CartSummary";
import PaymentMethodSwitch from "../../payments/PaymentMethodSwitch";

const mapState = (state: RootState) => ({
  currency: state.main.user.plan.currency,
  currentStep: state.main.user.steps.currentStep,
  totalSteps: state.main.user.steps.totalSteps,
  alias: state.main.storefront.storefront.alias,
  paymentGateway: state.main.storefront.storefront.paymentGateway,
  cart: state.main.user.cart.cart,
  bfAccountId: state.main.user.userDetails.bfAccountId,
});

const mapDispatch = {};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
  nextPage: Function
}

const PaymentMethod = (props: Props) => {
  useHistory();

  return (
    <div className="page">
      <StepContainer currentStep={props.currentStep} totalSteps={props.totalSteps} stepTitle={"Your Payment Method"} backLink={`/store/${props.alias}/plans`}>
        <span>You are buying:</span>
        <CartSummary cartToShow={props.cart} showTax={true}/>
        <span>You’ll be given the chance to review your purchase at the end of the checkout process.</span>
        <PaymentMethodSwitch onSuccessfulSubmit={() => props.nextPage()} buttonText="Next"/>
      </StepContainer>
    </div>
  );
}

export default connector(PaymentMethod);
