import {Button, Dropdown, Menu, MenuItem} from "../shoelace";
import React, {FC} from "react";
import clsx from "clsx";
import style from "./ProvinceDropdown.module.scss";
import {isNotEmpty} from "../../utils/validation";

const supportedCountries = ['US', 'CA'] as const;
type Country = typeof supportedCountries[number];

export const isSupportedCountry = (country: string) => supportedCountries.includes(country as Country);

export const asSupportedCountry = (country: string) => country as Country;

const stateMap = new Map<string, string>();
stateMap.set('Alabama', 'AL');
stateMap.set('Alaska', 'AK');
stateMap.set('Arizona', 'AZ');
stateMap.set('Arkansas', 'AR');
// stateMap.set('Armed Forces Ame', 'AA');
// stateMap.set('Armed Forces Eu', 'AE');
// stateMap.set('Armed Forces Pac', 'AP');
stateMap.set('California', 'CA');
stateMap.set('Colorado', 'CO');
stateMap.set('Connecticut', 'CT');
stateMap.set('Delaware', 'DE');
stateMap.set('District of Columbia', 'DC');
stateMap.set('Florida', 'FL');
stateMap.set('Georgia', 'GA');
stateMap.set('Hawaii', 'HI');
stateMap.set('Idaho', 'ID');
stateMap.set('Illinois', 'IL');
stateMap.set('Indiana', 'IN');
stateMap.set('Iowa', 'IA');
stateMap.set('Kansas', 'KS');
stateMap.set('Kentucky', 'KY');
stateMap.set('Louisiana', 'LA');
stateMap.set('Maine', 'ME');
stateMap.set('Maryland', 'MD');
stateMap.set('Massachusetts', 'MA');
stateMap.set('Michigan', 'MI');
stateMap.set('Minnesota', 'MN');
stateMap.set('Mississippi', 'MS');
stateMap.set('Missouri', 'MO');
stateMap.set('Montana', 'MT');
stateMap.set('Nebraska', 'NE');
stateMap.set('Nevada', 'NV');
stateMap.set('New Hampshire', 'NH');
stateMap.set('New Jersey', 'NJ');
stateMap.set('New Mexico', 'NM');
stateMap.set('New York', 'NY');
stateMap.set('North Carolina', 'NC');
stateMap.set('North Dakota', 'ND');
stateMap.set('Ohio', 'OH');
stateMap.set('Oklahoma', 'OK');
stateMap.set('Oregon', 'OR');
stateMap.set('Pennsylvania', 'PA');
stateMap.set('Rhode Island', 'RI');
stateMap.set('South Carolina', 'SC');
stateMap.set('South Dakota', 'SD');
stateMap.set('Tennessee', 'TN');
stateMap.set('Texas', 'TX');
stateMap.set('Utah', 'UT');
stateMap.set('Vermont', 'VT');
stateMap.set('Virginia', 'VA');
stateMap.set('Washington', 'WA');
stateMap.set('West Virginia', 'WV');
stateMap.set('Wisconsin', 'WI');
stateMap.set('Wyoming', 'WY');

const caStateMap = new Map<string, string>();
caStateMap.set('Alberta', 'AB');
caStateMap.set('British Columbia', 'BC');
caStateMap.set('Manitoba', 'MB');
caStateMap.set('New Brunswick', 'NB');
caStateMap.set('Northwest Territories', 'NT');
caStateMap.set('Nova Scotia', 'NS');
caStateMap.set('Nunavut', 'NU');
caStateMap.set('Ontario', 'ON');
caStateMap.set('Prince Edward Island', 'PE');
caStateMap.set('Quebec', 'QC');
caStateMap.set('Saskatchewan', 'SK');
caStateMap.set('Yukon Territory', 'YT');
caStateMap.set('Newfoundland and Labrador', 'NL');

export const provinceNameToCode = new Map(stateMap);
caStateMap.forEach((value, key) => provinceNameToCode.set(key, value));

const provinceNamesSets = {
  "US": new Set(stateMap.keys()),
  "CA": new Set(caStateMap.keys())
};
const provinceNamesSorted = {
  "US": Array.from(stateMap.keys()).sort((a, b) =>
      a.toLowerCase().localeCompare(b.toLowerCase())
  ),
  "CA": Array.from(caStateMap.keys()).sort((a, b) =>
      a.toLowerCase().localeCompare(b.toLowerCase())
  )
};

export const isValidProvinceValue = (country: string, province: string) =>
    isSupportedCountry(country) ?
        provinceNamesSets[country as Country].has(province) :
        isNotEmpty(province);

interface Props {
  country: Country
  province: string
  setProvince: (province: string) => void
}

const ProvinceDropdown: FC<Props> = ({country, province, setProvince}) => {
  return (
      <Dropdown className={clsx('form-input', 'light', style.province)}>
        <Button slot="trigger" caret className="province-button icon">
          {province}
        </Button>
        <Menu>
          {provinceNamesSorted[country]
              .map((prov) => (
                  <MenuItem
                      className="state-menu-item"
                      onClick={() => setProvince(prov)}
                      key={prov}
                  >
                    {prov}
                  </MenuItem>
              ))}
        </Menu>
      </Dropdown>
  );
}

export default ProvinceDropdown
