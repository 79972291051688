import {Currency, Plan, PlanWithoutQuote} from "../store/shared/plan/types";
import {AlertType, ShowAlertActionPayload} from "../store/shared/alert/types";
import React from "react";
import {ApiError} from "../api/types";
import {store} from '../index';
import {StorefrontStyleOptions} from "../store/storefront/types";
import {PlanGroup} from "../store/user/planGroup/types";

export function getCurrencySymbol(currency: Currency): string {
  try {
    return new Intl.NumberFormat("en-US", {style: 'currency', currency})
      .formatToParts(0)
      .filter(part => part.type === 'currency')[0].value;
  } catch {
    return '';
  }
}

export function formatPeriod(periodLength: number, periodName: string): string {
  if (periodLength === 1) {
    // TODO this won't work when we localise Flexcheck
    return periodName.slice(0, -1); // remove 's' if it isn't plural
  } else {
    return `${periodLength} ${periodName}`;
  }
}

export function formatPrice(price: number, currency: Currency, isSentence: boolean = false): string {
  return price === 0
    ? (isSentence ? 'nothing' : 'FREE')
    : new Intl.NumberFormat('en-US', { style: 'currency', currency, minimumFractionDigits: 2}).format(price);
}

export function generateUUID(): string {
  return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
}

export function getProductNameFromPath(path: string) {
  return path.split('/').filter(x => x.length > 0)[0];
}

export const getGroupPlanPathFromBfPlanPath = (bfPlanPath: string = '') => {
  return bfPlanPath.split("/").slice(0, -1).join("/").slice(1);
};

export const isPlanInGroup = (plan: PlanWithoutQuote, planGroup: PlanGroup) => {
  return planGroup.bfPlanPathPrefix.localeCompare(getGroupPlanPathFromBfPlanPath(plan.bfPlanPath),
      undefined, { sensitivity: 'base' }) === 0;
};

export function getPathWithoutProductName(path: string) {
  return path.split('/').filter(x => x.length > 0).slice(1).join('/');
}

// From https://stackoverflow.com/questions/36836011/checking-validity-of-string-literal-union-type-at-runtime
// StringUnion represents a runtime object of a literal union type. This allows for type checks at runtime.
export function StringUnionFunction<UnionType extends string>(...values: UnionType[]) {
  Object.freeze(values);
  const valueSet: Set<string> = new Set(values);

  const guard = (value: string): value is UnionType => {
    return valueSet.has(value);
  };

  const check = (value: string): UnionType => {
    if (!guard(value)) {
      const actual = JSON.stringify(value);
      const expected = values.map(s => JSON.stringify(s)).join(' | ');
      throw new TypeError(`Value '${actual}' is not assignable to type '${expected}'.`);
    }
    return value;
  };

  const unionNamespace = {guard, check, values};
  return Object.freeze(unionNamespace as typeof unionNamespace & {type: UnionType});
}

export type StringUnion = ReturnType<typeof StringUnionFunction>;

export const objectToCssString = (obj: { [s: string]: unknown; } | ArrayLike<unknown>) => {
  return Object.entries(obj).map(([k, v]) => `${k}:${v}`).join(';');
}

export const getStorefrontCss = () => {
  return store.getState().main.storefront.storefront.css
}

export const getUserBackgroundStyle = (overrideUrl?: string) => {
  const url = overrideUrl ||
              store.getState().main.storefront.storefront.backgroundImageUrl ||
              '';
  const options: StorefrontStyleOptions | undefined = store.getState().main.storefront.uiOptions.style;

  if (options?.customShoelacePrimaryColor) {
    document.documentElement.style.setProperty('--sl-color-primary-hue', options.customShoelacePrimaryColor.hue.toString());
    document.documentElement.style.setProperty('--sl-color-primary-saturation', `${options.customShoelacePrimaryColor.saturation}%`);
  }

  return options.generateCustomBackgroundCss(url)
};

export const isOTPFromApiErrorResponse = (response: ApiError): boolean => {
  if (response === undefined || response === null) {
    return false;
  }
  return response.data && response.data.code === 'RequiresOTP';
}

export const isAcceptConditionsFromApiErrorResponse = (response: ApiError): boolean => {
  if (response === undefined || response === null) {
    return false;
  }
  return response.data && response.data.exception.includes('LockedException');
}

export const getAlertFromApiErrorResponse = (response: ApiError | Response, title: string): ShowAlertActionPayload => {
  const fallbackMessage = 'Please fix or try again later.';
  if (response === undefined || response === null) {
    return {
      type: AlertType.Error,
      message: [
        <b>{title}</b>,
        <br/>,
        fallbackMessage
      ]
    }
  }
  return {
    type: AlertType.Error,
    message: [
      <b>{title}</b>,
      <br/>,
      'data' in response
        ? response.data?.message || fallbackMessage
        : response.statusText || fallbackMessage
    ]
  }
}

export const getBasicAlert = (title: string, message: string = ''): ShowAlertActionPayload => {
  return {
    type: AlertType.Error,
    message: [
      <b>{title}</b>,
      <br/>,
      message
    ]
  }
}

export const getBasicWarning = (title: string, message: string = ''): ShowAlertActionPayload => {
  return {
    type: AlertType.Warning,
    message: [
      <b>{title}</b>,
      <br/>,
      message
    ]
  }
}

export function addTrailingSlash(url: string | undefined) {
  return url?.slice(-1) === '/' ? url : `${url}/`;
}

export const readCookie = (name: string) => {
  const nameEQ: string = name + "=";
  const ca = document.cookie.split(';');
  for(let i=0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ')
      c = c.substring(1,c.length);
    if (c.indexOf(nameEQ) === 0)
      return c.substring(nameEQ.length,c.length);
  }
  return null;
}

export const getAvailableCurrencies = (plans: Array<Plan>): Array<Currency> => {
  return Array.from(new Set(plans.map(plan => plan.baseCurrency)));
}

export const getBfAppUrl = () => {
  if (!window.REACT_APP_BFJS_API_URL?.length) {
    return 'https://app.billforward.net/';
  }
  return addTrailingSlash(window.REACT_APP_BFJS_API_URL);
}

export const camelCaseToSentence = (text: string) => {
  // https://stackoverflow.com/a/7225450
  const result = text.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
}

export const intersection = (data: Array<Array<any>>) => {
  // https://stackoverflow.com/a/51874332
  return data.reduce((a, b) => a.filter(c => b.includes(c)));
}

//TODO: Check we have a valid cookie?
export const portalIsLoggedIn = (storefrontAlias: string, portalStorefrontAliasLoggedInto: string | undefined) =>
  portalStorefrontAliasLoggedInto && storefrontAlias === portalStorefrontAliasLoggedInto;

export const getStoreRoute = (alias: string, group?: string) => {
  const storeRoute = `/store/${alias}`
  return group ? `${storeRoute}/group/${group}` : storeRoute
}
