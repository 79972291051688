import React, {RefObject, UIEvent} from "react";
import ReactMarkdown from "react-markdown";
import gfm from 'remark-gfm';
import clsx from 'clsx'
import {Button, Dialog} from "./shoelace";
import SlDialogElement from "@shoelace-style/shoelace/dist/components/dialog/dialog";

import './TermsAndConditionsDialog.scss';

interface Props {
  className?: string
  markdownText?: string
  src?: string
  name: string
}

class TermsAndConditionsDialog extends React.Component<Props> {
  private readonly dialog: RefObject<SlDialogElement>;

  constructor(props: Props) {
    super(props);
    this.dialog = React.createRef<SlDialogElement>();
  }

  public showDialog = (event: UIEvent) => {
    event.preventDefault();
    this.dialog.current!.show();
  }
  public hideDialog = (event: UIEvent) => {
    event.preventDefault();
    this.dialog.current!.hide();
  }

  render() {
    return (
      <Dialog label="Terms and Conditions" className={clsx("terms-and-conditions-dialog", this.props.className)} ref={this.dialog}>
        {this.props.markdownText ?
            <ReactMarkdown className={this.props.className} plugins={[gfm]} children={this.props.markdownText} linkTarget="_blank" /> :
            <iframe src={this.props.src} title={this.props.name} className={this.props.className} />
        }
        <Button slot="footer" className={`secondary small custom`} onClick={this.hideDialog}>Close</Button>
      </Dialog>
    );
  }
}

export default TermsAndConditionsDialog;
