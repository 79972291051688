import React, {RefObject} from "react";
import {connect, ConnectedProps} from "react-redux";
import {Button, Icon, Spinner} from "./shoelace";

import './ImageUpload.scss';
import {apiUploadImage} from "../api";
import {getAlertFromApiErrorResponse} from "../utils/utils";
import {showAlert} from "../store/shared/alert/actions";
import ImageCropDialog from "./ImageCropDialog";
import {UserImageType} from "./ImageUploadTabs";

const mapDispatch = {
  showAlert: showAlert
};

const connector = connect(null, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
  onSuccess: (url: string) => void,
  fileName?: string,
  initialSrc?: string,
  reloadPreview: () => void,
  setSaveButtonDisabled: (disabled: boolean) => void,
  userImageType: UserImageType
}

type UploadedImage = {
  dataUrl: string,
  file: Blob | null,
  filename: string
}

interface State {
  rawImage: UploadedImage | null,
  croppedImage: UploadedImage | null,
  isLoading: boolean,
  isUploading: boolean,
  fileTooLarge: boolean,
  canUploadImage: boolean,
  isCropping: boolean
}

const maxBytes = (10 * 1024 * 1024); // 10Mb

class ImageUploadFromFile extends React.Component<Props, State> {
  private readonly uploadButtonRef: RefObject<HTMLInputElement>;

  constructor(props: Props) {
    super(props);

    this.uploadButtonRef = React.createRef<HTMLInputElement>();

    this.state = {
      rawImage: null,
      croppedImage: {
        dataUrl: this.props.initialSrc || '',
        file: null,
        filename: this.props.fileName || ''
      },
      isLoading: false,
      isUploading: false,
      fileTooLarge: false,
      canUploadImage: false,
      isCropping: false
    }
  }

  private onUpload = () => {
    this.uploadButtonRef.current!.click();
  }

  private removeFile = () => {
    this.uploadButtonRef.current!.value = "";
    this.setState({croppedImage: null});
    this.props.onSuccess('');
    this.props.reloadPreview();
  }

  // Converts a Blob into a DataURL string. Requires a callback function since the read operation is asynchronous
  private dataUrlFromBlob = (file: Blob, callback: (dataUrl: string) => void) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = (e: unknown) => {
      callback([reader.result as string][0]);
    }
  }

  private saveCroppedImage = (blob: Blob) => {
    this.dataUrlFromBlob(blob, dataUrl => {
      this.setState(state => ({
        croppedImage: {
          dataUrl: dataUrl,
          file: blob,
          filename: state.rawImage?.filename || '' // change
        },
        isCropping: false,
        canUploadImage: true
      }));
    });
  }

  private onCancelCrop = () => {
    this.setState({isCropping: false});
  }

  private onFileChange = () => {
    this.setState({isLoading: true, fileTooLarge: false});
    const files = this.uploadButtonRef.current!.files;
    const file = files ? files[0] : null;

    if (!file) {
      this.setState({isLoading: false});
      this.uploadButtonRef.current!.value = "";
      return;
    } else if (file.size >= maxBytes) {
      this.setState({fileTooLarge: true, isLoading: false});
    } else {
      this.dataUrlFromBlob(file, dataUrl => {
        const rawImage = {
          dataUrl: dataUrl,
          file: file,
          filename: file.name
        };
        this.setState({
          rawImage: rawImage,
          isLoading: false,
          isCropping: true
        });
        this.uploadButtonRef.current!.value = "";
      });
    }
  }

  private onImageError = () => {
    this.setState({
      rawImage: null,
      isLoading: false
    });
  }
  private onImageLoad = () => {
    if (!this.state.canUploadImage || !this.state.croppedImage) return;

    this.setState({isUploading: true});
    this.props.setSaveButtonDisabled(true);
    apiUploadImage({
      image: this.state.croppedImage.file,
      name: this.state.croppedImage.filename.split('.')[0]
    }).then(response => {
      this.props.onSuccess(response.data);
      this.setState({isUploading: false});
      this.props.setSaveButtonDisabled(false);
      this.props.reloadPreview();
    }, error => {
      this.props.showAlert(getAlertFromApiErrorResponse(error.response, 'There was an error uploading your image to the cloud.'));
      this.setState({isUploading: false});
      this.props.setSaveButtonDisabled(false);
    });
  }


  shouldComponentUpdate(nextProps: Readonly<Props>, nextState: Readonly<State>, nextContext: any): boolean {
    this.forceUpdate(); return true;
  }

  private getImageUploadPreviewSectionContent() {
    if (this.state.isUploading)
      return (
          <div className="image-upload-filename">
            <span className="filename-text">
              <Spinner className="filename-spinner"/>
              Uploading...
            </span>
        </div>)

    if (this.state.fileTooLarge)
      return (
          <div className="image-upload-filename">
            <span>
              <Icon name="exclamation-triangle" className="warning-icon"/>
              The file size is too large. (max 10MB)
            </span>
        </div>)

    return (
      <div className="image-upload-filename" hidden={!this.state.croppedImage?.dataUrl}>
        <span className="filename-text">{this.state.croppedImage?.filename}</span>
        {!this.state.croppedImage?.dataUrl ? null :
            <Button className="plan-remove-button error small icon" onClick={this.removeFile}
                    hidden={!this.state.croppedImage?.dataUrl ? true : undefined}>
              <Icon name="x" className="x-icon"/>
            </Button>
        }
      </div>)
  }

  render() {
    return (
        <div className="image-upload-container">
          <div className="image-upload-input">
            <Button className="image-upload-button secondary small" onClick={this.onUpload}
                    disabled={this.state.isLoading || this.state.isUploading}>Upload Image</Button>
            <input
                type="file"
                hidden
                multiple={false}
                onChange={this.onFileChange}
                ref={this.uploadButtonRef}
                accept="image/png,image/svg+xml,image/apng,image/gif,image/jpeg"
            />
          </div>
          <div className="image-upload-preview-section">
            {this.getImageUploadPreviewSectionContent()}
            <div className={`image-upload-preview ${this.state.isLoading ? 'full-height' : ''}`}
              hidden={this.state.croppedImage?.dataUrl == null && !this.state.isUploading}>
              {this.state.isLoading
                ? <div className="image-upload-preview-overlay">
                  <Spinner className="image-upload-preview-spinner"/>
                </div>
                : null
              }
              <img src={this.state.croppedImage?.dataUrl} onError={this.onImageError} onLoad={this.onImageLoad}
                   hidden={!this.state.croppedImage?.dataUrl} alt="Preview"/>
            </div>
          </div>
          <ImageCropDialog
              imageDataUrl={this.state.rawImage?.dataUrl || ''}
              open={this.state.isCropping}
              saveCroppedImage={this.saveCroppedImage}
              onCancelCrop={() => this.onCancelCrop()}
              userImageType={this.props.userImageType}
          />
      </div>
    )
  }
}

export default connector(ImageUploadFromFile);
