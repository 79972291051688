import React, { FC } from "react";
import { Button, Icon } from "./shoelace";
import { ReactComponent as Logo } from "../assets/bf_logo.svg";

import "./MerchantTopBar.scss";

interface Props {
  showMenu: () => void
}

const MerchantTopBar: FC<Props> = ({ showMenu }) => {
  return (
    <div className="merchant-top-bar">
      <div className="merchant-top-bar-header">
        <Logo className="logo" alt="Billforward" />
        <span className="merchant-sidebar-title">Flexcheck</span>
      </div>
      <Button className='merchant-menu-button icon' variant='text' onClick={showMenu}><Icon name='list' /></Button>
    </div>
  );
};

export default MerchantTopBar;
