import {SubscriptionActionTypes, SubscriptionState, UPDATE_SUBSCRIPTIONS} from "./types";

const initialState: SubscriptionState = {
  subscriptions: []
}

export function subscriptionReducer(state: SubscriptionState = initialState, action: SubscriptionActionTypes): SubscriptionState {
  switch (action.type) {
    case UPDATE_SUBSCRIPTIONS:
      return { ...state, subscriptions: action.payload };
    default:
      return state;
  }
}
