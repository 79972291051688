import React, {FC} from "react";
import {formatPrice} from "../utils/utils";
import {Currency} from "../store/shared/plan/types";
import "./PriceText.scss";
import clsx from "clsx";

interface Props {
  oldPrice?: number;
  currentPrice: number;
  currency: Currency;
  isSentence?: boolean;
  isLoading?: boolean;
  staySmall?: boolean;
  rightAlign?: boolean;
}

const PriceText: FC<Props> = ({
  oldPrice,
  currentPrice,
  currency,
  isSentence,
  isLoading,
  staySmall,
  rightAlign,
}) => {
  const isDiscounted = oldPrice !== undefined && oldPrice > currentPrice;

  return (
    <div
      className={clsx(
          "price-text",
          isLoading && "loading",
          rightAlign && "price-right",
          isSentence && 'is-sentence'
      )}
    >
      {isDiscounted ? (
        <p className={clsx("old-price", staySmall && "small")}>
          {formatPrice(oldPrice!!, currency, isSentence)}
        </p>
      ) : null}
      <p>
        {formatPrice(currentPrice, currency, isSentence)}
      </p>
    </div>
  );
};

export default PriceText;
