import React from "react";

import { RootState } from "../store";
import { connect, ConnectedProps } from "react-redux";
import { api } from "../api";

import "./PlanActiveCheckbox.scss";
import { Spinner } from "./shoelace";
import { Plan } from "../store/shared/plan/types";
import { updatePlanHidden } from "../store/merchant/plan/actions";
import { showAlert } from "../store/shared/alert/actions";
import MerchantCheckbox from "./MerchantCheckbox";
import { AlertType } from "../store/shared/alert/types";

const mapState = (state: RootState) => ({
  plans: state.main.merchant.plan.flexcheckPlans,
});

const mapDispatch = {
  updatePlanHidden: updatePlanHidden,
  showAlert: showAlert,
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
  plan: Plan;
}

type State = {
  isLoading: boolean;
};

class PlanActiveCheckbox extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isLoading: false,
    };
  }

  private handleClick = () => {
    let newHidden = !this.props.plan.hidden;
    this.setState({ isLoading: true });
    api
      .put("plan", {
        bfPlanPath: this.props.plan.bfPlanPath,
        hidden: newHidden,
      })
      .then(
        (response) => {
          this.props.updatePlanHidden(
            response.data.definition.id,
            response.data.hidden
          );
          this.setState({ isLoading: false });
        },
        (error) => {
          this.setState({ isLoading: false });
          this.props.showAlert({
            type: AlertType.Error,
            message: [
              `There was an error ${newHidden ? "disabling" : "enabling"} `,
              <b>{this.props.plan.bfPlanPath}</b>,
              ".",
              <br />,
              "Your changes have not been saved.",
            ],
          });
        }
      );
  };

  render() {
    return (
      <div className="plan-active-checkbox-container">
        {this.state.isLoading ? (
          <Spinner className="plan-active-checkbox-spinner" />
        ) : (
          <MerchantCheckbox
            handleClick={this.handleClick}
            active={!this.props.plan.hidden}
          />
        )}
      </div>
    );
  }
}

export default connector(PlanActiveCheckbox);
