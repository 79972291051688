import React from "react";
import FormBase, {BaseState, InputTypeGenerator} from "../shared/FormBase";
import SquarePaymentMethodForm from "./SquarePaymentMethodForm";
import './SquareExtraDetailsForm.scss'
import CountryDropdown from "../shared/CountryDropdown";
import {isNotEmpty} from "../../utils/validation";

interface Props {
  onSuccessfulSubmit: (id: string) => void
}

const typeGenerator = new InputTypeGenerator([
  {name: "city", type: "text"},
  {name: "addressLine1", type: "text"},
  {name: "addressLine2", type: "text"},
  {name: "postcode", type: "text"}
]);
type InputName = typeof typeGenerator.InputName.type;

type State = BaseState<InputName> & {
  country: string
}

class SquareExtraDetailsForm extends FormBase<Props, State, InputName> {
  getInitialState(baseState: BaseState<InputName>): State {
    return {...baseState, country: 'GB'};
  }

  onSubmitValidForm(): boolean {
    return true;
  }

  constructor(props: Props) {
    super(
      props,
      typeGenerator,
      {
        setValid: {
          city: isNotEmpty,
          addressLine1: isNotEmpty,
          addressLine2: (value: string) => true,
          postcode: isNotEmpty
        },
        invalidMessages: {
          city: 'Please enter a city.',
          addressLine1: 'Please enter the first line of your address.',
          addressLine2: 'Please enter the second line of your address.',
          postcode: 'Please enter a valid postcode.'
        },
        initialFormData: {
          city: '',
          addressLine1: '',
          addressLine2: '',
          postcode: ''
        }
      },
    );
  }

  private setCountry = (code: string) => {
    this.setState({country: code});
  }

  render() {
    return (
      <form className={"square-extra-details-form"} ref={this.formRef}>
        <div className="form-section-title">Postal Address</div>
        <div className={"form-section"}>
          {this.getInputComponent('addressLine1', 'Address Line 1')}
          {this.getInputComponent('addressLine2', 'Address Line 2')}
          <div className={"form-pair"}>
            {this.getInputComponent('city', 'City')}
            <div className={"country-dropdown-container"}>
              <span className={"country-label"}>Country</span>
              <CountryDropdown country={this.state.country} setCountry={this.setCountry}/>
            </div>
            {this.getInputComponent('postcode', 'Postcode')}
          </div>
        </div>
        <div className="form-section-title">Billing Details</div>
        <SquarePaymentMethodForm onSuccessfulSubmit={this.props.onSuccessfulSubmit} onSubmitExtraDetails={this.onSubmit} extraDetails={{
          country: this.state.country,
          city: this.state.formData.city,
          addressLine1: this.state.formData.addressLine1,
          addressLine2: this.state.formData.addressLine2,
          postcode: this.state.formData.postcode
        }}/>
      </form>
    )
  }
}

export default SquareExtraDetailsForm;
