import React from "react";

import StepContainer from "../../StepContainer";

import {RootState} from "../../../store";
import {connect, ConnectedProps} from "react-redux";
import {Alert, Icon, Spinner} from "../../shoelace";
import LoadingButton from "../../shared/LoadingButton";

import './Confirm.scss';
import PriceText from "../../PriceText";
import {KeyPlanSuccess} from "../../../store/shared/plan/types";
import CartSummary from "../../CartSummary";
import {CartQuote} from "../../../api/types";
import {AlertType} from "../../../store/shared/alert/types";
import {showAlert} from "../../../store/shared/alert/actions";
import CheckoutStatusDialog from "../../CheckoutStatusDialog";
import {checkoutOne} from "../../../store/user/cart/actions";
import {cartToCartIdMap} from "../../../api/conversions";
import {CartIdMap} from "../../../store/user/cart/types";
import {getQuoteForCart} from "../../../api/quotes";
import {checkoutIndividualPlans, checkoutPortalUserIndividualPlans} from "../../../api/checkoutOneByOne";
import moment, {Moment} from "moment";

const mapState = (state: RootState) => ({
    currency: state.main.user.plan.currency,
    currentStep: state.main.user.steps.currentStep,
    totalSteps: state.main.user.steps.totalSteps,
    customerId: state.main.user.userDetails.customerId,
    bfAccountId: state.main.user.userDetails.bfAccountId,
    userDetails: state.main.user.userDetails.details,
    couponValue: state.main.user.coupon.couponValue,
    cartState: state.main.user.cart,
    cart: state.main.user.cart.cart,
    checkedOut: state.main.user.cart.checkedOut,
    onSuccessfulPurchase: state.main.storefront.uiOptions.onSuccessfulPurchase,
    alias: state.main.storefront.storefront.alias,
    squareConfig: state.main.storefront.squareConfig,
    portalStorefrontAliasLoggedInto: state.main.user.auth.portalStorefrontAliasLoggedInto
});

const mapDispatch = {
    showAlert: showAlert,
    checkoutOne: checkoutOne
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
    nextPage: Function
}

type State = {
    isLoading: boolean,
    isGettingQuote: boolean,
    quote: CartQuote,
    isStatusDialogOpen: boolean,
    checkoutStatuses: Array<KeyPlanSuccess>
}

class Confirm extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isLoading: false,
            isGettingQuote: true,
            quote: {} as CartQuote,
            isStatusDialogOpen: false,
            checkoutStatuses: []
        }
    }

    private handleSuccess = (cartMap: CartIdMap) => (id: string) => {
        const cartLine = cartMap[id];
        return this.props.checkoutOne(cartLine);
    };

    private onConfirm = () => {
        this.setState({isLoading: true});
        const cartIdMap = cartToCartIdMap(this.props.cart);
        const checkoutRequests = Object.keys(cartIdMap).map(key => {
            return { key, body: {
                cartId: this.props.cartState.cartId,
                cartLineId: key,
                paymentMethodId: this.props.portalStorefrontAliasLoggedInto === this.props.alias ? undefined : this.props.cartState.paymentMethodId
            }}
        });

        (this.props.portalStorefrontAliasLoggedInto === this.props.alias
          ? checkoutPortalUserIndividualPlans(checkoutRequests, this.handleSuccess(cartIdMap))
          : checkoutIndividualPlans(checkoutRequests, this.props.customerId, this.handleSuccess(cartIdMap))
        ).then(responses => {

            let cartLinesByStatus: {successes: string[], failures: string[]} = {
                successes: [],
                failures: []
            };

            const checkoutStatuses: Array<KeyPlanSuccess> = []

            for (let key in responses) {
                responses[key].success
                    ? cartLinesByStatus.successes.push(key)
                    : cartLinesByStatus.failures.push(key)

                checkoutStatuses.push({key, plan: cartIdMap[key].plan, success: responses[key].success})
            }

            if (cartLinesByStatus.failures.length === 0) {
                if (this.props.onSuccessfulPurchase) {
                    this.props.onSuccessfulPurchase().then(() => {
                        this.setState({checkoutStatuses: checkoutStatuses, isLoading: false}, () => this.props.nextPage());
                    });
                } else {
                    this.setState({checkoutStatuses: checkoutStatuses, isLoading: false}, () => this.props.nextPage());
                }
            } else {
                this.setState({checkoutStatuses: checkoutStatuses, isLoading: false, isStatusDialogOpen: true});
            }
        })
    }

    componentDidMount() {
        this.setState({isGettingQuote: true});
        // Below should have already happened but in that case this is a no-op so worth us retrying so we can
        //  show the user an error in the case of failure.
        getQuoteForCart(this.props.cart, this.props.bfAccountId).then(response => {
            this.setState({
                isGettingQuote: false,
                quote: response
            });
        }, error => {
            this.props.showAlert({
                type: AlertType.Error,
                message: <b>There was an error calculating your quote.</b>
            });
            this.setState({isGettingQuote: false});
        });
    }

    renderRecurringPaymentMessage(hasImmediatePayment: boolean) {
        if (this.state.quote.isOneOff)
            return null;

        if (this.props.cart.length === 1)
            return (
                <div className="confirm-price-section recurring">
                    <Icon className="price-icon" name="calendar2"/>
                    <div className="confirm-price-sentence">
                        <span>{hasImmediatePayment ? "Then you'll" : "You'll"} be charged</span>
                        <PriceText
                            oldPrice={this.props.cart[0].plan.price.pricePerPeriod}
                            currentPrice={this.props.cart[0].plan.price.discountedPricePerPeriod}
                            currency={this.props.currency}
                            isSentence
                        />
                        {this.props.cart[0].plan.price.taxPerPeriod <= 0 ? null : (
                            <>
                                (including
                                <PriceText
                                    currentPrice={this.props.cart[0].plan.price.taxPerPeriod}
                                    currency={this.props.currency}
                                    isSentence
                                />
                                taxes and fees)
                            </>
                        )}
                        <span className="textual-space-before">every <b>{this.props.cart[0].plan.period}</b>.</span>
                    </div>
                </div>
            )
        else return (
            <div className="confirm-price-section recurring">
                <Icon className="price-icon" name="calendar2"/>
                <div className="confirm-price-sentence">
                    <span>Then you'll be charged an <em>average</em> of </span>
                    <PriceText
                        oldPrice={this.state.quote.recurringAveragePricePerMonth}
                        currentPrice={this.state.quote.discountedRecurringAveragePricePerMonth}
                        currency={this.props.currency}
                        isSentence
                    />
                    {this.state.quote.discountedRecurringAverageTaxPerMonth > 0 ? null : (
                        <>
                            (including
                            <PriceText
                                currentPrice={this.state.quote.discountedRecurringAverageTaxPerMonth}
                                currency={this.props.currency}
                                isSentence
                            />
                            taxes and fees)
                        </>
                    )}
                    <span className="textual-space-before">every month.</span>
                </div>
            </div>
        )
    }

    private isPresentAndNotToday(start?: Moment) {
        if (!start) {
            return false; //missing
        }
        return !start.isBetween(moment().subtract(1, 'days'), moment().add(1, 'days'));
    }

    render() {
        const hasImmediatePayment = this.state.quote.immediatePayment !== null && this.state.quote.immediatePayment > 0
        return (<div className="page">
            <StepContainer currentStep={this.props.currentStep} totalSteps={this.props.totalSteps} stepTitle={"Confirm"}
                           backLink={this.props.portalStorefrontAliasLoggedInto === this.props.alias ? `/store/${this.props.alias}/` : `/store/${this.props.alias}/payment-method`}>
                {this.props.cart.length > 0 ? <>
                    <span>You are buying:</span>
                    <CartSummary showDetails cartToShow={this.props.cart} showTax={true}/>
                </> : null}
                {this.props.checkedOut.length > 0 ? <>
                    <span>You have bought:</span>
                    <CartSummary showDetails cartToShow={this.props.checkedOut} hideViewButton showTax={true}/>
                </> : null}
                {this.state.isGettingQuote ? (
                    <div className="quote-spinner-container">
                        <Spinner className="quote-spinner custom"/>
                    </div>
                ) : (
                    <div>
                        {hasImmediatePayment
                            ? <div className="confirm-price-section initial">
                                <Icon className="price-icon" name="bag-check"/>
                                <div className="confirm-price-sentence">
                                    <span>You'll be charged</span>
                                    <PriceText
                                        currentPrice={this.state.quote.immediatePayment}
                                        currency={this.props.currency}
                                        isSentence
                                    />
                                    {this.state.quote.immediateTax === 0 ? null : (
                                        <>
                                            (including
                                            <PriceText
                                                currentPrice={this.state.quote.immediateTax}
                                                currency={this.props.currency}
                                                isSentence
                                            />
                                            taxes and fees){" "}
                                        </>
                                    )}
                                    <span className="textual-space-before">immediately.</span>
                                </div>
                            </div>
                            : null
                        }
                        {this.renderRecurringPaymentMessage(hasImmediatePayment)}
                        {this.state.quote.isOneOff || this.props.cart.length === 1 ? null :
                            <Alert className="billing-warning" variant="primary" open>
                                <Icon slot="icon" name="info-circle"/>
                                <span><b>You will be billed independently for each plan you are purchasing.</b><br/>Check your cart to see how often you will be billed for each plan.</span>
                            </Alert>
                        }
                        {this.isPresentAndNotToday(this.state.quote.firstRecurringPaymentDate) && !this.state.quote.isOneOff
                            ? <div className="confirm-price-section date">
                                <Icon className="price-icon" name="arrow-repeat"/>
                                <div className="confirm-price-sentence">
                                    <span>Your first recurring payment will be due on <b>{this.state.quote.firstRecurringPaymentDate.format('Do MMMM YYYY')}</b>.</span>
                                </div>
                            </div>
                            : null}
                    </div>
                )}
                <div className="step-container-footer">
                    <LoadingButton onSubmit={this.onConfirm} noCustomStyle disabled={this.state.isGettingQuote}
                                   isLoading={this.state.isLoading}>Confirm Payment</LoadingButton>
                </div>
            </StepContainer>
            <CheckoutStatusDialog
              checkoutStatuses={this.state.checkoutStatuses}
              closeDialog={() => this.setState({isStatusDialogOpen: false})}
              open={this.state.isStatusDialogOpen}
            />
        </div>)
    };
}

export default connector(Confirm);
