import {
  CLOSE_PANEL,
  CLOSE_PANEL_FINISH,
  CLOSE_PANEL_START,
  OPEN_PANEL,
  OPEN_PANEL_FINISH,
  OPEN_PANEL_START,
  PanelActionTypes,
  PanelState,
} from "./types";

const initialState: PanelState = {
  isLoading: false,
  isOpen: false,
  planId: null,
  panelType: null,
};

const panelReducer = (
  state = initialState,
  action: PanelActionTypes
): PanelState => {
  switch (action.type) {
    case OPEN_PANEL_START:
      return {
        ...state,
        isLoading: true,
      };
    case CLOSE_PANEL_START:
      return {
        ...state,
        isLoading: true,
      };
    case OPEN_PANEL:
      return {
        ...state,
        ...action.payload,
        isOpen: true,
      };
    case CLOSE_PANEL:
      return {
        ...state,
        isOpen: false,
      };
    case OPEN_PANEL_FINISH:
      return {
        ...state,
        isLoading: false,
      };
    case CLOSE_PANEL_FINISH:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default panelReducer;
