import React, {ReactElement} from "react";

import {RootState} from "../../store";
import {connect, ConnectedProps} from "react-redux";

import './AlertStack.scss';
import AlertToast from "./AlertToast";
import {Alert} from "../../store/shared/alert/types";
import {removeAlert} from "../../store/shared/alert/actions";

const mapState = (state: RootState) => ({
  lastAlert: state.main.merchant.alert.lastAlert
});

const mapDispatch = {
  removeAlert: removeAlert
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
  merchant?: boolean
}

type State = {
  alerts: AlertCache
}

class AlertCache { // Simple cache for storing alerts. If max length is exceeded, the oldest alert is discarded
  private readonly maxLength: number;
  private alerts: Array<ReactElement> = [];

  constructor(maxLength: number) {
    this.maxLength = maxLength;
  }

  public store = (element: ReactElement) => {
    if(this.alerts.length >= this.maxLength) {
      this.alerts.shift();
    }
    this.alerts.push(element);
  }

  public removeByKey = (key: string) => {
    this.alerts = this.alerts.filter(el => el.key !== key);
  }

  public getAlerts = () => [...this.alerts];
}

class AlertStack extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      alerts: new AlertCache(5)
    };
  }

  private addAlert = (alert: Alert) => {
    let newAlerts = this.state.alerts;
    newAlerts.store(<AlertToast alert={alert} key={alert.id}/>);
    this.setState({alerts: newAlerts});
  }

  //Wouldn't this be better done by shouldComponentUpdate?
  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    // When the showAlert method is dispatched from elsewhere, lastAlertId is changed
    // This component detects the change and adds the alert to the cache
    if (this.props.lastAlert.id && prevProps.lastAlert.id !== this.props.lastAlert.id) {
      this.addAlert(this.props.lastAlert);
      this.props.removeAlert(this.props.lastAlert.id); //Now we've consumed the alert we can remove it from "last alert"
    }
  }

  //Handle any alerts that got fired before this component loads
  componentDidMount() {
    if (this.props.lastAlert.id) {
      this.addAlert(this.props.lastAlert);
      this.props.removeAlert(this.props.lastAlert.id); //Now we've consumed the alert we can remove it from "last alert"
    }
  }

  render() {
    return (
      <div className={`alert-stack ${this.props.merchant ? 'merchant' : ''}`}>
        {this.state.alerts.getAlerts()}
      </div>
    )
  }
}

export default connector(AlertStack);
