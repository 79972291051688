import {Middleware} from "redux";
import {RootState} from "../index";
import {CLOSE_PANEL, CLOSE_PANEL_START, OPEN_PANEL, OPEN_PANEL_START, PanelActionTypes, PanelTypes,} from "./types";
import {closePanel, closePanelFinish, openPanel, openPanelFinish,} from "./actions";
import {getCompositeQuote} from "../../api/quotes";
import {updateOneBillforwardPlanFromQuote} from "../merchant/plan/actions";

export const panelWatcher: Middleware<{}, RootState> =
  (storeApi) => (next) => (action) => {
    const result = next(action);
    const panelAction = action as PanelActionTypes;
    switch (panelAction.type) {
      case OPEN_PANEL_START:
        const planId = panelAction.payload.planId;
        if (planId && panelAction.payload.panelType === PanelTypes.AddPlan) {
          getCompositeQuote({ planId: planId, coupons: [] })
              .then(quote => {
                storeApi.dispatch(
                    updateOneBillforwardPlanFromQuote(quote, planId));
                storeApi.dispatch(openPanel(panelAction.payload));
              });
          //TODO: Show something to let the user know that plan data is loading.
        } else {
          storeApi.dispatch(openPanel(panelAction.payload));
        }
        break;
      case CLOSE_PANEL_START:
        storeApi.dispatch(closePanel());
        break;
      case OPEN_PANEL:
        storeApi.dispatch(openPanelFinish());
        break;
      case CLOSE_PANEL:
        storeApi.dispatch(closePanelFinish());
        break;
    }
    return result;
  };
