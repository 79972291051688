import {NavigationState} from "./types";

const initialState: NavigationState = {
  menuItems: (alias: string) => [
    {
      name: 'Home',
      path: `/store/${alias}/portal`,
      icon: 'house-door-fill'
    },
    {
      name: 'Subscriptions',
      path: `/store/${alias}/portal/subscriptions`,
      icon: 'arrow-repeat'
    },
    {
      name: 'Billing History',
      path: `/store/${alias}/portal/billing-history`,
      icon: 'cash'
    },
    {
      name: 'Account',
      path: `/store/${alias}/portal/account`,
      icon: 'person-circle'
    }
  ]
}

export function navigationReducer(state: NavigationState = initialState): NavigationState {
  return state;
}
