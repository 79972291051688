import React, { FC, ReactNode } from "react";

import "./OptionsPanel.scss";

interface Props {
  title?: string;
  children?: ReactNode;
}

const OptionsPanel: FC<Props> = ({ title, children }) => {
  return (
    <div className="options-panel-container">
      <h3 className="options-panel-title">{title || "Options"}</h3>
      <div className="options-panel">{children}</div>
    </div>
  );
};

export default OptionsPanel;
