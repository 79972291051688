// State

import {SavedPaymentMethod} from "../../shared/user/types";
import {ApiAddress, CustomField} from "../../../api/types";

export type AccountDetails = {
  firstName: string,
  lastName: string,
  email: string,
  customFields: Array<CustomFieldInstance>
}

export type CustomFieldInstance = {
  customField: CustomField
  content?: string
}



export interface AccountState {
  billingAddress?: ApiAddress,
  deliveryAddress?: ApiAddress,
  paymentMethods: Array<SavedPaymentMethod>,
  primaryPaymentMethod?: SavedPaymentMethod,
  accountDetails: AccountDetails
}

// Actions

export const UPDATE_BILLING_ADDRESS = 'UPDATE_BILLING_ADDRESS';
export const UPDATE_DELIVERY_ADDRESS = 'UPDATE_DELIVERY_ADDRESS';
export const UPDATE_PAYMENT_METHODS = 'UPDATE_PAYMENT_METHODS';
export const UPDATE_ACCOUNT_DETAILS = 'UPDATE_ACCOUNT_DETAILS';



interface UpdateBillingAddressAction {
  type: typeof UPDATE_BILLING_ADDRESS;
  payload: ApiAddress;
}

interface UpdateDeliveryAddressAction {
  type: typeof UPDATE_DELIVERY_ADDRESS;
  payload: ApiAddress;
}

interface UpdatePaymentMethodsAction {
  type: typeof UPDATE_PAYMENT_METHODS;
  payload: Array<SavedPaymentMethod>;
}

interface UpdateAccountDetailsAction {
  type: typeof UPDATE_ACCOUNT_DETAILS;
  payload: AccountDetails;
}

export type AccountActionTypes =
  UpdateBillingAddressAction
  | UpdateDeliveryAddressAction
  | UpdatePaymentMethodsAction
  | UpdateAccountDetailsAction;
