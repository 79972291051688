import {NavigationState} from "./types";

const initialState: NavigationState = {
  menuItems: [
    {
      name: 'Plans',
      path: '/merchant/plans',
      icon: 'briefcase-fill'
    },
    {
      name: 'Look & Feel',
      path: '/merchant/look-feel',
      icon: 'easel-fill'
    },
    {
      name: 'Settings',
      path: '/merchant/settings',
      icon: 'gear-fill'
    }
  ]
}

export function navigationReducer(state: NavigationState = initialState): NavigationState {
  return state;
}
