import {
  PlanActionTypes,
  PlanState,
  UPDATE_BILLFORWARD_PLANS,
  UPDATE_FLEXCHECK_PLANS,
  UPDATE_ONE_BILLFORWARD_PLAN,
  UPDATE_PLAN_HIDDEN,
  UPDATE_SELECTED_PLAN_TO_ADD
} from "./types";
import {Plan, Product} from "../../shared/plan/types";
import {BfApiPlan, CompositeQuote} from "../../../api/types";
import {getProductNameFromPath} from "../../../utils/utils";

const initialState: PlanState = {
  flexcheckPlans: [],
  billforwardPlans: [],
  billforwardProducts: [],
  selectedPlanToAdd: {} as BfApiPlan
}

function changeHidden(plans: Array<Plan>, bfId: string, hidden: boolean): Array<Plan> {
  let flexcheckPlans = [...plans];
  let planIndex = flexcheckPlans.findIndex(plan => plan.bfId === bfId);
  if (planIndex >= 0) {
    flexcheckPlans[planIndex].hidden = hidden;
  }
  return flexcheckPlans;
}

function groupPlansByProduct(plans: Array<BfApiPlan>): Array<Product> {
  // Generate array of distinct product names that appear in the plans
  const productNames: Array<string> = Array.from(new Set(plans.map(plan => getProductNameFromPath(plan.path))));
  // Create Product object for each product, containing only the plans belonging to that product
  return productNames.map(product => {
    return {
      name: product,
      plans: plans.filter(plan => getProductNameFromPath(plan.path) === product)
    } as Product;
  });
}

function mergeQuoteInto(billforwardPlans: Array<BfApiPlan>, planId: string, quote: CompositeQuote): Array<BfApiPlan> {
  return billforwardPlans.map(p => {
    if (p.id === planId) {
      return { ...p, quote }
    }
    return p;
  });
}

function findPlan(billforwardPlans: Array<BfApiPlan>, planId: string) {
  return billforwardPlans.find(p => p.id === planId);
}

export function planReducer(state: PlanState = initialState, action: PlanActionTypes): PlanState {
  switch (action.type) {
    case UPDATE_PLAN_HIDDEN:
      return { ...state, flexcheckPlans: changeHidden(state.flexcheckPlans, action.payload.bfId, action.payload.isHidden) };
    case UPDATE_FLEXCHECK_PLANS:
      return { ...state, flexcheckPlans: action.payload.sort((planA, planB) => {return planA.displayIndex-planB.displayIndex}) };
    case UPDATE_BILLFORWARD_PLANS:
      return { ...state, billforwardPlans: action.payload, billforwardProducts: groupPlansByProduct(action.payload) };
    case UPDATE_SELECTED_PLAN_TO_ADD:
      return { ...state, selectedPlanToAdd: action.payload };
    case UPDATE_ONE_BILLFORWARD_PLAN:
      const bfPlans = mergeQuoteInto(state.billforwardPlans, action.planId, action.payload);
      const thePlan = findPlan(bfPlans, action.planId)
          || state.selectedPlanToAdd; //Fallback to not changing the state.
      return { ...state, billforwardPlans: bfPlans, billforwardProducts: groupPlansByProduct(bfPlans), selectedPlanToAdd: thePlan };
    default:
      return state;
  }
}
