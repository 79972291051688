import {CustomizedPlan, Plan} from "../store/shared/plan/types";
import {updatePlanWithQuote} from "../store/shared/plan/utils";
import {store} from "../index";
import {showAlert} from "../store/shared/alert/actions";
import {getAlertFromApiErrorResponse, getBasicAlert} from "../utils/utils";
import {
  ApiPlan,
  CartQuote,
  CompositeQuote,
  CompositeUpgradeQuote,
  GetPlansParams,
  GetQuoteData,
  GetUpgradeQuoteData
} from "./types";
import {apiGetPlans, apiGetQuote, filterCoupons} from "./index";
import {apiPlanToPlan, cartToCartIdMap, cartToQuote} from "./conversions";
import {Cart, CartLine} from "../store/user/cart/types";
import {updateCartFromQuote} from "../store/user/cart/actions";

export const fetchFlexcheckPlans = async (params: GetPlansParams): Promise<Array<Plan>> => {
  let plansResponse = await apiGetPlans(params);
  const plans = plansResponse.data as Array<ApiPlan>;
  return getQuotesForPlans(plans, filterCoupons(params.coupons));
};

export const getCompositeQuote = async (data: GetQuoteData): Promise<CompositeQuote> => {
  const results = await Promise.all([
    apiGetQuote({
      ...data,
      quoteFor: 'InitialPeriod',
      populatePaymentInfo: true
    }),
    apiGetQuote({
      ...data,
      quoteFor: 'RecurringPeriod'
    })
  ]);
  return {
    initialQuote: results[0].data,
    recurringQuote: results[1].data
  };
}

export const getCompositeUpgradeQuote = async (data: GetUpgradeQuoteData): Promise<CompositeUpgradeQuote> => {
  let results = await Promise.all([
    apiGetQuote({
      ...data,
      quoteFor: 'Upgrade'
    }),
    apiGetQuote({
      ...data,
      quoteFor: 'RecurringPeriod'
    })
  ]);
  return {
    upgradeQuote: results[0].data,
    recurringQuote: results[1].data
  } as CompositeUpgradeQuote;
}

const getQuotesForPlans = async (plans: Array<ApiPlan>, couponCodes: Array<string>): Promise<Array<Plan>> => {
  let plansFromQuotes: Array<{ isError: boolean, plan: Plan }> = await Promise.all(plans.map(plan => getCompositeQuote({
    planId: plan.definition.id,
    coupons: couponCodes
  })
      .then(
          quote => ({isError: false, plan: apiPlanToPlan(plan, quote)}),
          error => ({isError: true, plan: apiPlanToPlan(plan, {} as CompositeQuote, true)}))));

  if (plansFromQuotes.find(p => p.isError) !== undefined) {
    store.dispatch(showAlert(getBasicAlert('There was a problem retrieving pricing data for some plans.', 'These plans will be temporarily unavailable for purchase.')));
  }

  return plansFromQuotes.map(p => p.plan);
}

export const updateQuoteForCustomizedPlan = (plan: CustomizedPlan, couponCodes: string[], bfAccountId?: string): Promise<CustomizedPlan> => {
  return getCompositeQuote({
    planId: plan.bfId,
    coupons: couponCodes,
    quantities: plan.customizedPricingComponents,
    accountId: bfAccountId
  }).then(compositeQuote => {
    return updatePlanWithQuote(plan, compositeQuote, bfAccountId) as CustomizedPlan;
  }, error => {
    store.dispatch(showAlert(getAlertFromApiErrorResponse(error.response, `Could not update the price of ${plan.bfPlanPath}.`)));
    return plan;
  })
}

type PlanAndLine = {
  line: CartLine;
  plan: CustomizedPlan;
}

export const getQuoteForCart = async (cart: Cart, bfAccountId: string): Promise<CartQuote> => {
  // First ensure we have fully up-to-date quotes
  const cartPlans = Object.values(cartToCartIdMap(cart)); //I guess this de-duplicates by line id?

  const quotePromises = cartPlans.map(async (cl: CartLine): Promise<PlanAndLine> => {
    // Note that below only checks the Account ID - so if we allow users to update their addresses in a purchase
    //  flow later, then we will need to revisit this logic.
    if (bfAccountId === cl.plan.bfAccountId || !cl.plan.localisedTax) {
      return Promise.resolve({plan: cl.plan, line: cl});
    }
    const result = await updateQuoteForCustomizedPlan(cl.plan, cl.plan.couponCodes, bfAccountId);

    store.dispatch(updateCartFromQuote(cl.id, result));

    return {plan: result, line: cl};
  });

  let results = await Promise.all(quotePromises);

  const updatedCartLines: Cart = results.map(({plan: quote, line: cartLine}): CartLine => {
    return {
      ...cartLine,
      plan: quote
    };
  });
  return cartToQuote(updatedCartLines);
}
