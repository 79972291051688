import React, { FC } from "react";
import MerchantPage from "../../MerchantPage";
import PlansAddList from "../../PlansAddList";

interface Props {
  plansLoading: boolean;
}

const AddPlan: FC<Props> = ({ plansLoading }) => {
  return (
    <MerchantPage
      className="add-plan-page"
      title="Add Plan"
      description="Choose a plan from your Billforward organisation to add to Flexcheck"
    >
      <PlansAddList plansLoading={plansLoading} />
    </MerchantPage>
  );
};

export default AddPlan;
