import {CustomizedPlan} from "../../shared/plan/types";
import {ResetUserAction} from "../resetUser";

// State

export type CartLine = {
  id: string,
  plan: CustomizedPlan
}

export type CartIdMap = {
  [lineId: string]: CartLine
}

export type Cart = Array<CartLine>

export interface CartState {
  cart: Cart;
  checkedOut: Cart;
  cartId: string;
  paymentMethodId?: string;
}

// Actions

export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_ONE = 'REMOVE_ONE';
export const CHECKOUT_ONE = 'CHECKOUT_ONE';
export const CLEAR_CART = 'CLEAR_CART';
export const SUCCESSFUL_PAYMENT_METHOD = 'SUCCESSFUL_PAYMENT_METHOD';
export const LOGGED_IN_PAYMENT_METHOD = 'LOGGED_IN_PAYMENT_METHOD';
export const SET_CART_ID = 'SET_CART_ID';
export const UPDATE_CART_FROM_QUOTE = "UPDATE_CART_FROM_QUOTE";
// These actions will prompt the redux watcher to post the cart update to the server
export const CART_ACTIONS = [ADD_TO_CART, REMOVE_ONE, CLEAR_CART, CHECKOUT_ONE, UPDATE_CART_FROM_QUOTE];

interface CheckoutOneAction {
  type: typeof CHECKOUT_ONE;
  payload: CartLine;
}

interface AddToCartAction {
  type: typeof ADD_TO_CART;
  payload: {
    plan: CustomizedPlan,
    couponCodes: Array<string>
  };
}

interface RemoveOneAction {
  type: typeof REMOVE_ONE;
  payload: string;
}

interface ClearCartAction {
  type: typeof CLEAR_CART;
}

interface SetPaymentMethodAction {
  type: typeof SUCCESSFUL_PAYMENT_METHOD | typeof LOGGED_IN_PAYMENT_METHOD,
  payload: string
}

interface SetCartIdAction {
  type: typeof SET_CART_ID,
  payload: string
}

interface UpdateCartFromQuote {
  type: typeof UPDATE_CART_FROM_QUOTE,
  quote: CustomizedPlan
  id: string
}

export type CartActionTypes =
    AddToCartAction
  | RemoveOneAction
  | ClearCartAction
  | SetPaymentMethodAction
  | SetCartIdAction
  | CheckoutOneAction
  | ResetUserAction
  | UpdateCartFromQuote;
