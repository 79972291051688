import React, {ReactNode, useContext, useState} from "react";
import {Context} from 'react-square-payment-form';

import '../shared/LoadingButton.scss';
import LoadingButton from "../shared/LoadingButton";


interface Props {
  resetHasError: () => void,
  hasError?: boolean,
  disabled?: boolean,
  className?: string,
  children?: ReactNode
}

const SquareLoadingButton = (props: Props) => {
  const squareContext = useContext(Context);

  const [touched, setTouched] = useState(false);
  const onSubmit = () => {
    props.resetHasError();
    setTouched(touched => true);
    squareContext.onCreateNonce();
  }

  let loading = touched && !props.hasError;
  return (
    <LoadingButton onSubmit={onSubmit} isLoading={loading}>{props.children}</LoadingButton>
  )
}

export default SquareLoadingButton;
