import React from "react";
import MerchantPage from "../../MerchantPage";
import PreviewCheckoutPanel from "../../PreviewCheckoutPanel";
import LookAndFeelForm from "../../LookAndFeelForm";

const LookAndFeel = () => {
  const reloadPreview = () => {
    const previewElement: HTMLElement | null =
      document.getElementById("preview-iframe");
    try {
      // @ts-ignore
      previewElement?.contentDocument.location.reload(true);
    } catch {
      console.error("Could not reload iframe element.");
    }
  };

  return (
    <MerchantPage
      title="Look & Feel"
      description="Customise the colours and styles of your Flexcheck checkout"
    >
      <PreviewCheckoutPanel
        reloadPreview={reloadPreview}
      />
      <LookAndFeelForm reloadPreview={reloadPreview} />
    </MerchantPage>
  );
};

export default LookAndFeel;
