import {RootState} from "./store";
import {connect, ConnectedProps, useDispatch} from "react-redux";
import React, {ReactNode, useCallback, useEffect} from "react";

import './PortalApp.scss';
import {Switch, useHistory, useLocation} from "react-router";
import {Redirect, Route} from "react-router-dom";
import {portalLogin, portalLogout} from "./store/persisted/auth/actions";
import {updateStorefront} from "./store/storefront/actions";
import LoginCheckWrapper from "./components/PortalLoginCheckWrapper";
import {showAlert} from "./store/shared/alert/actions";
import Home from "./components/pages/portal/Home";
import PortalTopBar from "./components/PortalTopBar";
import {Subscription} from "./store/portal/subscription/types";
import SubscriptionDetail from "./components/pages/portal/SubscriptionDetail";
import PortalPage from "./components/PortalPage";
import {Payment} from "./store/portal/payment/types";
import PaymentDetail from "./components/pages/portal/PaymentDetail";
import SpinnerPage from "./components/shared/SpinnerPage";
import {resetPlanGroup, updatePlanGroups} from './store/user/planGroup/actions'
import {fetchPlanGroups} from "./api/storefront";
import useQuery from "./hooks/useQuery";

const Subscriptions = React.lazy(() => import("./components/pages/portal/Subscriptions"));
const BillingHistory = React.lazy(() => import("./components/pages/portal/BillingHistory"));
const Account = React.lazy(() => import("./components/pages/portal/Account"));

const mapState = (state: RootState) => ({
  storefront: state.main.storefront.storefront,
  subscriptions: state.main.portal.subscription.subscriptions,
  payments: state.main.portal.payment.payments
});

const mapDispatch = {
  portalLogout: portalLogout,
  updateStorefront: updateStorefront,
  showAlert: showAlert,
  portalLogin: portalLogin,
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
}

const PortalApp = (props: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const query = useQuery();

  const token = query.get("access_token");
  if (token) {
    portalLogin(props.storefront.alias, {access_token: token});
    query.delete("access_token");
  }

  const getSubscriptionFromId = (id: string | undefined): Subscription | undefined => {
    return id === undefined ? undefined : props.subscriptions.find(x => x.id === id);
  }

  const getPaymentFromId = (id: string | undefined): Payment | undefined => {
    return id === undefined ? undefined : props.payments.find(x => x.id === id);
  }

  const handleLogout = () => {
    props.portalLogout();
    history.push(`/store/${props.storefront.alias}/login`);
  }

  const redirectToHome = <Redirect to={{pathname: `/store/${props.storefront.alias}/portal`}}/>

  const lazyLoad = (child: ReactNode) => <React.Suspense fallback={<SpinnerPage/>}>{child}</React.Suspense>

  const loadPlanGroups = useCallback(async () => {
    // try {
      const planGroups = await fetchPlanGroups();
      dispatch(updatePlanGroups(planGroups));
    // } catch (err) {
    //   setError(true);
    // } finally {
    //   setPlanGroupsLoading(false);
    // }
  }, [dispatch]);

  useEffect(() => {
    dispatch(resetPlanGroup());
    loadPlanGroups();
  }, [loadPlanGroups, dispatch]);

  return (
    <div className="portal-container">
      <PortalTopBar currentRoute={useLocation().pathname}/>
      <div className="portal-page-container">
        <LoginCheckWrapper alias={props.storefront.alias}>
          <Switch>
            <Route exact path={`/store/${props.storefront.alias}/portal`} render={() => lazyLoad(<Home/>)}/>
            <Route exact path={`/store/${props.storefront.alias}/portal/subscriptions`} render={() => lazyLoad(<Subscriptions/>)}/>
            <Route path={`/store/${props.storefront.alias}/portal/subscriptions/view/:id`} render={props => {
              const subscription = getSubscriptionFromId(props.match.params.id);
              return subscription
                ? lazyLoad(<SubscriptionDetail subscription={subscription}/>)
                : lazyLoad(<PortalPage><span>No subscription found matching this ID.</span></PortalPage>);
            }}/>
            <Route exact path={`/store/${props.storefront.alias}/portal/billing-history`} render={() => lazyLoad(<BillingHistory/>)}/>
            <Route path={`/store/${props.storefront.alias}/portal/billing-history/view/:id`} render={props => {
              const payment = getPaymentFromId(props.match.params.id);
              return payment
                ? lazyLoad(<PaymentDetail payment={payment}/>)
                : lazyLoad(<PortalPage><span>No payment found matching this ID.</span></PortalPage>);
            }}/>
            <Route exact path={`/store/${props.storefront.alias}/portal/account`} render={() => lazyLoad(<Account handleLogout={handleLogout}/>)}/>
            <Route render={() => {
              return redirectToHome;
            }}/>
          </Switch>
        </LoginCheckWrapper>
      </div>
    </div>
  );
}

export default connector(PortalApp);
