import { PlanGroup, PlanGroupActionTypes, RESET_PLAN_GROUP, UPDATE_PLAN_GROUP, UPDATE_PLAN_GROUPS } from './types'

export function updatePlanGroup(newPlanGroup: PlanGroup): PlanGroupActionTypes {
  return {
    type: UPDATE_PLAN_GROUP,
    payload: newPlanGroup
  }
}

export function resetPlanGroup(): PlanGroupActionTypes {
  return {
    type: RESET_PLAN_GROUP
  }
}

export function updatePlanGroups(newPlanGroups: PlanGroup[]): PlanGroupActionTypes {
  return{
    type: UPDATE_PLAN_GROUPS,
    payload: newPlanGroups
  }
}