import React, {FC, useState} from "react";
import RichTextEditor, {EditorValue} from 'react-rte';
import {Button} from "../shoelace";
import TermsAndConditionsDialog from "../TermsAndConditionsDialog";

import './MarkdownTextArea.scss';

//TODO: Replace with Lexical? https://lexical.dev/docs/packages/lexical-markdown

const toolbarConfig = {
  display: [
    "BLOCK_TYPE_DROPDOWN" as const,
    "INLINE_STYLE_BUTTONS" as const,
    "BLOCK_TYPE_BUTTONS" as const,
    "LINK_BUTTONS" as const,
    "IMAGE_BUTTON" as const,
    "HISTORY_BUTTONS" as const
  ],
  INLINE_STYLE_BUTTONS: [
    {label: 'Bold', style: 'BOLD', className: 'custom-css-class'},
    {label: 'Italic', style: 'ITALIC'}
  ],
  BLOCK_TYPE_DROPDOWN: [
    {label: 'Normal', style: 'unstyled'},
    {label: 'Heading Large', style: 'header-one'},
    {label: 'Heading Medium', style: 'header-two'},
    {label: 'Heading Small', style: 'header-three'}
  ],
  BLOCK_TYPE_BUTTONS: [
    {label: 'UL', style: 'unordered-list-item'},
    {label: 'OL', style: 'ordered-list-item'}
  ],
  extraProps: {
    style: {
    }
  }
}

interface Props  {
  value: string,
  onChange: (markdown: string, initial: boolean) => void,
  tcsPreview?: boolean
}

const MarkdownTextArea: FC<Props> = ({value, onChange, tcsPreview}) => {
  const [state, setState] = useState<EditorValue>(RichTextEditor.createValueFromString(value || '', 'markdown'))

  const dialog = React.useRef<TermsAndConditionsDialog>(null)

  const convertThenOnChange = (content: EditorValue) => {
    setState(content)
    onChange(content.toString('markdown'), content.toString('markdown') === value);
  }
  return (
    <div className="markdown-text-area-container">
      <RichTextEditor
        className="markdown-editor"
        value={state}
        onChange={convertThenOnChange}
        toolbarConfig={toolbarConfig}
      />
      {tcsPreview && (
        <div className="preview-button-container">
          <Button
            className="small secondary"
            onClick={ev => dialog.current!.showDialog(ev)}
          >
            Preview
          </Button>
        </div>
      )}
      {tcsPreview && (
        <TermsAndConditionsDialog
          ref={dialog}
          markdownText={value}
          className='preview'
          name='preview'
        />
      )}
    </div>
  );
}

export default MarkdownTextArea
