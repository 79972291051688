import React, {FC} from "react";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import {Button} from "./shoelace";
import StepContainer from "./StepContainer";
import PlanCardMini from "./PlanCardMini";
import {templatePlan} from "../utils/templates";

import "./PreviewPlanCardPanel.scss";
import "./PreviewCompleteStep.scss";

interface Props {
  content: string;
}

const PreviewCompleteStep: FC<Props> = ({ content }) => {
  return (
    <div className="preview-step-container">
      <StepContainer
        currentStep={4}
        totalSteps={3}
        stepTitle={"Congratulations!"}
      >
        <span>You just bought:</span>
        <PlanCardMini
          plan={templatePlan}
          showDetails={false}
          hidePrice={false}
          showTax={false}
        />
        <div className="complete-message">
          <span>
            Your subscription is registered under the email{" "}
            <b>abc@example.com</b>.
          </span>
        </div>
        <ReactMarkdown plugins={[gfm]} children={content} linkTarget="_blank" />
        <div className="step-container-footer">
          <Button className="submit custom">Continue</Button>
        </div>
      </StepContainer>
    </div>
  );
};

export default PreviewCompleteStep;
