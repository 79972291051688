import stripeLogo from "../../assets/icons/gateways/stripe.ico";
import squareLogo from "../../assets/icons/gateways/square.ico";
import {CustomField, SquareConfig} from "../../api/types";
import {ReactNode} from "react";

// State

export type PlanDisplayType = "ByPlan" | "ByGroup" | "ByPlanWithMoreInfo";

export type StorefrontType = "BfAccountingOrg" | "ApplockerOrg" | "CustomerOrg";

export interface TermOrCondition {
  contents: string; //Contents of popup or URL if starts with http.
  title: string;
  linkText: string;
  name: string;
  required: boolean;
}

export interface Storefront {
  name: string;
  bfOrganizationId: string;
  id: string;
  alias: string;
  created: string;
  updated: string;
  logoUrl?: string;
  backgroundImageUrl?: string;
  paymentGateway: string;
  termsConditions: string | TermOrCondition[];
  purchaseMessage?: string;
  allowCart: boolean;
  allowCoupons: boolean;
  captureCompany: boolean;
  captureAddress: boolean;
  secondaryAddress?: string;
  trading: boolean;
  publicToken: string;
  allowCustomerPortal: boolean;
  confirmEmail: boolean;
  type: StorefrontType;
  css: string;
  displayType: PlanDisplayType;
  showSubscriptionControls: boolean;
  defaultCountry: string;
  disableCountry: boolean;
}

export function termsConditions(sf: Storefront): TermOrCondition[] {
  if (typeof sf.termsConditions === "string") {
    return [
        {
          name: "termsAndConditions",
          required: true,
          contents: sf.termsConditions,
          title: "I have read and agree to the ",
          linkText: "Terms & Conditions"
        }
      ];
  }
  return sf.termsConditions;
}

export type StorefrontStyleOptions = {
  generateCustomBackgroundCss: (
    url: string
  ) => { [s: string]: unknown } | ArrayLike<unknown>; // Generates custom CSS to add to the <body> HTML element. Used primarily for background styling.
  customShoelacePrimaryColor?: {
    // Alters the base theme colour, which affects checkboxes, radio buttons, hyperlinks, etc.
    hue: number;
    saturation: number;
  };
};

export interface StorefrontUiOptions {
  password: {
    validate: (value: string) => boolean; // Function to validate a user-provided password upon password creation
    validationMessage: string; // Message to show upon failed validation - should be an explanation of the requirements
  };
  style: StorefrontStyleOptions;
  bonusPlanCard?: ReactNode; // An additional custom plan card that will be shown on a storefront - usually used for "Enterprise" plans with bespoke pricing
  onSuccessfulPurchase?: () => Promise<any>; // Additional behaviour to be executed between receiving a successful purchase response and continuing to the "Complete" page
  completedPurchase: {
    onComplete?: () => void; // Overrides default behaviour for when customer clicks "Continue" after they complete a purchase
    renderCompletedMessage?: () => ReactNode; // Overrides default message on the "Complete" page under the purchase summary
    fullList: boolean; // Whether to show the full list of purchased items
    showPrice: boolean; // Whether to show the price of each purchased item
  };
}

export type PaymentGateway = {
  name: PaymentGatewayName;
  logo: any;
};
export type PaymentGatewayName = "Stripe" | "Square" | "Shuttle";
type PaymentGatewayDirectory = {
  [name in PaymentGatewayName]: PaymentGateway;
};
export const gatewayDirectory: PaymentGatewayDirectory = {
  Stripe: { name: "Stripe", logo: stripeLogo },
  Square: { name: "Square", logo: squareLogo },
  Shuttle: { name: "Shuttle", logo: null },
};
export type PaymentGatewayStatus = "active" | "inactive" | null;

export interface StorefrontState {
  storefront: Storefront;
  uiOptions: StorefrontUiOptions;
  squareConfig: SquareConfig;
  customFields: Array<CustomField>;
}

// Actions
export const UPDATE_STOREFRONT = "UPDATE_STOREFRONT";
export const UPDATE_SQUARE_CONFIG = "UPDATE_SQUARE_CONFIG";
export const UPDATE_CUSTOM_FIELDS = "UPDATE_CUSTOM_FIELDS";
export const UPDATE_UI_OPTIONS = "UPDATE_UI_OPTIONS";

interface UpdateStorefrontAction {
  type: typeof UPDATE_STOREFRONT;
  payload: Storefront;
}

interface UpdateSquareConfigAction {
  type: typeof UPDATE_SQUARE_CONFIG;
  payload: SquareConfig;
}

interface UpdateCustomFieldsAction {
  type: typeof UPDATE_CUSTOM_FIELDS;
  payload: Array<CustomField>;
}

interface UpdateUiOptionsAction {
  type: typeof UPDATE_UI_OPTIONS;
  payload: StorefrontUiOptions;
}

export type StorefrontActionTypes =
  | UpdateStorefrontAction
  | UpdateSquareConfigAction
  | UpdateCustomFieldsAction
  | UpdateUiOptionsAction;
