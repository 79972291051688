import React from "react";

import {RootState} from "../store";
import {connect, ConnectedProps} from "react-redux";
import {Button} from "./shoelace";
import LoadingButton from "./shared/LoadingButton";
import FormBase, {BaseState, InputTypeGenerator} from "./shared/FormBase";
import {apiResetPassword} from "../api";
import {PasswordResetStatus} from "./pages/portal/PasswordReset";
import {Link} from "react-router-dom";

const mapState = (state: RootState) => ({
  storefrontPasswordOptions: state.main.storefront.uiOptions.password,
  alias: state.main.storefront.storefront.alias
});

const mapDispatch = {
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
  code: string,
  setStatus: (status: PasswordResetStatus, statusMessage: string) => void
}
type State = BaseState<InputName> & {
  isPasswordChanged: boolean
}

const typeGenerator = new InputTypeGenerator([
  {name: "newPassword", type: "text"},
  {name: "confirmNewPassword", type: "text"}
]);
type InputName = typeof typeGenerator.InputName.type;

class PasswordResetRequestForm extends FormBase<Props, State, InputName> {
  getInitialState(baseState: BaseState<InputName>): State {
    return {...baseState, isPasswordChanged: false};
  }

  onSubmitValidForm() {
    this.setState({isLoading: true});
    apiResetPassword({
      code: this.props.code,
      newPassword: this.state.formData.newPassword
    }).then(response => {
      this.setState({isLoading: false, isPasswordChanged: true});
      this.props.setStatus('success', 'Your password has been changed.')
    }, error => {
      this.setState({isLoading: false});
      if (error.response.data.message === "Reset code has expired.") {
        this.props.setStatus('error', 'The request has expired. Please request another password reset email.')
      } else if (error.response.data.message === "Invalid reset code.") {
        this.props.setStatus('error', 'The reset code is invalid. Please request another password reset email.')
      } else {
        this.props.setStatus('error', 'There was a problem trying to change your password.')
      }
    });

    return true;
  }

  constructor(props: Props) {
    super(
      props,
      typeGenerator,
      {
        setValid: {
          newPassword: props.storefrontPasswordOptions.validate,
          confirmNewPassword: (value: string) => value === this.state.formData.newPassword
        },
        invalidMessages: {
          newPassword: props.storefrontPasswordOptions.validationMessage,
          confirmNewPassword: 'The passwords do not match.'
        },
        initialFormData: {
          newPassword: '',
          confirmNewPassword: ''
        }
      },
    );
  }

  render() {
    return (
      <form ref={this.formRef}>
        <div className={"form-section"}>
          {this.getInputComponent('newPassword', 'New Password', {type: 'password', disabled: this.state.isPasswordChanged})}
          {this.getInputComponent('confirmNewPassword', 'Confirm New Password', {type: 'password', disabled: this.state.isPasswordChanged})}
        </div>
        <div className="form-footer">
          {this.state.isPasswordChanged
            ? <Link to={`/store/${this.props.alias}/login`}><Button className="submit custom">go to login</Button></Link>
            : <LoadingButton submit className={"submit custom"} isLoading={this.state.isLoading} onSubmit={this.onSubmit}>Set password</LoadingButton>
          }
        </div>
      </form>
    );
  }
}

export default connector(PasswordResetRequestForm);
