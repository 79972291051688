import { FC, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import PlansGrid from "../../PlansGrid";
import { useDispatch, useSelector } from "react-redux";
import { selectCouponValue } from "../../../store/user/coupon/selectors";
import {
  selectStorefrontAllowCart,
  selectStorefrontAllowCoupons,
} from "../../../store/storefront/selectors";
import { CustomizedPlan } from "../../../store/shared/plan/types";
import { AlertType } from "../../../store/shared/alert/types";
import { addToCart, clearCart } from "../../../store/user/cart/actions";
import { showAlert } from "../../../store/shared/alert/actions";
import CouponPanel from "../../CouponPanel";
import { updatePlanGroup } from "../../../store/user/planGroup/actions";
import { selectPlanGroup } from "../../../store/user/planGroup/selectors";

import "./PlanGroup.scss";
import {selectSource} from "../../../store/persisted/source/selectors";
import {fetchPlanGroup} from "../../../api/storefront";
import PlanCardMarkdown from "../../PlanCardMarkdown";

interface Props {
  goToNextPage: () => void;
}

const PlanGroup: FC<Props> = ({ goToNextPage }) => {
  const { path } = useParams<{ path: string }>();
  const dispatch = useDispatch();
  const planGroup = useSelector(selectPlanGroup);
  const couponCode = useSelector(selectCouponValue);
  const source = useSelector(selectSource);
  const allowCart = useSelector(selectStorefrontAllowCart);
  const allowCoupons = useSelector(selectStorefrontAllowCoupons);
  const [planGroupLoading, setPlanGroupLoading] = useState(true);
  const [error, setError] = useState(false);

  const loadPlanGroup = useCallback(
    async (planGroupPath: string) => {
      try {
        const newPlanGroup = await fetchPlanGroup(planGroupPath);
        dispatch(updatePlanGroup(newPlanGroup));
      } catch (err) {
        setError(true);
      } finally {
        setPlanGroupLoading(false);
      }
    },
    [dispatch]
  );

  const handleAddToCart = (plan: CustomizedPlan) => {
    if (allowCart) {
      dispatch(addToCart(plan, [couponCode, source]));
      dispatch(
        showAlert({
          type: AlertType.Success,
          message: [<b>{plan.name}</b>, " has been added to your cart."],
        })
      );
    } else {
      dispatch(clearCart());
      dispatch(addToCart(plan, [couponCode, source]));
      goToNextPage();
    }
  };

  useEffect(() => {
    loadPlanGroup(path);
  }, [path, loadPlanGroup]);

  if (!planGroupLoading && !planGroup) {
    return (
      <div className="plan-group page">
        <section className="info">
          <h2>No group found with name: {path}</h2>
        </section>
      </div>
    );
  }

  return (
    <div className="plan-group page">
      {planGroup?.heroImg && <img className="plan-group-img" src={planGroup.heroImg} alt={planGroup.bfPlanPathPrefix} />}
      {planGroup?.bfPlanPathPrefix && (
        <section className="info">
          {planGroup.description ?
            <PlanCardMarkdown content={planGroup.description}
                              className="plan-group-markdown" /> :
            <h2>{planGroup.bfPlanPathPrefix}</h2>
          }
        </section>
      )}
      <PlansGrid
        handleAddToCart={handleAddToCart}
        loading={planGroupLoading}
        error={error}
      />
      {allowCoupons && <CouponPanel />}
    </div>
  );
};

export default PlanGroup;
