import React from "react";
import {Input} from "./shoelace";
import {RootState} from "../store";
import {connect, ConnectedProps} from "react-redux";
import {Link} from "react-router-dom";
import LoadingButton from "./shared/LoadingButton";
import FormBase, {BaseState, InputTypeGenerator} from "./shared/FormBase";
import {showAlert} from "../store/shared/alert/actions";
import './LoginForm.scss';
import {portalLogin} from "../store/persisted/auth/actions";
import {isNotEmpty} from "../utils/validation";
import {getAlertFromApiErrorResponse, getBasicAlert} from "../utils/utils";
import {apiPortalLogin} from "../api/portalEndpoints";

const mapState = (state: RootState) => ({
  storefrontAlias: state.main.storefront.storefront.alias,
  userDetails: state.main.user.userDetails.details
});

const mapDispatch = {
  showAlert: showAlert,
  portalLogin: portalLogin
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
  nextPage: Function,
}

const typeGenerator = new InputTypeGenerator([
  {name: "username", type: "text"},
  {name: "password", type: "text"}
]);
type InputName = typeof typeGenerator.InputName.type;

class PortalLoginForm extends FormBase<Props, BaseState<InputName>, InputName> {
  getInitialState(baseState: BaseState<InputName>): BaseState<InputName> {
    return baseState;
  }

  onSubmitValidForm() {
    this.setState({isLoading: true});
    apiPortalLogin({
      username: this.state.formData.username,
      password: this.state.formData.password,
      storefront: this.props.storefrontAlias
    }).then(response => {
      this.props.portalLogin(this.props.storefrontAlias, response.data);
      this.props.nextPage();
    }, error => {
      if (error.response.data.exception?.endsWith("BadCredentialsException")) {
        this.props.showAlert(getBasicAlert('The username or password you entered is incorrect.'));
      } else {
        this.props.showAlert(getAlertFromApiErrorResponse(error, 'There was a problem logging you in.'));
      }
      this.setState({isLoading: false});
      return false;
    })
    return true;
  }

  constructor(props: Props) {
    super(
      props,
      typeGenerator,
      {
        setValid: {
          username: isNotEmpty,
          password: isNotEmpty
        },
        invalidMessages: {
          username: 'Username cannot be blank.',
          password: 'Password cannot be blank.'
        },
        initialFormData: {
          username: props.userDetails.email,
          password: props.userDetails.password
        }
      },
    );
  }

  render() {
    return (
      <form className={"login-form"} ref={this.formRef}>
        <div className={"form-section"}>
          <Input
            className={`form-input light ${this.state.touched['username'] ? 'touched':''}`}
            name={'username'}
            ref={this.inputRefs['username']}
            value={this.state.formData['username']}
            label={'Username'}
            disabled={this.state.isLoading}>
              <div slot="help-text">{this.invalidMessages['username']}</div>
          </Input>
          <Input
            className={`form-input light ${this.state.touched['password'] ? 'touched':''}`}
            name={'password'}
            ref={this.inputRefs['password']}
            value={this.state.formData['password']}
            label={'Password'}
            disabled={this.state.isLoading}
            type={'password'}
            togglePassword>
            <div slot="help-text">{this.invalidMessages['password']}</div>
          </Input>
          <Link to={"/store/" + this.props.storefrontAlias + "/password-reset"} className={"password-reset-link"}>I forgot my password...</Link>
        </div>
        <div className={"form-footer"}>
          <LoadingButton submit noCustomStyle isLoading={this.state.isLoading}>Login</LoadingButton>
        </div>
        {/*<Button>Sign in with Google</Button>*/}
        {/*<Button>Sign in with Facebook</Button>*/}
      </form>
    )
  }
}

export default connector(PortalLoginForm);
